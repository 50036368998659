import React from "react"
import { useTheme, Stack, Link } from "@mui/material"
import Button from "./Button"
import CenterImg from "../assets/icons/mobile_forbidden_icon.png"

export default function MobileForbidden() {
  const theme = useTheme()
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        flexGrow: 1,
        padding: "10vw",
        color: theme.palette.mute.main,
        textAlign: "center",
      }}
    >
      <img src={CenterImg} width="106" height="106" />
      <div
        style={{ fontSize: "24px", fontWeight: 700, margin: "15px 0 10px 0" }}
      >
        Stay tuned
      </div>
      <div>
        Mobile version of the current page is coming soon. Please switch to
        another page.
      </div>
      <Link href="#/swap" sx={{ mt: 2 }} underline="none">
        <Button>Go to Swap</Button>
      </Link>
    </Stack>
  )
}
