import { useTheme, Components } from "@mui/material"

export default function DialogTheme(): Components {
  const theme = useTheme()
  return {
    MuiDialog: {
      defaultProps: {
        BackdropProps: {
          timeout: 500,
        },
        PaperProps: {
          sx: {
            [theme.breakpoints.down("sm")]: {
              width: "100%",
              height: "calc(100vh - 112px)", // header 64, footer 48
              margin: "16px 0 0",
              borderRadius: "16px 16px 0 0",
            },
          },
        },
      },
      styleOverrides: {
        paper: {
          borderRadius: 16,
          backgroundImage: "none",
          border: "unset",
        },
      },
    },
  }
}
