import React from "react";
import { POOLS_MAP, Pool } from '../constants'
import { Grid, Container, Box, Stack, Paper, Typography, IconButton } from "@mui/material"
import DepositRewardsItem from '../components/DepositRewardsItem'

import styles from './DepositRewards.module.scss'

const DepositRewards = () => {
    return (
        <Container
          disableGutters={true}
          maxWidth={false}
          sx={{ 
            maxWidth: 920, 
            marginTop: { xs:"20px", sm:"40px" },
            padding: {xs:"0 16px", sm: 2 }
          }}
        //   className={styles.DepositRewards}
        >
            {
                Object.values(POOLS_MAP).map((item: Pool, index: number) => (
                    <DepositRewardsItem data={item} key={index}/>
                ))
            }
        </Container>
    )
}

export default DepositRewards