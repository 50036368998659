import { ChainId } from "./index"

export const NETWORK_LABEL: Partial<Record<ChainId, string>> = {
  [ChainId.MAINNET]: "Ethereum",
  [ChainId.ARBITRUM]: "Arbitrum",
  [ChainId.OPTIMISM]: "Optimism",
  [ChainId.FANTOM]: "Fantom",
  [ChainId.ROPSTEN]: "Ropsten",
  [ChainId.HARDHAT]: "Hardhat 👷🏼‍♂️",
  [ChainId.KOVAN]: "KOVAN",
  [ChainId.BSCTEST]: "BSCTEST",
  [ChainId.SHIBUYA]: "SHIBUYA",
  [ChainId.ASTAR]: "ASTAR",
}
