import "./ReviewWithdraw.scss"

import { Box, Typography, Divider } from "@mui/material"
import React, { ReactElement } from "react"

// import { AppState } from "../state/index"
import Button from "./Button"
import { GasPrices } from "../state/user"
// import HighPriceImpactConfirmation from "./HighPriceImpactConfirmation"
import { ReviewWithdrawData } from "./WithdrawPage"
import { formatBNToString } from "../utils"
// import { formatSlippageToString } from "../utils/slippage"
// import { isHighPriceImpact } from "../utils/priceImpact"
// import { useSelector } from "react-redux"
// import { useTranslation } from "react-i18next"
import { BigNumber } from "@ethersproject/bignumber"
// import usdsLogo from "../assets/icons/usds-logo.png"
import { ReactComponent as CloseSVG } from "./../assets/icons/close.svg"
import { isCryptoPool } from "../constants"
// import { useGasPrice, GAS_PRECISION } from "../hooks/useGas"
import bellIcon from "../assets/icons/bell.svg"

interface Props {
  onClose: () => void
  onConfirm: () => void
  data: ReviewWithdrawData
  gas: GasPrices
  lpTokenAmount: BigNumber
  lpToken: string
  poolName?: string
  withdrawType?: string
}

function ReviewWithdraw({
  onClose,
  onConfirm,
  data,
  lpTokenAmount,
  lpToken,
  withdrawType,
  poolName,
}: Props): ReactElement {
  // console.log(formatBNToString(lpTokenAmount, 18, 2));
  // const { t } = useTranslation()
  // const gasPrice = useGasPrice()
  // const {
  //   // slippageCustom,
  //   // slippageSelected,
  //   transactionDeadlineSelected,
  //   transactionDeadlineCustom,
  // } = useSelector((state: AppState) => state.user)
  // const [hasConfirmedHighPriceImpact, setHasConfirmedHighPriceImpact] =
  //   useState(false)
  // const isHighSlippageTxn = isHighPriceImpact(data.priceImpact)
  // const deadline = formatDeadlineToNumber(
  //   transactionDeadlineSelected,
  //   transactionDeadlineCustom,
  // )

  // const getLpTokenAmount = (): string => {
  //   let str = formatBNToString(lpTokenAmount, 18)
  //   str = (+str).toFixed(6).toString()
  //   return commify(str)
  // }

  return (
    <div className="reviewWithdraw">
      <Typography variant="h4" align="left" gutterBottom={true}>
        Preview Withdraw
        <span onClick={onClose}>
          <CloseSVG />
        </span>
      </Typography>

      {/* <div>
        <h4>Withdraw token</h4>
        <div className="withdrawInfoItem">
          <div className="lp-name">
            <img src={usdsLogo} width={64} height={64} />
            <span>Siriususd LP</span>
          </div>
          <div className="lp-value">{getLpTokenAmount()}</div>
        </div>
      </div> */}
      {/* <div className="divider"></div> */}
      <div className="table">
        {isCryptoPool(poolName) && withdrawType === "ALL" ? (
          <Box
            mb={2}
            mx={3}
            className="alert"
            display="flex"
            justifyContent="center"
          >
            <img src={bellIcon} />
            <Typography fontSize={12} ml={1}>
              Due to technical limits, we are unable to estimate how many tokens
              you will receive, your funds are safe and you can proceed with
              withdrawal normally, your tokens will deliver once withdrawal tx
              is confirmed.
            </Typography>
          </Box>
        ) : (
          <>
            <Typography
              variant="subtitle2"
              className="rs_token_subtitle"
              sx={{ fontWeight: 700, marginBottom: "15px" }}
            >
              You will receive
            </Typography>

            <div className="tokenList">
              {data.withdraw.map((token, index) => (
                <div className="eachToken" key={index}>
                  <div className="token">
                    <img src={token.icon} alt="icon" width={24} height={24} />
                    <span>{token.symbol}</span>
                  </div>
                  <div className="value">
                    <span className="value">{`${
                      token.value.split(".")[0]
                    }.${token.value.split(".")[1].slice(0, 2)}`}</span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <Box marginY={4}>
          <Divider />
        </Box>
        <Typography
          variant="subtitle2"
          className="rs_token_subtitle"
          sx={{ fontWeight: 700, marginBottom: "15px" }}
        >
          You are withdrawing
        </Typography>
        <div className="withdrawInfoItem">
          <span className="label">{lpToken}</span>
          <span className="value">
            {formatBNToString(lpTokenAmount, 18, 2)}
          </span>
        </div>

        {/* <div className="divider"></div> */}
          {/* <div className="withdrawInfoItem">
            <span className="label">{t("gas")}</span>
            <span className="value">
            {formatBNToString(gasPrice, GAS_PRECISION)} GWEI
            </span>
          </div> */}
        {/* TODO: Create a light API to expose the cached BlockNative gas estimates. */}
        {/* {data.txnGasCost?.valueUSD && (
          <div className="withdrawInfoItem">
            <span className="label">{t("estimatedTxCost")}</span>
            <span className="value">
              {`≈$${commify(formatBNToString(data.txnGasCost.valueUSD, 2, 2))}`}{" "}
            </span>
          </div>
        )} */}
        {/* <div className="withdrawInfoItem">
          <span className="label">{t("maxSlippage")}</span>
          <span className="value">
            {formatSlippageToString(slippageSelected, slippageCustom)}%
          </span>
        </div>
        <div className="withdrawInfoItem">
          <span className="label">{t("deadline")}</span>
          <span className="value">
            {deadline} {t("minutes")}
          </span>
        </div> */}
        {/* <div className="withdrawInfoItem">
          <span className="label">{`${t("rates")}:`}</span>
          <div className="rates value">
            {data.rates.map((rate, index) => (
              <span key={index}>
                1 {rate.name} = ${rate.rate}
              </span>
            ))}
          </div>
        </div> */}
      </div>
      {/* {isHighSlippageTxn && (
        <div className="withdrawInfoItem">
          <HighPriceImpactConfirmation
            checked={hasConfirmedHighPriceImpact}
            onCheck={(): void =>
              setHasConfirmedHighPriceImpact((prevState) => !prevState)
            }
          />
        </div>
      )} */}
      {/* <div className="bottom">
        <p>{t("estimatedOutput")}</p>
        <div className="buttonWrapper">
          <Button
            onClick={onConfirm}
            kind="primary"
            disabled={isHighSlippageTxn && !hasConfirmedHighPriceImpact}
          >
            {t("confirmWithdraw")}
          </Button>
          <Button onClick={onClose} kind="cancel">
            {t("cancel")}
          </Button>
        </div>
      </div> */}

      <Box marginTop={4}>
        <Button
          // disabled={true}
          kind="primary"
          fullWidth={true}
          onClick={onConfirm}
          size="large"
          className="deposit-btn"
        >
          Confirm Withdraw
        </Button>
      </Box>
    </div>
  )
}

export default ReviewWithdraw
