import { useCallback, useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { parseUnits } from "@ethersproject/units"
import { BigNumber } from "@ethersproject/bignumber"
import { EXCH_URL } from "../constants"
import { ChainId } from "../constants/pools/common"
import { useActiveWeb3React } from "."
import { AppState } from "../state"
import { GasPrices } from "../state/user"

const NETWORK_NAME = {
  [ChainId.SHIBUYA]: "shibuya",
  [ChainId.ASTAR]: "astar",
}
export const GAS_PRECISION = 9
export const GAS_VALS = {
  Standard: "2",
  Fast: "3",
  Instant: "4",
}

const convertGas = (val: string) => parseUnits(val, GAS_PRECISION)

type GasResponse = {
  code: number // 200 success
  data: {
    timestamp: number
    slow: string
    average: string
    fast: string
    tip: {
      average: string
      slow: string
      fast: string
    }
    eip1559: {
      priorityFeePerGas: {
        slow: string
        fast: string
        average: string
      }
      baseFeePerGas: string
    }
  }
}

export function useGasPrice() {
  const [gasPrice, setGasPrice] = useState(convertGas(GAS_VALS.Standard))
  const { chainId } = useActiveWeb3React()
  const { gasPriceSelected, gasCustom } = useSelector(
    (state: AppState) => state.user,
  )

  const getData = useCallback(async () => {
    if (gasPriceSelected === GasPrices.Custom)
      return setGasPrice(convertGas(gasCustom?.valueRaw || GAS_VALS.Standard))

    if (!chainId) return
    // @ts-ignore
    const network = NETWORK_NAME[chainId] || "astar"
    try {
      const res = await fetch(`${EXCH_URL}/api/gasnow?network=${network}`)
      if (!res?.body) return
      const result: GasResponse = await res.json()
      if (result?.code !== 200) return
      const data = result.data || {}

      let val = data.average
        ? BigNumber.from(data.average)
        : convertGas(GAS_VALS.Standard)
      switch (gasPriceSelected) {
        case GasPrices.Standard:
          val = data.slow
            ? BigNumber.from(data.slow)
            : convertGas(GAS_VALS.Standard)
          break
        case GasPrices.Fast:
          val = data.average
            ? BigNumber.from(data.average)
            : convertGas(GAS_VALS.Fast)
          break
        case GasPrices.Instant:
          val = data.fast
            ? BigNumber.from(data.fast)
            : convertGas(GAS_VALS.Instant)
          break
      }
      setGasPrice(val)
    } catch (err) {
      console.error("useGasPrice", err)
    }
  }, [chainId])

  useEffect(() => {
    void getData()
  }, [getData])

  return gasPrice
}
