/* eslint-disable */
import { useState, useEffect, useCallback } from "react"
import { Zero } from "@ethersproject/constants"
import { BigNumber } from "@ethersproject/bignumber"
import moment from "moment"
import { formatBNToString } from "../utils"
import { getLastThursday } from "../utils/dateTime"
import {
  PoolName,
  POOLS_MAP,
  STABLECOIN_POOL_NAME,
  VE_TOKEN,
  Token,
} from "../constants"
import {
  useTokenContract,
  useVeTokenContract,
  useFarmsControllerContract,
} from "../hooks/useContract"
import { useActiveWeb3React } from "../hooks"

export type RewardRateListType = {
  name: string
  address: string
  value?: number
}[]
export function useRewardRateList() {
  const [rewardRateList, setRewardRateList] = useState<RewardRateListType>([])
  const _contract = useFarmsControllerContract()
  const { chainId } = useActiveWeb3React()
  const lastThu = getLastThursday().unix()
  const availablePools = { ...POOLS_MAP }

  const getRewardRateList = useCallback(() => {
    if (!chainId) return

    const list: RewardRateListType = []
    Object.values(availablePools).map((item) => {
      const address = item.farmAddresses?.[chainId]
      if (!address) return
      const lpTokenSymbol = item?.lpToken?.symbol
      list.push({
        name: lpTokenSymbol ? `${lpTokenSymbol} Farm` : item.name,
        address,
      })
    })
    if (!list.length) return

    void Promise.all(
      list.map((item) =>
        _contract
          ?.gaugeRelativeWeight(item.address, lastThu)
          .then((res: BigNumber) => {
            item.value = +((+formatBNToString(res, 18) || 0) * 100).toFixed(2)
          }),
      ),
    ).then(() => {
      setRewardRateList(list)
    })
  }, [_contract, chainId])

  useEffect(() => {
    getRewardRateList()
  }, [getRewardRateList])

  return [rewardRateList, getRewardRateList] as [RewardRateListType, () => void]
}

export function useUsedPower(address: string): [number, () => void] {
  const [usedPower, setUsedPower] = useState(0)
  const { account } = useActiveWeb3React()
  const _contract = useFarmsControllerContract()

  const getUsedPower = useCallback(() => {
    if (!account || !address) return void setUsedPower(0)

    _contract?.voteUserSlopes(account, address).then((res: BigNumber[]) => {
      const [slop, power, end] = res
      setUsedPower(
        BigNumber.from(power || Zero)
          .div(100)
          .toNumber(),
      )
    })
  }, [_contract, account, address])

  useEffect(() => {
    getUsedPower()
  }, [getUsedPower])

  return [usedPower, getUsedPower]
}

// get used power per pool
export type useAllUsedPower = { [k in PoolName]: number }
export function useAllUsedPower(): [useAllUsedPower, () => void] {
  const [data, setData] = useState<useAllUsedPower>({} as useAllUsedPower)
  const { chainId, account } = useActiveWeb3React()
  const _contract = useFarmsControllerContract()

  const getData = useCallback(() => {
    if (!chainId) return
    Object.values(POOLS_MAP)
      .filter((i) => i?.farmAddresses?.[chainId])
      .map((i) => {
        const address = i.farmAddresses?.[chainId] as string

        _contract?.voteUserSlopes(account, address).then((res: BigNumber[]) => {
          const [slop, power, end] = res
          setData((_state) => ({
            ..._state,
            [i.name]: BigNumber.from(power || Zero)
              .div(100)
              .toNumber(),
          }))
        })
      })
  }, [chainId, _contract, account])

  useEffect(() => {
    getData()
  }, [getData])

  return [data, getData]
}

export function useLastVoteTime(address: string): [number, () => void] {
  const [lastVoteTime, setLastVoteTime] = useState(0)
  const { account } = useActiveWeb3React()
  const _contract = useFarmsControllerContract()

  const getLastVoteTime = useCallback(() => {
    if (!account || !address) return void setLastVoteTime(0)

    _contract?.lastUserVote(account, address).then((res: BigNumber) => {
      const num = BigNumber.from(res).toNumber()
      const invalidTimeStamp = num && moment.unix(num)?.isValid()
      setLastVoteTime(invalidTimeStamp ? num : 0)
    })
  }, [_contract, address])

  useEffect(() => {
    getLastVoteTime()
  }, [getLastVoteTime])

  return [lastVoteTime, getLastVoteTime]
}

export type PoolItem = {
  name: PoolName
  tokens: Token[]
  farmAddress: string
  lpTokenName: string
  isOutdated?: boolean
}
export function usePoolList() {
  const [poolList, setPoolList] = useState<PoolItem[]>([])
  const { chainId } = useActiveWeb3React()
  const availablePools = { ...POOLS_MAP }

  const getPoolList = useCallback(() => {
    if (!chainId) return
    const list = Object.values(availablePools)
      .filter((i) => i?.farmAddresses?.[chainId])
      .map((i) => ({
        name: i.name,
        farmAddress: i.farmAddresses?.[chainId] as string,
        tokens: i.underlyingPoolTokens || i.poolTokens,
        lpTokenName: `${i.lpToken.symbol} Farm`,
        isOutdated: i.isOutdated
      }))
    setPoolList(list)
  }, [chainId])

  useEffect(() => {
    getPoolList()
  }, [getPoolList])

  return poolList
}

export function useUsdPoolData() {
  const [usdPool, setUsdPool] = useState<PoolItem>({} as PoolItem)
  const { chainId } = useActiveWeb3React()

  const getUsdPool = useCallback(() => {
    const poolInfo = POOLS_MAP[STABLECOIN_POOL_NAME]
    if (!chainId || !poolInfo) return

    const farmAddress = poolInfo.farmAddresses?.[chainId]
    if (!farmAddress) return

    const data = {
      name: poolInfo.name,
      farmAddress: farmAddress,
      tokens: poolInfo.poolTokens,
      lpTokenName: `${poolInfo.lpToken.symbol} Farm`,
    }
    setUsdPool(data)
  }, [chainId])

  useEffect(() => {
    getUsdPool()
  }, [getUsdPool])

  return usdPool
}

export function useVeTokenMinted() {
  const [veTokenMinted, setVeTokenMinted] = useState(Zero)
  const veTokenContract = useVeTokenContract()

  const getVeTokenMinted = useCallback(() => {
    veTokenContract?.["totalSupply()"]().then((res: BigNumber) => {
      setVeTokenMinted(res)
    })
  }, [veTokenContract])

  useEffect(() => {
    getVeTokenMinted()
  }, [getVeTokenMinted])

  return { veTokenMinted, getVeTokenMinted }
}

export function useVeTokenVoted() {
  const [veTokenVoted, setVeTokenVoted] = useState(Zero)
  const FarmsControllerContract = useFarmsControllerContract()

  const getVeTokenVoted = useCallback(() => {
    FarmsControllerContract?.getTotalWeight().then((res: BigNumber) => {
      setVeTokenVoted(res)
    })
  }, [FarmsControllerContract])

  useEffect(() => {
    getVeTokenVoted()
  }, [getVeTokenVoted])

  return { veTokenVoted, getVeTokenVoted }
}

export function useVeSRSBalance() {
  const [veSRSBalance, setVeSRSBalance] = useState(Zero)
  const { account } = useActiveWeb3React()
  const veSRSToken = useTokenContract(VE_TOKEN)

  const getVesrsBalance = useCallback(() => {
    if (!account) return
    veSRSToken?.balanceOf(account).then((res: BigNumber) => {
      setVeSRSBalance(res)
    })
  }, [account, veSRSToken])

  useEffect(() => {
    getVesrsBalance()
  }, [getVesrsBalance])

  return { veSRSBalance, getVesrsBalance }
}

export function useVeSRSUnlockTime() {
  const veTokenContract = useVeTokenContract()
  const { account } = useActiveWeb3React()
  const [unlockTime, setUnlockTime] = useState()
  const getmyUnlockTime = useCallback(async () => {
    const LockTimeRes = await veTokenContract?.locked(account)
    if (!LockTimeRes?.end) return
    const unlockTime = LockTimeRes.end.toNumber()
    setUnlockTime(unlockTime)
  }, [veTokenContract, account])

  useEffect(() => {
    void getmyUnlockTime()
  }, [getmyUnlockTime])

  return unlockTime
}
