import { AddressZero, Zero } from "@ethersproject/constants"
import { JsonRpcSigner, Web3Provider } from "@ethersproject/providers"
import { formatUnits, parseUnits } from "@ethersproject/units"
import { BigNumber } from "@ethersproject/bignumber"
import { Contract } from "@ethersproject/contracts"
import { ContractInterface, ethers } from "ethers"
import { Deadlines } from "../state/user"
import { MulticallProvider } from "../types/ethcall"
import { Provider } from "ethcall"
import { getAddress } from "@ethersproject/address"
import {
  ChainId,
  PoolTypes,
  TOKENS_MAP,
  EXTRA_TOKENS_MAP,
  AIRDROP_TOKENS_MAP,
  Token,
  ARB_USD_POOL_NAME,
  D4_POOL_NAME,
  FRAX_ARB_USD_POOL_V2_NAME,
  FRAX_OPT_USD_METAPOOL_NAME,
  FTM_USD_POOL_NAME,
  OPT_USD_POOL_NAME,
  POOLS_MAP,
  PoolName,
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_V2_NAME,
  SUSD_METAPOOL_NAME,
  SUSD_METAPOOL_V2_NAME,
  USDS_ARB_USD_METAPOOL_NAME,
  WCUSD_METAPOOL_NAME,
  WCUSD_METAPOOL_V2_NAME,
  BAI_METAPOOL_NAME,
  OUSD_METAPOOL_NAME,
  STARLAY_POOL_NAME,
  JPYC_POOL_NAME,
  WBTC_METAPOOL_NAME,
  WETH_METAPOOL_NAME,
  WBNB_METAPOOL_NAME,
  DOT_METAPOOL_NAME,
  LASTR_METAPOOL_NAME,
  WBTC_WETH_4SRS_METAPOOL_NAME,
  AVAULT_POOL_NAME,
  PUSDT_METAPOOL_NAME,
  GRAPH_URL,
} from "../constants"


// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: string): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export const parseBalance = (value: string, decimals = 18): BigNumber => {
  return ethers.utils.parseUnits(value || "0", decimals)
}

// account is not optional
export function getSigner(
  library: Web3Provider,
  account: string,
): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(
  library: Web3Provider,
  account?: string,
): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(
  address: string,
  ABI: ContractInterface,
  library: Web3Provider,
  account?: string,
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account))
}

export function formatBNToShortString(
  bn: BigNumber,
  nativePrecision: number,
): string {
  const bnStr = bn.toString()
  const numLen = bnStr.length - nativePrecision
  if (numLen <= 0) return "0.0"
  const div = Math.floor((numLen - 1) / 3)
  const mod = numLen % 3
  const suffixes = ["", "k", "m", "b", "t", "p", "e18", "e21", "e24", "e27", "e30"]
  return `${bnStr.substr(0, mod || 3)}.${bnStr[mod || 3]}${suffixes[div] || ""}`
}

export function bnToShortNumAndSuffix(
  bn: BigNumber,
  precision?: number,
): { value: number; suffix: string } {
  const bnStr = bn.toString()
  const numLen = bnStr.length - (precision || 0)
  if (numLen <= 0) return { value: 0, suffix: "" }
  const suffixes = ["", "k", "m", "b", "t", "p", "e18", "e21", "e24", "e27", "e30"]
  const div = Math.floor((numLen - 1) / 3)
  const mod = numLen % 3 || 3
  const value = +`${bnStr.substr(0, mod)}.${bnStr[mod]}` || 0
  const suffix = suffixes[div] || ""
  return { value, suffix }
}

export function formatBNToString(
  bn: BigNumber,
  nativePrecison: number,
  decimalPlaces?: number,
): string {
  const fullPrecision = formatUnits(bn, nativePrecison)
  const decimalIdx = fullPrecision.indexOf(".")
  return decimalPlaces === undefined || decimalIdx === -1
    ? fullPrecision
    : fullPrecision.slice(
        0,
        decimalIdx + (decimalPlaces > 0 ? decimalPlaces + 1 : 0), // don't include decimal point if places = 0
      )
}

export function formatBNToPercentString(
  bn: BigNumber,
  nativePrecison: number,
  decimalPlaces = 2,
): string {
  return `${formatBNToString(bn, nativePrecison - 2, decimalPlaces)}%`
}

export function shiftBNDecimals(bn: BigNumber, shiftAmount: number): BigNumber {
  if (shiftAmount < 0) throw new Error("shiftAmount must be positive")
  return bn.mul(BigNumber.from(10).pow(shiftAmount))
}

// const bignum2decimal = (bn: any, decimal = 18) =>
//     !bn ? '0' : Fraction.from(BigNumber.from(bn), BigNumber.from(10).pow(decimal)).toString()

export function calculateExchangeRate(
  amountFrom: BigNumber,
  tokenPrecisionFrom: number,
  amountTo: BigNumber,
  tokenPrecisionTo: number,
): BigNumber {
  return amountFrom.gt("0")
    ? amountTo
        .mul(BigNumber.from(10).pow(36 - tokenPrecisionTo)) // convert to standard 1e18 precision
        .div(amountFrom.mul(BigNumber.from(10).pow(18 - tokenPrecisionFrom)))
    : BigNumber.from("0")
}

export function formatDeadlineToNumber(
  deadlineSelected: Deadlines,
  deadlineCustom?: string,
): number {
  let deadline = 20
  switch (deadlineSelected) {
    case Deadlines.Ten:
      deadline = 10
      break
    case Deadlines.Twenty:
      deadline = 20
      break
    case Deadlines.Thirty:
      deadline = 30
      break
    case Deadlines.Forty:
      deadline = 40
      break
    case Deadlines.Custom:
      deadline = +(deadlineCustom || formatDeadlineToNumber(Deadlines.Twenty))
      break
  }
  return deadline
}

// A better version of ether's commify util
export function commify(str: string, decimals = 2): string {
  const parts = str.split(".")
  if (parts.length > 2) throw new Error("commify string cannot have > 1 period")
  // if (parts.length === 1) return str;
  const [partA, partB] = parts
  const hasPercent = partB !== undefined && partB.indexOf("%") > -1 ? "%" : ""
  if (partA.length === 0)
    return partB === undefined
      ? ""
      : `.${partB.replace("%", "").slice(0, 2)}${hasPercent}`
  const mod = partA.length % 3
  const div = Math.floor(partA.length / 3)
  // define a fixed length array given the expected # of commas added
  const commaAArr = new Array(partA.length + (mod === 0 ? div - 1 : div))
  // init pointers for original string and for commified array
  let commaAIdx = commaAArr.length - 1
  // iterate original string backwards from the decimals since that's how commas are added
  for (let i = partA.length - 1; i >= 0; i--) {
    // revIdx is the distance from the decimal place eg "3210."
    const revIdx = partA.length - 1 - i
    // add the character to the array
    commaAArr[commaAIdx--] = partA[i]
    // add a comma if we are a multiple of 3 from the decimal
    if ((revIdx + 1) % 3 === 0) {
      commaAArr[commaAIdx--] = ","
    }
  }
  const commifiedA = commaAArr.join("")

  return partB === undefined
    ? commifiedA
    : `${commifiedA}.${partB.replace("%", "").slice(0, decimals)}${hasPercent}`
}

export function intersection<T>(set1: Set<T>, set2: Set<T>): Set<T> {
  return new Set([...set1].filter((item) => set2.has(item)))
}

export function getTokenByAddress(
  address: string,
  chainId: ChainId,
): Token | null {
  return (
    Object.values(TOKENS_MAP).find(
      ({ addresses }) =>
        addresses[chainId] &&
        address.toLowerCase() === addresses[chainId].toLowerCase(),
    ) || null
  )
}

export function getAirdropTokenByAddress(
  address: string,
  chainId: ChainId,
): Token | null {
  return (
    Object.values(AIRDROP_TOKENS_MAP).find(
      ({ addresses }) =>
        addresses[chainId] &&
        address.toLowerCase() === addresses[chainId].toLowerCase(),
    ) || null
  )
}

export function getExtraTokenByAddress(
  address: string,
  chainId: ChainId,
): Token | undefined | null {
  return (
    Object.values(EXTRA_TOKENS_MAP).find(
      ({ addresses }) =>
        addresses[chainId] &&
        address.toLowerCase() === addresses[chainId].toLowerCase(),
    ) || null
  )
}

export function calculatePrice(
  amount: BigNumber | string,
  tokenPrice = 0,
  decimals?: number,
): BigNumber {
  // returns amount * price as BN 18 precision
  if (typeof amount === "string") {
    if (isNaN(+amount)) return Zero

    if (
      (+amount * tokenPrice)
        .toLocaleString("fullwide", { useGrouping: false })
        .split(".")[0].length > 20
    )
      return parseUnits(
        (+amount * tokenPrice).toLocaleString("fullwide", {
          useGrouping: false,
        }),
        18,
      )

    return parseUnits((+amount * tokenPrice).toFixed(6), 18)
  } else if (decimals != null) {
    return amount
      .mul(parseUnits(tokenPrice.toFixed(6), 18))
      .div(BigNumber.from(10).pow(decimals))
  }
  return Zero
}

export function getTokenSymbolForPoolType(poolType: PoolTypes): string {
  if (poolType === PoolTypes.ETH) {
    return "WETH"
  } else if (poolType === PoolTypes.USD) {
    return "USDC"
  } else {
    return ""
  }
}

export async function getMulticallProvider(
  library: Web3Provider,
  chainId: ChainId,
): Promise<MulticallProvider> {
  const ethcallProvider = new Provider() as MulticallProvider
  await ethcallProvider.init(library)
  if (chainId === ChainId.HARDHAT) {
    ethcallProvider.multicallAddress =
      "0xa85233C63b9Ee964Add6F2cffe00Fd84eb32338f"
  } else if (chainId === ChainId.ROPSTEN) {
    ethcallProvider.multicallAddress =
      "0x53c43764255c17bd724f74c4ef150724ac50a3ed"
  } else if (chainId === ChainId.ARBITRUM) {
    ethcallProvider.multicallAddress =
      "0xab16069d3e9e352343b2040ce7d7715c585994f9"
  } else if (chainId === ChainId.OPTIMISM) {
    ethcallProvider.multicallAddress =
      "0x266557A864680A1401A3506c0eb72934BD13Bf59"
  } else if (chainId === ChainId.FANTOM) {
    ethcallProvider.multicallAddress =
      "0xc04d660976c923ddba750341fe5923e47900cf24"
  } else if (chainId === ChainId.BSCTEST) {
    ethcallProvider.multicallAddress =
      "0xe348b292e8eA5FAB54340656f3D374b259D658b8"
  } else if (chainId === ChainId.SHIBUYA) {
    ethcallProvider.multicallAddress =
      "0x86336584505Af4dF458F8695cB6181E654420693"
  } else if (chainId === ChainId.ASTAR) {
    ethcallProvider.multicallAddress =
      "0x827D24f4a545e26A877C17019a8D815D538f46A7"
  }

  return ethcallProvider
}

export function toThousands(num: number): string {
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
}

export function sliceNum(num: number, decimals = 2): string {
  if (!num) return "0"
  const n = num.toString()
  if (n.indexOf(".") < 0) return num.toString()
  return n.substring(0, n.indexOf(".") + (decimals + 1))
}

export function timestampToUnix(timestamp: any) {
  return +timestamp > 1e11 ? Math.floor(timestamp / 1e3) : +timestamp || 0
}

export function getPoolRoute(poolName: PoolName) {
  if (poolName === D4_POOL_NAME) {
    return {
      name: D4_POOL_NAME,
      poolRoute: "/pools/d4",
    }
  } else if (poolName === STABLECOIN_POOL_NAME) {
    return {
      name: STABLECOIN_POOL_NAME,
      poolRoute: "/pools/usd",
    }
  } else if (poolName === STABLECOIN_POOL_V2_NAME) {
    return {
      name: STABLECOIN_POOL_V2_NAME,
      poolRoute: "/pools/usdv2",
    }
  } else if (poolName === SUSD_METAPOOL_NAME) {
    return {
      name: SUSD_METAPOOL_NAME,
      poolRoute: "/pools/susd",
    }
  } else if (poolName === SUSD_METAPOOL_V2_NAME) {
    return {
      name: SUSD_METAPOOL_V2_NAME,
      poolRoute: "/pools/susdv2",
    }
  } else if (poolName === WCUSD_METAPOOL_NAME) {
    return {
      name: WCUSD_METAPOOL_NAME,
      poolRoute: "/pools/wcusd",
    }
  } else if (poolName === WCUSD_METAPOOL_V2_NAME) {
    return {
      name: WCUSD_METAPOOL_V2_NAME,
      poolRoute: "/pools/wcusdv2",
    }
  } else if (poolName === ARB_USD_POOL_NAME) {
    return {
      name: ARB_USD_POOL_NAME,
      poolRoute: "/pools/arbusd",
    }
  } else if (poolName === FTM_USD_POOL_NAME) {
    return {
      name: FTM_USD_POOL_NAME,
      poolRoute: "/pools/ftmusd",
    }
  } else if (poolName === OPT_USD_POOL_NAME) {
    return {
      name: OPT_USD_POOL_NAME,
      poolRoute: "/pools/optusd",
    }
  } else if (poolName === FRAX_OPT_USD_METAPOOL_NAME) {
    return {
      name: FRAX_OPT_USD_METAPOOL_NAME,
      poolRoute: "/pools/frax-optusd",
    }
  } else if (poolName === FRAX_ARB_USD_POOL_V2_NAME) {
    return {
      name: FRAX_ARB_USD_POOL_V2_NAME,
      poolRoute: "/pools/frax-arbusdv2",
    }
  } else if (poolName === USDS_ARB_USD_METAPOOL_NAME) {
    return {
      name: USDS_ARB_USD_METAPOOL_NAME,
      poolRoute: "/pools/usds-arbusd",
    }
  } else if (poolName === BAI_METAPOOL_NAME) {
    return {
      name: BAI_METAPOOL_NAME,
      poolRoute: "/pools/bai",
    }
  } else if (poolName === OUSD_METAPOOL_NAME) {
    return {
      name: BAI_METAPOOL_NAME,
      poolRoute: "/pools/ousd",
    }
  } else if (poolName === STARLAY_POOL_NAME) {
    return {
      name: STARLAY_POOL_NAME,
      poolRoute: "/pools/starly",
    }
  } else if (poolName === JPYC_POOL_NAME) {
    return {
      name: JPYC_POOL_NAME,
      poolRoute: "/pools/jpyc",
    }
  } else if (poolName === WBTC_METAPOOL_NAME) {
    return {
      name: WBTC_METAPOOL_NAME,
      poolRoute: "/pools/wbtc",
    }
  } else if (poolName === WBNB_METAPOOL_NAME) {
    return {
      name: WBNB_METAPOOL_NAME,
      poolRoute: "/pools/wbnb",
    }
  } else if (poolName === DOT_METAPOOL_NAME) {
    return {
      name: DOT_METAPOOL_NAME,
      poolRoute: "/pools/dot",
    }
  } else if (poolName === LASTR_METAPOOL_NAME) {
    return {
      name: LASTR_METAPOOL_NAME,
      poolRoute: "/pools/nastr",
    }
  } else if (poolName === WBTC_WETH_4SRS_METAPOOL_NAME) {
    return {
      name: WBTC_WETH_4SRS_METAPOOL_NAME,
      poolRoute: "/pools/wbtc-weth-4srs",
    }
  } else if (poolName === AVAULT_POOL_NAME) {
    return {
      name: AVAULT_POOL_NAME,
      poolRoute: "/pools/avault",
    }
  } else if (poolName === PUSDT_METAPOOL_NAME) {
    return {
      name: PUSDT_METAPOOL_NAME,
      poolRoute: "/pools/pusdt",
    }
  } else {
    return {
      name: WETH_METAPOOL_NAME,
      poolRoute: "/pools/weth",
    }
  }
}
export function bigNumberMulNum(_big:any, _num:any):BigNumber {
  const _big_no = +formatBNToString(_big, 18);
  const _fin_no = _big_no * _num;
  const _fin_bn = parseUnits(_fin_no.toString(), 18)

  return _fin_bn;
}

export const isExpensiveToken = (symbol?: string) =>
  symbol && ["WBTC", "WETH", "4WBTC", "4WETH"].includes(symbol)

export const expensiveDecimals = (symbol?: string) =>
  isExpensiveToken(symbol) ? 6 : 2

export const getGraph = async (query: any) =>
  await (
    await fetch(GRAPH_URL, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ query }),
    })
  ).json()
