import "./PoolInfoCard.scss"

import {
  POOLS_MAP,
  POOL_FEE_PRECISION,
  PoolTypes,
  TOKENS_MAP,
} from "../constants"
import React, { ReactElement } from "react"
import { utils } from "ethers"
const { parseUnits } = utils
import { formatBNToPercentString, formatBNToString } from "../utils"
import { PoolDataType } from "../hooks/usePoolData"
import { BigNumber } from "@ethersproject/bignumber"
import { commify } from "@ethersproject/units"
import { useTranslation } from "react-i18next"
import { shortenAddress } from "../utils/shortenAddress"
import { useActiveWeb3React } from "../hooks"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box } from "@mui/material"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import { isMobile } from "react-device-detect"
import { Zero } from "@ethersproject/constants"
interface Props {
  data: PoolDataType | null
}

function PoolInfoCard({ data }: Props): ReactElement | null {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  if (data == null) return null
  const { type: poolType } = POOLS_MAP[data?.name]
  const formattedDecimals = poolType === PoolTypes.USD ? 2 : 4
  const swapFee = data?.swapFee
    ? formatBNToPercentString(data.swapFee, POOL_FEE_PRECISION)
    : null
  const adminFee = data?.adminFee
    ? formatBNToPercentString(data.adminFee, POOL_FEE_PRECISION)
    : null

  let LPFeeShares = null
  let veHolderShares = null
  let buyBackSRS = null
  if (swapFee && data?.adminFee) {
    const LPFeeSharesBN = parseUnits("1", POOL_FEE_PRECISION).sub(data.adminFee)
    const veHolderSharesBN = data.adminFee.mul(parseUnits("0.57142857", 8))
    const buyBackSRSBN = data.adminFee.mul(parseUnits("0.42857143", 8))
    LPFeeShares = Math.round(
      +formatBNToString(LPFeeSharesBN, POOL_FEE_PRECISION - 2),
    )
    // POOL_FEE_PRECISION + 8 - 2
    veHolderShares = Math.round(
      +formatBNToString(veHolderSharesBN, POOL_FEE_PRECISION + 6),
    )
    buyBackSRS = Math.round(
      +formatBNToString(buyBackSRSBN, POOL_FEE_PRECISION + 6),
    )
  }

  const formattedData = {
    name: data?.name,
    swapFee,
    aParameter: data?.aParameter
      ? commify(formatBNToString(data.aParameter, 0, 0))
      : "-",
    virtualPrice: data?.virtualPrice
      ? commify(formatBNToString(data.virtualPrice, 18, 5))
      : "-",
    utilization: data?.utilization
      ? formatBNToPercentString(data.utilization, 18, 0)
      : "-",
    reserve: data?.reserve
      ? commify(formatBNToString(data.reserve, 18, 2))
      : "-",
    // adminFee: swapFee && adminFee ? `${adminFee} of ${swapFee}` : null,
    LPFeeShares: LPFeeShares ? `${LPFeeShares}% of ${swapFee}` : null,
    veHolderShares: veHolderShares ? `${veHolderShares}% of ${swapFee}` : null,
    buyBackSRS: buyBackSRS ? `${buyBackSRS}% of ${swapFee}` : null,
    volume: data?.volume ? commify(formatBNToString(data.volume, 18, 2)) : "-",
    tokens:
      data?.tokens.map((coin) => {
        const token = TOKENS_MAP[coin.symbol]
        return {
          symbol: token.symbol,
          name: token.name,
          icon: token.icon,
          percent: coin.percent,
          value: commify(formatBNToString(coin.value, 18, formattedDecimals)),
        }
      }) || [],
    lastPrices: 1 / +formatBNToString(data?.lastPrices || Zero, 18),
    lastPrices1: data?.lastPrices1?.toNumber()
      ? 1 / +formatBNToString(data?.lastPrices1 || Zero, 18)
      : 0,
    priceScale: 1 / +formatBNToString(data?.priceScale || Zero, 18),
    priceScale1: data?.priceScale1?.toNumber()
      ? 1 / +formatBNToString(data?.priceScale1 || Zero, 18)
      : 0,
    // lastPrices: data?.lastPrices
    //   ? formatBNToString(BigNumber.from(10).pow(18).div(data?.lastPrices), 0)
    //   : "0",
    // priceScale: data?.priceScale
    //   ? formatBNToString(BigNumber.from(10).pow(18).div(data?.priceScale), 0)
    //   : "0",
  }
  const poolTokens = POOLS_MAP[data.name].underlyingPoolTokens

  return (
    <div className="poolInfoCard">
      <div className="currency-reserves">
        <p className="title">
          <span>{t("Total Pool Value")}</span>
          <span>{`$${formattedData.reserve}`}</span>
        </p>
        <Box display="flex" justifyContent="space-between" className="intro">
          <span>{t("Pool Tokens")}</span>
          <span>{t("Percentage")}</span>
        </Box>
        <div className="tokenList">
          {formattedData.tokens.map((token, index) => (
            <div className="token" key={index}>
              <div>
                <img alt="icon" src={token.icon} />
                <span className="tokenValue">
                  {token.value} {token.symbol}
                </span>
              </div>
              <div>{token.percent}</div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <Accordion
          style={{ border: "none", marginTop: 0 }}
          className="info"
          defaultExpanded={isMobile}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ padding: 0, marginTop: 0 }}
          >
            <p className="title">{t("Detail Info")}</p>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <div className="infoItem">
              <span className="label">{t("Trading Fee")}:</span>
              <span className="value">{formattedData.swapFee}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("virtualPrice")}:</span>
              <span className="value">{formattedData.virtualPrice}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("A (Amplification Parameter)")}:</span>
              <span className="value">{formattedData.aParameter}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("Liquidity Utilization")}:</span>
              <span className="value">{formattedData.utilization}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("Total Liquidity")}:</span>
              <span className="value">{`$${formattedData.reserve}`}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("24 Hours Volume")}:</span>
              <span className="value">{`$${formattedData.volume}`}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("Pool Contract")}:</span>
              {chainId && (
                <span className="value">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC", textDecoration: "none" }}
                    href={getEtherscanLink(
                      POOLS_MAP[formattedData.name].addresses[chainId],
                      "address",
                      chainId,
                    )}
                  >
                    {shortenAddress(
                      POOLS_MAP[formattedData.name].addresses[chainId],
                    )}
                  </a>
                </span>
              )}
            </div>
            <div className="infoItem">
              <span className="label">{t("LP fee shares")}:</span>
              <span className="value">{formattedData.LPFeeShares}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("veHolder shares")}:</span>
              <span className="value">{formattedData.veHolderShares}</span>
            </div>
            <div className="infoItem">
              <span className="label">{t("Buy back SRS")}:</span>
              <span className="value">{formattedData.buyBackSRS}</span>
            </div>
            {data.lastPrices && poolTokens && (
              <div className="infoItem">
                <span className="label">{t("Price Oracle")}:</span>
                <span className="value">
                  {formattedData.lastPrices1 ? (
                    <>
                      <span>
                        {poolTokens[0]?.symbol}/{poolTokens[2]?.symbol}{" "}
                        {(+formattedData.lastPrices).toFixed(4)}
                      </span>
                      <br />
                      <span>
                        {poolTokens[1]?.symbol}/{poolTokens[2]?.symbol}{" "}
                        {(+formattedData.lastPrices1).toFixed(4)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        {poolTokens[0]?.symbol}/{poolTokens[1]?.symbol}{" "}
                        {(+formattedData.lastPrices).toFixed(4)}
                      </span>
                    </>
                  )}
                </span>
              </div>
            )}
            {data.priceScale && poolTokens && (
              <div className="infoItem">
                <span className="label">{t("Price Scale")}:</span>
                <span className="value">
                  {formattedData.priceScale1 ? (
                    <>
                      <span>
                        {poolTokens[0]?.symbol}/{poolTokens[2]?.symbol}{" "}
                        {(+formattedData.priceScale).toFixed(4)}
                      </span>
                      <br />
                      <span>
                        {poolTokens[1]?.symbol}/{poolTokens[2]?.symbol}{" "}
                        {(+formattedData.priceScale1).toFixed(4)}
                      </span>
                    </>
                  ) : (
                    <>
                      <span>
                        {poolTokens[0]?.symbol}/{poolTokens[1]?.symbol}{" "}
                        {(+formattedData.priceScale).toFixed(4)}
                      </span>
                    </>
                  )}
                </span>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  )
}

export default PoolInfoCard
