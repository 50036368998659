import { ChainId, NetworkContextName } from "../constants"
import { getCurrProvider } from "../connectors"
import { useEffect, useState, useCallback } from "react"

import { Web3Provider } from "@ethersproject/providers"
import { Web3ReactContextInterface } from "@web3-react/core/dist/types"
import { isMobile } from "react-device-detect"
import { useWeb3React as useWeb3ReactCore } from "@web3-react/core"

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & {
  chainId?: ChainId
} {
  const context = useWeb3ReactCore<Web3Provider>()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  return context.active ? context : contextNetwork
}

export function useEagerConnect(): boolean {
  const { activate, active } = useWeb3ReactCore() // specifically using useWeb3ReactCore because of what this hook does
  const [tried, setTried] = useState(false)

  const mountProvider = useCallback(async () => {
    try {
      const { currConnector, currProvider } = getCurrProvider()
      if (!currConnector) return setTried(true)

      const isAuthorized: boolean = await currProvider.isAuthorized()
      if (!isAuthorized && !isMobile) return setTried(true)

      const res = await activate(currProvider, undefined, true)
      setTried(true)
    } catch (err) {
      console.error("useEagerConnect", err)
      setTried(true)
    }
  }, [activate])

  // intentionally only running on mount (make sure it's only mounted once :))
  // The browser extension has not injected window.talismanEth or window.SubWallet. Make a delay here
  useEffect(() => {
    setTimeout(() => {
      void mountProvider()
    }, 1e3)
  }, [mountProvider])

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (active) {
      setTried(true)
    }
  }, [active])

  return tried
}

/**
 * Use for network and injected - logs user in
 * and out after checking what network theyre on
 * @param {boolean} suppress Suppress useEffect behaviour
 */
export function useInactiveListener(suppress = false): void {
  const { active, error, activate } = useWeb3ReactCore() // specifically using useWeb3React because of what this hook does

  useEffect(() => {
    const { currConnector } = getCurrProvider()

    if (currConnector?.on && !active && !error && !suppress) {
      const handleChainChanged = (): void => {
        const res = getCurrProvider()
        activate(res.currProvider, undefined, true).catch((error) => {
          console.error("Failed to activate after chain changed", error)
        })
      }

      const handleAccountsChanged = (accounts: string[]): void => {
        if (!accounts?.length) return
        const res = getCurrProvider()
        activate(res.currProvider, undefined, true).catch((error) => {
          console.error("Failed to activate after accounts changed", error)
        })
      }

      currConnector.on("chainChanged", handleChainChanged)
      currConnector.on("accountsChanged", handleAccountsChanged)

      return (): void => {
        if (currConnector.removeListener) {
          currConnector.removeListener("chainChanged", handleChainChanged)
          currConnector.removeListener("accountsChanged", handleAccountsChanged)
        }
      }
    }
    return undefined
  }, [active, error, suppress, activate])
}
