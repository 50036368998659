import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import pusdtLogo from "../../assets/icons/pusdt.svg"

export const PUSDT_METAPOOL_NAME = "Polkadot USDT Metapool"

const PUSDT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0xFFFFFFFF000000000000000000000001000007C0",
})
export const PUSDT_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x8E12781da51480D84715ad83eE482643601d843E",
})
export const PUSDT_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x8e39e47Ca4A44D4316b88727Eb4407De877a9235",
})
export const PUSDT_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x8277d2321CFEF47b508392C08177E05621511234",
})
export const PUSDT_FARM_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x8dBcd190e325d141E7698f0791792FFBb310A10e",
})

export const PUSDT = new Token(
  PUSDT_CONTRACT_ADDRESSES,
  6,
  "USDT",
  "tether",
  "tether USD",
  pusdtLogo,
)
export const PUSDT_SWAP_TOKEN = new Token(
  PUSDT_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4PUSDT",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  pusdtLogo,
  false,
  true,
)

export const PUSDT_POOL_TOKENS = [PUSDT, ...STABLECOIN_POOL_TOKENS]
export const PUSDT_UNDERLYING_POOL_TOKENS = [PUSDT, STABLECOIN_SWAP_TOKEN]

export const PUSDT_POOL_INFO: Pool = {
  name: PUSDT_METAPOOL_NAME,
  lpToken: PUSDT_SWAP_TOKEN,
  poolTokens: PUSDT_POOL_TOKENS,
  isSynthetic: true,
  addresses: PUSDT_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: PUSDT_FARM_ADDRESSES,
  type: PoolTypes.USD,
  route: "pusdt",
  metaSwapAddresses: PUSDT_META_SWAP_ADDRESSES,
  underlyingPoolTokens: PUSDT_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
  hot: true,
}
