import moment from "moment"

export function getFormattedShortTime(timestamp: string | number): string {
  const timestampNumber =
    typeof timestamp === "string" ? parseInt(timestamp) : timestamp
  const timeoptions = {
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  } as const
  return new Date(timestampNumber * 1000)
    .toLocaleTimeString([], timeoptions)
    .replace(",", "")
}

// Zero time of this Thursday or last Thursday
export function getLastThursday() {
  const now = moment().utc()
  const day = now.day()
  // Whether today is less than Thursday
  const ltThu = day < 4
  const Thu = now.subtract(ltThu ? day + 3 : day - 4, "d").startOf("day")
  return Thu
}

// Zero time of this Thursday or next Thursday
export function getUpcomingThursday() {
  const now = moment().utc()
  const day = now.day()
  const ltThu = day < 4
  const Thu = now.add((ltThu ? 4 : 11) - day, "d").startOf("day")
  return Thu
}
