import React, { ReactElement } from "react"
import { ReactComponent as ArrowIcon } from "../assets/icons/arrow-down2.svg"
import styles from "./TrendIcon.module.scss"

type Props = {
  current: number
  target: number
}

const TrendIcon = (props: Props): ReactElement => {
  const { current, target } = props
  return !target ? (
    <></>
  ) : target > current ? (
    <ArrowIcon className={`${styles.TrendIcon} ${styles.TrendIconUp}`} />
  ) : target < current ? (
    <ArrowIcon className={`${styles.TrendIcon} ${styles.TrendIconDown}`} />
  ) : (
    <></>
  )
}

export default TrendIcon
