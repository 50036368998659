import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import { WBTC } from "./wbtcMetapool"
import { WETH } from "./wethMetapool"
import wbtcLogo from "../../assets/icons/wbtc.svg"
import wethLogo from "../../assets/icons/weth.svg"

export const WBTC_WETH_4SRS_METAPOOL_NAME = "WBTC-WETH-4SRS Metapool"

export const WBTC_WETH_4SRS_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x8AC9eCfaE29Cf3753E27ca536B68355Af7935D74",
  // [ChainId.ASTAR]: "0xb27157e749d1026a317139BaEf9020CA726b694f",
})

export const WBTC_WETH_4SRS_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x15C03FC7C1ba88E55e858a532b6eB8229b0b8e32",
  // [ChainId.ASTAR]: "0xff390905269Ac30eA640dBaBdF5960D7B860f2CF",
})

export const WBTC_WETH_4SRS_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xA368CFEAdE5635B2A4D5B1e022b87e3ccB7e1e25",
  //   [ChainId.ASTAR]: "0xD25Cf814EeE54840A08Db8dfAbFE445B1DE37f0f",
})

export const WBTC_WETH_4SRS_FARM_ADDRESSES = buildAddresses({
  // [ChainId.SHIBUYA]: "0x26397569c66F52468d0a1E2f9F5113bcA1633895",
  // [ChainId.ASTAR]: "0x7f2fbBa3dd14Ef24aFA22E92796791D9a38bFBE0",
})

export const WBTC_WETH_4SRS_SWAP_TOKEN = new Token(
  WBTC_WETH_4SRS_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4BE",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  wbtcLogo,
  false,
  true,
)

export const WBTC_WETH_4SRS_POOL_TOKENS = [
  WBTC,
  WETH,
  ...STABLECOIN_POOL_TOKENS,
]
export const WBTC_WETH_4SRS_UNDERLYING_POOL_TOKENS = [
  WBTC,
  WETH,
  STABLECOIN_SWAP_TOKEN,
]

export const WBTC_WETH_4SRS_POOL_INFO: Pool = {
  name: WBTC_WETH_4SRS_METAPOOL_NAME,
  lpToken: WBTC_WETH_4SRS_SWAP_TOKEN,
  poolTokens: WBTC_WETH_4SRS_POOL_TOKENS,
  isSynthetic: true,
  addresses: WBTC_WETH_4SRS_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: WBTC_WETH_4SRS_FARM_ADDRESSES,
  type: PoolTypes.Crypto,
  route: "wbtc-weth-4srs",
  metaSwapAddresses: WBTC_WETH_4SRS_META_SWAP_ADDRESSES,
  underlyingPoolTokens: WBTC_WETH_4SRS_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
  unbalancePercent: 50,
}
