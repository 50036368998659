import { ChainId, SPA, TOKENS_MAP, EXCH_URL } from "../constants"
import { AppDispatch } from "../state"
import { Web3Provider } from "@ethersproject/providers"
import retry from "async-retry"
import { updateTokensPricesUSD, updateSrsPrice } from "../state/application"

const coinGeckoAPI = "https://api.coingecko.com/api/v3/simple/price"

interface CoinGeckoReponse {
  [tokenSymbol: string]: {
    usd: number
  }
}
const otherTokens = {
  ETH: "ethereum",
  WETH: "ethereum",
  VETH2: "ethereum", // TODO: pull vETH2 price once it's added to coingecko
  BTC: "bitcoin",
  KEEP: "keep-network",
  SGT: "sharedstake-governance-token",
  ALCX: "alchemix",
  [SPA.symbol]: SPA.geckoId,
}

export default function fetchTokenPricesUSD(
  dispatch: AppDispatch,
  chainId?: ChainId,
  library?: Web3Provider,
): void {
  const tokens = Object.values(TOKENS_MAP).filter(({ addresses }) =>
    chainId ? addresses[chainId] : false,
  )

  const tokenIds = Array.from(
    new Set(
      tokens.map(({ geckoId }) => geckoId).concat(Object.values(otherTokens)),
    ),
  )
  void retry(
    () =>
      fetch(`${coinGeckoAPI}?ids=${encodeURIComponent(
        tokenIds.join(","),
      )}&vs_currencies=usd
    `)
        .then((res) => res.json())
        .then((body: CoinGeckoReponse) => {
          const otherTokensResult = Object.keys(otherTokens).reduce(
            (acc, key) => {
              return {
                ...acc,
                [key]: body?.[otherTokens[key as keyof typeof otherTokens]].usd,
              }
            },
            {} as { [symbol: string]: number },
          )
          const result = tokens.reduce((acc, token) => {
            return { ...acc, [token.symbol]: body?.[token.geckoId]?.usd }
          }, otherTokensResult)
          result.alETH = result?.ETH || result.alETH || 0 // TODO: remove once CG price is fixed
          result.nUSD = 1
          dispatch(updateTokensPricesUSD(result))
        })
        .catch((err) => {
          console.error("updateTokensPricesUSD", err)
        }),
    { retries: 3 },
  )
}

export const fetchSrsPrice = (dispatch: AppDispatch) => {
  void retry(
    () =>
      fetch(`${EXCH_URL}/api/price`)
        .then(async (res) => {
          const body: any = await res.json()
          if (!body.ok) return
          const price = +body.data.priceUsd || 0
          dispatch(updateSrsPrice(price))
        })
        .catch((err) => console.error("updateSrsPrice", err)),
    { retries: 3 },
  )
}
