import { AppDispatch } from "../state"
import retry from "async-retry"
import { updateSwapStats } from "../state/application"
import { EXCH_URL } from "../constants"

export type SwapStatsReponse = {
  [swapAddress: string]: {
    oneDayVolume: string
    APY: string
    TVL: string
  }
}

type Response = {
  ok: boolean
  err?: string
  data: {
    generatedTimeMs: number
    data: SwapStatsReponse
  }
}

export const fetchSwapStats = (dispatch: AppDispatch) => {
  void retry(
    () =>
      fetch(`${EXCH_URL}/api/swap/volume`, { cache: "no-cache" }).then(
        async (res) => {
          const body: Response = await res.json()
          if (!body?.ok)
            throw new Error(body.err || "Unable to fetch swap stats from IPFS")

          dispatch(updateSwapStats(body.data.data))
        },
      ),
    { retries: 3 },
  )
}

export default fetchSwapStats
