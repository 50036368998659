import React, { ReactElement } from "react"
import {
  useTheme,
  useMediaQuery,
  ModalProps,
  Drawer,
  DrawerProps,
  Popover,
  PopoverProps,
} from "@mui/material"
import { CommonProps } from "../theme/OverridableComponent"

interface Props extends CommonProps {
  children?: React.ReactNode
  open: boolean
  onClose?: ModalProps["onClose"]
  DrawerProps?: DrawerProps
  PopoverProps?: PopoverProps
}

export default function CustomPopover(props: Props): ReactElement {
  const {
    className,
    style,
    open,
    onClose,
    children,
    DrawerProps,
    PopoverProps,
  } = props
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))

  const MobileDrawer = (
    <Drawer
      className={className}
      style={style}
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          [theme.breakpoints.down("sm")]: {
            borderWidth: "1px 0 0 0",
            borderRadius: "16px 16px 0 0",
            pb: 3,
          },
        },
      }}
      {...DrawerProps}
    >
      {children}
    </Drawer>
  )

  const PcPopover = (
    <Popover
      className={className}
      style={style}
      open={open}
      onClose={onClose}
      {...PopoverProps}
    >
      {children}
    </Popover>
  )

  if (isDownSm) return <>{MobileDrawer}</>
  return <>{PcPopover}</>
}
