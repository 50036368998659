//新增metapool
//需要更新底下的farmContracts/farmLpTokenContracts
//lpToken1
import { useState, useEffect, useCallback } from "react"
import { Zero } from "@ethersproject/constants"
import { BigNumber } from "@ethersproject/bignumber"
import { Contract } from "@ethersproject/contracts"
import { utils } from "ethers"
import { useSelector } from "react-redux"
import { AppState } from "../state"
import {
  formatBNToString,
  formatBNToShortString,
  commify,
  getExtraTokenByAddress,
  getPoolRoute,
} from "../utils"
import {
  PoolName,
  POOLS_MAP,
  STABLECOIN_POOL_NAME,
  SUSD_METAPOOL_NAME,
  OUSD_METAPOOL_NAME,
  BAI_METAPOOL_NAME,
  STARLAY_POOL_NAME,
  JPYC_POOL_NAME,
  SRS_TOKEN,
  TOKENS_MAP,
  PoolTypes,
  WBTC_METAPOOL_NAME,
  WETH_METAPOOL_NAME,
  WBNB_METAPOOL_NAME,
  DOT_METAPOOL_NAME,
  LASTR_METAPOOL_NAME,
  AVAULT_POOL_NAME,
  PUSDT_METAPOOL_NAME,
} from "../constants"
import {
  useFarmsContract,
  useTokenContract,
  useSRSContract,
} from "./useContract"
import { useActiveWeb3React } from "."
import usePoolData, { PoolDataType, UserShareType } from "./usePoolData"
import {
  useGRW,
  useRewardSRSPerSecond,
  useFarmExtraRewards,
  YearValueToAPY,
  useLPTokenPrice,
} from "./useFarmData"

const ONE_YEAR = 60 * 60 * 24 * 365

export function useMyTotalStaked() {
  const [myTotalStaked, setMyTotalStaked] = useState(Zero)
  const { account, chainId } = useActiveWeb3React()
  const farmsData = {} as { [k in PoolName]: { myStaked: BigNumber } }

  const farmContracts = {
    [STABLECOIN_POOL_NAME]: useFarmsContract(
      POOLS_MAP[STABLECOIN_POOL_NAME].farmAddresses,
    ),
    [SUSD_METAPOOL_NAME]: useFarmsContract(
      POOLS_MAP[SUSD_METAPOOL_NAME].farmAddresses,
    ),
    [OUSD_METAPOOL_NAME]: useFarmsContract(
      POOLS_MAP[OUSD_METAPOOL_NAME].farmAddresses,
    ),
    [BAI_METAPOOL_NAME]: useFarmsContract(
      POOLS_MAP[BAI_METAPOOL_NAME].farmAddresses,
    ),
    [STARLAY_POOL_NAME]: useFarmsContract(
      POOLS_MAP[STARLAY_POOL_NAME].farmAddresses,
    ),
    [JPYC_POOL_NAME]: useFarmsContract(POOLS_MAP[JPYC_POOL_NAME].farmAddresses),
    [WBTC_METAPOOL_NAME]: useFarmsContract(
      POOLS_MAP[WBTC_METAPOOL_NAME].farmAddresses,
    ),
    [WETH_METAPOOL_NAME]: useFarmsContract(
      POOLS_MAP[WETH_METAPOOL_NAME].farmAddresses,
    ),
    [WBNB_METAPOOL_NAME]: useFarmsContract(
      POOLS_MAP[WBNB_METAPOOL_NAME].farmAddresses,
    ),
    [DOT_METAPOOL_NAME]: useFarmsContract(
      POOLS_MAP[DOT_METAPOOL_NAME].farmAddresses,
    ),
  } as { [k in PoolName]: Contract | null }

  function totalStaked() {
    let total = "0"
    Object.values(farmsData).map((item) => {
      total = BigNumber.from(total)
        .add(item.myStaked || Zero)
        .toString()
    })
    setMyTotalStaked(BigNumber.from(total))
  }

  useEffect(() => {
    if (!account || !chainId) return void setMyTotalStaked(Zero)
    Object.values(POOLS_MAP).map((item) => {
      const _contract = farmContracts[item.name]
      if (!_contract || !item.farmAddresses?.[chainId]) return
      _contract
        ?.balanceOf(account)
        .then((res: BigNumber) => {
          farmsData[item.name] = { myStaked: res }
          totalStaked()
        })
        .catch(() => false)
    })
  }, [account, chainId, farmContracts, totalStaked])

  return myTotalStaked
}

export type MyExtraReward = {
  address: string
  vol: any
  symbol: any
}

export function useMyExtraRewards(poolName: PoolName) {
  const [myExtraRewards, setMyExtraRewards] = useState<MyExtraReward[]>([])
  const { account, chainId } = useActiveWeb3React()
  const farmContract = useFarmsContract(POOLS_MAP[poolName]?.farmAddresses)
  const getRewards = useCallback(async () => {
    try {
      if (!farmContract || !account) return

      const res1 = await farmContract?.rewardCount()
      const rewardSize = +formatBNToString(res1, 0)
      if (rewardSize <= 0) return

      const res2 = await farmContract?.getRewardTokensList(0, rewardSize)
      if (!res2?.length) return

      const rewards: MyExtraReward[] = []
      res2.map((address: string) => {
        farmContract
          ?.claimableReward(account, address)
          .then((res: BigNumber) => {
            rewards.push({
              address,
              vol: formatBNToString(res, 18),
              symbol:
                getExtraTokenByAddress(address, chainId || 592)?.symbol || "",
            })
            setMyExtraRewards(rewards)
          })
      })
    } catch (error) {
      setMyExtraRewards([])
    }
  }, [account, chainId, farmContract])

  useEffect(() => {
    void getRewards()
  }, [getRewards])

  return myExtraRewards
}

export function useMyAllExtraRewards() {
  const [myAllExtraRewards, setMyAllExtraRewards] = useState<{
    [key: string]: MyExtraReward[]
  }>({})

  const usdRewards = useMyExtraRewards(STABLECOIN_POOL_NAME)
  const susdRewards = useMyExtraRewards(SUSD_METAPOOL_NAME)
  const ousdRewards = useMyExtraRewards(OUSD_METAPOOL_NAME)
  const baiRewards = useMyExtraRewards(BAI_METAPOOL_NAME)
  // const starlayRewards = useMyExtraRewards(STARLAY_POOL_NAME)
  const jpycRewards = useMyExtraRewards(JPYC_POOL_NAME)
  const wbtcRewards = useMyExtraRewards(WBTC_METAPOOL_NAME)
  const wethRewards = useMyExtraRewards(WETH_METAPOOL_NAME)
  const wbnbRewards = useMyExtraRewards(WBNB_METAPOOL_NAME)
  const dotRewards = useMyExtraRewards(DOT_METAPOOL_NAME)
  const lAstrRewards = useMyExtraRewards(LASTR_METAPOOL_NAME)
  const avaultRewards = useMyExtraRewards(AVAULT_POOL_NAME)
  const pusdtRewards = useMyExtraRewards(PUSDT_METAPOOL_NAME)

  useEffect(() => {
    const data = {
      [STABLECOIN_POOL_NAME]: usdRewards,
      [SUSD_METAPOOL_NAME]: susdRewards,
      [OUSD_METAPOOL_NAME]: ousdRewards,
      [BAI_METAPOOL_NAME]: baiRewards,
      // [STARLAY_POOL_NAME]: starlayRewards,
      [JPYC_POOL_NAME]: jpycRewards,
      [WBTC_METAPOOL_NAME]: wbtcRewards,
      [WETH_METAPOOL_NAME]: wethRewards,
      [WBNB_METAPOOL_NAME]: wbnbRewards,
      [DOT_METAPOOL_NAME]: dotRewards,
      [LASTR_METAPOOL_NAME]: lAstrRewards,
      [AVAULT_POOL_NAME]: avaultRewards,
      [PUSDT_METAPOOL_NAME]: pusdtRewards,
    }
    setMyAllExtraRewards(data)
  }, [
    usdRewards,
    susdRewards,
    ousdRewards,
    baiRewards,
    // starlayRewards,
    jpycRewards,
    wbtcRewards,
    wethRewards,
    wbnbRewards,
    dotRewards,
    lAstrRewards,
    avaultRewards,
    pusdtRewards,
  ])

  return myAllExtraRewards
}

type MyFarmData = {
  myStaked: BigNumber
  myStakedPrice: BigNumber
  lPTokenPrice: number
  unClaimSRS: BigNumber
  tvl: BigNumber
  apy: string
  totalApy?: number
}
export function useMyFarmData(poolName: PoolName) {
  const [myFarmData, setMyFarmData] = useState<MyFarmData>({
    myStaked: Zero,
    myStakedPrice: Zero,
    lPTokenPrice: 1,
    unClaimSRS: Zero,
    tvl: Zero,
    apy: "",
  })
  const { account, chainId } = useActiveWeb3React()
  const { srsPrice } = useSelector((state: AppState) => state.application)
  const LPTokenPrice = useLPTokenPrice(poolName)
  const farmExtraRewards = useFarmExtraRewards(poolName)

  const farmContract = useFarmsContract(POOLS_MAP[poolName]?.farmAddresses)
  const tokenContract = useTokenContract(POOLS_MAP[poolName]?.lpToken)
  const rewardSrsPS = useRewardSRSPerSecond()
  const [rewardRate] = useGRW({ poolName })

  useEffect(() => {
    // get myStaked and unclaimSRS
    if (!farmContract || !account) return

    farmContract?.balanceOf(account).then((res: BigNumber) => {
      setMyFarmData((_state) => ({
        ..._state,
        myStaked: res,
        myStakedPrice: res
          .mul(utils.parseUnits((LPTokenPrice || 1).toString()))
          .div(BigNumber.from(10).pow(18)),
        lPTokenPrice: LPTokenPrice || 1,
      }))
    })
    farmContract?.claimableTokens(account).then((res: BigNumber) => {
      setMyFarmData((_state) => ({ ..._state, unClaimSRS: res }))
    })
  }, [account, poolName, farmContract, LPTokenPrice])

  const getLpToken = useCallback(() => {
    const poolInfo = POOLS_MAP[poolName]
    if (!chainId || !tokenContract || !poolInfo) return
    const farmAddress = poolInfo.farmAddresses?.[chainId]
    if (!farmAddress) return

    tokenContract?.balanceOf(farmAddress).then((res: BigNumber) => {
      const bal = res
        .mul(utils.parseUnits((LPTokenPrice || 1).toString()))
        .div(BigNumber.from(10).pow(18))

      const tvl = +formatBNToString(bal, 18) || 0

      const rewardPerYear = rewardSrsPS * ONE_YEAR * srsPrice * rewardRate

      const baseApr = tvl === 0 ? 0 : (100 * rewardPerYear) / tvl

      let _totalApy = Zero as any
      farmExtraRewards.map((item) => {
        _totalApy = _totalApy.add(
          utils
            .parseUnits((item.rewardPerYear || 0).toString())
            .mul(100)
            .div(bal),
        )
      })
      _totalApy = _totalApy.toNumber()
      _totalApy += baseApr

      setMyFarmData((_state) => ({
        ..._state,
        tvl: bal,
        apy: baseApr.toString(),
        totalApy: _totalApy,
      }))
    })
  }, [
    chainId,
    poolName,
    srsPrice,
    tokenContract,
    LPTokenPrice,
    rewardSrsPS,
    farmExtraRewards,
    rewardRate,
  ])
  useEffect(() => {
    void getLpToken()
  }, [getLpToken])

  return myFarmData
}

export type PoolProps = {
  name: string
  poolData: PoolDataType
  userShareData: UserShareType
  poolRoute: string
  farmData: MyFarmData
}
export type MyPoolsDataToken = {
  symbol: string
  icon: string
  name: string
  value: string
}
export type MyPoolsDataType = PoolProps & {
  tokens: MyPoolsDataToken[]
  name: string
  apy: BigNumber | null
  rewardApy: string
  deposited: string
  staked: string
  poolRoute: string
}
export type UseMyPoolsDataType = { [k in PoolName]: MyPoolsDataType }
export function usePropsForPool(poolName: PoolName) {
  const [myPoolData, setMyPoolData] = useState<PoolProps>({} as PoolProps)
  const [poolData, userShareData] = usePoolData(poolName)
  const farmData = useMyFarmData(poolName)

  useEffect(() => {
    setMyPoolData({
      name: poolName,
      poolData,
      userShareData: userShareData as UserShareType,
      poolRoute: getPoolRoute(poolName)?.poolRoute,
      farmData,
    })
  }, [poolName, poolData, userShareData, farmData])

  return myPoolData
}
export function useMyPoolsData() {
  const [myPoolsData, setMyPoolsData] = useState<UseMyPoolsDataType>(
    {} as UseMyPoolsDataType,
  )
  const { account, chainId } = useActiveWeb3React()

  const usdData = usePropsForPool(STABLECOIN_POOL_NAME)
  const susdData = usePropsForPool(SUSD_METAPOOL_NAME)
  const ousdData = usePropsForPool(OUSD_METAPOOL_NAME)
  const baiData = usePropsForPool(BAI_METAPOOL_NAME)
  // const starlayData = usePropsForPool(STARLAY_POOL_NAME)
  const jpycData = usePropsForPool(JPYC_POOL_NAME)
  const wbtcData = usePropsForPool(WBTC_METAPOOL_NAME)
  const wethData = usePropsForPool(WETH_METAPOOL_NAME)
  const wbnbData = usePropsForPool(WBNB_METAPOOL_NAME)
  const dotData = usePropsForPool(DOT_METAPOOL_NAME)
  const lAstrData = usePropsForPool(LASTR_METAPOOL_NAME)
  const avaultData = usePropsForPool(AVAULT_POOL_NAME)
  const pusdtData = usePropsForPool(PUSDT_METAPOOL_NAME)

  // @ts-ignore
  const poolProps: { [k in PoolName]: PoolProps } = {
    [STABLECOIN_POOL_NAME]: usdData,
    [SUSD_METAPOOL_NAME]: susdData,
    [OUSD_METAPOOL_NAME]: ousdData,
    [BAI_METAPOOL_NAME]: baiData,
    // [STARLAY_POOL_NAME]: starlayData,
    [JPYC_POOL_NAME]: jpycData,
    [WBTC_METAPOOL_NAME]: wbtcData,
    [WETH_METAPOOL_NAME]: wethData,
    [WBNB_METAPOOL_NAME]: wbnbData,
    [DOT_METAPOOL_NAME]: dotData,
    [LASTR_METAPOOL_NAME]: lAstrData,
    [AVAULT_POOL_NAME]: avaultData,
    [PUSDT_METAPOOL_NAME]: pusdtData,
  }

  useEffect(() => {
    if (!account || !chainId) return
    Object.values(POOLS_MAP).map((item) => {
      const { name, addresses } = item
      if (!addresses?.[chainId]) return

      const poolData = poolProps[name] || {}
      const uData = poolData.userShareData || {}
      const farm = poolData.farmData || {}

      // filter value "0.0"
      const staked = +farm?.myStaked?.toString() ? farm.myStaked : Zero

      let deposited = Zero
      if (uData.lpTokenBalance) {
        deposited = uData.lpTokenBalance.add(staked)
      }
      // As long as one of the fields has value, it belongs to myPools
      if (deposited.toString() === "0" && staked.toString() === "0") return

      deposited = deposited
        .mul(utils.parseUnits((farm.lPTokenPrice || 1).toString()))
        .div(BigNumber.from(10).pow(18))

      const { type: poolType } = POOLS_MAP[name]
      const formattedDecimals = poolType === PoolTypes.USD ? 2 : 4

      const tokens = (uData.tokens || []).map((coin) => {
        const token = TOKENS_MAP[coin.symbol]
        return {
          symbol: token.symbol,
          name: token.name,
          icon: token.icon,
          value: formatBNToString(
            coin.value,
            token.decimals,
            formattedDecimals,
          ),
        }
      })

      const data = {
        ...poolData,
        tokens,
        name,
        apy: poolData.poolData.apy,
        rewardApy: farm.apy ? farm.totalApy?.toString() : "",
        deposited: formatBNToShortString(deposited, 18),
        staked: formatBNToShortString(farm.myStakedPrice, 18),
        poolRoute: poolData.poolRoute,
      }
      setMyPoolsData((_state) => ({ ..._state, [name]: data }))
    })
  }, [
    account,
    chainId,
    usdData,
    susdData,
    ousdData,
    baiData,
    // starlayData,
    jpycData,
    wbtcData,
    wethData,
    wbnbData,
    dotData,
    lAstrData,
    avaultData,
    pusdtData,
  ])

  return myPoolsData
}

export function useMySrsBalance(): [BigNumber, () => void] {
  const [bal, setBal] = useState(Zero)
  const { account } = useActiveWeb3React()
  const _contract = useTokenContract(SRS_TOKEN)

  const getData = useCallback(() => {
    if (!account) return setBal(Zero)
    _contract?.balanceOf(account).then((res: BigNumber) => setBal(res))
  }, [account, _contract])

  useEffect(() => {
    getData()
  }, [getData])
  return [bal, getData]
}
