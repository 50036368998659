import { getCurrProvider } from "../connectors"

const SUPPORTED_NETWORKS: {
  [key: string]: any
} = {
  // [ChainId.MAINNET]: {
  //   chainId: "0x1",
  //   chainName: "Ethereum",
  //   nativeCurrency: {
  //     name: "Ethereum",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   rpcUrls: ["https://mainnet.infura.io/v3"],
  //   blockExplorerUrls: ["https://etherscan.com"],
  // },
  ASTAR: {
    chainId: "0x250",
    chainName: "Astar Network",
    nativeCurrency: {
      name: "ASTAR",
      symbol: "ASTR",
      decimals: 18,
    },
    rpcUrls: ["https://astar.blastapi.io/778e2d78-05c4-452d-af8e-4b70268c6c9d"],
    blockExplorerUrls: ["https://blockscout.com/astar"],
  },
  SHIBUYA: {
    chainId: "0x51",
    chainName: "Shibuya(testnet)",
    nativeCurrency: {
      name: "SHIBUYA",
      symbol: "SBY",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.shibuya.astar.network:8545"],
    blockExplorerUrls: ["https://blockscout.com/shibuya"],
  },
}

export default async function switchNetwork(NetworkName: string) {
  //TO DO：需要优化整理
  // console.log(NetworkName);
  const param = SUPPORTED_NETWORKS["ASTAR"]
  // console.log(param)
  const _chainid = param["chainId"]
  const { currConnector } = getCurrProvider()

  if (currConnector) {
    try {
      // check if the chain to connect to is installed
      await currConnector.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: _chainid }], // chainId must be in hexadecimal numbers
      })
    } catch (error: any) {
      // This error code indicates that the chain has not been added to MetaMask
      // if it is not, then install it into the user MetaMask
      if (error.code === 4902) {
        try {
          await currConnector.request({
            method: "wallet_addEthereumChain",
            params: [param],
          })
        } catch (addError) {
          console.error(addError)
        }
      }
      console.error(error)
    }
  } else {
    // if no window.ethereum then MetaMask is not installed
    alert(
      "MetaMask is not installed. Please consider installing it: https://metamask.io/download.html",
    )
  }
}
