import React, { ReactElement } from "react"
import { useTheme, Box } from "@mui/material"
import TopMenu from "./TopMenu"
import MobileBottomBar from "./MobileBottomBar"
import Footer from "./Footer"

interface Props {
  children: ReactElement | ReactElement[]
}

export default function Layout(props: Props): ReactElement {
  const { children } = props
  const theme = useTheme()
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <TopMenu />
      <Box
        sx={{
          flexGrow: 1,
          display: { xs: "flex", sm: "block" },
          // Used to fix if App.tsx adds <MobileView/>, this children will have an additional div
          ">div": {
            width: "100%",
            [theme.breakpoints.down("sm")]: {
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            },
          },
        }}
      >
        {children}
      </Box>
      <Box sx={{ display: { xs: "none", sm: "flex" } }}>
        <Footer />
      </Box>
      <MobileBottomBar />
    </Box>
  )
}
