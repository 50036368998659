import React, { useEffect, useState, ReactElement, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  useTheme,
  useMediaQuery,
  Grid,
  Container,
  Box,
  Typography,
  Dialog,
  Divider,
  Drawer,
  IconButton,
} from "@mui/material"
import GovernanceInfo from "./../components/GovernanceInfo"
// import ReviewGovernance from "./../components/ReviewGovernance"
import TokenInput from "../components/TokenInput"
import { useVeTokenContract, useTokenContract } from "../hooks/useContract"
import { useActiveWeb3React } from "../hooks"
import { SRS_TOKEN, VE_TOKEN } from "../constants"
import { BigNumber } from "ethers"
import Button from "../components/Button"
import {
  formatBNToShortString,
  formatBNToString,
  parseBalance,
  shiftBNDecimals,
  commify,
} from "../utils"
import CustomTooltip from "../components/CustomTooltip"
import { BottomPadding } from "../components/MobileBottomBar"
import ConfirmTransaction from "../components/ConfirmTransaction"
// import LockIcon from "@mui/icons-material/Lock"
import LockIcon from "../assets/icons/lock.svg"
import LockDisabledIcon from "../assets/icons/lock-disabled.svg"
import ReviewLock from "../components/ReviewLock"
import ReviewUnlock from "../components/ReviewUnlock"
import moment from "moment"
import { notify } from "../utils/notifyHandler"
import SRS_ICON from "./../assets/icons/srs.png"
import { ArrowBack, Close as CloseIcon } from "@mui/icons-material"
import _ from "lodash"
import { useGasPrice } from "./../hooks/useGas"
import { AppState } from "../state"
import { setFirstTimePopup } from "./GovernanceSlice"
import GovInfoIcon from "../assets/icons/gov_info_icon.png"
import { useTranslation } from "react-i18next"
import bellIcon from "../assets/icons/bell.svg"
import "./Governance.scss"

const PageDesc = (): ReactElement | null => {
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))
  const { firstTimePopup } = useSelector((state: AppState) => state.governance)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  if (!isDownSm || firstTimePopup === false) return null

  return (
    <Drawer
      anchor="bottom"
      open={firstTimePopup}
      onClose={() => dispatch(setFirstTimePopup(false))}
      PaperProps={{
        sx: { borderWidth: "1px 0 0 0", borderRadius: "16px 16px 0 0", p: 4 },
      }}
    >
      <IconButton
        onClick={() => dispatch(setFirstTimePopup(false))}
        sx={{ position: "absolute", right: 24, top: 24 }}
      >
        <CloseIcon />
      </IconButton>
      <Box pb={2}>
        <Typography variant="h2" mb={3}>
          {t("Governance")}
        </Typography>
        <Box textAlign="center">
          <img src={GovInfoIcon} />
        </Box>
        <Typography variant="subtitle2" mt={3}>
          {t("governanceIntro")}
        </Typography>
      </Box>
    </Drawer>
  )
}

const Governance = (): ReactElement => {
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))

  const [showReview, setShowReview] = useState(false)
  const [showLock, setShowLock] = useState(true)
  const [lockPopup, setLockPopup] = useState(false)
  const [unlockPopup, setUnlockPopup] = useState(false)
  const [isIncrease, setIsIncrease] = useState(false)

  const { account } = useActiveWeb3React()
  const gasPrice = useGasPrice()
  const veTokenContract = useVeTokenContract()
  const srsToken = useTokenContract(SRS_TOKEN)
  const [lockSrsInput, setLockSrsInput] = useState("")
  const [srsBalance, setSrsBalance] = useState("0")
  const [selectedTime, setSelectTime] = useState(0)
  const [lockPreviewData, setLockPreviewData] = useState({})
  const [showDialog, setShowDialog] = useState(false)
  const [lockedSrs, setLockedSrs] = useState("0")
  // const [myVeTokenMinted, setMyVeTokenMinted] = useState("0")
  const [unlockTime, setUnlockTime] = useState(0)
  const [myLockedSrs, setMyLockedSrs] = useState("0")
  const [flow, setFlow] = useState("")
  const [veTokenMinted, setVeTokenMinted] = useState("0")
  const [finalInput, setFinalInput] = useState("")
  const [finalSelectedTime, setFinalSelectedTime] = useState(0)
  const [percentage, setPercentage] = useState(0)

  //unlock b
  const [isInBlackList, setIsInBlackList] = useState(false)
  const [UnlockPreviewData, setUnlockPreviewData] = useState({})
  const vesrsToken = useTokenContract(VE_TOKEN)
  const [unlockSrsInput, setUnlockSrsInput] = useState("")
  const [finalVesrs, setFinalVesrs] = useState("")
  const [penaltyRate, setPenaltyRate] = useState("0.3")
  const [vesrsBalance, setVesrsBalance] = useState("0")

  const { t } = useTranslation()
  const getVesrsBalance = useCallback(async () => {
    const LockTimeRes = await veTokenContract?.locked(account)
    if (!LockTimeRes) return
    if (!LockTimeRes.end) return
    const unlockTime = LockTimeRes.end.toNumber()
    if (moment.unix(unlockTime).isBefore()) {
      veTokenContract?.getLastUserPoint(account).then((res: any) => {
        // console.log(formatBNToString(res[0], 18));
        if (res[0].gt(0)) setIsIncrease(true)
        setVesrsBalance(formatBNToString(res[0], 18))
      })
    } else {
      vesrsToken?.balanceOf(account).then((res: BigNumber) => {
        //没有到期，是不是从这里获取vesrs balance
        if (res.gt(0)) setIsIncrease(true)
        setVesrsBalance(formatBNToString(res, 18))
      })
    }
  }, [account, veTokenContract, vesrsToken])
  const getPenaltyRate = useCallback(() => {
    veTokenContract?.earlyWithdrawPenaltyRate().then((res: BigNumber) => {
      setPenaltyRate(formatBNToString(res, 18))
    })
  }, [veTokenContract])
  const withdraw = async (): Promise<void> => {
    if (moment.unix(unlockTime).isBefore()) {
      const transaction: Promise<void> = await veTokenContract?.withdraw({
        gasPrice: gasPrice,
      })
      notify.notification({
        type: "pending",
        message: t("yourTransHasBeenSentToNetwork"),
        autoDismiss: 5000,
      })

      return transaction
    } else {
      const transaction: Promise<void> =
        await veTokenContract?.emergencyWithdraw({ gasPrice: gasPrice })
      notify.notification({
        type: "pending",
        message: t("yourTransHasBeenSentToNetwork"),
        autoDismiss: 5000,
      })

      return transaction
    }
  }
  //unlock e

  const getSrsBalance = useCallback(() => {
    srsToken?.balanceOf(account).then((res: BigNumber) => {
      setSrsBalance(formatBNToString(res, 18))
    })
  }, [srsToken, account])

  const getLockedSrs = useCallback(() => {
    srsToken?.balanceOf(veTokenContract?.address).then((res: BigNumber) => {
      setLockedSrs(formatBNToString(res, 18, 2))
    })
  }, [srsToken, veTokenContract])

  // const getMyVeTokenMinted = useCallback(() => {
  //   veTokenContract?.["balanceOf(address)"](account).then((res: BigNumber) => {
  //     setMyVeTokenMinted(formatBNToString(res, 18))
  //     if (res.gt(0)) {
  //       setIsIncrease(true)
  //     }
  //   })
  // }, [veTokenContract, account])

  const getmyLockedSrs = useCallback(() => {
    veTokenContract
      ?.locked(account)
      .then((res: { amount: BigNumber; end: BigNumber }) => {
        if (res.end) {
          setUnlockTime(res.end.toNumber())
        }

        setMyLockedSrs(formatBNToString(res.amount, 18))
      })
  }, [veTokenContract, account])

  const getVeTokenMinted = useCallback(() => {
    veTokenContract?.["totalSupply()"]().then((res: BigNumber) => {
      setVeTokenMinted(formatBNToString(res, 18, 2))
    })
  }, [veTokenContract])

  const getBlackList = useCallback(async () => {
    try {
      const res = await veTokenContract?.isBlacklisted(account)
      setIsInBlackList(res)
    } catch (err) {
      console.error("getBlackList", err)
      setIsInBlackList(false)
    }
  }, [account, veTokenContract])

  useEffect(() => {
    getSrsBalance()
    getLockedSrs()
    // getMyVeTokenMinted()
    getmyLockedSrs()
    getVeTokenMinted()

    void getVesrsBalance()
    getPenaltyRate()
    void getBlackList()
  }, [])

  const createLock = async (): Promise<void> => {
    const now = moment().unix()
    const endTime = +now + selectedTime

    const transaction: Promise<void> = await veTokenContract?.createLock(
      parseBalance(lockSrsInput, 18),
      endTime,
      { gasPrice: gasPrice },
    )
    notify.notification({
      type: "pending",
      message: t("yourTransHasBeenSentToNetwork"),
      autoDismiss: 5000,
    })

    return transaction
  }

  const increaseAmount = async (): Promise<void> => {
    const transaction: Promise<void> = await veTokenContract?.increaseAmount(
      parseBalance(lockSrsInput, 18),
      { gasPrice: gasPrice },
    )
    notify.notification({
      type: "pending",
      message: t("yourTransHasBeenSentToNetwork"),
      autoDismiss: 5000,
    })

    return transaction
  }

  const increaseUnlockTime = async (): Promise<void> => {
    console.log(unlockTime, selectedTime)

    const endTime = +unlockTime + selectedTime

    const transaction: Promise<void> =
      await veTokenContract?.increaseUnlockTime(endTime, { gasPrice: gasPrice })
    notify.notification({
      type: "pending",
      message: t("yourTransHasBeenSentToNetwork"),
      autoDismiss: 5000,
    })

    return transaction
  }

  const afterCompleted = () => {
    // refresh
    getLockedSrs()
    getSrsBalance()
    // getMyVeTokenMinted()
    getmyLockedSrs()
    getVeTokenMinted()
    void getVesrsBalance()

    // cleanup
    if (isDownSm) {
      lockResetInput()
      setLockPopup(false)
    } else {
      setSelectTime(0)
      setLockSrsInput("")
    }
  }

  const getIsDisabledBtn = () => {
    if (+lockSrsInput > +srsBalance) {
      return true
    }

    return isIncrease
      ? !selectedTime && !lockSrsInput
      : !selectedTime || !lockSrsInput
  }

  const getBtnText = () => {
    if (+lockSrsInput > +srsBalance) {
      return t("insufficientBalance")
    }

    if (isIncrease) {
      if (flow === "addToken") {
        return !lockSrsInput ? t("EnterAmount") : t("PreviewLock")
      }
      if (flow === "addTime") {
        return !selectedTime ? t("AddLockPeriod") : t("PreviewLock")
      }
    }

    return !lockSrsInput || !selectedTime ? t("EnterAmount") : t("PreviewLock")
  }

  const getCompletedInfo = (): ReactElement => {
    if (showLock && isIncrease && unlockTime && finalSelectedTime) {
      return (
        <Typography fontSize={12}>
          {t("Youhaveupdatedunlocktimeto")}:{" "}
          {moment.unix(unlockTime + selectedTime).format("DD MMM,YYYY")}
        </Typography>
      )
    }

    return (
      <Typography fontSize={12}>
        {t("Youhavelocked")} {finalInput} SRS.
      </Typography>
    )
  }
  const getCompletedInfoForUnlock = (): ReactElement => {
    return (
      <Typography fontSize={12}>
        {t("Youhavelocked")} {commify(finalVesrs)} veSRS.
      </Typography>
    )
  }

  const ReviewBox = () => {
    const content = showLock ? (
      <ReviewLock
        isIncrease={isIncrease}
        data={lockPreviewData}
        onClose={() => setShowReview(false)}
        onConfirm={() => {
          setShowReview(false)
          setShowDialog(true)
        }}
      />
    ) : (
      <ReviewUnlock
        data={UnlockPreviewData}
        onClose={() => setShowReview(false)}
        onConfirm={() => {
          setShowReview(false)
          setShowDialog(true)
        }}
      />
    )

    if (!isDownSm)
      return <Box className={showReview ? "" : "hide"}>{content}</Box>

    return (
      <Drawer
        anchor="bottom"
        open={showReview}
        onClose={() => setShowReview(false)}
        BackdropProps={{ sx: { backgroundColor: "transparent" } }}
        PaperProps={{
          sx: {
            borderWidth: "1px 0 0 0",
            borderRadius: "16px 16px 0 0",
            backgroundColor: "transparent",
            height: "calc(100vh - 72px)",
          },
        }}
      >
        {content}
        <BottomPadding />
      </Drawer>
    )
  }

  const lockResetInput = () => {
    setLockSrsInput("")
    setSelectTime(0)
    setFinalInput("")
    setFinalSelectedTime(0)
    setFlow("")
    setPercentage(0)
  }

  const LockBox = () => {
    const lockPeriods = [
      {
        label: `15 ${t("days")}`,
        value: 86400 * 15,
      },
      {
        label: `1 ${t("Month")}`,
        value: 86400 * 30,
      },
      {
        label: `6 ${t("Months")}`,
        value: 86400 * (365 / 2),
      },
      {
        label: `1 ${t("Year")}`,
        value: 86400 * 365,
      },
      {
        label: `2 ${t("Years")}`,
        value: 86400 * 365 * 2,
      },
      {
        label: `3 ${t("Years")}`,
        value: 86400 * 365 * 3,
      },
    ]

    const content = (
      <>
        {((showLock && !isIncrease) || (showLock && isIncrease && flow)) && (
          <Box
            mt={4}
            sx={{
              [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              },
            }}
          >
            {flow && (
              <Box px={6} mb={2}>
                <ArrowBack
                  onClick={() => lockResetInput()}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Box>
            )}
            <TokenInput
              {...SRS_TOKEN}
              inputValue={lockSrsInput}
              onChange={(val) => {
                if (isIncrease) {
                  setSelectTime(0)
                }
                setLockSrsInput(val)
              }}
              max={srsBalance}
              disabled={flow === "addTime"}
              showBorderBottom={false}
              showMax={false}
            />
            <Box
              display="flex"
              justifyContent="space-around"
              mt={2}
              pb={2}
              px={"48px"}
              className="btns"
            >
              {[25, 50, 75, 100].map((item) => (
                <Button
                  kind="outline"
                  size="small"
                  key={item}
                  className={
                    percentage && percentage === item
                      ? "active percentage-btn"
                      : "percentage-btn"
                  }
                  disabled={flow === "addTime"}
                  onClick={() => {
                    setPercentage(item)
                    setLockSrsInput(
                      _.floor(+srsBalance * (item / 100), 4).toString(),
                    )
                  }}
                >
                  {item === 100 ? t("max") : `${item}%`}
                </Button>
              ))}
            </Box>
            <Box mt={2}>
              <Divider />
            </Box>
            <Box
              px={6}
              mt={4}
              display="flex"
              justifyContent="start"
              alignItems="center"
            >
              <img src={LockDisabledIcon} />
              <Box ml={1}>
                <Typography
                  fontSize={20}
                  fontWeight={600}
                  className={`${flow === "addToken" ? "text-disabled" : ""}`}
                >
                  {t("LockPeriod")}
                </Typography>
                <Typography
                  fontSize={12}
                  className={`${flow === "addToken" ? "text-disabled" : ""}`}
                >
                  <Typography component="span" fontSize={12}>
                    {t("unlockDate")}:{" "}
                    {unlockTime
                      ? moment.unix(unlockTime).format("DD MMM,YYYY")
                      : selectedTime
                      ? moment
                          .unix(+moment().unix() + selectedTime)
                          .format("DD MMM,YYYY")
                      : "-"}
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Grid
              columns={3}
              mt={2}
              px={6}
              container
              columnSpacing={4}
              rowSpacing={2}
            >
              {lockPeriods.map((lockPeriod) => (
                <Grid
                  textAlign="center"
                  item
                  xs={1}
                  key={lockPeriod.value}
                  onClick={() => {
                    if (
                      flow === "addToken" ||
                      unlockTime + lockPeriod.value - moment().unix() >
                        86400 * 365 * 3
                    ) {
                      return
                    }
                    if (isIncrease) {
                      setLockSrsInput("")
                    }
                    setSelectTime(lockPeriod.value)
                  }}
                >
                  <span
                    className={`lock-period-item ${
                      selectedTime === lockPeriod.value ? "selected" : ""
                    } ${
                      flow === "addToken" ||
                      unlockTime + lockPeriod.value - moment().unix() >
                        86400 * 365 * 3
                        ? "disabled"
                        : ""
                    }`}
                  >
                    {lockPeriod.label}
                  </span>
                </Grid>
              ))}
            </Grid>
            <Box flexGrow={1} />
            <Box mt={6}>
              <Button
                size="large"
                fullWidth
                onClick={(): void => {
                  setLockPreviewData({
                    veSRSAmount: isIncrease
                      ? lockSrsInput
                        ? commify(
                            // 增加币
                            (
                              (unlockTime - +moment().unix()) *
                              (+lockSrsInput / 94608000)
                            )
                              .toFixed(2)
                              .toString(),
                          )
                        : commify(
                            // 增加时间
                            (selectedTime * (+myLockedSrs / 94608000))
                              .toFixed(2)
                              .toString(),
                          )
                      : commify(
                          // 第一次添加
                          (selectedTime * (+lockSrsInput / 94608000))
                            .toFixed(2)
                            .toString(),
                        ),
                    unlockTime: moment
                      .unix(
                        unlockTime
                          ? unlockTime + selectedTime
                          : moment().unix() + selectedTime,
                      )
                      .format("DD MMM,YYYY"),
                    lockPeriod: lockPeriods.find(
                      (item) => item.value === selectedTime,
                    )?.label,
                    lockSrsInput: commify(
                      (+lockSrsInput).toFixed(2).toString(),
                    ),
                    totalLocked: commify((+myLockedSrs).toFixed(2).toString()),
                    myVeTokenMinted: commify(
                      (+vesrsBalance).toFixed(2).toString(),
                    ),
                    penaltyRate: penaltyRate,
                  })
                  setFinalInput(lockSrsInput)
                  setFinalSelectedTime(selectedTime)
                  setShowReview(true)
                }}
                className="lock-btn"
                disabled={getIsDisabledBtn()}
              >
                {getBtnText()}
              </Button>
            </Box>
          </Box>
        )}
        {showLock && isIncrease && !flow && (
          <Box
            py={4}
            display={{ xs: "block", sm: "flex" }}
            justifyContent="space-between"
            gap={{ xs: 2, sm: 4 }}
            px={{ xs: 3, sm: 5 }}
          >
            <Box
              flex={1}
              className="flow-add-srs"
              onClick={() => setFlow("addToken")}
            >
              <img src={SRS_ICON} width={54} height={54} />
              <Typography fontSize={{ xs: 16, sm: 20 }} fontWeight={600}>
                {t("AddmoreSRS")}
              </Typography>
              <Typography fontSize={12}>
                {t("balance")}: {commify((+srsBalance).toFixed(2).toString())}
              </Typography>
            </Box>
            <Box
              flex={1}
              className="flow-add-time"
              onClick={() => setFlow("addTime")}
            >
              <img src={LockIcon} width={38} height={44} />
              <Typography fontSize={{ xs: 16, sm: 20 }} fontWeight={600}>
                {t("AddLockPeriod")}
              </Typography>
              <Typography fontSize={12}>
                {t("unlockDate")}:{" "}
                {moment.unix(unlockTime).format("DD MMM,YYYY")}
              </Typography>

              <CustomTooltip
                className="shorten-lock"
                info={t("governanceTooltip1") || "governanceTooltip"}
              >
                <Typography
                  variant="body1"
                  color="#ffcdec"
                  fontSize={12}
                  sx={{
                    textDecoration: "dashed underline",
                    cursor: "pointer",
                  }}
                >
                  {t("Shortenlockperiod")}
                </Typography>
              </CustomTooltip>
            </Box>
          </Box>
        )}
      </>
    )

    if (!isDownSm) return content

    return (
      <Drawer
        anchor="bottom"
        open={lockPopup}
        onClose={() => {
          lockResetInput()
          setLockPopup(false)
        }}
        BackdropProps={{ sx: { backgroundColor: "transparent" } }}
        PaperProps={{
          sx: {
            borderWidth: "1px 0 0 0",
            borderRadius: "16px 16px 0 0",
            backgroundColor: "transparent",
            height: "calc(100vh - 72px)",
          },
        }}
      >
        <IconButton
          onClick={() => {
            lockResetInput()
            setLockPopup(false)
          }}
          sx={{ position: "absolute", right: 24, top: 24 }}
        >
          <CloseIcon />
        </IconButton>
        {/* <Box sx={{ backgroundColor: "var(--primary-background)" }}> */}
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "100%",
            pt: 5,
          }}
          className="gov-page"
        >
          {content}
        </Box>
        <BottomPadding />
      </Drawer>
    )
  }

  const UnlockBox = () => {
    const content = !showLock ? ( //unlock page
      <Box
        mt={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <TokenInput
          {...VE_TOKEN}
          inputValue={commify(vesrsBalance)}
          onChange={(val) => {
            setUnlockSrsInput(val)
          }}
          max={vesrsBalance}
          showBorderBottom={false}
          disabled={true}
          className="unlock_tokeninput"
        />
        <Box mt={2}>
          <Divider />
        </Box>
        <Box
          px={6}
          mt={4}
          display={{ sm: "flex" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" justifyContent="start" alignItems="center">
            <img src={LockDisabledIcon} />
            <Box ml={1}>
              <Typography fontSize={20} fontWeight={600}>
                {t("LockPeriod")}
              </Typography>
              <Typography fontSize={12}>
                {t("Earlyunlockpenaltyfee")}: {+penaltyRate * 100}%
              </Typography>
            </Box>
          </Box>
          <Box style={{ fontWeight: 700 }} mt={{ xs: 3, sm: 0 }}>
            {unlockTime
              ? moment.unix(unlockTime).isBefore()
                ? t("AnyTime")
                : moment.unix(unlockTime).format("DD MMM,YYYY")
              : "-"}
          </Box>
        </Box>
        <Box flexGrow={1} />
        {isInBlackList && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={4}
            className="alert"
            mx={"48px"}
            px={2}
          >
            <img src={bellIcon} />
            <Typography fontSize={12}>{t("govBlackListTooltip")}</Typography>
          </Box>
        )}

        <Box mt={2}>
          <Button
            size="large"
            fullWidth
            className="lock-btn"
            disabled={!+vesrsBalance || isInBlackList}
            onClick={() => {
              setUnlockPreviewData({
                veSRSAmount: vesrsBalance,
                isEmergency: !moment.unix(unlockTime).isBefore(),
                SRSAmount: myLockedSrs,
                penaltyRate: penaltyRate,
              })
              setFinalVesrs(vesrsBalance)
              setShowReview(true)
            }}
          >
            {t("PreviewUnlock")}
          </Button>
        </Box>
      </Box>
    ) : (
      <></>
    )

    if (!isDownSm) return content

    return (
      <Drawer
        anchor="bottom"
        open={unlockPopup}
        onClose={() => setUnlockPopup(false)}
        BackdropProps={{ sx: { backgroundColor: "transparent" } }}
        PaperProps={{
          sx: {
            borderWidth: "1px 0 0 0",
            borderRadius: "16px 16px 0 0",
            backgroundColor: "transparent",
            height: "calc(100vh - 72px)",
          },
        }}
      >
        <IconButton
          onClick={() => setUnlockPopup(false)}
          sx={{ position: "absolute", right: 24, top: 24 }}
        >
          <CloseIcon />
        </IconButton>
        {/* <Box sx={{ backgroundColor: "var(--primary-background)" }}> */}
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            width: "100%",
            pt: 5,
          }}
          className="gov-page"
        >
          {content}
        </Box>
        <BottomPadding />
      </Drawer>
    )
  }

  return (
    <Container
      className="gov-page"
      maxWidth="md"
      sx={{
        marginTop: { xs: 1, sm: 5 },
        [theme.breakpoints.down("sm")]: { px: 0 },
      }}
    >
      <PageDesc />
      <Typography variant="h2" mb={2} display={{ xs: "none", sm: "block" }}>
        {t("Governance")}
      </Typography>
      <Grid container spacing={{ sm: 3 }}>
        <Grid item xs={12} md={5}>
          <GovernanceInfo
            myLockedSrs={myLockedSrs}
            MyVeSRS={vesrsBalance}
            TotalLockSRS={lockedSrs}
            unlockTime={unlockTime}
            veTokenMinted={veTokenMinted}
            // TotalVeSRS=""
            // AverageLockedTime=""
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Box className="right-box">
            <Box className={showReview ? "hide" : ""}>
              <Box
                display="flex"
                justifyContent="center"
                pt={3}
                className="lock-unlock-box"
              >
                <div className="lock-unlock">
                  <div
                    className={`btn ${showLock && "active"}`}
                    onClick={() => {
                      setShowLock(true)
                      setLockPopup(true)
                    }}
                  >
                    {t("Lock")}
                  </div>
                  <div
                    className={`btn ${!showLock && "active"}`}
                    onClick={() => {
                      setShowLock(false)
                      setUnlockPopup(true)
                    }}
                  >
                    {t("Unlock")}
                  </div>
                </div>
                <Box display={{ sm: "none" }} mt={2}>
                  <BottomPadding />
                </Box>
              </Box>
              <LockBox />
              <UnlockBox />
            </Box>
            <ReviewBox />
          </Box>
        </Grid>
      </Grid>
      <Dialog open={!!showDialog} onClose={(): void => setShowDialog(false)}>
        {showLock && (
          <ConfirmTransaction
            tokens={[SRS_TOKEN]}
            targetContractAddress={veTokenContract?.address}
            handler={
              isIncrease
                ? lockSrsInput
                  ? increaseAmount
                  : increaseUnlockTime
                : createLock
            }
            afterCompleted={afterCompleted}
            onClose={() => setShowDialog(false)}
            completedInfo={getCompletedInfo()}
          />
        )}
        {!showLock && (
          <ConfirmTransaction
            tokens={[SRS_TOKEN]}
            targetContractAddress={veTokenContract?.address}
            handler={withdraw}
            afterCompleted={() => {
              afterCompleted()
              setIsIncrease(false)
            }}
            onClose={() => setShowDialog(false)}
            completedInfo={getCompletedInfoForUnlock()}
          />
        )}
      </Dialog>
    </Container>
  )
}

export default Governance
