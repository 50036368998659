import { Components } from "@mui/material"

export default function ToggleButtonTheme(): Components {
  return {
    MuiToggleButton: {
      styleOverrides: {
        // root: {
        //   textTransform: "none",
        //   color: theme.palette.primary.main,
        //   border: `1px solid ${theme.palette.primary.main}`,
        //   "&.Mui-selected": {
        //     backgroundColor: theme.palette.primary.main,
        //     border: `1px solid ${theme.palette.primary.main}`,
        //     color: "#150847",
        //     "&:hover": {
        //       backgroundColor: theme.palette.primary.main
        //       // backgroundColor: theme.palette.action.active,
        //     },
        //   },
        //   "&:hover": {
        //     backgroundColor: 'transparent',
        //     color: theme.palette.mute.main,
        //     border: `1px solid ${theme.palette.mute.main}`,
        //   },
        // },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        // root: {
        //   // backgroundColor: theme.palette.mute.main,
        //   maxHeight: 32,
        //   borderRadius: 99999,
        // },
        // grouped: {
        //   // border: 0,
        //   "&.Mui-disabled": {
        //     border: 0,
        //   },
        //   "&.Mui-selected": {
        //     // borderLeft: `1px solid ${theme.palette.primary.main}`,
        //     borderLeftWidth: `1px`,
        //     borderLeftColor: `${theme.palette.primary.main}`,
        //   },
        //   "&:not(:first-of-type)": {
        //     borderRadius: 99999,
        //     marginLeft: theme.spacing(0.5),
        //     borderLeftWidth: `1px`,
        //     borderLeftColor: `inherit`,
        //   },
        //   "&:first-of-type": {
        //     borderRadius: 99999,
        //     // borderLeft: `1px solid ${theme.palette.primary.main}`
        //   },
        // },
      },
    },
  }
}
