import React, { useEffect, useState, ReactElement, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  Grid,
  Container,
  Box,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material"
import { Zero } from "@ethersproject/constants"
import { BigNumber } from "@ethersproject/bignumber"
import moment from "moment"
import { useActiveWeb3React } from "../hooks"
import { useVeTokenContract } from "../hooks/useContract"
import { useMyAllExtraRewards, useMyPoolsData } from "../hooks/useMyData"
import { formatBNToString, commify, parseBalance } from "../utils"
import { ReactComponent as SrsSVG } from "./../assets/icons/srs.svg"
import { ReactComponent as VeSrsSVG } from "./../assets/icons/vesrs.svg"
import MoneyLogo from "../assets/Illustration-Money.png"
import styles from "./Dashboard.module.scss"
import CountUpBN from "../components/CountUpBN"
import CustomTooltip from "../components/CustomTooltip"
import MyPoolsComponent from "../components/MyPools"
import DailyTvlsChart from "../components/DailyTvlsChart"
import DailyVolumesTotal from "../components/DailyVolumesTotal"

const Dashboard = (): ReactElement => {
  const { t } = useTranslation()
  const history = useHistory()
  const { account } = useActiveWeb3React()

  const poolsData = useMyPoolsData()
  const myAllExtraRewards = useMyAllExtraRewards()
  const [TotalData, setTotalData] = useState({
    unClaimSRS: "",
    totalDeposit: Zero,
  })

  useEffect(() => {
    let _unClaimSRS = 0
    let _deposit = Zero

    Object.values(poolsData).map((item) => {
      _unClaimSRS += +formatBNToString(item?.farmData?.unClaimSRS || Zero, 18)
      _deposit = _deposit
        .add(item?.farmData?.myStakedPrice || Zero)
        .add(item?.userShareData?.usdBalance || Zero)
    })

    Object.values(myAllExtraRewards).map((rewards) => {
      rewards?.map((reward) => {
        if (reward?.symbol !== "SRS") return
        _unClaimSRS += +reward.vol || 0
      })
    })
    setTotalData({
      unClaimSRS: _unClaimSRS.toString(),
      totalDeposit: _deposit,
    })
  }, [poolsData, myAllExtraRewards])

  const veTokenContract = useVeTokenContract()
  const [myVeTokenMinted, setMyVeTokenMinted] = useState("0")
  const getMyVeTokenMinted = useCallback(() => {
    if (!account) return setMyVeTokenMinted("0")
    veTokenContract?.["balanceOf(address)"](account).then((res: BigNumber) => {
      setMyVeTokenMinted(formatBNToString(res, 18))
    })
  }, [account, veTokenContract])
  useEffect(() => {
    getMyVeTokenMinted()
  }, [getMyVeTokenMinted])

  const [myLockedSrs, setMyLockedSrs] = useState("0")
  const [unlockTime, setUnlockTime] = useState(0)
  const getmyLockedSrs = useCallback(() => {
    if (!account) {
      setMyLockedSrs("0")
      setUnlockTime(0)
      return
    }
    veTokenContract
      ?.locked(account)
      .then((res: { amount: BigNumber; end: BigNumber }) => {
        res.end && setUnlockTime(res.end.toNumber())
        setMyLockedSrs(formatBNToString(res.amount, 18))
      })
  }, [account, veTokenContract])
  useEffect(() => {
    getmyLockedSrs()
  }, [getmyLockedSrs])

  const [rightBoxData, setRightBoxData] = useState({})
  useEffect(() => {
    const data = {
      unClaimSRS: {
        icon: <SrsSVG />,
        link: "/farms",
        title: t("My Unclaimed SRS"),
        tooltip: t("unclaimedRewardsTooltip"),
        subtitle: t("Go to Farm"),
        amount: +TotalData.unClaimSRS ? commify(TotalData.unClaimSRS) : "-",
      },
      lockedSRS: {
        icon: <SrsSVG />,
        link: "/governance",
        title: t("My Locked SRS"),
        subtitle: t("Lock SRS to get veSRS"),
        unlockTime: unlockTime
          ? `${t("unlockDate")}: ${moment.unix(unlockTime).format("DD MMM,YYYY")}`
          : "", // only
        amount: +myLockedSrs ? commify(myLockedSrs) : "-",
      },
      veSRS: {
        icon: <VeSrsSVG />,
        link: "/governance",
        title: t("My veSRS"),
        subtitle: t("Go to Governance"),
        amount: +myVeTokenMinted ? commify(myVeTokenMinted) : "-",
      },
    }
    setRightBoxData(data)
  }, [TotalData, myLockedSrs, unlockTime, myVeTokenMinted])

  const sectionDeposit = (
    <Paper variant="outlined" className={styles.LeftItem}>
      <Box>
        <img src={MoneyLogo} className={styles.LeftItemIcon} alt="Money Logo" />
      </Box>
      <Box>
        <Typography variant="subtitle2">{t("My Total Deposit")}</Typography>
        <Typography variant="h1" sx={{ marginTop: "4px" }}>
          {+TotalData.totalDeposit.toString() ? (
            <CountUpBN
              prefix="$"
              end={TotalData.totalDeposit}
              duration={1}
              decimals={1}
            />
          ) : (
            "-"
          )}
        </Typography>
      </Box>
    </Paper>
  )

  const RightBox = (
    <Paper variant="outlined">
      <List>
        {Object.values(rightBoxData).map((item: any, index) => (
          <React.Fragment key={index}>
            {!!index && <Divider />}
            <ListItem
              className={styles.ListItem}
              onClick={() => history.push(item.link)}
            >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: "transparent" }}>{item.icon}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    {item.tooltip ? (
                      <CustomTooltip info={item.tooltip}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            borderBottom: "1px dashed #FFCDEC",
                            lineHeight: 1.2,
                          }}
                          className={styles.ListItemTitle2}
                        >
                          {item.title}
                        </Typography>
                      </CustomTooltip>
                    ) : (
                      <Typography variant="subtitle2">{item.title}</Typography>
                    )}
                    {item.unlockTime ? (
                      <Typography className={styles.ListItemTime}>
                        {item.unlockTime}
                      </Typography>
                    ) : (
                      <Box className={styles.ListItemText}>{item.subtitle}</Box>
                    )}
                    <Box className={styles.ListItemAmount}>{item.amount}</Box>
                  </>
                }
              />
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Paper>
  )

  return (
    <Container maxWidth="md" sx={{ marginTop: { xs: 1, sm: 5 } }}>
      <Typography variant="h2" mb={2} display={{ xs: "none", sm: "block" }}>
        {t("My Dashboard")}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {sectionDeposit}
        </Grid>
        <Grid item xs={12} sm={8}>
          {RightBox}
        </Grid>
      </Grid>
      <Box sx={{ my: 3 }}>
        <MyPoolsComponent />
      </Box>
      <Box sx={{ my: 3 }}>
        <DailyTvlsChart />
      </Box>
      <Box sx={{ my: 3 }}>
        <DailyVolumesTotal />
      </Box>
    </Container>
  )
}

export default Dashboard
