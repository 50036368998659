import React, { ReactElement, useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import i18next from "../i18n"
import {
  useTheme,
  useMediaQuery,
  Paper,
  Typography,
  Stack,
  Box,
  Grid,
} from "@mui/material"
import { BigNumber, utils } from "ethers"
const { parseUnits } = utils
import { Zero } from "@ethersproject/constants"
import { formatBNToShortString } from "../utils"
import { useActiveWeb3React } from "../hooks"
import { POOLS_MAP, STARLAY_SWAP_ADDRESSES, EXCH_URL } from "../constants"
import styles from "./DailyVolumesTotal.module.scss"

type ItemType = {
  name: string
  last24h: BigNumber
  last7d: BigNumber
  lastMonth: BigNumber
  total: BigNumber
  tokens: any[]
}
type Response = {
  ok: boolean
  data: {
    list: [
      {
        address: string
        last24h: string
        last7d: string
        lastMonth: string
        total: string
      },
    ]
  }
}

export default function DailyVolumesTotal(): ReactElement {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"))
  const { chainId } = useActiveWeb3React()
  const [totalData, setTotalData] = useState<ItemType[]>([])

  const fieldsMap = [
    { key: "last24h", name: i18next.t("Last 24 hours") },
    { key: "last7d", name: i18next.t("Last 7 days") },
    { key: "lastMonth", name: i18next.t("Last 30 days") },
    { key: "total", name: i18next.t("total") },
  ]

  const getData = useCallback(async () => {
    if (!chainId) return

    const poolsData = {} as any
    const starlayAddr = STARLAY_SWAP_ADDRESSES[chainId]
    Object.values(POOLS_MAP).map((item) => {
      const address = item.addresses?.[chainId]
      if (address === starlayAddr) return
      const metaSwapAddress = item.metaSwapAddresses?.[chainId]
      if (address) poolsData[address.toLowerCase()] = item
      if (metaSwapAddress) poolsData[metaSwapAddress.toLowerCase()] = item
    })
    if (!Object.keys(poolsData).length) return

    try {
      const res = await fetch(`${EXCH_URL}/api/swap/volume_stats`)
      const body: Response = await res.json()
      if (!body?.ok) return
      const list = body.data.list
      if (!list.length) return

      const poolsTotal: ItemType[] = []
      list.sort((a, b) => +b.total - +a.total)
      list.map((i) => {
        const poolData = poolsData[i.address]
        const poolName = poolData?.name
        if (!poolName) return

        poolsTotal.push({
          name: poolName,
          last24h: parseUnits(i.last24h),
          last7d: parseUnits(i.last7d),
          lastMonth: parseUnits(i.lastMonth),
          total: parseUnits(i.total),
          tokens: poolData.underlyingPoolTokens || poolData.poolTokens,
        })
      })

      const sumItem = { name: i18next.t("total") } as ItemType
      fieldsMap.map(
        (i) =>
          ((sumItem as any)[i.key] = poolsTotal.reduce(
            (sum, item) => sum.add((item as any)[i.key]),
            Zero,
          )),
      )
      poolsTotal.push(sumItem)

      setTotalData(poolsTotal)
    } catch (err) {
      console.error("getData", err)
    }
  }, [chainId])

  useEffect(() => {
    void getData()
  }, [getData])

  const MobilePools = (
    <>
      {totalData?.map((item, index) => (
        <Box className={styles.MItem} key={index}>
          <Stack spacing={2} direction="row">
            <Stack direction="row" spacing={-1} alignItems="center">
              {item.tokens?.map(({ symbol, icon }) => (
                <img width={24} height={24} key={symbol} src={icon} />
              ))}
            </Stack>
            <Box>{item.name}</Box>
          </Stack>
          <Grid container>
            {fieldsMap.map((i) => (
              <Grid className={styles.MItemSub} item xs={6} mt={2} key={i.key}>
                <Box>{i.name}</Box>
                <Box className={`${styles.MItemText} ${styles.gradientText}`}>
                  {+(item as any)?.[i.key]?.toString()
                    ? formatBNToShortString((item as any)?.[i.key], 18)
                    : "-"}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </>
  )

  const PcPools = (
    <>
      <Stack direction="row" fontSize={12} py={2}>
        <Box flex={1}>{t("assets")}</Box>
        <Box flex={1} textAlign="center">
          {t("name")}
        </Box>
        {fieldsMap.map((i, index) => (
          <Box flex={1} textAlign="center" key={`th_${index}`}>
            {i.name}
          </Box>
        ))}
      </Stack>
      {Object.values(totalData)?.map((item, index) => (
        <Stack direction="row" textAlign="center" py={1} key={`tr_${index}`}>
          <Stack flex={1} direction="row" spacing={-1} alignItems="center">
            {item.tokens?.map(({ symbol, icon }) => (
              <img width={24} height={24} key={symbol} src={icon} />
            ))}
          </Stack>
          <Box flex={1} whiteSpace="nowrap">
            {item.name}
          </Box>
          {fieldsMap.map((i) => (
            <Box
              flex={1}
              key={i.key}
              className={`${styles.MItemText} ${styles.gradientText}`}
            >
              {+(item as any)?.[i.key]?.toString()
                ? formatBNToShortString((item as any)?.[i.key], 18)
                : "-"}
            </Box>
          ))}
        </Stack>
      ))}
    </>
  )

  return (
    <Paper variant="outlined" sx={{ p: 3, px: { md: 5 } }}>
      <Typography variant="subtitle2">{t("Volumes Statistics")}</Typography>
      {isDownMd ? MobilePools : PcPools}
    </Paper>
  )
}
