import React, { useState } from "react"
import { ChevronDown } from "react-feather"
import { useActiveWeb3React } from "../hooks"
import USDCLogo from "../assets/icons/usdc.svg"
import USDTLogo from "../assets/icons/usdt.svg"
import BUSDLogo from "../assets/icons/busd.svg"
import DAILogo from "../assets/icons/dai.svg"
import { Popover } from "@headlessui/react"
import { usePopper } from "react-popper"
import ethers, { BigNumber } from "ethers"
import { useMintableTokenContract } from "../hooks/useContract"
import { USDT, USDC, BUSD, DAI, Token } from "../constants"
import css from "./Faucet.module.scss"
import { Container, Box, Dialog } from "@mui/material"
import Button from "../components/Button"
// import { useTransactionAdder } from 'state/transactions/hooks'

const Faucet = () => {
  const { account, chainId, library } = useActiveWeb3React()
  const [inputValue, setInputValue] = useState(account || "")
  const [referenceElement, setReferenceElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const [isGettingToken, setIsGettingToken] = useState(false)
  const { styles, attributes } = usePopper(referenceElement, popperElement)
  const usdcContract = useMintableTokenContract(USDC)
  const usdtContract = useMintableTokenContract(USDT)
  const busdContract = useMintableTokenContract(BUSD)
  const daiContract = useMintableTokenContract(DAI)
  // const addTransaction = useTransactionAdder()

  const onChange = (event: any) => {
    setInputValue(event.target.value)
  }

  const getTokens = async (
    address: string,
    contract: ethers.ethers.Contract | null,
    token: Token,
  ) => {
    const transaction = await contract?.mint(
      address,
      BigNumber.from(10).pow(token.decimals).mul(1000),
    )
    // addTransaction(transaction, { summary: `Received 1000 ${token.symbol} from faucet` })
    setIsGettingToken(true)
    contract?.once("Transfer", (from, to, value) => {
      console.log(`transfer ${value} ${token.symbol} from ${from} to ${to}`)
      setIsGettingToken(false)
    })
    await transaction.wait()
  }

  const addToken = (token: Token) => {
    if (library && library.provider.isMetaMask && library.provider.request) {
      library.provider
        .request({
          method: "wallet_watchAsset",
          params: {
            //@ts-ignore // need this for incorrect ethers provider type
            type: "ERC20",
            options: {
              address: token.addresses[81],
              symbol: token.symbol,
              decimals: token.decimals,
              image: token.icon,
            },
          },
        })
        .then(() => {
          // todo
        })
        .catch(() => {
          // todo
        })
    }
  }

  return (
    <Container className="faucet">
      <div className={css.section1}>
        <p>Sirius Shibuya Testnet Faucet</p>
      </div>

      <div className={css.section2}>
        <div className={css.inputContainer}>
          <input
            type="text"
            placeholder="input your address..."
            value={inputValue}
            onChange={onChange}
          />
        </div>

        <Popover className="col-span-12 sm:col-span-3">
          <Popover.Button
            ref={setReferenceElement as any}
            className={css.submitButton}
          >
            Give me tokens
            <ChevronDown className="inline-block w-6 h-6" />
          </Popover.Button>
          <Popover.Panel
            className={css.popper}
            ref={setPopperElement as any}
            style={{
              ...styles.popper,
            }}
            {...attributes.popper}
          >
            <div
              className="grid grid-cols-1 px-14"
              // style={{ background: "#141436" }}
            >
              <div
                className={css.tokenList}
                onClick={() => getTokens(inputValue, usdcContract, USDC)}
              >
                1000 USDC
              </div>
              <div
                className={css.tokenList}
                onClick={() => getTokens(inputValue, usdtContract, USDT)}
              >
                1000 USDT
              </div>
              <div
                className={css.tokenList}
                onClick={() => getTokens(inputValue, busdContract, BUSD)}
              >
                1000 BUSD
              </div>
              <div
                className={css.tokenList}
                onClick={() => getTokens(inputValue, daiContract, DAI)}
              >
                1000 DAI
              </div>
            </div>
          </Popover.Panel>
        </Popover>
      </div>

      <div className={css.section3}>
        <div
          className="text-xs cursor-pointer hover:text-red text-gray-3 my-2 sm:my-4"
          onClick={() => addToken(USDC)}
        >
          Add USDC to Metamask
          <img
            src={USDCLogo}
            width="18px"
            height="18px"
            style={{ transform: "translateY(3px)" }}
          />
        </div>
        <div
          className="text-xs cursor-pointer hover:text-red text-gray-3 my-2 sm:my-4"
          onClick={() => addToken(USDT)}
        >
          Add USDT to Metamask
          <img
            src={USDTLogo}
            width="18px"
            height="18px"
            style={{ transform: "translateY(3px)" }}
          />
        </div>
        <div
          className="text-xs cursor-pointer hover:text-red text-gray-3 my-2 sm:my-4"
          onClick={() => addToken(BUSD)}
        >
          Add BUSD to Metamask
          <img
            src={BUSDLogo}
            width="18px"
            height="18px"
            style={{ transform: "translateY(3px)" }}
          />
        </div>
        <div
          className="text-xs cursor-pointer hover:text-red text-gray-3 my-2 sm:my-4"
          onClick={() => addToken(DAI)}
        >
          Add DAI to Metamask
          <img
            src={DAILogo}
            width="18px"
            height="18px"
            style={{ transform: "translateY(3px)" }}
          />
        </div>
      </div>
    </Container>
  )
}

export default Faucet
