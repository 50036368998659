import { useState, useEffect, useCallback } from "react"
import { Zero } from "@ethersproject/constants"
import { BigNumber } from "@ethersproject/bignumber"
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import moment from "moment"
import { useContract } from "../hooks/useContract"
import { Token } from "../constants"
import Airdrop_ABI from "../constants/abis/Airdrop.json"
import { getAirdropTokenByAddress } from "../utils"
import { SocialChannels } from "../components/OfficialLinks"

const socialKeys = ["twitter", "telegram", "discord"]
export const socialList = socialKeys
  .map((k) => SocialChannels.find((i) => i.key === k))
  .filter((i) => i) as SocialChannels

export enum EventStatus {
  upcoming = "upcoming",
  ongoing = "ongoing",
  ended = "ended",
}

export type RecentEvent = {
  id: string // unique, can use date+sequence
  timeFrom?: string
  timeTo?: string
  deadline?: string // claim deadline
  link?: string // more info
  title: string
  desc: string
  contract?: string
  status?: EventStatus
}

export const RecentEvents: RecentEvent[] = [
  {
    id: "2022091401",
    timeTo: "2022-09-14",
    deadline: "2022-10-15",
    link: "https://medium.com/@avault/one-article-to-learn-hexa-farming-campaign-3779e3dc5a90",
    title: "SRS rewards for Hexa farming gleam rewards",
    desc: `This claiming page is for participants joined in the Hexa farming Gleam competition.
A total reward of 10,000 SRS.`,
    contract: "0x136aA92286806bDeDB8300B498E3D4cbbEa5388b",
  },
  {
    id: "2022081603",
    timeFrom: "2022-08-16",
    title: "SRS claiming for Solanium IDO participants",
    desc: `This claiming page is only available for Solanium SRS IDO participants. Please make sure the wallet you connect is the payment address for IDO.
Vesting: 20% TGE, 20% monthly release for another 5 months starting from 16th, Sep.
The vesting part will be claimable at Claim page from next month.

Notice: Only IDO payment address will be whitelisted to be SRS-transferrable so it can transfer SRS to exchanges. Please be aware before you making any transactions.
Claiming starts: 14 UTC, 16th, August.`,
    contract: "0x91E8D98C14F4EF3A41Bc7DDf9bd673F7D0175842",
  },
  {
    id: "2022081502",
    timeTo: "2022-08-15",
    deadline: "2022-09-15",
    title: "PolkaEx Community SRS Whitelist",
    desc: `Thank you for joining SRS whitelist.
SRS price: $0.036
TGE part will be claimable on the 16th, August and monthly release will happen on every 15th of the month.
Please note that SRS will be only claimable and transferrable with your payment address.
Let's keep supporting!`,
    contract: "0x90C3bB6673c2fA03Bafe500CB0EF775858fCeE61",
  },
  {
    id: "2022081501",
    timeTo: "2022-08-15",
    deadline: "2022-09-15",
    title: "Sirius Finance Community SRS Whitelist",
    desc: `Thank you for joining SRS whitelist.
SRS price: $0.036
TGE part will be claimable on the 16th, August and monthly release will happen on every 15th of the month.
Please note that SRS will be only claimable and transferrable with your payment address.
Cheers! Sirius Finance OG & Sirius OG, let's keep supporting!`,
    contract: "0x90C3bB6673c2fA03Bafe500CB0EF775858fCeE61",
  },
  {
    id: "2022061602",
    timeFrom: "2022-06-16",
    // timeTo: "2022-08-16",
    link: "https://twitter.com/Sirius_Finance/status/1537339126940282880?s=20&t=uaMbNE5ek-e_PBnxyvU3lg",
    title: "1 million SRS incentives OG program round 2",
    desc: `<div style="font-weight:bold;">Reward distribution:</div>
<li>A base reward of 1,000 SRS for each OG;</li>
<li>An extra reward of 2,000 SRS for OGs who excel;</li>
<li>A reward of 100,000 SRS will be given randomly to those who participated but are not admitted.</li>
<li>The rest will be given to OGs as a monthly reward distribution based on their contribution.</li>`,
  },
  {
    id: "2022071701",
    timeTo: "2022-07-17",
    deadline: "2022-08-18",
    link: "https://twitter.com/PolkaExOfficial/status/1542841426387824640?s=20&t=Gkyp1awVboRRJM4sFQGcVw",
    title: "SRS Airdrop for Active PolkaEx Users on Astar Network",
    desc: `To celebrate this long-awaited collaboration with PolkaEx, Sirius Finance is going to airdrop SRS to active PolkaEx users!

Who is eligible for SRS airdrop?
1) PolkaEx users who have assets deposited and staked on PolkaEx pools and farms on Astar Network;
2) The amount of SRS airdrop: total value($) = 1: 5

Snapshot time: 2022-07-18 4:30:00 UTC
Claimable period: 7/18 - 8/18

For more info about this collaboration, please click 'More' for more details.`,
    contract: "0x516A2fb16D49Adbf9dB15a8aA8603f8Ff472A3D2",
  },
  {
    id: "2022071001",
    timeTo: "2022-07-10",
    link: "https://twitter.com/Sirius_Finance/status/1545014782323003396?s=20&t=Ji_jo2oktY6o5E1qRI6nyw",
    title: "The very first airdrop to veSRS holders: PKEX",
    desc: `To indicate the very begining of the partnership with PolkaEx, veSRS holders will receive their first airdrop from PolkaEx official: PKEX. 

Thresholds:
1k - 5k veSRS: 1,000 PKEX
5k - 10k veSRS: 2,000 PKEX
10k - 100k veSRS: 5,000 PKEX
Above 100k veSRS: 10,000 PKEX

Snapshot time: 8 AM UTC, July, 10th.

PolkaEx, an inventive DeFi protocol built on top of stablecoin AMM Sirius Finance, starting with Astar Network, for the whole Polkadot ecosystem.`,
    contract: "0x027592BC63DAA18A602E49309525a9361f680320",
  },
  {
    id: "2022032901",
    timeTo: "2022-03-29",
    deadline: "2022-08-18",
    link: "https://medium.com/@siriusfinance/sirius-finance-shibuya-testnet-guide-step-by-step-tutorial-5accc0b3b4b5",
    title: "Sirius Finance 500,000 SRS Testnet Campaign",
    desc: `Requirements :
Perform all actions on the Sirius Finance Shibuya testnet using one wallet address
Perform at least 10 swaps
Add and remove liquidity(stablecoins) in the pool for at least 5 times
Deposit and withdraw LP tokens from the farm for at least 5 times
Claim rewards at least once
Submit your feedback to Sirius official discord: #Bug Report and #Product optimization based on the feedback type.
Please do not plagiarise out of the respect for others work`,
    contract: "0x6A0f26BFaD204999d37a2Cb2c8f84F6A70044E94",
  },
  {
    id: "2022061001",
    timeTo: "2022-06-10",
    deadline: "2022-09-15",
    link: "https://medium.com/@siriusfinance/celer-x-sirius-finance-cross-chain-campaign-with-6-000-srs-giveaway-c38d9a84da23",
    title: "60,000 SRS Cross-chain campaign with celer bridge",
    desc: `Use cBridge between Astar and other networks that cBridge supports to bridge assets greater than $500 USD(BUSD, USDC, USDT, DAI) to Astar Network.
Prize:

ALL the qualified addresses will share $5,000 SRS proportionally based on the actual amount.
10 qualified addresses will be selected randomly to win an extra reward of $100 worth of SRS each.`,
    contract: "0x78DdBd48ec3B9F36E2411747D9F7714C34695B6d",
  },
  {
    id: "2022041602",
    timeTo: "2022-04-16",
    deadline: "2022-09-15",
    link: "https://twitter.com/Sirius_Finance/status/1512444507811618818?s=20&t=s_2acznz2r1xT7HvwNnguA",
    title: "Sirius Finance Mainnet Dapp launch Warm-up Gleam contest",
    desc: `To further hype up Sirius Finance Community before our official dapp goes online, Sirius Finance is giving away 200,000 $SRS to the community!
The top 10 participants: 3,000 SRS each and OG role granted.
The top 50 participants: 1,500 SRS each
The top 100 participants: 1,000 SRS each
The top 200 participants: 400 SRS each
The top 400 participants: 100 SRS each

If you're in the winner list but unable to claim, please contact info@sirius.finance via your gleam registered email and provide your metamask address during claimable period, thank you.
<a href="https://docs.google.com/spreadsheets/d/1bq0O2sGMdUrJZDddxLRyWAehoGuKK38HYixr1nxL31U/edit?usp=sharing" target="_blank" rel="noreferrer">Winner list ↗</a>`,
    contract: "0xd3c0b0aDea75FbA94ba636dA7917893661973E00",
  },
  {
    id: "2022041601",
    timeTo: "2022-04-16",
    deadline: "2022-09-15",
    link: "https://twitter.com/Sirius_Finance/status/1514966570098827268?s=20&t=RidSL-U9t2YWTgZwuiXCQw",
    title: "Sirius Finance Mainnet Dapp launch 10,000 SRS raffle",
    desc: `Countdown to Sirius Finance Mainnet Launch on AstarNetwork: 24 HRS!
10,000 $SRS Airdrop for 100 winners:
Follow+RT+Tag 3 friends`,
    contract: "0x6c624403cfb5005ad3c4f94e71992563057c971a",
  },
  {
    id: "2022040601",
    timeTo: "2022-04-06",
    deadline: "2022-09-15",
    link: "https://twitter.com/Sirius_Finance/status/1510680073946681344?s=20&t=7vIDe-yavRaNh-eRtdIU-w",
    title: "Sirius Finance First-scheduled AMA Celebration 10,000 SRS airdrop",
    desc: `First-schedule AMA with GTS.
10,000 SRS Airdrop for 50 winners, if you:
Follow: @Sirius_Finance
RT this & tag 3 friends
Join Sirius official telegram and discord.`,
    contract: "0xbfddfF5d8E2E0A54D1de978E95aF9e78D225c2CF",
  },
  {
    id: "2022061601",
    timeTo: "2022-06-16",
    deadline: "2022-09-15",
    link: "https://medium.com/@siriusfinance/by-the-community-for-the-community-from-the-community-and-serves-the-community-a593d7962b80",
    title: "1 million SRS incentives OG program round 1",
    desc: `What is Sirius OG program?

Keynotes to takeaway:
  1 million $SRS reward
  3-month assessment
  Some $SRS for ALL participants

139 OGs including 21 Sirius OG until 15/8/2022. A total of 200 slots for OG+SiriusOG.`,
    contract: "0xD71499c0d30866307d50dcf83d82cd8C081CF4b5",
  },
]

export const useRecentEvents = (): [RecentEvent[], () => void] => {
  const [list, setList] = useState<RecentEvent[]>([])

  const getData = useCallback(() => {
    // A better way is request a url
    // const res = await fetch(url)
    const _list = RecentEvents as RecentEvent[]
    _list.map((i) => {
      const now = moment()
      if (i.timeFrom && !i.timeTo) {
        i.status = now.isBefore(i.timeFrom)
          ? EventStatus.upcoming
          : EventStatus.ongoing
      } else if (!i.timeFrom && i.timeTo) {
        i.status = now.isBefore(i.timeTo)
          ? EventStatus.ongoing
          : EventStatus.ended
      } else if (i.timeFrom && i.timeTo) {
        i.status = now.isBefore(i.timeFrom)
          ? EventStatus.upcoming
          : now.isAfter(i.timeTo)
          ? EventStatus.ended
          : EventStatus.ongoing
      } else {
        i.status = EventStatus.ended
      }
    })
    setList(_list)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])
  return [list, getData]
}

export type ClaimData = {
  contract?: string
  quantityAvailable: BigNumber
  totalAmount: BigNumber
  received: BigNumber
  token?: Token | null
}
export function useClaimData(currId?: string): [ClaimData, () => void] {
  const [claimData, setClaimData] = useState<ClaimData>({
    quantityAvailable: Zero,
    totalAmount: Zero,
    received: Zero,
  })

  const { account, chainId, error: Network_error } = useWeb3React()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError

  const [address, setAddress] = useState("")
  const [quantityAvailable, getQa] = useQuantityAvailable(address)
  const [vesting, getVesting] = useVestings(address)
  const [tokenAddress, getTokenAddress] = useTokenAddress(address)

  const reset = () => {
    setClaimData({ quantityAvailable: Zero, totalAmount: Zero, received: Zero })
    setAddress("")
  }

  const getData = useCallback(() => {
    try {
      if (isUnsupportChainIdError || !account || !chainId) return reset()
      const item = RecentEvents.find((i) => i.id === currId)
      if (!item?.contract) return reset()

      setAddress(item.contract)
      setClaimData({
        contract: item.contract,
        quantityAvailable,
        totalAmount: vesting.totalAmount,
        received: vesting.received,
        token: getAirdropTokenByAddress(tokenAddress, chainId),
      })
    } catch (err) {
      console.error("useClaimData", err)
    }
  }, [
    isUnsupportChainIdError,
    account,
    chainId,
    currId,
    quantityAvailable,
    vesting,
    tokenAddress,
  ])

  const refresh = () => {
    getQa()
    getVesting()
    getTokenAddress()
  }

  useEffect(() => {
    reset()
  }, [])
  useEffect(() => {
    getData()
  }, [getData])
  return [claimData, refresh]
}

export function useQuantityAvailable(
  address?: string,
): [BigNumber, () => void] {
  const [qa, setQa] = useState(Zero)
  const _contract = useContract(address, Airdrop_ABI)

  const getData = useCallback(() => {
    if (!address || !_contract) return setQa(Zero)
    _contract?.vestedAmount().then((res: BigNumber) => setQa(res))
  }, [address, _contract])

  useEffect(() => {
    getData()
  }, [getData])
  return [qa, getData]
}

type useVestings = { totalAmount: BigNumber; received: BigNumber }
export function useVestings(address?: string): [useVestings, () => void] {
  const [vesting, setVesting] = useState({ totalAmount: Zero, received: Zero })
  const { account } = useWeb3React()
  const _contract = useContract(address, Airdrop_ABI)

  const reset = () => setVesting({ totalAmount: Zero, received: Zero })

  const getData = useCallback(() => {
    if (!account || !address || !_contract) return reset()
    _contract?.vestings(account).then((res: BigNumber[]) => {
      const [totalAmount, received] = res
      setVesting({ totalAmount, received })
    })
  }, [account, address, _contract])

  useEffect(() => {
    getData()
  }, [getData])
  return [vesting, getData]
}

export function useTokenAddress(address?: string): [string, () => void] {
  const [addr, setAddr] = useState("")
  const _contract = useContract(address, Airdrop_ABI)

  const getData = useCallback(() => {
    if (!address || !_contract) return setAddr("")
    _contract?.token().then((res: string) => setAddr(res))
  }, [address, _contract])

  useEffect(() => {
    getData()
  }, [getData])
  return [addr, getData]
}
