import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { ReactComponent as CloseSVG } from "./../assets/icons/close.svg"
import bellIcon from "../assets/icons/bell.svg"
import Button from "./Button"
import SRS_ICON from "./../assets/icons/srs.png"
import VESRS_ICON from "./../assets/icons/vesrs.svg"
import "./ReviewLock.scss"

interface Props {
  onClose: () => void
  onConfirm: () => void
  data: any
  isIncrease: boolean
}

const ReviewLock = ({
  onClose,
  onConfirm,
  data,
  isIncrease,
}: Props): ReactElement => {
  const { t } = useTranslation()
  return (
    <div className="ReviewLock">
      <Box className="top" px={{ xs: 3, sm: 5 }} pt={3}>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" align="left" gutterBottom={true}>
            Preview Lock
          </Typography>
          <span className="close-btn" onClick={onClose}>
            <CloseSVG />
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <span className="label">
            {isIncrease ? t("You are adding") : t("You are locking")}
          </span>
          <span className="value">
            {data.lockSrsInput}
            <img src={SRS_ICON} width={24} height={24} />
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <span className="label">{t("You are receiving")}</span>
          <span className="value">
            {data.veSRSAmount} veSRS
            <img src={VESRS_ICON} width={24} height={24} />
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <span className="label">{t("Unlock Date")}</span>
          <span className="value">{data.unlockTime}</span>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <span className="label">
            {isIncrease ? t("Adding Lock Period") : t("LockPeriod")}
          </span>
          <span className="value">{data.lockPeriod || `0 ${t("Days")}`}</span>
        </Box>
      </Box>
      <Box className="bottom" px={{ xs: 3, sm: 5 }} pt={3}>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <span className="label">{t("Total locked SRS")}</span>
          <span className="value">
            {data.totalLocked}
            <img src={SRS_ICON} width={24} height={24} />
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <span className="label">{t("Total minted veSRS")}</span>
          <span className="value">
            {data.myVeTokenMinted} veSRS
            <img src={VESRS_ICON} width={24} height={24} />
          </span>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box className="bottom" px={{ xs: 3, sm: 5 }}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb={4}
          className="alert"
        >
          <img src={bellIcon} />
          <Typography fontSize={12} ml={1}>
            {t("unlockEarlyPenaltyTips", { rate: data.penaltyRate * 100 })}
          </Typography>
        </Box>
      </Box>
      <Button size="large" fullWidth onClick={onConfirm} className="lock-btn">
        {t("Confirm Lock")}
      </Button>
    </div>
  )
}

export default ReviewLock
