import React, { useState, useContext } from "react"
import Button from "./../components/Button"
import { Box } from "@mui/material"

export const TabContext = React.createContext<any>(null);

export function FilterTabContext(props: any) {
  const [defaultValue, setDefaultValue] = useState(props.initValue?props.initValue:"all");

  return (
    <TabContext.Provider value={[defaultValue, setDefaultValue]}>
      {props.children}
    </TabContext.Provider>
  )
}

export function FilterTabList(props: any) {
  return (
    <Box className="progress_button_group" {...props}>
      {props.children}
    </Box>
  )
}

export function FilterTab(props: any) {
  const [value, setValue] = useContext(TabContext);

  const handleClick = (_kind:any) => {
    setValue(_kind);
  }
  return (
    <Button
      kind="outline"
      size="small"
      className={value === props.kind ? "active" : ""}
      onClick={() => handleClick(props.kind)}
    >
      {props.children}
    </Button>
  )
}

export function FilterTabItem(props: any) {
  const [value] = useContext(TabContext);

  return (
    <div style={{ display: props.kind === value || value==="all"?"block":"none"}}>{props.children}</div>
  )
}