import {
  isMetaPool,
  OUSD_METAPOOL_NAME,
  BAI_METAPOOL_NAME,
  LASTR_METAPOOL_NAME,
  AVAULT_POOL_NAME,
  POOLS_MAP,
  STABLECOIN_SWAP_TOKEN,
  isCryptoPool,
  isNativePool,
  ASTR_SYMBOL,
} from "../constants"
import {
  Box,
  Container,
  Dialog,
  Paper,
  Stack,
  Typography,
  useTheme,
  Switch,
  FormGroup,
  FormControlLabel,
  Grid,
  Slider,
} from "@mui/material"
import Button from "./Button"
import { PoolDataType, UserShareType } from "../hooks/usePoolData"
import React, { ReactElement, useState, useEffect } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ConfirmTransaction from "./ConfirmTransaction"
import { DepositTransaction } from "../interfaces/transactions"
import MyShareCard from "./MyShareCard"
import PoolInfoCard from "./PoolInfoCard"
import ReviewDeposit from "./ReviewDeposit"
import TokenInput from "./TokenInput"
import { Zero } from "@ethersproject/constants"
import { formatBNToPercentString, formatDeadlineToNumber } from "../utils"
import { logEvent } from "../utils/googleAnalytics"
import "./WithdrawPage.scss"
import { commify, formatBNToString, getPoolRoute } from "../utils"
import { useActiveWeb3React } from "../hooks"
import { AppState } from "../state/index"
import { useSelector } from "react-redux"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
import bellIcon from "../assets/icons/bell.svg"
import { isMobile } from "react-device-detect"
import CustomTooltip from "../components/CustomTooltip"

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  title: string
  onConfirmTransaction: () => Promise<void>
  onChangeTokenInputValue: (tokenSymbol: string, value: string) => void
  onToggleDepositWrapped: () => void
  shouldDepositWrapped: boolean
  tokens: Array<{
    symbol: string
    name: string
    icon: string
    decimals: number
    max: string
    inputValue: string
  }>
  exceedsWallet: boolean
  selected?: { [key: string]: any }
  poolData: PoolDataType | null
  myShareData: UserShareType | null
  transactionData: DepositTransaction
  afterCompleted: () => void
}

/* eslint-enable @typescript-eslint/no-explicit-any */
const DepositPage = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const {
    tokens,
    exceedsWallet,
    poolData,
    myShareData,
    transactionData,
    shouldDepositWrapped,
    onChangeTokenInputValue,
    onConfirmTransaction,
    onToggleDepositWrapped,
    afterCompleted,
  } = props
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const validDepositAmount = transactionData.to.totalAmount.gt(0)
  const shouldDisplayWrappedOption =
    isMetaPool(poolData?.name) || isCryptoPool(poolData?.name)
  const theme = useTheme()
  const { chainId } = useActiveWeb3React()
  const { tokenPricesUSD } = useSelector((state: AppState) => state.application)

  const [showReview, setShowReview] = useState(false)
  const [finalLPAmount, setFinalLPAmount] = useState(Zero)
  const [isUnbalance, setIsUnbalance] = useState(false) // 是否失衡
  const [unbalanceTokenSymbol, setUnbalanceTokenSymbol] = useState("")
  const [isShowMobileDetails, setIsShowMobileDetails] = useState(false)
  const [minimumAmount, setMinimumAmount] = useState("")

  const pool = poolData?.name && POOLS_MAP[poolData?.name]

  const [showOptimize, setShowOptimize] = useState(false)
  const [isOptimize, setIsOptimize] = useState(true)
  const [optimize, setOptimize] = useState(0)
  const [optimizeMax, setOptimizeMax] = useState(0)
  const [currInput, setCurrInput] = useState({ symbol: "", value: "" })
  const onChangeIsOptimize = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsOptimize(e.target.checked)
    setOptimize(~~(optimizeMax / 2))
  }
  const onChangeOptimize = (e: Event, val: number | number[]) => {
    setOptimize(val as number)
  }

  // Optimize and adjust deposit amount
  useEffect(() => {
    const { symbol, value } = currInput
    if (
      !symbol ||
      !isUnbalance ||
      !tokenPricesUSD ||
      // symbol !== unbalanceTokenSymbol ||
      (!shouldDepositWrapped && !isNativePool(poolData?.name))
    )
      return

    const found = poolData?.tokens.find((i) => i.symbol !== symbol)
    if (!found) return

    const tokenUSD = tokenPricesUSD[symbol]
    const foundUSD = tokenPricesUSD[found.symbol]
    if (!foundUSD) return

    const unbalProportion = (50 - optimize) / (50 + optimize)
    const proportion =
      showOptimize && optimize !== 0
        ? found.symbol === unbalanceTokenSymbol
          ? unbalProportion
          : 1 / unbalProportion
        : 1
    let amount = ((tokenUSD * +value) / (foundUSD * proportion)).toFixed(6)
    amount = +amount ? amount : ""

    setMinimumAmount(amount)
    // setTimeout(() => {
    onChangeTokenInputValue(found.symbol, amount)
    // }, 200)
  }, [currInput, optimize])

  const addresses = pool
    ? shouldDepositWrapped
      ? pool?.metaSwapAddresses
      : pool?.addresses
    : undefined
  const targetContractAddress = addresses && chainId ? addresses[chainId] : ""

  const poolRoute = pool && getPoolRoute(pool.name)

  const { addToken } = useAddTokenToMetamask({
    ...(pool ? pool.lpToken : STABLECOIN_SWAP_TOKEN),
    icon: `${window.location.origin}/usds-logo.png`,
  })

  useEffect(() => {
    if (!pool) {
      return
    }

    if (!poolData) {
      return
    }

    if (!isCryptoPool(poolData.name) && !isNativePool(poolData.name)) {
      return
    }

    if (!pool?.unbalancePercent) {
      return
    }

    const unbal = pool?.unbalancePercent
    if (!!unbal && unbal !== 50) {
      setShowOptimize(true)

      const max = Math.abs(50 - unbal)
      setOptimizeMax(max)
      setOptimize(~~(max / 2))
    } else {
      setShowOptimize(false)
    }

    if (poolData.tokens.some((item) => +item.percent.split("%")[0] === 0)) {
      return
    }

    const found = poolData.tokens.find(
      (token) => +token.percent.split("%")[0] < (pool?.unbalancePercent || 45),
    )
    if (found) {
      setIsUnbalance(true)
      setUnbalanceTokenSymbol(found.symbol)
    }
  }, [poolData, isMetaPool])

  const getCompletedInfo = (): ReactElement => {
    return (
      <>
        {pool && (
          <Typography fontSize={12} fontWeight={400}>
            {t("youHaveReceived")}{" "}
            {commify(formatBNToString(finalLPAmount, 18))}{" "}
            {pool?.lpToken.symbol}
          </Typography>
        )}
        <Typography color="#FFCDEC" fontSize={12} fontWeight={400}>
          <span className="add-to-wallet" onClick={addToken}>
            {t("addToWallet")}
          </span>
        </Typography>
      </>
    )
  }

  const isDisabledBtn = () => {
    if (
      !currInput.value ||
      !validDepositAmount ||
      poolData?.isPaused ||
      exceedsWallet
    )
      return true

    // if (isUnbalance) {
    //   const found = transactionData.from.items.find(
    //     (item) => item.token.symbol !== unbalanceTokenSymbol,
    //   )
    //   if (found) {
    //     return +formatBNToString(found.amount, 18) < +minimumAmount
    //   }
    // }
    return false
  }

  return (
    <Container
      className="deposit-withdraw-page"
      disableGutters={true}
      maxWidth={false}
      sx={{
        maxWidth: 920,
        marginTop: "40px",
        paddingX: {
          xs: 0,
          sm: 0,
        },
      }}
    >
      <Box
        className="back-to-pools"
        fontSize={20}
        mb={4}
        display="flex"
        justifyContent="space-between"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Link
          style={{ color: "#EBEBEB", display: "flex", alignItems: "center" }}
          to="/pools"
        >
          <ArrowBack />
          <span>{t("Back to pool list")}</span>
        </Link>
        <Link
          style={{ color: "#EBEBEB", display: "flex", alignItems: "center" }}
          to="/farms"
        >
          <span>{t("Stake LP")}</span>
          <ArrowForward />
        </Link>
      </Box>
      {isMobile && isShowMobileDetails && (
        <Box mb={4}>
          <Typography
            fontSize={14}
            fontWeight={700}
            ml={2}
            color="#FFCDEC"
            onClick={() => {
              setIsShowMobileDetails(false)
            }}
          >
            {t("back")}
          </Typography>
        </Box>
      )}
      <Grid container spacing={4} columns={15}>
        <Grid xs={15} sm={6} item>
          <Box className="left-info">
            <Box p="20px" className="apy-info">
              <Box display="flex" justifyContent="space-between">
                <Typography fontWeight={700}>{poolData?.name}</Typography>
                {!isShowMobileDetails && (
                  <Typography
                    fontWeight={700}
                    fontSize={14}
                    color="#FFCDEC"
                    display={{ sm: "none" }}
                    onClick={() => setIsShowMobileDetails(true)}
                  >
                    {t("See Pool Details")}
                  </Typography>
                )}
              </Box>
              <Box display="flex" justifyContent="space-between">
                <CustomTooltip info={t("feeRewardTooltip") || ""}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    fontSize={24}
                    sx={{
                      textDecoration: "dashed underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("Fee Reward")}
                  </Typography>
                </CustomTooltip>
                <Typography fontWeight={700} fontSize={24} className="apy">
                  {poolData?.apy
                    ? `${formatBNToPercentString(poolData.apy, 18, 2)}`
                    : "-"}
                </Typography>
              </Box>
              {poolData?.name === OUSD_METAPOOL_NAME && (
                <Typography mt={2} fontSize={12}>
                  How do I get oUSD? You can{' '}
                  <a
                    href="https://app.orcusfinance.io/mint-redeem"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    mint oUSD
                  </a>
                  {' '}on{' '}
                  <a
                    href="https://orcusfinance.io"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    Orcus Finance
                  </a>
                  .
                </Typography>
              )}
              {poolData?.name === BAI_METAPOOL_NAME && (
                <Typography mt={2} fontSize={12}>
                  How do I get BAI? You can mint BAI on{" "}
                  <a
                    href="https://astar.astriddao.xyz/#/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    AstridDAO
                  </a>
                  .
                </Typography>
              )}
              {poolData?.name === LASTR_METAPOOL_NAME && (
                <Typography mt={2} fontSize={12}>
                  How do I get nASTR? You can stake ASTR on &nbsp;
                  <a
                    href="https://algem.io"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    Algem
                  </a>
                  &nbsp;and mint liquid nASTR token.
                </Typography>
              )}
              {poolData?.name === AVAULT_POOL_NAME && (
                <Typography mt={2} fontSize={12}>
                  How do I get aLP? You can deposit the LP of
                  USDT-USDC/BUSD-USDC/BAI-USDC/DAI-USDC on&nbsp;
                  <a
                    href="https://www.avault.network/vault"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    Avault
                  </a>
                  &nbsp; and get aLP. Or&nbsp;
                  <a
                    href="https://www.avault.network/zap"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    ZAP
                  </a>
                  &nbsp; from single token to LP first, then deposit.
                </Typography>
              )}
            </Box>
            <MyShareCard data={myShareData} />
            {(!isMobile || isShowMobileDetails) && (
              <PoolInfoCard data={poolData} />
            )}
          </Box>
        </Grid>
        <Grid
          xs={15}
          sm={9}
          item
          sx={{ display: { xs: isShowMobileDetails ? "none" : "" } }}
        >
          <Paper className="deposit-main">
            <Box className={showReview ? "hide" : ""}>
              <Box
                display="flex"
                justifyContent="center"
                pt={3}
                position="relative"
              >
                <div className="deposit-withdraw">
                  <div className="active">{t("deposit")}</div>
                  <div>
                    {poolRoute && (
                      <Link to={`${poolRoute.poolRoute}/withdraw`}>
                        {t("withdraw")}
                      </Link>
                    )}
                  </div>
                </div>
                {shouldDisplayWrappedOption && !isMobile && !isUnbalance && (
                  <FormGroup className="use-lp">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={onToggleDepositWrapped}
                          checked={shouldDepositWrapped}
                        />
                      }
                      label={t("Use LP") as string}
                    />
                  </FormGroup>
                )}
              </Box>
              {shouldDisplayWrappedOption && isMobile && (
                <Box height={10} my={2}>
                  <FormGroup className="use-lp">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={onToggleDepositWrapped}
                          checked={shouldDepositWrapped}
                        />
                      }
                      label={t("Use LP") as string}
                    />
                  </FormGroup>
                </Box>
              )}
              <Box>
                <Stack direction="column" spacing={1} mt={4}>
                  {chainId &&
                    tokens.map((token, index) => (
                      <TokenInput
                        key={index}
                        {...token}
                        disabled={
                          poolData?.isPaused
                          // || (isUnbalance && token.symbol !== unbalanceTokenSymbol)
                        }
                        onChange={(value): void => {
                          onChangeTokenInputValue(token.symbol, value)
                          setCurrInput({ symbol: token.symbol, value })
                        }}
                      />
                    ))}
                </Stack>
              </Box>

              {showOptimize && (
                <Stack
                  pt={2}
                  px={4}
                  spacing={5}
                  direction="row"
                  alignItems="center"
                >
                  <Box flexGrow="1">
                    {!isOptimize && (
                      <Slider
                        marks
                        valueLabelDisplay="auto"
                        min={0}
                        max={optimizeMax}
                        value={optimize}
                        onChange={onChangeOptimize}
                      />
                    )}
                  </Box>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ mr: 0 }}
                      label={t("Optimize Deposit") as string}
                      control={
                        <Switch
                          checked={isOptimize}
                          onChange={onChangeIsOptimize}
                        />
                      }
                    />
                  </FormGroup>
                </Stack>
              )}

              {isUnbalance && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  my={4}
                  className="alert"
                  mx={"48px"}
                  px={2}
                >
                  <img src={bellIcon} />
                  <Typography fontSize={12}>
                    {t("preventFurtherImbalanceTooltip")}
                  </Typography>
                </Box>
              )}
              <Box pt={0}>
                <Box mt={4}>
                  <Button
                    size="large"
                    fullWidth
                    onClick={(): void => {
                      // if (
                      //   isUnbalance &&
                      //   transactionData.from.items.find(
                      //     (item) => item.token.symbol !== unbalanceTokenSymbol,
                      //   )
                      // ) {
                      //   // 程序上阻止过多的币种提交
                      //   return
                      // }
                      setShowReview(true)
                    }}
                    className="deposit-btn"
                    disabled={isDisabledBtn()}
                  >
                    {exceedsWallet ? t("Insufficient balance") : t("Preview Deposit")}
                  </Button>
                </Box>
              </Box>
            </Box>
            {showReview && (
              <Box>
                <ReviewDeposit
                  poolName={poolData?.name}
                  transactionData={transactionData}
                  onConfirm={() => {
                    setFinalLPAmount(transactionData.to.totalAmount)
                    setCurrentModal("confirm")
                    setShowReview(false)
                    logEvent(
                      "deposit",
                      (poolData && { pool: poolData?.name }) || {},
                    )
                    // await onConfirmTransaction?.()
                    // setCurrentModal(null)
                  }}
                  onClose={(): void => setShowReview(false)}

                  // data={reviewData}
                  // gas={gasPriceSelected}
                  // onConfirm={async (): Promise<void> => {
                  //   setCurrentModal("confirm")
                  //   setShowReview(false)
                  //   logEvent("withdraw", (poolData && { pool: poolData?.name }) || {})
                  //   await onConfirmTransaction?.()
                  //   setCurrentModal(null)
                  // }}
                  // lpTokenAmount={formStateData.lpTokenAmountToSpend}
                  // // onClose={(): void => setCurrentModal(null)}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={!!currentModal} onClose={(): void => setCurrentModal(null)}>
        {/* {currentModal === "review" ? (
          <ReviewDeposit
            transactionData={transactionData}
            onConfirm={() => {
              setCurrentModal("confirm")
              logEvent("deposit", (poolData && { pool: poolData?.name }) || {})
              // await onConfirmTransaction?.()
              // setCurrentModal(null)
            }}
            onClose={(): void => setCurrentModal(null)}
          />
        ) : null} */}
        {currentModal === "confirm" && chainId ? (
          <ConfirmTransaction
            targetContractAddress={targetContractAddress}
            tokens={transactionData.from.items.map((item) => item.token).filter((token) => token.symbol !== ASTR_SYMBOL)}
            handler={onConfirmTransaction}
            onClose={(): void => setCurrentModal(null)}
            afterCompleted={afterCompleted}
            completedInfo={getCompletedInfo()}
            showStakeLP={pool ? pool?.lpToken.symbol : ""}
          />
        ) : null}
      </Dialog>
    </Container>
  )
}

export default DepositPage
