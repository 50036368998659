import React, { ReactElement } from "react"
import { Box, Typography, Popover, Link, IconButton } from "@mui/material"
import { CallMade, Menu as MenuIcon } from "@mui/icons-material"
import { Links as docLinks, SocialChannelsDOM } from "./OfficialLinks"
import "./TopMenuOthers.scss"
import { useTranslation } from "react-i18next"

export default function TopMenuOthers(): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const { t } = useTranslation()

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined
  return (
    <>
      <Box className="settingFrame">
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ p: 0 }}>
          <MenuIcon />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          className="adv_paper_container"
        >
          <Box className="top_menu_others_con" width={166}>
            <Typography variant="subtitle2">{t("Others")}</Typography>

            <Link
              href="https://cbridge.celer.network/#/transfer"
              underline="none"
              target="_blank"
              rel="noreferrer"
              mt={1}
              display={{ xs: "block", lg: "none" }}
            >
              cBridge
              <CallMade sx={{ fontSize: 14, verticalAlign: "middle" }} />
            </Link>

            {docLinks.map((item) => (
              <Box key={item.key}>
                <a target="_blank" rel="noreferrer" href={item.link}>
                  {t(item.name)}
                </a>
              </Box>
            ))}

            <Box className="TopMenuOthersIcons">
              <SocialChannelsDOM />
            </Box>
          </Box>
        </Popover>
      </Box>
    </>
  )
}
