import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import {
  Dialog,
  DialogProps,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material"
import styles from "./CustomConfirm.module.scss"

interface Props {
  open: boolean
  onClose?: () => void
  onConfirm?: () => void
  title?: React.ReactNode
  content?: React.ReactNode
  children?: React.ReactNode
  DialogProps?: DialogProps
}

export default function CustomConfirm(props: Props): ReactElement {
  const { open, onClose, onConfirm, title, children, content, DialogProps } =
    props
  const { t } = useTranslation()
  return (
    <Dialog open={open} onClose={onClose} {...DialogProps}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {children || (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions className={styles.Btns}>
        <Button className={styles.Btn} onClick={onClose}>
          {t("Close")}
        </Button>
        <Button
          className={`${styles.Btn} ${styles.BtnConfirm}`}
          autoFocus
          onClick={onConfirm}
        >
          {t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
