import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import wethLogo from "../../assets/icons/weth.svg"

export const WETH_METAPOOL_NAME = "WETH Metapool"

const WETH_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x1A4d3f6866FE5727ADe2D68a0CC6b70A20d07C8F",
  [ChainId.ASTAR]: "0x81ecac0d6be0550a00ff064a4f9dd2400585fe9c",
})
export const WETH_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x53009E9984D1E2dD0987B2a0Ca753717B059dfD2",
  [ChainId.ASTAR]: "0xa1A56912bC682469ce1393557519659a5Fe6C3Fe",
})
export const WETH_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x7d963af393FE8178765091A747Feb3439117C5ff",
  [ChainId.ASTAR]: "0x46F63Ec42eFcf972FCeF2330cC22e6ED1fCEB950",
})
export const WETH_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x90C3bB6673c2fA03Bafe500CB0EF775858fCeE61",
  [ChainId.ASTAR]: "0x2d5Da7c463B3E8f4CF1AF08a1aA0a5DB9BB644F7",
})
export const WETH_FARM_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xfE7ABdaE5c2E85c4A86b5aaE060F266e8A544C8B",
  [ChainId.ASTAR]: "0x9e56b431AA4Bc8B8D04057977e3606A9110E479f",
})

export const WETH = new Token(
  WETH_CONTRACT_ADDRESSES,
  18,
  "WETH",
  "weth",
  "Wrapped Ether",
  wethLogo,
  true,
)
export const WETH_SWAP_TOKEN = new Token(
  WETH_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4WETH",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  wethLogo,
  false,
  true,
)

export const WETH_POOL_TOKENS = [WETH, ...STABLECOIN_POOL_TOKENS]
export const WETH_UNDERLYING_POOL_TOKENS = [WETH, STABLECOIN_SWAP_TOKEN]

export const WETH_POOL_INFO: Pool = {
  name: WETH_METAPOOL_NAME,
  lpToken: WETH_SWAP_TOKEN,
  poolTokens: WETH_POOL_TOKENS,
  isSynthetic: true,
  addresses: WETH_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: WETH_FARM_ADDRESSES,
  type: PoolTypes.Crypto,
  route: "weth",
  metaSwapAddresses: WETH_META_SWAP_ADDRESSES,
  underlyingPoolTokens: WETH_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
  unbalancePercent: 50,
}
