import {
  POOLS_MAP,
  PoolName,
  TRANSACTION_TYPES,
  Token,
  isMetaPool,
  isCryptoPool,
  isNativePool,
  isXTriSwapPool
} from "../constants"
import { addSlippage, subtractSlippage } from "../utils/slippage"
import { formatUnits, parseUnits } from "@ethersproject/units"
import { notifyCustomError, notify } from "../utils/notifyHandler"
import { useLPTokenContract, useSwapContract } from "./useContract"
import META_SWAP_ABI from "../constants/abis/metaSwap.json"
import { MetaSwap } from "../../types/ethers-contracts/MetaSwap"
import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { GasPrices } from "../state/user"
import { NumberInputState } from "../utils/numberInputState"
import checkAndApproveTokenForTrade from "../utils/checkAndApproveTokenForTrade"
import { formatDeadlineToNumber, getContract } from "../utils"
import { updateLastTransactionTimes } from "../state/application"
import { useActiveWeb3React } from "."
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { useMemo } from "react"
import { useGasPrice } from "./useGas"
import CryptometapoolABI from "../constants/abis/Cryptometapool.json"
import { Cryptometapool } from "../../types/ethers-contracts/Cryptometapool"
import { CryptometapoolDeposit } from "../../types/ethers-contracts/CryptometapoolDeposit"
import { SwapETH } from "../../types/ethers-contracts/SwapETH"
import XTriSwapABI from "../constants/abis/XTriSwap.json"
import { XTriSwap } from "../../types/ethers-contracts/XTriSwap"

interface ApproveAndWithdrawStateArgument {
  tokenFormState: { [symbol: string]: NumberInputState }
  withdrawType: string
  lpTokenAmountToSpend: BigNumber
}

export function useApproveAndWithdraw(
  poolName: PoolName,
): (
  state: ApproveAndWithdrawStateArgument,
  shouldWithdrawWrapped: boolean,
) => Promise<void> {
  const dispatch = useDispatch()
  const swapContract = useSwapContract(poolName)
  const { account, chainId, library } = useActiveWeb3React()
  const { gasStandard, gasFast, gasInstant } = useSelector(
    (state: AppState) => state.application,
  )
  const {
    slippageCustom,
    slippageSelected,
    gasPriceSelected,
    gasCustom,
    transactionDeadlineCustom,
    transactionDeadlineSelected,
    infiniteApproval,
  } = useSelector((state: AppState) => state.user)
  const lpTokenContract = useLPTokenContract(poolName)
  const POOL = POOLS_MAP[poolName]
  const _gasPrice = useGasPrice()

  const metaSwapContract = useMemo(() => {
    if (isMetaPool(POOL.name) && POOL.metaSwapAddresses && chainId && library) {
      return getContract(
        POOL.metaSwapAddresses?.[chainId],
        META_SWAP_ABI,
        library,
        account ?? undefined,
      ) as MetaSwap
    }
    return null
  }, [chainId, library, POOL.metaSwapAddresses, account])

  const cryptoSwapContract = useMemo(() => {
    if (
      isCryptoPool(POOL.name) &&
      POOL.metaSwapAddresses &&
      chainId &&
      library
    ) {
      return getContract(
        POOL.metaSwapAddresses?.[chainId],
        CryptometapoolABI,
        library,
        account ?? undefined,
      ) as Cryptometapool
    }
    return null
  }, [chainId, library, POOL.metaSwapAddresses, account])

  const xTriSwapContract = useMemo(() => {
    if (
      isXTriSwapPool(POOL.name) &&
      POOL.metaSwapAddresses &&
      chainId &&
      library
    ) {
      return getContract(
        POOL.metaSwapAddresses?.[chainId],
        XTriSwapABI,
        library,
        account ?? undefined,
      ) as XTriSwap
    }
    return null
  }, [chainId, library, POOL.metaSwapAddresses, account])

  return async function approveAndWithdraw(
    state: ApproveAndWithdrawStateArgument,
    shouldWithdrawWrapped = false,
  ): Promise<any> {
    try {
      if (!account) throw new Error("Wallet must be connected")
      if (!swapContract) throw new Error("Swap contract is not loaded")
      if (state.lpTokenAmountToSpend.isZero()) return
      if (lpTokenContract == null) return

      const poolTokens = shouldWithdrawWrapped
        ? (POOL.underlyingPoolTokens as Token[])
        : POOL.poolTokens
      const effectiveSwapContract = shouldWithdrawWrapped
        ? (metaSwapContract as MetaSwap)
        : swapContract
      let gasPrice
      if (gasPriceSelected === GasPrices.Custom && gasCustom?.valueSafe) {
        gasPrice = gasCustom.valueSafe
      } else if (gasPriceSelected === GasPrices.Standard) {
        gasPrice = gasStandard
      } else if (gasPriceSelected === GasPrices.Instant) {
        gasPrice = gasInstant
      } else {
        gasPrice = gasFast
      }
      gasPrice = parseUnits(gasPrice?.toString() || "45", "gwei")
      const allowanceAmount =
        state.withdrawType === "IMBALANCE"
          ? addSlippage(
              state.lpTokenAmountToSpend,
              slippageSelected,
              slippageCustom,
            )
          : state.lpTokenAmountToSpend
      await checkAndApproveTokenForTrade(
        lpTokenContract,
        effectiveSwapContract?.address || cryptoSwapContract?.address || "",
        account,
        allowanceAmount,
        infiniteApproval,
        _gasPrice,
        {
          onTransactionError: () => {
            throw new Error("Your transaction could not be completed")
          },
        },
      )

      console.debug(
        `lpTokenAmountToSpend: ${formatUnits(state.lpTokenAmountToSpend, 18)}`,
      )
      const deadline = Math.round(
        new Date().getTime() / 1000 +
          60 *
            formatDeadlineToNumber(
              transactionDeadlineSelected,
              transactionDeadlineCustom,
            ),
      )
      let spendTransaction
      if (state.withdrawType === "ALL") {
        // 输入比例，combo
        if (isCryptoPool(poolName)) {
          if (shouldWithdrawWrapped) {
            if (isXTriSwapPool(poolName)) {
              spendTransaction = await xTriSwapContract?.removeLiquidity(
                state.lpTokenAmountToSpend,
                poolTokens.map(({ symbol }) =>
                  subtractSlippage(
                    BigNumber.from(state.tokenFormState[symbol].valueSafe),
                    slippageSelected,
                    slippageCustom,
                  ),
                ),
                // account,
                { gasPrice: _gasPrice },
              )
            } else {
              spendTransaction = await cryptoSwapContract?.removeLiquidity(
                state.lpTokenAmountToSpend,
                poolTokens.map(({ symbol }) =>
                  subtractSlippage(
                    BigNumber.from(state.tokenFormState[symbol].valueSafe),
                    slippageSelected,
                    slippageCustom,
                  ),
                ),
                account,
                { gasPrice: _gasPrice },
              )
            }
          } else {
            spendTransaction = await (
              swapContract as any as CryptometapoolDeposit
            )?.removeLiquidity(
              state.lpTokenAmountToSpend,
              poolTokens.map(({ symbol }) =>
                subtractSlippage(
                  BigNumber.from(state.tokenFormState[symbol].valueSafe),
                  slippageSelected,
                  slippageCustom,
                ),
              ),
              deadline,
              { gasPrice: _gasPrice },
            )
          }
        } else {
          spendTransaction = await effectiveSwapContract.removeLiquidity(
            state.lpTokenAmountToSpend,
            poolTokens.map(({ symbol }) =>
              subtractSlippage(
                BigNumber.from(state.tokenFormState[symbol].valueSafe),
                slippageSelected,
                slippageCustom,
              ),
            ),
            deadline,
            { gasPrice: _gasPrice },
          )
        }
      } else if (state.withdrawType === "IMBALANCE") {
        // 输入币种，多个
        if (isCryptoPool(poolName)) {
          spendTransaction = await cryptoSwapContract?.removeLiquidity(
            state.lpTokenAmountToSpend,
            poolTokens.map(({ symbol }) =>
              subtractSlippage(
                BigNumber.from(state.tokenFormState[symbol].valueSafe),
                slippageSelected,
                slippageCustom,
              ),
            ),
            account,
            { gasPrice: _gasPrice },
          )
        } else {
          spendTransaction =
            await effectiveSwapContract.removeLiquidityImbalance(
              poolTokens.map(
                ({ symbol }) => state.tokenFormState[symbol].valueSafe,
              ),
              addSlippage(
                state.lpTokenAmountToSpend,
                slippageSelected,
                slippageCustom,
              ),
              deadline,
              { gasPrice: _gasPrice },
            )
        }
      } else {
        // 取单币 removeLiquidityOneCoin
        // state.withdrawType === [TokenSymbol]
        if (isCryptoPool(poolName)) {
          if (shouldWithdrawWrapped) {
            spendTransaction = await cryptoSwapContract?.removeLiquidityOneCoin(
              state.lpTokenAmountToSpend,
              poolTokens.findIndex(
                ({ symbol }) => symbol === state.withdrawType,
              ),
              subtractSlippage(
                BigNumber.from(
                  state.tokenFormState[state.withdrawType || ""].valueSafe,
                ),
                slippageSelected,
                slippageCustom,
              ),
              // 0,
              account,
              { gasPrice: _gasPrice },
            )
          } else {
            spendTransaction = await (
              swapContract as any as CryptometapoolDeposit
            )?.removeLiquidityOneToken(
              state.lpTokenAmountToSpend,
              poolTokens.findIndex(
                ({ symbol }) => symbol === state.withdrawType,
              ),
              subtractSlippage(
                BigNumber.from(
                  state.tokenFormState[state.withdrawType || ""].valueSafe,
                ),
                slippageSelected,
                slippageCustom,
              ),
              deadline,
              { gasPrice: _gasPrice },
            )
          }
        } else {
          spendTransaction =
            await effectiveSwapContract.removeLiquidityOneToken(
              state.lpTokenAmountToSpend,
              poolTokens.findIndex(
                ({ symbol }) => symbol === state.withdrawType,
              ),
              subtractSlippage(
                BigNumber.from(
                  state.tokenFormState[state.withdrawType || ""].valueSafe,
                ),
                slippageSelected,
                slippageCustom,
              ),
              deadline,
              { gasPrice: _gasPrice },
            )
        }
      }

      // notifyHandler(spendTransaction.hash, "withdraw")

      notify.notification({
        type: "pending",
        message: "Your transaction has been sent to the network",
        autoDismiss: 5000,
      })
      return spendTransaction
      notify.notification({
        type: "success",
        message: "Your transaction has succeeded",
        autoDismiss: 5000,
      })
      dispatch(
        updateLastTransactionTimes({
          [TRANSACTION_TYPES.WITHDRAW]: Date.now(),
        }),
      )
    } catch (e) {
      console.error(e)
      throw e
      // notifyCustomError(e as Error)
    }
  }
}
