import { TOKENS_MAP } from "../constants"
import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import classnames from "classnames"
import { commify, formatBNToString, expensiveDecimals } from "../utils"
import { Dialog } from "@mui/material"
import ConfirmTransaction from "./ConfirmTransaction"
import { SRS_TOKEN } from "../constants"
import styles from "./TokenInput.module.scss"
import { useActiveWeb3React } from "../hooks"
import { useTokenContract } from "../hooks/useContract"
import { MaxUint256 } from "@ethersproject/constants"
import { BigNumber } from "@ethersproject/bignumber"
import { notifyCustomError, notify } from "../utils/notifyHandler"
interface Props {
  symbol?: string
  icon?: string
  max?: string
  decimals?: number
  inputValue: string
  onChange: (value: string) => void
  disabled?: boolean
  showMax?: boolean
  simple?: boolean
  className?: string
  targetContractAddress?: string
  showBorderBottom?: boolean
}

function TokenInput({
  symbol,
  icon,
  max,
  decimals,
  inputValue,
  onChange,
  disabled,
  showMax = true,
  simple = false,
  className = "",
  targetContractAddress,
  showBorderBottom = true,
}: Props): ReactElement {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const [isApproved, setIsApproved] = useState(true)
  const [txHash, setTxHash] = useState("")
  const tokenContract = useTokenContract(
    _.get(TOKENS_MAP, symbol || "") || SRS_TOKEN,
  )
  const [showConfirmModal, setShowConfirmModal] = useState(false)

  function onChangeInput(e: React.ChangeEvent<HTMLInputElement>): void {
    const parsedValue = parseFloat("0" + e.target.value)
    const periodIndex = e.target.value.indexOf(".")
    const isValidInput = e.target.value === "" || !isNaN(parsedValue)
    const isValidPrecision =
      periodIndex === -1 ||
      e.target.value.length - 1 - periodIndex <= (decimals || 18)
    if (isValidInput && isValidPrecision) {
      // don't allow input longer than the token allows
      onChange(e.target.value)
    }
  }

  const getApprovedStatus = useCallback(() => {
    tokenContract
      ?.allowance(account, targetContractAddress)
      .then((res: BigNumber) => {
        setIsApproved(+formatBNToString(res, 18) > 0)
      })
      .catch(() => {
        // todo
      })
  }, [tokenContract, account, targetContractAddress])

  useEffect(() => {
    if (targetContractAddress) {
      getApprovedStatus()
    }
  }, [targetContractAddress, getApprovedStatus])

  const approve = async () => {
    try {
      setShowConfirmModal(true)
      const transaction = await tokenContract?.approve(
        targetContractAddress,
        MaxUint256.toString(),
      )

      setTxHash(transaction?.hash)
      notify.notification({
        type: "pending",
        message: "Your transaction has been sent to the network",
        autoDismiss: 5000,
      })
      const ret = await transaction?.wait()
      notify.notification({
        type: "success",
        message: `Approve ${symbol} has succeeded`,
        autoDismiss: 5000,
      })
      if (ret?.status) {
        setIsApproved(true)
        setShowConfirmModal(false)
      }
    } catch (error) {
      notifyCustomError(error as Error)
    } finally {
      setShowConfirmModal(false)
      setTxHash("")
    }
  }

  const getDisabled = () => {
    if (disabled) {
      return true
    }

    if (targetContractAddress && !isApproved) {
      return true
    }

    return false
  }

  return (
    <div className={className}>
      {/* {max != null && (
        <div className={styles.balanceContainer}>
          <span>{t("balance")}:</span>
          &nbsp;
          <a onClick={() => onChange(String(max))}>{max}</a>
        </div>
      )} */}

      <div
        className={classnames(styles.tokenInputContainer, {
          [styles.disabled]: getDisabled(),
          [styles.showBorderBottom]: showBorderBottom,
        })}
        id="tokenInput"
      >
        {!simple && (
          <>
            <img alt="icon" src={icon} />
            <div className={styles.tokenSymbolAndName}>
              <p
                className={classnames({
                  [styles.boldText]: true,
                  [styles.textDisabled]: getDisabled(),
                })}
              >
                {symbol}
              </p>
              <p
                className={classnames({
                  [styles.smallText]: true,
                  [styles.textDisabled]: getDisabled(),
                })}
              >
                {t("Bal.")}{" "}
                {max ? commify(max, expensiveDecimals(symbol)) : "-"}
              </p>
            </div>
          </>
        )}
        <div className={styles.inputGroup}>
          <input
            className={classnames({
              [styles.errorInput]: +inputValue > (max ? +max : 0) && showMax,
              [styles.showMax]: showMax,
            })}
            autoComplete="off"
            autoCorrect="off"
            type="text"
            placeholder="0.00"
            spellCheck="false"
            disabled={getDisabled()}
            value={inputValue}
            onChange={onChangeInput}
            onFocus={(e: React.ChangeEvent<HTMLInputElement>): void =>
              e.target.select()
            }
            onKeyDown={(e) => {
              const c = e.keyCode
              if (c == 110 || c == 190) {
                // 110 (190) - 小(主)键盘上的点
                // (val.indexOf(".") >= 0 || !val.length) && prevent(e); // 已有小数点或者文本框为空，不允许输入点
              } else {
                if (
                  (c != 8 &&
                    c != 46 && // 8 - Backspace, 46 - Delete
                    (c < 37 || c > 40) && // 37 (38) (39) (40) - Left (Up) (Right) (Down) Arrow
                    (c < 48 || c > 57) && // 48~57 - 主键盘上的0~9
                    (c < 96 || c > 105)) || // 96~105 - 小键盘的0~9
                  e.shiftKey
                ) {
                  // Shift键，对应的code为16
                  e.preventDefault() // 阻止事件传播到keypress
                }
              }
            }}
          />
          {targetContractAddress && !isApproved && (
            <>
              <button
                className={styles.maxBtn}
                // 小数点后保留四位
                onClick={approve}
              >
                Approve
              </button>
              <Dialog
                open={!!showConfirmModal}
                onClose={(): void => setShowConfirmModal(false)}
              >
                <ConfirmTransaction
                  onClose={(): void => setShowConfirmModal(false)}
                  txHash={txHash}
                />
              </Dialog>
            </>
          )}
          {showMax && isApproved && (
            <button
              className={classnames({
                [styles.maxBtn]: true,
                [styles.maxBtnDisabled]: getDisabled(),
              })}
              // 小数点后保留四位
              onClick={() => {
                if (getDisabled()) {
                  return
                }
                onChange(
                  String(
                    _.floor(max ? +max : 0, 4),
                    // `${max?.split(".")[0]}.${max?.split(".")[1].slice(0, 4)}`,
                  ),
                )
              }}
            >
              Max
            </button>
          )}
          {/* <p className={styles.smallText}>
            ≈$
            {commify(
              formatBNToString(
                calculatePrice(inputValue, tokenUSDValue),
                18,
                2,
              ),
            )}
          </p> */}
        </div>
      </div>
    </div>
  )
}

export default TokenInput
