import "./PoolOverview.scss"
import { Stack, Box } from "@mui/material"
import {
  POOLS_MAP,
  PoolTypes,
  TOKENS_MAP,
  isMetaPool,
} from "../constants"
import { Partners, PoolDataType, UserShareType } from "../hooks/usePoolData"
import React, { ReactElement } from "react"
import {
  formatBNToPercentString,
  formatBNToShortString,
  formatBNToString,
} from "../utils"
import { useHistory } from "react-router-dom"
import { Zero } from "@ethersproject/constants"
import { useTranslation } from "react-i18next"
import styles from "./PoolOverview.module.scss"

interface Props {
  poolRoute: string
  poolData: PoolDataType
  userShareData: UserShareType | null
  hot?: boolean
}

export default function PoolOverview({
  poolData,
  poolRoute,
  userShareData,
  hot,
}: Props): ReactElement | null {
  const history = useHistory()
  const { t } = useTranslation()
  const { type: poolType, isOutdated, show } = POOLS_MAP[poolData.name]
  const formattedDecimals = poolType === PoolTypes.USD ? 2 : 4

  const formattedData = {
    name: poolData.name,
    reserve: poolData.reserve
      ? formatBNToShortString(poolData.reserve, 18)
      : "-",
    aprs: Object.keys(poolData.aprs).reduce((acc, key) => {
      const apr = poolData.aprs[key as Partners]?.apr
      return apr
        ? {
            ...acc,
            [key]: formatBNToPercentString(apr, 18),
          }
        : acc
    }, {} as Partial<Record<Partners, string>>),
    apy: poolData.apy ? `${formatBNToPercentString(poolData.apy, 18, 2)}` : "-",
    volume: poolData.volume
      ? `$${formatBNToShortString(poolData.volume, 18)}`
      : "-",
    userBalanceUSD: formatBNToShortString(
      userShareData?.usdBalance || Zero,
      18,
    ),
    sdlPerDay: formatBNToShortString(poolData?.sdlPerDay || Zero, 18),
    tokens: poolData.tokens.map((coin) => {
      const token = TOKENS_MAP[coin.symbol]
      return {
        symbol: token.symbol,
        name: token.name,
        icon: token.icon,
        value: formatBNToString(coin.value, token.decimals, formattedDecimals),
      }
    }),
  }
  const hasShare = !!userShareData?.usdBalance.gt("0")
  const isMetapool = isMetaPool(formattedData.name)

  return (
    <Stack
      direction="row"
      p={3}
      className="pool-item"
      onClick={() => history.push(show ? `${poolRoute}/deposit` : "/pools")}
    >
      <Box flex={2}>
        <Box display={{ sm: "none" }}>{formattedData.name}</Box>
        <Stack direction="row" spacing={-1} alignItems="center">
          {formattedData.tokens?.map(({ symbol, icon }) => (
            <img width={24} height={24} key={symbol} src={icon} />
          ))}
        </Stack>
      </Box>
      <Box flex={2} display={{ xs: "none", sm: "block" }} position="relative">
        {formattedData.name}

        {hot && <Box className={styles.Hot}>HOT</Box>}
      </Box>
      <Box flex={1} textAlign="center" display={{ xs: "none", sm: "block" }}>
        {`$${formattedData.userBalanceUSD}`}
      </Box>
      <Box flex={1} textAlign="center">
        {formattedData.apy}
      </Box>
      <Box flex={1} textAlign="center">
        {`$${formattedData.reserve}`}
      </Box>
    </Stack>
  )
}
