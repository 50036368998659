import { Components } from "@mui/material"

export default function BackdropTheme(): Components {
  return {
    MuiBackdrop: {
      // styleOverrides: {
      //   root: {
      //     backgroundColor: "rgba(255, 255, 255, 0.8)",
      //   },
      // },
    },
  }
}
