import React, { ReactElement, useState } from "react"
import {
  useTheme,
  Box,
  Stack,
  Typography,
  Popover,
  IconButton,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import CheckIcon from "@mui/icons-material/Check"
import zhIcon from "../assets/flag/zh.png"
import enIcon from "../assets/flag/en.png"
import jpIcon from "../assets/flag/jp.png"
import ruIcon from "../assets/flag/ru.png"
import inIcon from "../assets/flag/in.png"
import trIcon from "../assets/flag/tr.png"

const languageOptions = [
  { key: "en", icon: enIcon, displayText: "English" },
  { key: "jp", icon: jpIcon, displayText: "日本語" },
  { key: "ru", icon: ruIcon, displayText: "русский язык" },
  { key: "in", icon: inIcon, displayText: "Bahasa Indonesia" },
  { key: "tr", icon: trIcon, displayText: "Türkiye" },
  { key: "zh", icon: zhIcon, displayText: "中文(简体)" },
]

export default function TopMenuLanguage(): ReactElement {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const currLang = i18n.language
  const currIcon =
    languageOptions.find((i) => i.key === currLang)?.icon || enIcon

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={{ p: 0 }}>
        <img src={currIcon} width={20} height={20} />
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Box p={3}>
          <Typography variant="subtitle2">{t("language")}</Typography>
          {languageOptions.map(({ displayText, key, icon }) => (
            <Stack
              key={key}
              direction="row"
              alignItems="center"
              sx={{
                cursor: "pointer",
                color: currLang === key ? theme.palette.primary.main : "",
                "&:hover": { color: theme.palette.primary.main },
              }}
              onClick={() =>
                !void i18n.changeLanguage(key) && setAnchorEl(null)
              }
            >
              <img src={icon} width={16} height={16} />
              <Box ml={1}>{displayText}</Box>
              {currLang === key && <CheckIcon fontSize="small" />}
            </Stack>
          ))}
        </Box>
      </Popover>
    </>
  )
}
