import React, { ReactElement, useEffect } from "react"
import { utils } from "ethers"
import { BigNumber } from "@ethersproject/bignumber"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"
import { useSelector } from "react-redux"
import { AppState } from "../state"
import { SRS_TOKEN } from "../constants"
import CustomTooltip from "./CustomTooltip"
import { commify, formatBNToString } from "../utils"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
import { useMySrsBalance } from "../hooks/useMyData"
import { ReactComponent as SrsSVG } from "./../assets/icons/srs.svg"
import styles from "./SRSPrice.module.scss"

export default function SRSPrice(): ReactElement {
  const { t } = useTranslation()
  const { srsPrice } = useSelector((state: AppState) => state.application)
  const { addToken } = useAddTokenToMetamask({
    ...SRS_TOKEN,
    icon: `${window.location.origin}/srs.svg`,
  })
  const [bal, getBal] = useMySrsBalance()

  useEffect(() => {
    getBal()
    const t = setInterval(() => getBal(), 6e4)
    return () => clearInterval(t)
  }, [getBal])

  const srsVal = bal
    .mul(utils.parseUnits(srsPrice.toString()))
    .div(BigNumber.from(10).pow(18))

  return (
    <CustomTooltip
      info={t("youHaveSRSAmount", {
        amount: commify(formatBNToString(bal, 18)),
        value: commify(formatBNToString(srsVal, 18)),
      })}
    >
      <Box className={styles.btn} onClick={() => addToken()}>
        <SrsSVG width="30px" height="30px" /> ${srsPrice}
      </Box>
    </CustomTooltip>
  )
}
