import "./ConfirmTransaction.scss"

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
  ReactDOM,
  ReactNode,
} from "react"
import Lottie from "lottie-react"
import { Token, ChainId } from "../constants"
import { useTranslation } from "react-i18next"
import DialogTitle from "./DialogTitle"
import { formatBNToString, getContract } from "../utils"
import ERC20_ABI from "../constants/abis/erc20.json"
import { useActiveWeb3React } from "../hooks"
import { Contract } from "@ethersproject/contracts"
import { MaxUint256, Zero } from "@ethersproject/constants"
import { BigNumber } from "@ethersproject/bignumber"
import { notifyCustomError, notify } from "../utils/notifyHandler"
import { Web3Provider } from "@ethersproject/providers"
import _ from "lodash"
import {
  Box,
  Stepper,
  Step,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Link as Link2,
} from "@mui/material"
import { styled } from "@mui/material"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import completedIcon from "../assets/icons/completed-icon.png"
import failedIcon from "../assets/icons/failed-icon.png"
import sfLoadingJson from "../assets/animation/sf-loading.json"
import Button from "./Button"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import { Link } from "react-router-dom"
import { useGasPrice } from "./../hooks/useGas"

interface Props {
  onClose?: () => void
  txHash?: string
  targetContractAddress?: string
  tokens?: Array<Token>
  handler?: () => Promise<any>
  afterCompleted?: () => void
  afterFailed?: () => void
  showStakeLP?: string
  showToLockBtn?: boolean
  completedInfo?: ReactElement
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#6C7DFF",
    },
  },
}))

function ConfirmTransaction({
  onClose,
  txHash,
  targetContractAddress,
  tokens,
  handler,
  afterCompleted,
  afterFailed,
  showStakeLP,
  showToLockBtn,
  completedInfo,
}: Props): ReactElement {
  const { t } = useTranslation()
  const { account, library, chainId } = useActiveWeb3React()
  const [isApprovedList, setIsApprovedList] = useState<Array<any>>(
    tokens?.map((t) => ({
      ...t,
      address: t.addresses[chainId || ChainId.SHIBUYA],
      isApproved: true,
      init: true,
    })) || [],
  )
  const [activeStep, setActiveStep] = React.useState(1)
  const [getApprovedStatusFinished, setGetApprovedStatusFinished] =
    useState(false)
  const [approveHash, setApproveHash] = useState("")
  const [confirmedHash, setConfirmedHash] = useState("")
  const [approveError, setApproveError] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [translationError, setTranslationError] = useState<any>({})
  const ref = useRef<HTMLElement>()
  const gasPrice = useGasPrice()

  const getApprovedStatus = useCallback(
    (tokenContract) => {
      tokenContract
        ?.allowance(account, targetContractAddress)
        .then((res: BigNumber) => {
          const list = _.cloneDeep(isApprovedList)
          const found = list.find((t) => t.address === tokenContract.address)

          if (found && found.init) {
            found.isApproved = +formatBNToString(res, 18) > 0
            found.init = false
            setIsApprovedList(list)
          }
        })
        .catch(() => {
          // todo
        })
    },
    [account, targetContractAddress, isApprovedList],
  )

  const approve = async (token: any) => {
    try {
      const tokenContract = getContract(
        token.addresses[chainId ?? ChainId.SHIBUYA] || "",
        ERC20_ABI,
        library as Web3Provider,
        account ?? undefined,
      )

      const transaction = await tokenContract?.approve(
        targetContractAddress,
        MaxUint256.toString(),
        // Zero.toString()
        { gasPrice: gasPrice },
      )
      setApproveHash(transaction?.hash)
      notify.notification({
        type: "pending",
        message: t("yourTransHasBeenSentToNetwork"),
        autoDismiss: 5000,
      })
      const list = isApprovedList.filter((item) => !item.isApproved)
      if (list.length === activeStep) {
        const ret = await transaction?.wait()
        if (ret?.status) {
          setActiveStep(list.length + 1)
          notify.notification({
            type: "success",
            message: `${t("Approve")} ${token.symbol} ${t("hassucceeded")}`,
            autoDismiss: 5000,
          })
        }
      } else {
        setActiveStep(activeStep + 1)
        const ret = await transaction?.wait()
        if (ret?.status) {
          notify.notification({
            type: "success",
            message: `${t("Approve")} ${token.symbol} ${t("hassucceeded")}`,
            autoDismiss: 5000,
          })
        }
      }
    } catch (error: any) {
      if (error.code === 4001) {
        setApproveHash("")
      }
      setApproveError(true)
      setTranslationError(error)
      notifyCustomError(error as Error)
    }
  }

  useEffect(() => {
    if (targetContractAddress && tokens?.length && !getApprovedStatusFinished) {
      tokens?.forEach((token) => {
        const tokenContract = getContract(
          token.addresses[chainId ?? ChainId.SHIBUYA] || "",
          ERC20_ABI,
          library as Web3Provider,
          account ?? undefined,
        )
        getApprovedStatus(tokenContract)
      })
    }
  }, [
    targetContractAddress,
    getApprovedStatus,
    tokens
  ])

  const executeHandler = async () => {
    try {
      const spendTransaction = await handler?.()
      setConfirmedHash(spendTransaction?.hash)
      const ret = await spendTransaction?.wait()
      if (ret?.status) {
        setCompleted(true)
        afterCompleted?.()
        notify.notification({
          type: "success",
          message: t("Yourtransactionhassucceeded"),
          autoDismiss: 5000,
        })
      }
    } catch (error: any) {
      if (error.code === 4001) {
        setConfirmedHash("")
      }
      afterFailed?.()
      setApproveError(true)
      setTranslationError(error)
      notifyCustomError(error as Error)
    }
  }

  useEffect(() => {
    if (getApprovedStatusFinished) {
      setTimeout(() => {
        ref.current?.click()
      }, 200)
    }
  }, [getApprovedStatusFinished])

  useEffect(() => {
    if (isApprovedList.every((item) => !item.init)) {
      setGetApprovedStatusFinished(true)
    }
  }, [isApprovedList])

  useEffect(() => {
    if (activeStep === 1) {
      return
    }

    const list = isApprovedList.filter((item) => !item.isApproved)
    if (activeStep <= list.length) {
      void approve(list[activeStep - 1])
    } else {
      void executeHandler()
    }
  }, [activeStep])

  const submit = useCallback(async () => {
    setApproveError(false)
    setTranslationError({})

    const list = isApprovedList.filter((item) => !item.isApproved)

    if (list.length) {
      if (!targetContractAddress) {
        return
      }
      void approve(list[0])
    } else {
      await executeHandler()
    }
  }, [
    setApproveError,
    setTranslationError,
    isApprovedList,
    approve,
    executeHandler,
  ])

  useEffect(() => {
    if (approveError || !_.isEmpty(translationError)) {
      const list = _.cloneDeep(isApprovedList)
      list.forEach((item) => {
        item.init = true
        item.isApproved = false
      })
      setIsApprovedList(list)
    }
  }, [approveError, translationError, isApprovedList, setIsApprovedList])

  const reTry = () => {
    void submit()
  }

  return (
    <div className="confirmTransaction">
      <DialogTitle onClose={onClose}></DialogTitle>
      <button
        onClick={submit}
        style={{ display: "none" }}
        ref={ref as React.RefObject<HTMLButtonElement>}
      >
        submit
      </button>
      <Box width={"50%"} marginX="auto">
        {getApprovedStatusFinished &&
        isApprovedList.filter((item) => !item.isApproved).length &&
        !approveError &&
        _.isEmpty(translationError) ? (
          <Stepper activeStep={activeStep} connector={<QontoConnector />}>
            {isApprovedList
              .filter((item) => !item.isApproved)
              .map((item, index) => (
                <Step key={index}>{index + 1}</Step>
              ))}
            <Step>
              {isApprovedList.filter((item) => !item.isApproved).length + 1}
            </Step>
          </Stepper>
        ) : null}
      </Box>
      {!approveError && !completed && (
        <>
          <Box marginY={4}>
            {getApprovedStatusFinished &&
            isApprovedList.filter((item) => !item.isApproved).length &&
            activeStep <=
              isApprovedList.filter((item) => !item.isApproved).length ? (
              <>
                <Typography fontSize={20} mb={1} fontWeight={700}>
                  {t("Waitingforapproval")}
                </Typography>
                <Typography fontSize={12} fontWeight={400}>
                  {t("confirmTransactionApprovalDesc")}
                </Typography>
              </>
            ) : (
              <Typography fontSize={20} mb={1} fontWeight={700}>
                {confirmedHash
                  ? t("TransactionPending")
                  : t("Waitingforconfirmation")}
              </Typography>
            )}
          </Box>
          <Box className="iconFlexGrow1">
            <Lottie
              animationData={sfLoadingJson}
              loop={true}
              style={{ width: "64px", height: "64px", margin: "auto" }}
            />
          </Box>
        </>
      )}
      {!approveError && completed && (
        <Box marginTop={4}>
          <Typography fontSize={20} mb={1} fontWeight={700}>
            {t("TransactionCompleted")}
          </Typography>
          {completedInfo}
          <Box my={4} className="iconFlexGrow1">
            <img src={completedIcon} width={45} height={45} />
          </Box>
          <Box marginX="auto">
            {!!showStakeLP && (
              <Link to={`/farms/${showStakeLP}`} className="stake-lp">
                <Button size="large" className="stake-lp-btn">
                  {t("StakeLP")}
                </Button>
              </Link>
            )}
            {showToLockBtn && (
              <Link to="/governance" className="stake-lp">
                <Button size="large" className="stake-lp-btn">
                  {t("LockSRSGetveSRS")}
                </Button>
              </Link>
            )}
            <Button onClick={onClose} className="done-btn">
              {t("Done")}
            </Button>
          </Box>
          {chainId && confirmedHash && (
            <Box marginX="auto">
              <Typography>
                <a
                  className="tx-link"
                  href={getEtherscanLink(confirmedHash, "tx", chainId)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("ViewDetails")}
                </a>
              </Typography>
            </Box>
          )}
        </Box>
      )}
      {approveError && (
        <Box marginY={4}>
          <Typography fontSize={20} mb={1} fontWeight={700}>
            
            {t("TransactionFailed")}
          </Typography>
          {(translationError?.data?.message || translationError?.message) && (
            <Box>
              <Accordion className="error-details">
                <AccordionSummary>
                  <Typography fontSize={12} textAlign="center">
                    {t("Technicaldetails")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography fontSize={12}>
                    {translationError?.data?.message ||
                      translationError?.message}<br />
                    <Link2
                      
                      rel="noreferrer"
                      target="_blank"
                      href="https://docs.sirius.finance/faq/why-did-my-transaction-fail"
                    >
                      
                      {t("MoreDetail")}
                    </Link2>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          <Box my={4} className="iconFlexGrow1">
            <img src={failedIcon} width={45} height={45} />
          </Box>
          <Box marginX="auto">
            <Button size="large" className="stake-lp-btn" onClick={reTry}>
              
              {t("TryAgain")}
            </Button>
            <Button onClick={onClose} className="done-btn">
              
              {t("cancel")}
            </Button>
          </Box>
          {chainId && (confirmedHash || approveHash) && (
            <Box marginX="auto">
              <Typography>
                <a
                  className="tx-link"
                  href={getEtherscanLink(
                    confirmedHash || approveHash,
                    "tx",
                    chainId,
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("ViewDetails")}
                  
                </a>
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </div>
  )
}

export default ConfirmTransaction
