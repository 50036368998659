import React, { ReactElement } from "react"
import { useTheme, Link } from "@mui/material"
import { ReactComponent as Email } from "./../assets/icons/Email.svg"
import { ReactComponent as Twitter } from "./../assets/icons/Twitter.svg"
import { ReactComponent as Github } from "./../assets/icons/Github.svg"
import { ReactComponent as Telegram } from "./../assets/icons/Telegram.svg"
import { ReactComponent as Discord } from "./../assets/icons/Discord.svg"
import { ReactComponent as Medium } from "./../assets/icons/Medium.svg"

export const Links = [
  {
    key: "risk",
    name: "Risk",
    link: "https://docs.sirius.finance/risks/risks-of-using-sirius-finance",
  },
  { key: "docs", name: "Docs", link: "https://docs.sirius.finance" },
  { key: "website", name: "Website", link: "https://sirius.finance" },
]

export type SocialChannels = { key: string; icon: ReactElement; link: string }[]
export const SocialChannels: SocialChannels = [
  { key: "email", icon: <Email />, link: "mailto:business@sirius.finance" },
  {
    key: "twitter",
    icon: <Twitter />,
    link: "https://twitter.com/Sirius_Finance",
  },
  { key: "github", icon: <Github />, link: "https://github.com/SiriusFinance" },
  {
    key: "telegram",
    icon: <Telegram />,
    link: "https://t.me/siriusfinanceofficial",
  },
  {
    key: "medium",
    icon: <Medium />,
    link: "https://medium.com/@siriusfinance",
  },
  { key: "discord", icon: <Discord />, link: "https://discord.gg/7yHgwnWF7F" },
]

export function SocialChannelsDOM(): ReactElement {
  const theme = useTheme()
  return (
    <React.Fragment>
      {SocialChannels.map((item) => (
        <Link
          key={item.key}
          href={item.link}
          target="_blank"
          rel="noreferrer"
          sx={{
            mt: 3,
            mr: 3,
            fontSize: 24,
            display: "inline-block",
            verticalAlign: "middle",
            color: theme.palette.mute.main,
            "&:hover": { color: theme.palette.primary.main },
          }}
        >
          {item.icon}
        </Link>
      ))}
    </React.Fragment>
  )
}

export const OfficialLinks = [...Links, ...SocialChannels]

export default OfficialLinks
