import {
  BTC_POOL_NAME,
  POOLS_MAP,
  PoolName,
  TRANSACTION_TYPES,
  Token,
  isLegacySwapABIPool,
  isCryptoPool,
  ASTR_SYMBOL,
  isNativePool,
} from "../constants"
import { formatDeadlineToNumber, getContract } from "../utils"
import { notifyCustomError, notify } from "../utils/notifyHandler"
import {
  useAllContracts,
  useLPTokenContract,
  useSwapContract,
} from "./useContract"
import { useDispatch, useSelector } from "react-redux"

import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { Zero } from "@ethersproject/constants"
import { Erc20 } from "../../types/ethers-contracts/Erc20"
import { GasPrices } from "../state/user"
import { IS_PRODUCTION } from "../utils/environment"
import META_SWAP_ABI from "../constants/abis/metaSwap.json"
import CryptometapoolABI from "../constants/abis/Cryptometapool.json"
import { Cryptometapool } from "../../types/ethers-contracts/Cryptometapool"
import { CryptometapoolDeposit } from "../../types/ethers-contracts/CryptometapoolDeposit"
import { XTriSwap } from "../../types/ethers-contracts/XTriSwap"
import { MetaSwap } from "../../types/ethers-contracts/MetaSwap"
import { NumberInputState } from "../utils/numberInputState"
import { SwapFlashLoan } from "../../types/ethers-contracts/SwapFlashLoan"
import { SwapETH } from "../../types/ethers-contracts/SwapETH"
import { SwapFlashLoanNoWithdrawFee } from "../../types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import { SwapGuarded } from "../../types/ethers-contracts/SwapGuarded"
import checkAndApproveTokenForTrade from "../utils/checkAndApproveTokenForTrade"
import { parseUnits } from "@ethersproject/units"
import { subtractSlippage } from "../utils/slippage"
import { updateLastTransactionTimes } from "../state/application"
import { useActiveWeb3React } from "."
import { useMemo } from "react"
import { ContractTransaction } from "@ethersproject/contracts"
import { useGasPrice } from "./useGas"
import { parseBalance } from "../utils"

interface ApproveAndDepositStateArgument {
  [tokenSymbol: string]: NumberInputState
}

export function useApproveAndDeposit(
  poolName: PoolName,
): (
  state: ApproveAndDepositStateArgument,
  shouldDepositWrapped?: boolean,
) => Promise<void> {
  const dispatch = useDispatch()
  const swapContract = useSwapContract(poolName)
  const lpTokenContract = useLPTokenContract(poolName)
  const tokenContracts = useAllContracts()
  const { account, chainId, library } = useActiveWeb3React()
  const _gasPrice = useGasPrice()
  const { gasStandard, gasFast, gasInstant } = useSelector(
    (state: AppState) => state.application,
  )
  const {
    slippageCustom,
    slippageSelected,
    gasPriceSelected,
    gasCustom,
    transactionDeadlineCustom,
    transactionDeadlineSelected,
    infiniteApproval,
  } = useSelector((state: AppState) => state.user)

  const POOL = POOLS_MAP[poolName]
  const metaSwapContract = useMemo(() => {
    if (POOL.metaSwapAddresses && chainId && library) {
      return getContract(
        POOL.metaSwapAddresses?.[chainId],
        META_SWAP_ABI,
        library,
        account ?? undefined,
      ) as MetaSwap
    }
    return null
  }, [chainId, library, POOL.metaSwapAddresses, account])

  const cryptoMetaSwapContract = useMemo(() => {
    if (POOL.metaSwapAddresses && chainId && library) {
      return getContract(
        POOL.metaSwapAddresses?.[chainId],
        CryptometapoolABI,
        library,
        account ?? undefined,
      ) as Cryptometapool
    }
    return null
  }, [chainId, library, POOL.metaSwapAddresses, account])

  return async function approveAndDeposit(
    state: ApproveAndDepositStateArgument,
    shouldDepositWrapped = false,
  ): Promise<any> {
    try {
      if (!account) throw new Error("Wallet must be connected")
      if (
        !swapContract ||
        !lpTokenContract ||
        (shouldDepositWrapped && !metaSwapContract)
      )
        throw new Error("Swap contract is not loaded")

      const poolTokens = shouldDepositWrapped
        ? (POOL.underlyingPoolTokens as Token[])
        : POOL.poolTokens
      const effectiveSwapContract = shouldDepositWrapped
        ? isCryptoPool(poolName)
          ? cryptoMetaSwapContract
          : (metaSwapContract as MetaSwap)
        : swapContract

      let gasPriceUnsafe: string | number | undefined
      if (gasPriceSelected === GasPrices.Custom) {
        gasPriceUnsafe = gasCustom?.valueSafe
      } else if (gasPriceSelected === GasPrices.Fast) {
        gasPriceUnsafe = gasFast
      } else if (gasPriceSelected === GasPrices.Instant) {
        gasPriceUnsafe = gasInstant
      } else {
        gasPriceUnsafe = gasStandard
      }
      const gasPrice = parseUnits(
        gasPriceUnsafe ? String(gasPriceUnsafe) : "45",
        9,
      )
      const approveSingleToken = async (token: Token): Promise<void> => {
        const spendingValue = BigNumber.from(state[token.symbol].valueSafe)
        if (spendingValue.isZero()) return
        const tokenContract = tokenContracts?.[token.symbol] as Erc20
        if (tokenContract == null) return
        await checkAndApproveTokenForTrade(
          tokenContract,
          effectiveSwapContract?.address || "",
          account,
          spendingValue,
          infiniteApproval,
          _gasPrice,
          {
            onTransactionError: () => {
              throw new Error("Your transaction could not be completed")
            },
          },
        )
        return
      }
      // For each token being deposited, check the allowance and approve it if necessary
      if (!IS_PRODUCTION) {
        for (const token of poolTokens) {
          await approveSingleToken(token)
        }
      } else {
        await Promise.all(
          poolTokens
            .filter((token) => token.symbol !== ASTR_SYMBOL)
            .map((token) => approveSingleToken(token)),
        )
      }

      const isFirstTransaction = (await lpTokenContract.totalSupply()).isZero()
      let minToMint: BigNumber
      if (isFirstTransaction) {
        minToMint = BigNumber.from("0")
      } else {
        if (isLegacySwapABIPool(poolName)) {
          minToMint = await (
            effectiveSwapContract as SwapFlashLoan
          ).calculateTokenAmount(
            account,
            poolTokens.map(({ symbol }) => state[symbol].valueSafe),
            true, // deposit boolean
          )
        }
        if (isCryptoPool(poolName)) {
          minToMint = Zero
        } else {
          minToMint = await (
            effectiveSwapContract as SwapFlashLoanNoWithdrawFee
          ).calculateTokenAmount(
            poolTokens.map(({ symbol }) => state[symbol].valueSafe),
            true, // deposit boolean
          )
        }
      }

      minToMint = subtractSlippage(minToMint, slippageSelected, slippageCustom)
      const deadline = formatDeadlineToNumber(
        transactionDeadlineSelected,
        transactionDeadlineCustom,
      )

      let spendTransaction
      const txnAmounts = poolTokens.map(({ symbol }) => state[symbol].valueSafe)
      const txnDeadline = Math.round(
        new Date().getTime() / 1000 + 60 * deadline,
      )
      if (poolName === BTC_POOL_NAME) {
        const swapGuardedContract = effectiveSwapContract as SwapGuarded
        spendTransaction = await swapGuardedContract?.addLiquidity(
          txnAmounts,
          minToMint,
          txnDeadline,
          [],
          { gasPrice: _gasPrice },
        )
      }
      if (isCryptoPool(poolName)) {
        if (shouldDepositWrapped) {
          const targetContract = effectiveSwapContract as Cryptometapool

          spendTransaction = await targetContract?.addLiquidity(
            txnAmounts,
            0,
            account,
            { gasPrice: _gasPrice },
          )
        } else {
          const targetContract = effectiveSwapContract as unknown
          console.log(
            (targetContract as CryptometapoolDeposit)?.address,
            [
              txnAmounts[0],
              txnAmounts[1],
              txnAmounts[2],
              txnAmounts[3],
              txnAmounts[4],
            ],
            0,
            txnDeadline,
          )

          spendTransaction = await (
            targetContract as CryptometapoolDeposit
          )?.addLiquidity(txnAmounts, 0, txnDeadline, { gasPrice: _gasPrice })
        }
      } else if (isNativePool(poolName)) {
        const swapFlashLoanContract =
          effectiveSwapContract as unknown as SwapETH

        spendTransaction = await swapFlashLoanContract?.addLiquidity(
          txnAmounts,
          minToMint,
          txnDeadline,
          { gasPrice: _gasPrice, value: txnAmounts[0] },
        )
      } else {
        const swapFlashLoanContract = effectiveSwapContract as SwapFlashLoan
        spendTransaction = await swapFlashLoanContract?.addLiquidity(
          txnAmounts,
          minToMint,
          txnDeadline,
          { gasPrice: _gasPrice },
        )
      }

      // notifyHandler(spendTransaction.hash, "deposit", '')
      notify.notification({
        type: "pending",
        message: "Your transaction has been sent to the network",
        autoDismiss: 5000,
      })

      return spendTransaction
      await spendTransaction.wait()
      notify.notification({
        type: "success",
        message: "Your transaction has succeeded",
        autoDismiss: 5000,
      })
      dispatch(
        updateLastTransactionTimes({
          [TRANSACTION_TYPES.DEPOSIT]: Date.now(),
        }),
      )
      return Promise.resolve()
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}
