import { PaletteOptions } from "@mui/material"

// Define custom color types

interface OtherColorTypes {
  divider: string
  border: string
  heavierColor: string
}
declare module "@mui/material/styles/createPalette" {
  interface SimplePaletteColorOptions {
    states?: {
      outlinedRestingBorder?: string
      outlinedHoverBackground?: string
      containedHoverBackground?: string
    }
  }
  interface PaletteColor {
    states?: {
      outlinedRestingBorder?: string
      outlinedHoverBackground?: string
      containedHoverBackground?: string
    }
  }
  interface Palette {
    mute: SimplePaletteColorOptions
    other: OtherColorTypes
  }
  interface PaletteOptions {
    mute: SimplePaletteColorOptions
    other: OtherColorTypes
  }
}

const GREY_TONES = {
  100: "#E4E4E4",
  200: "#A7A7A7",
  300: "#7D7D7D",
  500: "#404040",
  700: "#252525",
}

const lightPalette: PaletteOptions | undefined = {
  mode: "light",
  primary: {
    main: "#01BEEE",
    dark: "#01BEEE",
    light: "#83EBEB",
    states: {
      outlinedRestingBorder: "#01BEEE",
      outlinedHoverBackground: "#E6FFFF",
      containedHoverBackground: "#01BEEE",
    },
  },
  secondary: {
    main: "#01BEED",
    dark: "#D07647",
    light: "#01BEED",
    states: {
      outlinedRestingBorder: "#01BEED",
    },
  },
  mute: {
    main: "#FFFFFF",
    dark: "#01BEED",
    states: {
      containedHoverBackground: "#01BEED",
    },
  },
  info: {
    main: "#4B11F2",
    states: {
      outlinedRestingBorder: "#4B11F2",
      outlinedHoverBackground: "#C9B8FB",
      containedHoverBackground: "#2F099F",
    },
  },
  background: {
    default: "#FFFFFF",
    paper: "#EFF4FF",
  },
  action: {
    hover: "#01BEED",
    hoverOpacity: 0.1,
    active: "#01BEEE",
    disabled: "#A7A7A7",
  },
  success: {
    main: "#01BEEE",
  },
  warning: {
    main: "#FAEA5D",
    dark: "#817F48",
    light: "#FFEA91",
  },
  error: {
    main: "#FB5A5A",
    dark: "#68282F",
  },
  text: {
    secondary: "#252525",
  },
  divider: "#EFF4FF",
  grey: GREY_TONES,
  other: {
    divider: "#EFF4FF",
    border: "#7D7D7D",
    heavierColor: "#EFF4FF",
  },
}

const darkPalette: PaletteOptions | undefined = {
  mode: "dark",
  primary: {
    main: "#FFCDEC",
    dark: "#037777",
    light: "#83EBEB",
    states: {
      outlinedRestingBorder: "#FFCDEC",
      outlinedHoverBackground: "#037777",
      containedHoverBackground: "#E6FFFF",
    },
  },
  secondary: {
    main: "#E6AD76",
    dark: "#D07647",
    light: "#FAF3CE",
    states: {
      outlinedRestingBorder: "#E6AD76",
      outlinedHoverBackground: "#D07647",
      containedHoverBackground: "#FAF3CE",
    },
  },
  mute: {
    main: "#EBEBEB",
    light: "#4B11F2",
    states: {
      containedHoverBackground: "#4B11F2",
    },
  },
  info: {
    main: "#4B11F2",
    dark: "#2F099F",
    light: "#9B91FF",
    states: {
      outlinedRestingBorder: "#4B11F2",
      outlinedHoverBackground: "#270782",
      containedHoverBackground: "#C9B8FB",
    },
  },

  action: {
    hover: "#311188",
    active: "#4B11F2",
    disabled: "#A7A7A7",
    disabledBackground: "#404040",
    hoverOpacity: 0.5,
    selected: "#4B11F2",
  },
  success: {
    main: "#FFCDEC",
    dark: "#037777",
    light: "#83EBEB",
  },
  error: {
    main: "#FB5A5A",
    dark: "#68282F",
    light: "#FDA49A",
  },
  background: {
    default: "#222461",
    paper: "#35235E",
  },
  divider: "rgba(235, 235, 235, 0.1)",
  other: {
    divider: "#FFCDEC",
    border: "#7D7D7D",
    heavierColor: "#150847",
  },
  grey: GREY_TONES,
}
export default { lightPalette, darkPalette }
