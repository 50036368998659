import {
  POOLS_MAP,
  PoolName,
  isLegacySwapABIPool,
  isMetaPool,
  isCryptoPool,
} from "../constants"
import React, { ReactElement, useEffect, useState } from "react"
import WithdrawPage, { ReviewWithdrawData } from "../components/WithdrawPage"
import { commify, formatUnits, parseUnits } from "@ethersproject/units"

import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { SwapFlashLoan } from "../../types/ethers-contracts/SwapFlashLoan"
import { SwapFlashLoanNoWithdrawFee } from "../../types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import { Zero } from "@ethersproject/constants"
import { calculateGasEstimate } from "../utils/gasEstimate"
import { calculatePriceImpact } from "../utils/priceImpact"
import { formatSlippageToString } from "../utils/slippage"
import { useActiveWeb3React } from "../hooks"
import { useApproveAndWithdraw } from "../hooks/useApproveAndWithdraw"
import usePoolData from "../hooks/usePoolData"
import { useSelector } from "react-redux"
import { useSwapContract } from "../hooks/useContract"
import useWithdrawFormState from "../hooks/useWithdrawFormState"
import { useGasPrice } from "../hooks/useGas"
import { formatBNToString } from "../utils"

interface Props {
  poolName: PoolName
}
function Withdraw({ poolName }: Props): ReactElement {
  const [poolData, userShareData] = usePoolData(poolName)
  const gasPrice = useGasPrice()
  const { slippageCustom, slippageSelected } =
    useSelector((state: AppState) => state.user)
  const { tokenPricesUSD } = useSelector(
    (state: AppState) => state.application,
  )
  const approveAndWithdraw = useApproveAndWithdraw(poolName)
  const swapContract = useSwapContract(poolName)
  const { account } = useActiveWeb3React()
  const POOL = POOLS_MAP[poolName]

  const [estWithdrawBonus, setEstWithdrawBonus] = useState(Zero)
  const [shouldWithdrawWrapped, setShouldWithdrawWrapped] = useState(
    isMetaPool(poolData.name) || isCryptoPool(poolData.name),
  )
  const [withdrawFormState, updateWithdrawFormState] =
    useWithdrawFormState(poolName)

  useEffect(() => {
    // evaluate if a new withdraw will exceed the pool's per-user limit
    async function calculateWithdrawBonus(): Promise<void> {
      console.log(swapContract, userShareData, poolData, account)

      if (
        swapContract == null ||
        userShareData == null ||
        poolData == null ||
        account == null
      ) {
        return
      }

      const tokenInputSum = parseUnits(
        (shouldWithdrawWrapped
          ? POOL.underlyingPoolTokens || []
          : POOL.poolTokens
        )
          .reduce(
            (sum, { symbol }) =>
              sum + (+withdrawFormState.tokenInputs[symbol]?.valueRaw || 0),
            0,
          )
          .toString(),
        18,
      )
      let withdrawLPTokenAmount
      if (poolData.totalLocked.gt(0) && tokenInputSum.gt(0)) {
        if (isLegacySwapABIPool(poolData.name)) {
          withdrawLPTokenAmount = await (
            swapContract as SwapFlashLoan
          ).calculateTokenAmount(
            account,
            (shouldWithdrawWrapped
              ? POOL.underlyingPoolTokens || []
              : POOL.poolTokens
            ).map(
              ({ symbol }) =>
                withdrawFormState.tokenInputs[symbol]?.valueSafe ||
                Zero.toString(),
            ),
            false,
          )
        } else {
          withdrawLPTokenAmount = await (
            swapContract as SwapFlashLoanNoWithdrawFee
          ).calculateTokenAmount(
            (shouldWithdrawWrapped
              ? POOL.underlyingPoolTokens || []
              : POOL.poolTokens
            ).map(
              ({ symbol }) =>
                withdrawFormState.tokenInputs[symbol]?.valueSafe ||
                Zero.toString(),
            ),
            false,
          )
        }
      } else {
        // when pool is empty, estimate the lptokens by just summing the input instead of calling contract
        withdrawLPTokenAmount = tokenInputSum
      }
      setEstWithdrawBonus(
        calculatePriceImpact(
          withdrawLPTokenAmount,
          tokenInputSum,
          poolData.virtualPrice,
          true,
        ),
      )
    }
    // void calculateWithdrawBonus()
  }, [
    poolData,
    withdrawFormState,
    swapContract,
    userShareData,
    account,
    POOL.poolTokens,
    POOL.underlyingPoolTokens,
    shouldWithdrawWrapped,
  ])

  async function onConfirmTransaction(): Promise<void> {
    const { withdrawType, tokenInputs, lpTokenAmountToSpend } =
      withdrawFormState
    return approveAndWithdraw(
      {
        tokenFormState: tokenInputs,
        withdrawType,
        lpTokenAmountToSpend,
      },
      shouldWithdrawWrapped,
    )
  }

  const afterCompleted = () => {
    // Clear input after deposit
    updateWithdrawFormState({
      fieldName: "reset",
      value: "reset",
      shouldWithdrawWrapped,
    })
  }

  const tokensData = React.useMemo(
    () =>
      (shouldWithdrawWrapped
        ? POOL.underlyingPoolTokens || []
        : POOL.poolTokens
      ).map(({ name, symbol, icon }) => ({
        name,
        symbol,
        icon,
        inputValue:
          withdrawFormState.tokenInputs[symbol]?.valueRaw || Zero.toString(),
      })),
    [
      withdrawFormState,
      POOL.poolTokens,
      POOL.underlyingPoolTokens,
      shouldWithdrawWrapped,
    ],
  )
  const gasAmount = calculateGasEstimate("removeLiquidityImbalance").mul(
    gasPrice,
  ) // units of gas * GWEI/Unit of gas

  const txnGasCost = {
    amount: gasAmount,
    valueUSD: tokenPricesUSD?.ETH
      ? parseUnits(tokenPricesUSD.ETH.toFixed(2), 18) // USD / ETH  * 10^18
          .mul(gasAmount) // GWEI
          .div(BigNumber.from(10).pow(25)) // USD / ETH * GWEI * ETH / GWEI = USD
      : null,
  }

  const reviewWithdrawData: ReviewWithdrawData = {
    withdraw: [],
    rates: [],
    slippage: formatSlippageToString(slippageSelected, slippageCustom),
    priceImpact: estWithdrawBonus,
    txnGasCost: txnGasCost,
  }

  ;(shouldWithdrawWrapped
    ? POOL.underlyingPoolTokens || []
    : POOL.poolTokens
  ).forEach(({ name, decimals, icon, symbol }) => {
    if (
      BigNumber.from(
        withdrawFormState.tokenInputs[symbol]?.valueSafe || Zero,
      ).gt(0)
    ) {
      reviewWithdrawData.withdraw.push({
        name,
        symbol,
        value: commify(
          formatUnits(
            withdrawFormState.tokenInputs[symbol].valueSafe,
            decimals,
          ),
        ),
        icon,
      })
      if (tokenPricesUSD != null) {
        reviewWithdrawData.rates.push({
          name,
          symbol,
          value: formatUnits(
            withdrawFormState.tokenInputs[symbol].valueSafe,
            decimals,
          ),
          rate: commify(
            (
              tokenPricesUSD[symbol] ||
              +formatBNToString(poolData.virtualPrice, 18, 5)
            )?.toFixed(2),
          ),
        })
      }
    }
  })

  return (
    <WithdrawPage
      title={poolName}
      reviewData={reviewWithdrawData}
      tokensData={tokensData}
      poolData={poolData}
      myShareData={userShareData}
      formStateData={withdrawFormState}
      onConfirmTransaction={onConfirmTransaction}
      onFormChange={updateWithdrawFormState}
      afterCompleted={afterCompleted}
      shouldWithdrawWrapped={shouldWithdrawWrapped}
      onToggleWithdrawWrapped={() => {
        setShouldWithdrawWrapped(!shouldWithdrawWrapped)
        afterCompleted()
      }}
    />
  )
}

export default Withdraw
