/* eslint-disable */
import React, { useEffect, useState } from "react"
import DialogTitle from "./DialogTitle"
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material"
import "./ReviewExtraRewards.scss"
import Button from "../components/Button"
import { ArrowForward } from "@mui/icons-material"
import moment from "moment"
import { useTranslation } from "react-i18next"


interface Props {
  onClose: () => void
  onSubmit: () => void
  MyExtraRewards: any
  forceClaim: boolean
  setForceClaim: any
}

export default function ReviewExtraRewards({
  onClose,
  onSubmit,
  MyExtraRewards,
  forceClaim,
  setForceClaim,
}: Props) {
  // const [forceClaim, setForceClaim] = useState(false);
  // const forceClaim = true;
  const [buttonDisable, setButtonDisable] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    let expire_TokenNum = 0
    MyExtraRewards.map((MyExtraReward: any, _key: any) => {
      if (!MyExtraReward.unlock_time) {
        expire_TokenNum += +(+MyExtraReward.vol).toFixed(2)
      }
    })
    // console.log(expire_TokenNum);
    if (expire_TokenNum === 0 && !forceClaim) {
      setButtonDisable(true)
    } else {
      setButtonDisable(false)
    }
  }, [MyExtraRewards, forceClaim])
  return (
    <Box className="ReviewExtraRewards">
      <DialogTitle onClose={onClose}>{t("extraRewards")}</DialogTitle>
      <Box className="content">
        {MyExtraRewards.map((MyExtraReward: any, _key: any) => (
          <Typography
            variant="subtitle2"
            key={MyExtraReward["symbol"]}
            className={
              !MyExtraReward.unlock_time || forceClaim ? "row" : "row grey"
            }
          >
            <span>
              {MyExtraReward["symbol"]}
              {MyExtraReward.unlock_time ? (
                <>
                  {t("unlockDate")}:{" "}
                  {moment.unix(MyExtraReward.unlock_time).format("LLL")}
                </>
              ) : (
                ""
              )}
              :
            </span>
            <span style={{minWidth: "100px", textAlign: "right"}}>
              {(+MyExtraReward["vol"]).toFixed(2)}
              {forceClaim && MyExtraReward.unlock_time ? (
                <span>
                  <ArrowForward
                    sx={{
                      fontSize: "14px",
                      margin: "0 4px 0 4px",
                      transform: "translateY(2px)",
                    }}
                  />
                  <span className="error">
                    {(+MyExtraReward["vol"] / 2).toFixed(2)}
                  </span>
                </span>
              ) : (
                ""
              )}
            </span>
          </Typography>
        ))}
        <FormGroup className="rer_switch" style={{ marginBottom: "5vh" }}>
          <FormControlLabel
            control={
              <Switch
                onChange={() =>
                  setForceClaim((forceClaim: boolean) => !forceClaim)
                }
                checked={forceClaim}
                // disabled={true}
              />
            }
            label={`${t("extraRewardReveiwSwitchLabel")}`}
          />
        </FormGroup>
        <Button
          fullWidth={true}
          size="medium"
          onClick={() => {
            onClose()
            onSubmit()
          }}
          disabled={buttonDisable}
        >
          {buttonDisable ? <>{t("insufficientNumber")}</> : <>{t("claimExtra")}</>}
        </Button>
      </Box>
    </Box>
  )
}
