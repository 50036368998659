import React, { useEffect, useState, ReactElement } from "react"
import {
  Grid,
  Container,
  Box,
  Stack,
  Paper,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material"
import { useSRSContract } from "../hooks/useContract"
import FarmRow from "../components/FarmRow"
import FarmDetail from "../components/FarmDetail"
import styles from "./Farms.module.scss"
import { POOLS_MAP, Token, PoolTypes } from "../constants"
import { useActiveWeb3React } from "../hooks"
import { BigNumber } from "@ethersproject/bignumber"
import { formatBNToString, formatBNToShortString, commify } from "../utils"
import { ReactComponent as SrsSVG } from "./../assets/icons/srs.svg"
import SrsSVGImg from "./../assets/icons/srs.svg"
import { useParams } from "react-router-dom"
import CustomPopover from "../components/CustomPopover"
import { isMobile } from "react-device-detect"
import { Close as CloseIcon } from "@mui/icons-material"
import {
  FilterTabContext,
  FilterTab,
  FilterTabItem,
  FilterTabList,
} from "../components/FilterTabs"
import { useTranslation } from "react-i18next"

interface poolRoute {
  poolName: string
}
type FarmItem = {
  [lpToken: string]: Token
}

interface LooseObject {
  [key: string]: any
}

const Farms = (): ReactElement => {
  const [anchorPopover, setAnchorPopover] = useState(1)
  const poolRoute: poolRoute = useParams()
  const isDetail = Object.prototype.hasOwnProperty.call(poolRoute, "poolName")
  const { account, chainId } = useActiveWeb3React()
  // const notfirstView = localStorage.getItem("farm_no_show_desc");
  const [notfirstView, setNotfirstView] = useState(false)
  const [showArchived, setShowArchived] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setNotfirstView(localStorage.getItem("farm_no_show_desc4") ? true : false)
    localStorage.setItem("farm_no_show_desc4", "1")
  }, [])
  const srsContract = useSRSContract()
  // const FarmControllerContract = useFarmsControllerContract()
  // console.log(22222);
  // FarmControllerContract?.gaugeRelativeWeight("0x9464c206e0BF6310a130e690209dbFf1a7D4Dc65", 1649894400).then((res: BigNumber) => {
  //   console.log(formatBNToString(res, 18, 2));
  //   console.log(res);console.log(111111);
  // });
  const [FarmListData, setFarmListData] = useState<FarmItem[]>([])
  // const FarmListData: FarmItem[] = []
  useEffect(() => {
    Object.values(POOLS_MAP)
      .filter((item: any) => item.show)
      .map((item: any) => {
        if (!chainId) return
        if (!item.farmAddresses[chainId]) return
        const FarmItemData: FarmItem = {}
        FarmItemData["lpToken"] = item.lpToken
        FarmItemData["poolName"] = item.name
        FarmItemData["poolTokens"] =
          item.underlyingPoolTokens || item.poolTokens
        FarmItemData["farmAddresses"] = item.farmAddresses
        FarmItemData["type"] = item.type
        FarmItemData["hot"] = item.hot
        FarmItemData["isOutdated"] = item.isOutdated
        // FarmItemData['cold'] = item.is
        // FarmListData.push(FarmItemData)
        setFarmListData((prev) => [...prev, FarmItemData])
      })
  }, [])

  // useEffect(() => {
  //   const FarmListData: FarmItem[] = []
  //   Object.values(POOLS_MAP).map((item) => {
  //     const FarmItemData: FarmItem = {}
  //     FarmItemData["lpToken"] = item.lpToken
  //     FarmListData.push(FarmItemData)
  //   })
  // }, [account])

  const [TotalData, setTotalData] = useState({})

  const [TotalRewardSRSPerSecond, setTotalRewardSRSPerSecond] = useState(0)
  useEffect(() => {
    srsContract?.rate().then((res: BigNumber) => {
      setTotalRewardSRSPerSecond(+formatBNToString(res, 18))
    })
  }, [srsContract])

  const [TotalDataShow, setTotalDataShow] = useState({
    TotalValue: "",
    MyStakeValue: "",
    unClaimSRS: "",
    extraRewards: {},
  })

  useEffect(() => {
    // console.log(TotalData);

    let TotalValueRaw = BigNumber.from(0)
    let MyStakeValue = 0
    let unClaimSRS = 0
    const extraRewards: LooseObject = {}
    Object.values(TotalData).map((item: any) => {
      unClaimSRS += +item.unClaimSRS
      MyStakeValue += +item.MyStakeValue
      TotalValueRaw = TotalValueRaw.add(item.TotalValueRaw)

      // extra rewards begin
      item.MyExtraRewards.map((item2: any) => {
        if (!extraRewards[item2.symbol]) {
          extraRewards[item2.symbol] = 0
        }
        // console.log(item2.vol);
        extraRewards[item2.symbol] += +item2.vol
      })
      // console.log(extraRewards);
      // extra rewards end
    })
    const d = {
      unClaimSRS: unClaimSRS.toString(),
      MyStakeValue: MyStakeValue.toString(),
      TotalValue: formatBNToShortString(TotalValueRaw, 18),
      extraRewards: extraRewards,
    }
    setTotalDataShow(d)
  }, [TotalData, Object.keys(TotalData).length])
  return (
    <>
      {isDetail ? (
        <>
          {FarmListData.map((farmData, index) => (
            <div key={index}>
              {poolRoute.poolName === farmData.lpToken.symbol && (
                <FarmDetail
                  farmData={farmData}
                  TotalRewardSRSPerSecond={TotalRewardSRSPerSecond}
                  // RewardRate={1 / FarmListData.length}
                />
              )}
            </div>
          ))}
        </>
      ) : (
        <Container
          disableGutters={true}
          maxWidth={false}
          sx={{
            maxWidth: 920,
            marginTop: { xs: "20px", sm: "40px" },
            padding: { xs: "0 16px", sm: "0" },
          }}
        >
          <Typography
            variant="h2"
            align="left"
            gutterBottom={true}
            mb={2}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {t("farms")}
          </Typography>
          <Grid container spacing={4} columns={15}>
            <Grid item xs={15} sm={6}>
              <Paper variant="outlined">
                <Box className={styles.FarmLayoutLeft}>
                  <Box sx={{ display: { xs: "none", sm: "block" } }}>
                    <SrsSVG style={{ width: "56px", height: "56px" }} />
                  </Box>

                  <Typography
                    variant="subtitle2"
                    sx={{ display: { xs: "none", sm: "block" } }}
                  >
                    {t("farmInfoIntro")}
                  </Typography>
                  <Box>
                    <Typography variant="subtitle2">
                      {t("totalValueStaked")}
                    </Typography>
                    <Typography variant="h1">
                      ${TotalDataShow.TotalValue}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography variant="subtitle2">
                      {t("myStakedValue")}
                    </Typography>
                    <Typography variant="h1">
                      {+TotalDataShow?.MyStakeValue
                        ? "$" + commify(TotalDataShow?.MyStakeValue)
                        : "-"}
                    </Typography>
                  </Box>
                  {Object.keys(TotalDataShow?.extraRewards).length === 0 ? (
                    <Box>
                    <Typography variant="subtitle2">
                      {t("myUnclaimed")} SRS
                    </Typography>
                      <Typography variant="h1">
                        {(+TotalDataShow?.unClaimSRS).toFixed(2)}
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}

                  {Object.entries(TotalDataShow?.extraRewards).map(
                    (value: any, key: any) => (
                      <Box key={key}>
                        {+value[1] ? (
                          <>
                            <Typography>
                              {t("myUnclaimed")} {value[0]}
                            </Typography>
                            <Typography variant="h1">
                              {value[0] === "SRS" ? (
                                <>
                                  {+value[1] + +TotalDataShow?.unClaimSRS
                                    ? (
                                        +value[1] + +TotalDataShow?.unClaimSRS
                                      ).toFixed(2)
                                    : "-"}
                                </>
                              ) : (
                                <>
                                  {+value[1]
                                    ? commify(value[1].toString())
                                    : "-"}
                                </>
                              )}
                            </Typography>
                          </>
                        ) : (
                          ""
                        )}
                      </Box>
                    ),
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={15} sm={9}>
              <FilterTabContext>
                <FilterTabList style={{ marginTop: 0 }}>
                  {[
                    ["all", "All"] as const,
                    [PoolTypes.USD, "Stablecoins"] as const,
                    [PoolTypes.Crypto, "Crypto"] as const,
                  ].map(([filterKey, text]) => (
                    <FilterTab kind={filterKey} key={filterKey}>
                      {t(text)}
                    </FilterTab>
                  ))}
                </FilterTabList>
                <Box
                  height={4}
                  mb={6}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={() => setShowArchived(!showArchived)}
                          checked={showArchived}
                        />
                      }
                      label={
                        <Typography fontSize={12}>
                          {t("Show Archived")}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </Box>
                {/* hot farm 置顶 */}
                {FarmListData.filter((item) => item.hot).map(
                  (farmData, index) => (
                    <FilterTabItem kind={farmData.type} key={index}>
                      <FarmRow
                        farmData={farmData}
                        TotalRewardSRSPerSecond={TotalRewardSRSPerSecond}
                        // RewardRate={1 / FarmListData.length}
                        setTotalData={setTotalData}
                      />
                    </FilterTabItem>
                  ),
                )}
                {FarmListData.filter((item) => !item.hot).filter((item) => showArchived ? true : !item.isOutdated).map(
                  (farmData, index) => (
                    <FilterTabItem kind={farmData.type} key={index}>
                      <FarmRow
                        farmData={farmData}
                        TotalRewardSRSPerSecond={TotalRewardSRSPerSecond}
                        // RewardRate={1 / FarmListData.length}
                        setTotalData={setTotalData}
                      />
                    </FilterTabItem>
                  ),
                )}
              </FilterTabContext>
            </Grid>
          </Grid>
          {isMobile && !notfirstView ? (
            <CustomPopover
              open={Boolean(anchorPopover)}
              onClose={() => setAnchorPopover(0)}
              PopoverProps={{
                open: Boolean(anchorPopover),
                // anchorEl: anchorPopover,
                anchorOrigin: { vertical: "bottom", horizontal: "left" },
              }}
            >
              <IconButton
                onClick={() => setAnchorPopover(0)}
                sx={{ position: "absolute", right: 24, top: 24 }}
              >
                <CloseIcon />
              </IconButton>
              <Stack
                alignItems="center"
                spacing={6}
                sx={{ padding: "40px 0 20px" }}
              >
                <Typography variant="h1">{t("farms")}</Typography>
                <img
                  src={SrsSVGImg}
                  style={{ width: "56px", height: "56px" }}
                />
                <Typography variant="subtitle2">
                  {t("farmInfoIntro")}
                </Typography>
              </Stack>
            </CustomPopover>
          ) : (
            ""
          )}
        </Container>
      )}
    </>
  )
}

export default Farms
