import {
  Box,
  // Button,
  Container,
  Dialog,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Slider,
  FormGroup,
  Switch,
  Grid,
} from "@mui/material"
import Button from "./Button"
import { PoolDataType, UserShareType } from "../hooks/usePoolData"
import React, { ReactElement, useState } from "react"
import { Link } from "react-router-dom"
import AdvancedOptions from "./AdvancedOptions"
import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import ConfirmTransaction from "./ConfirmTransaction"
import MyFarm from "./MyFarm"
import MyShareCard from "./MyShareCard"
import PoolInfoCard from "./PoolInfoCard"
import ReviewWithdraw from "./ReviewWithdraw"
import TokenInput from "./TokenInput"
import { WithdrawFormState } from "../hooks/useWithdrawFormState"
import { Zero } from "@ethersproject/constants"
import {
  formatBNToPercentString,
  formatDeadlineToNumber,
  getPoolRoute,
} from "../utils"
import { formatSlippageToString } from "../utils/slippage"
import { logEvent } from "../utils/googleAnalytics"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import usdsLogo from "../assets/icons/usds-logo.png"
import "./WithdrawPage.scss"
import { POOLS_MAP, isMetaPool, OUSD_METAPOOL_NAME, BAI_METAPOOL_NAME, isCryptoPool } from "../constants"
import { useActiveWeb3React } from "../hooks"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import link_icon from "../assets/icons/link_icon.svg"
import { ArrowBack, ArrowForward } from "@mui/icons-material"
import { isMobile } from "react-device-detect"
import CustomTooltip from "../components/CustomTooltip"
import bellIcon from "../assets/icons/bell.svg"

export interface ReviewWithdrawData {
  withdraw: {
    name: string
    symbol: string
    value: string
    icon: string
  }[]
  rates: {
    name: string
    symbol: string
    value: string
    rate: string
  }[]
  slippage: string
  priceImpact: BigNumber
  txnGasCost: {
    amount: BigNumber
    valueUSD: BigNumber | null // amount * ethPriceUSD
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  title: string
  tokensData: Array<{
    symbol: string
    name: string
    icon: string
    inputValue: string
  }>
  reviewData: ReviewWithdrawData
  selected?: { [key: string]: any }
  poolData: PoolDataType | null
  myShareData: UserShareType | null
  formStateData: WithdrawFormState
  onFormChange: (action: any) => void
  onConfirmTransaction: () => Promise<void>
  afterCompleted: () => void
  shouldWithdrawWrapped: boolean
  onToggleWithdrawWrapped: () => void
}
/* eslint-enable @typescript-eslint/no-explicit-any */

const WithdrawPage = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const {
    tokensData,
    poolData,
    myShareData,
    onFormChange,
    formStateData,
    reviewData,
    onConfirmTransaction,
    afterCompleted,
    shouldWithdrawWrapped,
    onToggleWithdrawWrapped,
  } = props

  const {
    gasPriceSelected,
    slippageCustom,
    slippageSelected,
    transactionDeadlineSelected,
    transactionDeadlineCustom,
  } = useSelector((state: AppState) => state.user)
  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const theme = useTheme()
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"))
  const { chainId } = useActiveWeb3React()

  const pool = poolData?.name && POOLS_MAP[poolData?.name]

  const addresses = pool
    ? shouldWithdrawWrapped
      ? pool?.metaSwapAddresses
      : pool?.addresses
    : undefined
  const targetContractAddress = addresses && chainId ? addresses[chainId] : ""
  const poolRoute = pool && getPoolRoute(pool.name)
  const shouldDisplayWrappedOption =
    isMetaPool(poolData?.name) || isCryptoPool(poolData?.name)
  const [isShowMobileDetails, setIsShowMobileDetails] = useState(false)

  const onSubmit = (): void => {
    console.log(formStateData)

    // setCurrentModal("review")
    setShowReview(true)
  }
  const handleWithdrawChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFormChange({
      fieldName: "withdrawType",
      value: event.target.value,
      shouldWithdrawWrapped,
    })
  }
  const noShare = !myShareData || myShareData.lpTokenBalance.eq(Zero)
  
  const [showReview, setShowReview] = useState(false)
  return (
    <Container
      className="deposit-withdraw-page"
      disableGutters={true}
      maxWidth={false}
      sx={{
        maxWidth: 920,
        marginTop: "40px",
        paddingX: {
          xs: 0,
          sm: 0,
        },
      }}
    >
      <Box
        className="back-to-pools"
        fontSize={20}
        mb={4}
        display="flex"
        justifyContent="space-between"
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Link
          style={{ color: "#EBEBEB", display: "flex", alignItems: "center" }}
          to="/pools"
        >
          <ArrowBack />
          <span>{t("Back to pool list")}</span>
        </Link>
        <Link
          style={{ color: "#EBEBEB", display: "flex", alignItems: "center" }}
          to="/farms"
        >
          <span>{t("Stake LP")}</span>
          <ArrowForward />
        </Link>
      </Box>
      {isMobile && isShowMobileDetails && (
        <Box mb={4}>
          <Typography
            fontSize={14}
            fontWeight={700}
            ml={2}
            color="#FFCDEC"
            onClick={() => {
              setIsShowMobileDetails(false)
            }}
          >
            {t("back")}
          </Typography>
        </Box>
      )}
      <Grid container spacing={4} columns={15}>
        <Grid xs={15} sm={6} item>
          <Box className="left-info">
            <Box p="20px" className="apy-info">
              <Box display="flex" justifyContent="space-between">
                <Typography fontWeight={700}>{poolData?.name}</Typography>
                {!isShowMobileDetails && (
                  <Typography
                    fontWeight={700}
                    fontSize={14}
                    color="#FFCDEC"
                    display={{ sm: "none" }}
                    onClick={() => setIsShowMobileDetails(true)}
                  >
                    {t("See Pool Details")}
                  </Typography>
                )}
              </Box>
              <Box display="flex" justifyContent="space-between">
                <CustomTooltip info={t("feeRewardTooltip") || ""}>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    fontSize={24}
                    sx={{
                      textDecoration: "dashed underline",
                      cursor: "pointer",
                    }}
                  >
                    {t("Fee Reward")}
                  </Typography>
                </CustomTooltip>
                <Typography fontWeight={700} fontSize={24} className="apy">
                  {poolData?.apy
                    ? `${formatBNToPercentString(poolData.apy, 18, 2)}`
                    : "-"}
                </Typography>
              </Box>
              {poolData?.name === OUSD_METAPOOL_NAME && (
                <Typography mt={2} fontSize={12}>
                  How do I get oUSD? You can{" "}
                  <a
                    href="https://app.orcusfinance.io/mint-redeem"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    mint oUSD
                  </a>{" "}
                  on{" "}
                  <a
                    href="https://orcusfinance.io"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    Orcus Finance
                  </a>
                  .
                </Typography>
              )}
              {poolData?.name === BAI_METAPOOL_NAME && (
                <Typography mt={2} fontSize={12}>
                  How do I get BAI? You can mint BAI on{' '}
                  <a
                    href="https://astar.astriddao.xyz/#/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#FFCDEC" }}
                  >
                    AstridDAO
                  </a>
                  .
                </Typography>
              )}
            </Box>
            <MyShareCard data={myShareData} />
            {(!isMobile || isShowMobileDetails) && (
              <PoolInfoCard data={poolData} />
            )}
          </Box>
        </Grid>
        <Grid
          xs={15}
          sm={9}
          item
          sx={{ display: { xs: isShowMobileDetails ? "none" : "" } }}
        >
          <Paper className="withdraw-main">
            <Box className={showReview ? "hide" : ""}>
              <Box
                display="flex"
                justifyContent="center"
                pt={3}
                position="relative"
              >
                <div className="deposit-withdraw">
                  <div>
                    {poolRoute && (
                      <Link to={`${poolRoute.poolRoute}/deposit`}>{t("deposit")}</Link>
                    )}
                  </div>
                  <div className="active">{t("withdraw")}</div>
                </div>
                {shouldDisplayWrappedOption && !isMobile && (
                  <FormGroup className="use-lp">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={onToggleWithdrawWrapped}
                          checked={shouldWithdrawWrapped}
                        />
                      }
                      label={t("Use LP") as string}
                    />
                  </FormGroup>
                )}
              </Box>
              {shouldDisplayWrappedOption && isMobile && (
                <Box height={10} my={2}>
                  <FormGroup className="use-lp">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={onToggleWithdrawWrapped}
                          checked={shouldWithdrawWrapped}
                        />
                      }
                      label={t("Use LP") as string}
                    />
                  </FormGroup>
                </Box>
              )}
              <Box>
                {/* <Box px={3}>
                  <Slider
                    sx={{
                      height: "10px",
                    }}
                    value={
                      formStateData.percentage ? +formStateData.percentage : 0
                    }
                    onChange={(e, value) => {
                      onFormChange({
                        fieldName: "percentage",
                        value: value.toString(),
                      })
                    }}
                  />
                </Box> */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  px={3}
                  mt={4}
                >
                  <Box>
                    <Typography
                      variant="body1"
                      fontSize={14}
                      fontWeight={700}
                      noWrap
                      lineHeight={"32px"}
                    >{`${t("withdrawPercentage")} (%):`}</Typography>
                  </Box>
                  <TextField
                    className="withdraw-percentage-input"
                    placeholder="0"
                    size="small"
                    data-testid="withdrawPercentageInput"
                    onChange={(e): void =>
                      onFormChange({
                        fieldName: "percentage",
                        value: e.currentTarget.value,
                        shouldWithdrawWrapped,
                      })
                    }
                    value={
                      formStateData.percentage ? formStateData.percentage : ""
                    }
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  mt={2}
                  pb={4}
                  px={3}
                  className="btns"
                >
                  {[25, 50, 75, 100].map((item) => (
                    <Button
                      kind="outline"
                      size="small"
                      key={item}
                      className={
                        formStateData.percentage &&
                        +formStateData.percentage === item
                          ? "active percentage-btn"
                          : "percentage-btn"
                      }
                      onClick={() => {
                        onFormChange({
                          fieldName: "percentage",
                          value: item.toString(),
                          shouldWithdrawWrapped,
                        })
                      }}
                    >
                      {item === 100 ? t("max") : `${item}%`}
                    </Button>
                  ))}
                </Box>
                {/* <Box textAlign="end" width="100%" minHeight="24px">
                  <Typography color="error">
                    {formStateData.error?.message || ""}
                  </Typography>
                </Box> */}
                <Box
                  px={3}
                  display="flex"
                  justifyContent="space-around"
                  mb={4}
                  mt={2}
                >
                  <Button
                    kind="outline"
                    size="small"
                    className={
                      formStateData.withdrawType &&
                      formStateData.withdrawType === "ALL"
                        ? "active token-btn"
                        : "token-btn"
                    }
                    onClick={() => {
                      onFormChange({
                        fieldName: "withdrawType",
                        value: "ALL",
                        shouldWithdrawWrapped,
                      })
                    }}
                  >
                    {t("Combo")}
                  </Button>
                  {!isCryptoPool(poolData?.name) && tokensData.map((token) => (
                    <Button
                      kind="outline"
                      size="small"
                      key={token.symbol}
                      className={
                        formStateData.withdrawType &&
                        formStateData.withdrawType === token.symbol
                          ? "active token-btn"
                          : "token-btn"
                      }
                      onClick={() => {
                        onFormChange({
                          fieldName: "withdrawType",
                          value: token.symbol,
                          shouldWithdrawWrapped,
                        })
                      }}
                    >
                      <img
                        src={token.icon}
                        width={14}
                        height={14}
                        style={{ marginRight: "2px" }}
                      />
                      {`${token.symbol}`}
                    </Button>
                  ))}
                  {/* <RadioGroup
                    row
                    value={formStateData.withdrawType}
                    onChange={handleWithdrawChange}
                    sx={{ mb: 2 }}
                    className="radio-group"
                  >
                    <FormControlLabel
                      value="ALL"
                      control={<Radio />}
                      label="Combo"
                      data-testid="withdrawPercentageCombo"
                    />
                    {tokensData.map((t) => {
                      return (
                        <FormControlLabel
                          key={t.symbol}
                          control={<Radio />}
                          value={t.symbol}
                          disabled={poolData?.isPaused}
                          label={t.symbol}
                          data-testid="withdrawTokenRadio"
                        />
                      )
                    })}
                  </RadioGroup> */}
                </Box>
                {isCryptoPool(poolData?.name) &&
                formStateData.withdrawType === "ALL" ? (
                  <Box mb={2} mx={3} className="alert" display="flex" justifyContent="center">
                    <img src={bellIcon} />
                    <Typography fontSize={12} ml={1}>
                      {t("unableEstimateReceiveTooltip")}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box width="100%" minHeight="24px" mb={2} px={3}>
                      <Typography
                        variant="body1"
                        fontSize={16}
                        fontWeight={500}
                        noWrap
                      >
                        {t("You will receive")}
                      </Typography>
                    </Box>
                    <Stack spacing={1}>
                      {tokensData.map((token, index) => (
                        <TokenInput
                          showMax={false}
                          key={`tokenInput-${index}`}
                          {...token}
                          // inputValue={parseFloat(token.inputValue).toFixed(5)}
                          onChange={(value): void =>
                            onFormChange({
                              fieldName: "tokenInputs",
                              value: value,
                              tokenSymbol: token.symbol,
                              shouldWithdrawWrapped,
                            })
                          }
                          disabled={
                            isCryptoPool(poolData?.name) || poolData?.isPaused
                          }
                        />
                      ))}
                    </Stack>
                  </>
                )}
              </Box>
              <Box pt={0}>
                {/* <AdvancedOptions /> */}
                <Box mt={4}>
                  <Button
                    size="large"
                    fullWidth
                    data-testid="withdrawBtn"
                    className="deposit-btn"
                    disabled={
                      noShare ||
                      !!formStateData.error ||
                      formStateData.lpTokenAmountToSpend.isZero()
                    }
                    onClick={onSubmit}
                  >
                    {t("withdraw")}
                  </Button>
                </Box>
              </Box>
            </Box>
            {showReview && poolData && (
              <Box>
                <ReviewWithdraw
                  withdrawType={formStateData.withdrawType}
                  poolName={poolData.name}
                  data={reviewData}
                  gas={gasPriceSelected}
                  lpToken={poolData.lpToken}
                  onConfirm={() => {
                    setCurrentModal("confirm")
                    setShowReview(false)
                    logEvent(
                      "withdraw",
                      (poolData && { pool: poolData?.name }) || {},
                    )
                  }}
                  lpTokenAmount={formStateData.lpTokenAmountToSpend}
                  // onClose={(): void => setCurrentModal(null)}
                  onClose={(): void => setShowReview(false)}
                />
              </Box>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={!!currentModal} onClose={(): void => setCurrentModal(null)}>
        {/* {currentModal === "review" ? (
          <ReviewWithdraw
            data={reviewData}
            gas={gasPriceSelected}
            onConfirm={async (): Promise<void> => {

              setCurrentModal("confirm")
              logEvent("withdraw", (poolData && { pool: poolData?.name }) || {})
              await onConfirmTransaction?.()
              setCurrentModal(null)
            }}
            lpTokenAmount={formStateData.lpTokenAmountToSpend}
            onClose={(): void => setCurrentModal(null)}
          />
        ) : null} */}
        {currentModal === "confirm" && pool ? (
          <ConfirmTransaction
            tokens={[pool.lpToken]}
            targetContractAddress={targetContractAddress}
            handler={onConfirmTransaction}
            onClose={(): void => setCurrentModal(null)}
            afterCompleted={afterCompleted}
          />
        ) : null}
      </Dialog>
    </Container>
  )
}

export default WithdrawPage
