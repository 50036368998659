/* eslint-disable */
import { useState, useEffect, useCallback, useMemo } from "react"
import { utils } from "ethers"
import {
  useFarmsControllerContract,
  useArthRouterContract,
} from "./useContract"
import { formatBNToString, getExtraTokenByAddress, formatBNToShortString, } from "../utils"
import { BigNumber } from "@ethersproject/bignumber"
import { Zero } from "@ethersproject/constants"
import moment from "moment"
import { getLastThursday } from "../utils/dateTime"
import {
  PoolName,
  POOLS_MAP,
  Pool,
  ORU_TOKEN,
  SRS_TOKEN,
  USDC,
  ChainId,
  isCryptoPool,
  isNativePool,
} from "../constants"
import {
  useFarmsContract,
  useTokenContract,
  useSRSContract,
} from "./useContract"
import { useActiveWeb3React } from "."
import { Contract } from "ethers"
import { getContract, bigNumberMulNum } from "../utils"
import CryptometapoolABI from "../constants/abis/Cryptometapool.json"
import { useSelector } from "react-redux"
import { AppState } from "../state/index"
import usePoolData from "./usePoolData"

const ONE_DAY = 60 * 60 * 24
const ONE_YEAR = ONE_DAY * 365

export function useRewardRate(FarmAddress: string) {
  const FarmControllerContract = useFarmsControllerContract()
  const [RewardRate, setRewardRate] = useState(0)
  useEffect(() => {
    FarmControllerContract?.gaugeRelativeWeight(
      FarmAddress,
      getNearThursday(),
    ).then((res: BigNumber) => {
      // console.log(formatBNToString(res, 18));
      // console.log(res);console.log(111111);
      setRewardRate(+formatBNToString(res, 18) ? +formatBNToString(res, 18) : 0)
    })
  }, [FarmControllerContract, FarmAddress])
  return RewardRate
}
type TokenPrices = {
  [key: string]: number
}

//新加EXTRA TOEKN 流程 （extra rewards）
//1.配置constants 里的 EXTRA_TOKENS_MAP
//2.在下方函数里，配置价格获取的方法
export function useExtraTokenPrice() {
  const { account, chainId } = useActiveWeb3React()
  const { srsPrice } = useSelector((state: AppState) => state.application)
  const [Prices, setPrices] = useState<TokenPrices>({ SRS: srsPrice })
  const ArthRouterContract = useArthRouterContract()

  useEffect(() => {
    // ORU
    if (chainId !== ChainId.ASTAR) return
    ArthRouterContract?.getAmountsOut(BigNumber.from(10).pow(6), [
      USDC.addresses[chainId],
      ORU_TOKEN.addresses[chainId],
    ]).then((res: any) => {
      const p = 1/+formatBNToString(res[1], 18);
      setPrices((prev) => ({
        ...prev,
        [ORU_TOKEN.symbol]: p,
      }))
    })
  }, [chainId, ArthRouterContract])
  useEffect(() => {
    // SRS
    setPrices((prev) => ({ ...prev, [SRS_TOKEN.symbol]: srsPrice }))
  }, [srsPrice])

  return Prices
}

export type IExtraRewards = {
  address: string
  rewardPerYearValue: any
  symbol: any
  rate: any
  tokenPrice: number
  rewardPerDayNum: number
}
type IMyExtraRewards = {
  address: string
  vol: any
  symbol: any
}
export function useExtraRewards(FarmContract: Contract | null): any[] {
 
  const TOKENS_PRICE = useExtraTokenPrice()
  const [ExtraRewards, setExtraRewards] = useState<IExtraRewards[] | undefined>(undefined);
  const [MyExtraRewards, setMyExtraRewards] = useState<IMyExtraRewards[]>([])
  const { account, chainId } = useActiveWeb3React()

  const getExtraRewards = useCallback(async () => {
    const rewardCount = await FarmContract?.rewardCount()
    const ExtraRewardSize = +formatBNToString(rewardCount, 0)
    const ExtraRewardsArr:IExtraRewards[] = []
    const MyExtraRewardsArr = []
    if (ExtraRewardSize <= 0) return setExtraRewards(ExtraRewardsArr); 
    const RewardTokensList = await FarmContract?.getRewardTokensList(
      0,
      ExtraRewardSize,
    )
    for (const i in RewardTokensList) {
      const address = RewardTokensList[i]
      const symbol = getExtraTokenByAddress(address, chainId || 592)?.symbol
      if (!symbol)
        return console.error(
          `error: this Extra Reward Token not defined:${address}`,
        )
      const tokenPrice = TOKENS_PRICE[symbol] || 0

      const secondRate = await FarmContract?.rewardData(address)
      const isZero = moment.unix(secondRate.periodFinish.toNumber()).isBefore()
      const rate = formatBNToString(secondRate.rate, 18)
      const rewardPerSecondNum = isZero ? 0 : +rate
      const rewardPerDayNum = rewardPerSecondNum * ONE_DAY
      const rewardPerYearValue = rewardPerSecondNum * ONE_YEAR * tokenPrice

      ExtraRewardsArr.push({
        address: address,
        symbol: symbol,
        rewardPerYearValue,
        rate,
        tokenPrice,
        rewardPerDayNum,
      })
      if (account) {
        const vol = await FarmContract?.claimableReward(account, address)
        //获取当前token是否有锁仓时间
        let my_unlock_time = 0
        try {
          const token_locktime = (
            await FarmContract?.rewardDataLockTime(address)
          ).toNumber() //如果为0，代表为非锁仓token
          if (token_locktime !== 0) {
            //说明这个token是有锁仓期的
            const my_unlock_time_temp = (
              await FarmContract?.userUnlockTime(address, account)
            ).toNumber() //获取当前用户该token，剩余的锁仓时间
            if (my_unlock_time_temp !== 0) {
              my_unlock_time = my_unlock_time_temp
            } else {
              //如果是0，说明是老用户。
              my_unlock_time = (
                await FarmContract?.FIRST_EPOCH_UNLOCKTIME()
              ).toNumber()
            }
          }
        } catch (e) {
          console.log(e)
        }
        MyExtraRewardsArr.push({
          address: address,
          vol: formatBNToString(vol, 18),
          symbol: symbol,
          unlock_time: my_unlock_time,
        })
      }
    }
    setExtraRewards(ExtraRewardsArr)
    setMyExtraRewards(MyExtraRewardsArr)
  }, [chainId, FarmContract, account, TOKENS_PRICE])
  useEffect(() => {
    void getExtraRewards()
  }, [getExtraRewards])

  return [ExtraRewards, MyExtraRewards, getExtraRewards]
}


export function useLPTokenPrice(PoolName: string):number {
  
  const xswapAddresses = POOLS_MAP[PoolName]?.metaSwapAddresses;//crypto
  const AllTokens = POOLS_MAP[PoolName]?.underlyingPoolTokens;
  // console.log(AllTokens)
  
  const { account, chainId, library } = useActiveWeb3React()
  const [LPTokenPrice, setLPTokenPrice] = useState(0);
  const [usdPoolData] = usePoolData(PoolName as PoolName)
  const { tokenPricesUSD } = useSelector(
    (state: AppState) => state.application,
  )
  const symbol = POOLS_MAP[PoolName]?.poolTokens[0]['symbol']
  const LPTokenContract = useTokenContract(POOLS_MAP[PoolName]?.lpToken);
    // console.log(symbol);

  const cryptoSwapContract = useMemo(() => {
    // console.log(xswapAddresses);
    // console.log(chainId);
    if (xswapAddresses && chainId && library) {
      if(!xswapAddresses[chainId]) return null;
      return getContract(
        xswapAddresses[chainId],
        CryptometapoolABI,
        library,
        account ?? undefined,
      )
    }
    return null
  }, [chainId, library, xswapAddresses, account])

  const getPoolTotalValue = useCallback(async () => {
    if(tokenPricesUSD?.[symbol] === undefined) return ;
    if(AllTokens === undefined) return ;
    let PoolTotalValue = 0;
    for(const i in AllTokens){
      const item = AllTokens[i];
      const balance = await cryptoSwapContract?.balances(i);
      const symbol = item['symbol'];
      PoolTotalValue += +formatBNToString(balance, 18) * tokenPricesUSD?.[symbol]
    }
    
    LPTokenContract?.totalSupply().then((res2:any) => {
      const TotalLPNum = +formatBNToString(res2,18);
      if(!TotalLPNum) return ;
      setLPTokenPrice(PoolTotalValue/TotalLPNum);
    });

  }, [cryptoSwapContract, tokenPricesUSD, symbol])

  const getNativePoolPrice = useCallback(async () => {
    const reserve = usdPoolData.reserve
    if (!reserve) return
    LPTokenContract?.totalSupply().then((res: BigNumber) => {
      const price = +utils.formatUnits(
        reserve.mul(BigNumber.from(10).pow(18)).div(res),
      )
      setLPTokenPrice(price)
    })
  }, [usdPoolData, LPTokenContract])

  useEffect(() => {
    if (isCryptoPool(PoolName)) return void getPoolTotalValue()
    if (isNativePool(PoolName)) return void getNativePoolPrice()
    setLPTokenPrice(1)
    // Applicable to all pools
    // getNativePoolPrice()
  }, [PoolName, getPoolTotalValue, getNativePoolPrice])

  return LPTokenPrice;
}

export function useFarmRowData(farmData:any, TotalRewardSRSPerSecond:any) {//基本数据
  const { srsPrice } = useSelector((state: AppState) => state.application)
  const LPTokenPrice = useLPTokenPrice(farmData.poolName);
  const FarmContract = useFarmsContract(farmData.farmAddresses)
  const LpTokenContract = useTokenContract(farmData.lpToken)
  const { account, chainId, library } = useActiveWeb3React()
  const RewardRate = useRewardRate(farmData.farmAddresses[chainId || 592])
  

  const [FarmRowData, setFarmRowData] = useState<any>({
    TotalValue: "",
    TotalValueRaw: BigNumber.from(0),
    MyStakeValue: "",
    MyStakeLPNum: "",
    unClaimSRS: "",
    apy: "",
  })
  const getFarmContractData = useCallback(() => {
    if(!LPTokenPrice) return ;
    if (account) {
      FarmContract?.balanceOf(account).then((res: BigNumber) => {
        // console.log(formatBNToString(bigNumberMulNum(res, LPTokenPrice), 18));
        setFarmRowData((_state:any) => ({
          ..._state,
          MyStakeValue: formatBNToString(bigNumberMulNum(res, LPTokenPrice), 18),
          MyStakeLPNum: formatBNToString(res, 18),
        }))
        // console.log(formatBNToString(res, 18));
      })
      FarmContract?.claimableTokens(account).then((res: BigNumber) => {
        setFarmRowData((_state:any) => ({
          ..._state,
          unClaimSRS: formatBNToString(res, 18),
        }))
      })
    } else {
      setFarmRowData((_state:any) => ({ ..._state, MyStakeValue: "", MyStakeLPNum: "" }))
      setFarmRowData((_state:any) => ({ ..._state, unClaimSRS: "", MyStakeLPNum: "" }))
    }
  }, [FarmContract, account, LPTokenPrice])

  const getLpTokenContractData = useCallback(() => {
    if(!LPTokenPrice) return ;
    
    LpTokenContract?.balanceOf(FarmContract?.address).then((res: BigNumber) => {
      // console.log(1);
      // console.log(LPTokenPrice);
      const tvl_bn = bigNumberMulNum(res, LPTokenPrice);//tvl value
      const tvl_num = +formatBNToString(tvl_bn, 18);//tvl value：十进制数
      // console.log(tvl_num);

      const oneYearRewardValue =
        TotalRewardSRSPerSecond * 60 * 60 * 24 * 365 * srsPrice * RewardRate

        let apy = 0
      if (tvl_num !== 0) {
        apy = (100 * oneYearRewardValue) / tvl_num
      }
      
      setFarmRowData((_state:any) => ({
        ..._state,
        TotalValueRaw: tvl_bn,
        TotalValue: formatBNToShortString(tvl_bn, 18),
        apy: apy.toString(),
      }))
    })
  }, [
    LpTokenContract,
    FarmContract,
    TotalRewardSRSPerSecond,
    RewardRate,
    srsPrice,
    LPTokenPrice,
  ])

  useEffect(() => {
    getFarmContractData();
  }, [getFarmContractData])
  useEffect(() => {
    getLpTokenContractData();
  }, [getLpTokenContractData])

  return [FarmRowData, getFarmContractData, getLpTokenContractData];
}

function getNearThursday(_date?: any) {
  const str = _date || new Date()
  const Today0 = moment(str).utcOffset(0)
  let ago = 0
  Today0.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

  const day = new Date(str).getUTCDay()
  const diff = 0
  if (day < 4) {
    ago = 7 - (4 - day)
  } else {
    ago = day - 4
  }
  const final_time = Today0.subtract(ago, "days").unix()
  // console.log(ago)
  // console.log(new Date(final_time*1000));
  return final_time
}

export function useRewardSRSPerSecond() {
  const [rewardSRSPerSecond, setRewardSRSPerSecond] = useState(0)
  const srsContract = useSRSContract()
  useEffect(() => {
    srsContract?.rate().then((res: BigNumber) => {
      setRewardSRSPerSecond(+formatBNToString(res, 18))
    })
  }, [srsContract])

  return rewardSRSPerSecond
}

export type FarmExtraReward = {
  address: string
  rewardPerYear: any
  symbol: any
  rate: any
  tokenPrice: number
  rewardPerDayNum: number
}
export function useFarmExtraRewards(poolName: PoolName) {
  const [farmExtraRewards, setFarmExtraRewards] = useState<FarmExtraReward[]>(
    [],
  )
  const TOKENS_PRICE = useExtraTokenPrice()
  const { chainId } = useActiveWeb3React()
  const farmContract = useFarmsContract(POOLS_MAP[poolName]?.farmAddresses)

  const getRewards = useCallback(async () => {
    try {
      if (!farmContract) return

      const res1 = await farmContract?.rewardCount()
      const rewardSize = +formatBNToString(res1, 0)
      if (rewardSize <= 0) return

      const res2 = await farmContract?.getRewardTokensList(0, rewardSize)
      if (!res2?.length) return

      const rewards: FarmExtraReward[] = []
      res2.map((address: string) => {
        const symbol = getExtraTokenByAddress(address, chainId || 592)?.symbol
        if (!symbol) return
        const tokenPrice = TOKENS_PRICE[symbol] || 0
        farmContract?.rewardData(address).then((res: any) => {
          const isZero = moment.unix(res.periodFinish.toNumber()).isBefore()
          const rate = formatBNToString(res.rate, 18)
          const rewardPerSecondNum = isZero ? 0 : +rate
          const rewardPerDayNum = rewardPerSecondNum * ONE_DAY
          const rewardPerYear = rewardPerSecondNum * ONE_YEAR * tokenPrice

          rewards.push({
            address,
            symbol,
            rewardPerYear,
            rate,
            tokenPrice,
            rewardPerDayNum,
          })
          setFarmExtraRewards(rewards)
        })
      })
    } catch (error) {
      setFarmExtraRewards([])
    }
  }, [chainId, farmContract, TOKENS_PRICE])

  useEffect(() => {
    void getRewards()
  }, [getRewards])

  return farmExtraRewards
}

// useGaugeRelativeWeight
export function useGRW({
  poolName,
  timeStamp,
  tempPools,
}: {
  poolName: PoolName
  timeStamp?: number
  tempPools?: boolean
}): [number, () => void] {
  const [rewardRate, setRewardRate] = useState(0)
  const { chainId } = useActiveWeb3React()
  const _contract = useFarmsControllerContract()
  const availablePools = tempPools ? { ...POOLS_MAP } : POOLS_MAP

  const getRewardRate = useCallback(() => {
    if (!poolName || !chainId) return
    const address = availablePools[poolName]?.farmAddresses?.[chainId]
    if (!address) return void setRewardRate(0)

    _contract
      ?.gaugeRelativeWeight(address, timeStamp || getLastThursday().unix())
      .then((res: BigNumber) => setRewardRate(+formatBNToString(res, 18) || 0))
  }, [chainId, _contract, poolName, timeStamp, tempPools])

  useEffect(() => {
    getRewardRate()
  }, [getRewardRate])

  return [rewardRate, getRewardRate]
}

export const YearValueToAPY = (_YearValue: any, _TotalValueRaw: any) => {
  return (
    (100 * (+_YearValue || 0)) /
    +formatBNToString(_TotalValueRaw, 18)
  ).toFixed(2)
}
export type FarmStatData = {
  tvl: BigNumber
  apy: string
  contract?: Contract
  totalApy?: number
  rewardRate?: number
  rewardPerSecond?: number
  rewardPerDay?: number
}
export function useFarmStatData(poolName: PoolName) {
  const [farmStatData, setFarmStatData] = useState<FarmStatData>({
    tvl: Zero,
    apy: "",
  })
  const { chainId } = useActiveWeb3React()
  const { srsPrice } = useSelector((state: AppState) => state.application)
  const farmExtraRewards = useFarmExtraRewards(poolName)
  const tokenContract = useTokenContract(POOLS_MAP[poolName]?.lpToken)
  const rewardSRSPerSecond = useRewardSRSPerSecond()
  const [rewardRate] = useGRW({ poolName })

  const getLpToken = useCallback(async () => {
    try {
      const poolInfo = POOLS_MAP[poolName]
      if (!chainId || !tokenContract || !poolInfo) return
      const farmAddress = poolInfo.farmAddresses?.[chainId]
      if (!farmAddress) return

      const res = await tokenContract.balanceOf(farmAddress)
      const tvl = +formatBNToString(res, 18)
      const rewardPerSecond = rewardSRSPerSecond * srsPrice * rewardRate
      const rewardPerDay = rewardPerSecond * ONE_DAY
      const rewardPerYear = rewardPerSecond * ONE_YEAR
      const apy = tvl === 0 ? 0 : (100 * rewardPerYear) / tvl

      let totalAPY = 0
      farmExtraRewards?.map((item) => {
        totalAPY += +YearValueToAPY(item.rewardPerYear || 0, res) || 0
      })
      totalAPY += +apy

      setFarmStatData({
        tvl: res,
        apy: apy.toString(),
        totalApy: totalAPY,
        rewardRate,
        rewardPerSecond,
        rewardPerDay,
      })
    } catch (error) {
      setFarmStatData({ tvl: Zero, apy: "" })
    }
  }, [
    chainId,
    srsPrice,
    poolName,
    tokenContract,
    rewardSRSPerSecond,
    farmExtraRewards,
    rewardRate,
  ])
  useEffect(() => {
    void getLpToken()
  }, [getLpToken])

  return farmStatData
}

// The percentage of my used voting power in all pools
export function useVoteUserPower() {
  const [voteUserPower, setVoteUserPower] = useState(0)
  const { account } = useActiveWeb3React()
  const _contract = useFarmsControllerContract()

  const getVoteUserPower = useCallback(() => {
    if (!account) return void setVoteUserPower(0)

    _contract?.voteUserPower(account).then((res: BigNumber) => {
      setVoteUserPower(BigNumber.from(res).div(100).toNumber())
    })
  }, [account, _contract])

  useEffect(() => {
    getVoteUserPower()
  }, [getVoteUserPower])

  return { voteUserPower, getVoteUserPower }
}

export type BaseAprDataType = Pool & {
  tvl?: number
  rewardRate?: number
  rewardPerSec?: number
  rewardPerDay?: number
  rewardPerYear?: number
  baseApr?: number
  rewardSrsPerDay?: number
}
export function useBaseAprData({
  poolName,
  timeStamp,
  tempPools,
}: {
  poolName: PoolName
  timeStamp?: number
  tempPools?: boolean
}): [BaseAprDataType, () => void] {
  const [baseAprData, setBaseAprData] = useState<BaseAprDataType>(
    {} as BaseAprDataType,
  )
  const availablePools = tempPools ? { ...POOLS_MAP } : POOLS_MAP
  const { chainId } = useActiveWeb3React()
  const { srsPrice } = useSelector((state: AppState) => state.application)
  const tokenContract = useTokenContract(availablePools[poolName]?.lpToken)
  const lpTokenPrice = useLPTokenPrice(poolName)
  const rewardSrsPS = useRewardSRSPerSecond()
  const [rewardRate, getRewardRate] = useGRW({ poolName, timeStamp, tempPools })

  const getBaseAprData = useCallback(async () => {
    try {
      setBaseAprData({} as BaseAprDataType)

      const poolInfo = availablePools[poolName]
      if (!chainId || !tokenContract || !poolInfo) return
      const farmAddress = poolInfo.farmAddresses?.[chainId]
      if (!farmAddress) return

      const res = await tokenContract.balanceOf(farmAddress)
      const bal = res
        .mul(utils.parseUnits((lpTokenPrice || 1).toString()))
        .div(BigNumber.from(10).pow(18))

      const tvl = +formatBNToString(bal, 18) || 0

      const rewardPerSec = rewardSrsPS * srsPrice * rewardRate
      const rewardPerDay = rewardPerSec * ONE_DAY
      const rewardPerYear = rewardPerSec * ONE_YEAR
      const baseApr = tvl === 0 ? 0 : (100 * rewardPerYear) / tvl

      const rewardSrsPerDay = rewardSrsPS * rewardRate * ONE_DAY

      const data = {
        ...poolInfo,
        tvl,
        rewardRate,
        rewardPerSec,
        rewardPerDay,
        rewardPerYear,
        baseApr,
        rewardSrsPerDay,
      }
      setBaseAprData(data)
    } catch (error) {
      setBaseAprData({} as BaseAprDataType)
    }
  }, [
    poolName,
    chainId,
    srsPrice,
    tokenContract,
    lpTokenPrice,
    rewardSrsPS,
    rewardRate,
  ])

  const updateData = useCallback(() => {
    getRewardRate()
    void getBaseAprData()
  }, [getRewardRate])

  useEffect(() => {
    void getBaseAprData()
  }, [getBaseAprData])

  return [baseAprData, updateData]
}
