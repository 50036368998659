import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import dotLogo from "../../assets/icons/dot.svg"

export const DOT_METAPOOL_NAME = "DOT Metapool"

const DOT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xA7d9F56251456531Df57E595D13CAa7F40a13423",
  [ChainId.ASTAR]: "0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF",
})
export const DOT_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x0002956d80C135CA95CBbb856A14647d1d41FCE3",
  [ChainId.ASTAR]: "0x458dbcFBD75D9e0D7DC9cc77404F169910Def21A",
})
export const DOT_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x3FD00cC5f7Ac6818989E8ACBDB05C81fCd5E6f7b",
  [ChainId.ASTAR]: "0x92ac9e0A41707b6e05277c159ab31dd8724f9b97",
})
export const DOT_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xaAFc4fAFB644B574bdb981A01DCF613cb81A5654",
  [ChainId.ASTAR]: "0x75d0043F4312e8CC142e8470C11D1951f877F081",
})
export const DOT_FARM_ADDRESSES = buildAddresses({
  // [ChainId.SHIBUYA]: "0x50A0a95246a417aCd2Ae7648bE91D2c4a1bBD678",
  [ChainId.ASTAR]: "0xf382B015a4715233534c210Ce4850A27f95699EC",
})

export const DOT = new Token(
  DOT_CONTRACT_ADDRESSES,
  10,
  "DOT",
  "polkadot",
  "DOT Metapool",
  dotLogo,
  true,
)
export const DOT_SWAP_TOKEN = new Token(
  DOT_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4DOT",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  dotLogo,
  false,
  true,
)

export const DOT_POOL_TOKENS = [DOT, ...STABLECOIN_POOL_TOKENS]
export const DOT_UNDERLYING_POOL_TOKENS = [DOT, STABLECOIN_SWAP_TOKEN]

export const DOT_POOL_INFO: Pool = {
  name: DOT_METAPOOL_NAME,
  lpToken: DOT_SWAP_TOKEN,
  poolTokens: DOT_POOL_TOKENS,
  isSynthetic: true,
  addresses: DOT_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: DOT_FARM_ADDRESSES,
  type: PoolTypes.Crypto,
  route: "dot",
  metaSwapAddresses: DOT_META_SWAP_ADDRESSES,
  underlyingPoolTokens: DOT_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
  unbalancePercent: 50,
}
