import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import ousdLogo from "../../assets/icons/ousd.svg"
import ousd4Logo from "../../assets/icons/4ousd.svg"

export const OUSD_METAPOOL_NAME = "oUSD Metapool"

const OUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x29F6e49c6E3397C3A84F715885F9F233A441165C",
})
export const OUSD_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x2425947FD79cbf4C2C2deeD0246b0C9EdC65EB7D",
})
export const OUSD_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0xD18AbE9bcedeb5A9a65439e604b0BE8db0bdB176",
})
export const OUSD_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x535406b90E8Df2Cf2168E8579ea3d1D7A0e0AdDC",
})
export const OUSD_FARM_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x5FF4735274b0C7ADf7de52768645aA08AE4bcB20",
})

export const OUSD = new Token(
  OUSD_CONTRACT_ADDRESSES,
  18,
  "oUSD",
  "nusd",
  "Orcus Stablecoin",
  ousdLogo,
  true,
)
export const OUSD_SWAP_TOKEN = new Token(
  OUSD_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4oUSD",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  ousd4Logo,
  false,
  true,
)

export const OUSD_POOL_TOKENS = [OUSD, ...STABLECOIN_POOL_TOKENS]
export const OUSD_UNDERLYING_POOL_TOKENS = [OUSD, STABLECOIN_SWAP_TOKEN]

export const OUSD_POOL_INFO: Pool = {
  name: OUSD_METAPOOL_NAME,
  lpToken: OUSD_SWAP_TOKEN,
  poolTokens: OUSD_POOL_TOKENS,
  isSynthetic: true,
  addresses: OUSD_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: OUSD_FARM_ADDRESSES,
  type: PoolTypes.USD,
  route: "ousd",
  metaSwapAddresses: OUSD_META_SWAP_ADDRESSES,
  underlyingPoolTokens: OUSD_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
}
