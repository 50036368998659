import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import nastrLogo from "../../assets/icons/nastr.png"
import astrLogo from "../../assets/icons/astar.svg"

export const LASTR_METAPOOL_NAME = "nASTR/ASTR Pool"
export const ASTR_SYMBOL = "ASTR"

const NASTR_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xa51599eC60eA10F6f24b639daC42C25Fa02c9247",
  [ChainId.ASTAR]: "0xE511ED88575C57767BAfb72BfD10775413E3F2b0",
})
export const NASTR_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x5a9B56f64f0AA1282EEB299A09A9D284bE6dE26a",
  [ChainId.ASTAR]: "0xcB274236fBA7B873FC8F154bb0475a166C24B119",
})
export const NASTR_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x35F1Dd344978A57612d08e8E017B9c99AAE4cFd6",
  [ChainId.ASTAR]: "0xEEa640c27620D7C448AD655B6e3FB94853AC01e3",
})
export const NASTR_FARM_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x528cF43a18e088B93367c12Be0663D42f7a93A2F",
  [ChainId.ASTAR]: "0xdCfFa5a92ef31DCc8979Ab44A0406859d7763c45",
})

export const NASTR = new Token(
  NASTR_CONTRACT_ADDRESSES,
  18,
  "nASTR",
  "astar",
  "nASTR Token",
  nastrLogo,
  true,
)
export const NASTR_SWAP_TOKEN = new Token(
  NASTR_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "Liquid ASTR",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  nastrLogo,
  false,
  true,
)

const ASTR_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x04efa209F9e74E612a529c393Cf9F1141E696F06",
  [ChainId.ASTAR]: "0xaeaaf0e2c81af264101b9129c00f4440ccf0f720",
})
export const ASTR = new Token(
  ASTR_CONTRACT_ADDRESSES,
  18,
  ASTR_SYMBOL,
  "astar",
  "ASTR Token",
  astrLogo,
  true,
)

const NASTR_POOL_TOKENS = [ASTR, NASTR]

export const LASTR_POOL_INFO: Pool = {
  name: LASTR_METAPOOL_NAME,
  lpToken: NASTR_SWAP_TOKEN,
  poolTokens: NASTR_POOL_TOKENS,
  isSynthetic: false,
  addresses: NASTR_SWAP_ADDRESSES,
  farmAddresses: NASTR_FARM_ADDRESSES,
  type: PoolTypes.USD,
  route: "nastr",
  rewardPids: buildPids({}),
  show: true,
  unbalancePercent: 40,
  unsort: true,
}
