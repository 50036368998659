import React, { ReactElement, useState } from "react"
import {
  useTheme,
  useMediaQuery,
  Box,
  BoxProps,
  Drawer,
  DrawerProps,
  Tooltip,
  TooltipProps,
  IconButton,
} from "@mui/material"
import { Close } from "@mui/icons-material"

type Props = BoxProps & {
  children: React.ReactNode
  info: React.ReactNode
  TooltipProps?: TooltipProps
  DrawerProps?: DrawerProps
}

export default function CustomTooltip(props: Props): ReactElement {
  const { children, info, TooltipProps, DrawerProps, ...BoxProps } = props
  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up("sm"))
  const [open, setOpen] = useState(false)

  if (isPC)
    return (
      <Tooltip title={info || ""} placement="top" {...TooltipProps}>
        <Box display="inline-block" {...BoxProps}>
          {children}
        </Box>
      </Tooltip>
    )

  return (
    <>
      <Box
        display="inline-block"
        {...BoxProps}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setOpen(true)
        }}
      >
        {children}
      </Box>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: { borderWidth: "1px 0 0 0", borderRadius: "16px 16px 0 0", p: 4 },
        }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        {...DrawerProps}
      >
        <IconButton
          onClick={() => setOpen(false)}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <Close />
        </IconButton>
        {info}
      </Drawer>
    </>
  )
}
