import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import a4SRSLogo from "../../assets/icons/a4SRS.svg"
import aBaiLogo from "../../assets/icons/aBai.svg"
import aDaiLogo from "../../assets/icons/aDai.svg"
import aUsdtLogo from "../../assets/icons/aUsdt.svg"
import aBusdLogo from "../../assets/icons/aBusd.svg"

export const AVAULT_POOL_NAME = "Avault 4Pool"

export const AVAULT_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x170E2e0939317D60baCEC48d7116498c882430A8",
})
export const AVAULT_SWAP_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0xD8Bc543273B0E19eed34a295614963720c89f9e4",
})
export const AVAULT_FARM_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0xa6B91ddDca40137B9442b4aD7076bF319eacC59E",
})
export const AVAULT_SWAP_TOKEN = new Token(
  AVAULT_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4ALP",
  "binance-usd",
  "siriusLP",
  a4SRSLogo,
  false,
  true,
)

const ABAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0xDBd71969aC2583A9A20Af3FB81FE9C20547f30F3",
})
export const ABAI = new Token(
  ABAI_CONTRACT_ADDRESSES,
  18,
  "aBaiUsdc",
  "bai-stablecoin",
  "Avault BaiUsdc",
  aBaiLogo,
)

const ADAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x9914Bff0437f914549c673B34808aF6020e2B453",
})
export const ADAI = new Token(
  ADAI_CONTRACT_ADDRESSES,
  18,
  "aDaiUsdc",
  "dai",
  "Avault DaiUsdc",
  aDaiLogo,
)

const AUSDT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x02Dac4898B2c2cA9D50fF8D6a7726166CF7bCFD0",
})
export const AUSDT = new Token(
  AUSDT_CONTRACT_ADDRESSES,
  18,
  "aUsdtUsdc",
  "tether",
  "Avault UsdtUsdc",
  aUsdtLogo,
)

const ABUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.ASTAR]: "0x347e53263F8fb843EC605A1577eC7C8c0cAC7a58",
})
export const ABUSD = new Token(
  ABUSD_CONTRACT_ADDRESSES,
  18,
  "aBusdUsdc",
  "binance-usd",
  "Avault BusdUsdc",
  aBusdLogo,
)

export const AVAULT_POOL_TOKENS = [ABAI, ADAI, ABUSD, AUSDT]

export const AVAULT_POOL_INFO: Pool = {
  name: AVAULT_POOL_NAME,
  lpToken: AVAULT_SWAP_TOKEN,
  poolTokens: AVAULT_POOL_TOKENS,
  isSynthetic: false,
  addresses: AVAULT_SWAP_ADDRESSES,
  farmAddresses: AVAULT_FARM_ADDRESSES,
  type: PoolTypes.USD,
  route: "avault",
  rewardPids: buildPids({}),
  show: true,
}
