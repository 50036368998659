import React, { ReactElement, useCallback, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import ECharts from "echarts-for-react"
import moment from "moment"
import { useTheme, Paper, Typography } from "@mui/material"
import { useActiveWeb3React } from "../hooks"
import { POOLS_MAP, EXCH_URL } from "../constants"

type optionDataType = {
  poolNames: any[]
  rawData: any[]
  datasetWithFilters: any[]
  seriesList: any[]
}
type Response = {
  ok: boolean
  data: {
    list: [
      {
        address: string
        dailyTvls: { timestamp: string; tvl: string }[]
        dailyVolumes: { timestamp: string; volume: string }[]
      },
    ]
  }
}

type ConstructChartdataOpt = {
  poolsData: any
  swaps: any[]
}
function constructTvlData(opt: ConstructChartdataOpt) {
  const { poolsData, swaps } = opt

  const poolNames: string[] = []
  const rawData = [["day", "poolName", "tvl", "volume"]]
  swaps
    .filter((i) => i?.dailyTvls?.length)
    .sort((a, b) => +a.dailyTvls[0].timestamp - +b.dailyTvls[0].timestamp)
    .map((i) => {
      const poolData = poolsData[i.address]
      const poolName = poolData?.name
      if (!poolName) return
      if (!poolNames.includes(poolName)) poolNames.push(poolName)
      i.dailyTvls.map((j: any) => {
        rawData.push([
          moment.unix(j.timestamp).format("YYYY-MM-DD"),
          poolName,
          +(+j.tvl || 0).toFixed(2),
        ])
      })
    })
  if (!poolNames.length) return

  const datasetWithFilters = [] as any[]
  const seriesList = [] as any[]
  poolNames.map((poolName) => {
    const datasetId = "dataset_" + poolName
    datasetWithFilters.push({
      id: datasetId,
      fromDatasetId: "dataset_raw",
      transform: {
        type: "filter",
        config: {
          and: [
            { dimension: "poolName", "=": poolName },
            { dimension: "tvl", gte: 0 },
          ],
        },
      },
    })
    seriesList.push({
      type: "line",
      datasetId,
      showSymbol: false,
      smooth: true,
      name: poolName,
      labelLayout: { moveOverlap: "shiftY" },
      emphasis: { focus: "series" },
      encode: { x: "day", y: "tvl" },
    })
  })
  return {
    poolNames,
    rawData,
    datasetWithFilters,
    seriesList,
  }
}

function constructVolumeData(opt: ConstructChartdataOpt) {
  const { poolsData, swaps } = opt

  const poolNames: string[] = []
  const rawData = [["day", "poolName", "tvl", "volume"]]
  swaps
    .filter((i) => i?.dailyVolumes?.length)
    .sort((a, b) => +a.dailyVolumes[0].timestamp - +b.dailyVolumes[0].timestamp)
    .map((i) => {
      const poolData = poolsData[i.address]
      const poolName = poolData?.name
      if (!poolName) return
      if (!poolNames.includes(poolName)) poolNames.push(poolName)
      i.dailyVolumes.map((j: any) => {
        rawData.push([
          moment.unix(j.timestamp).format("YYYY-MM-DD"),
          poolName,
          null,
          +(+j.volume || 0).toFixed(2),
        ])
      })
    })
  if (!poolNames.length) return

  const datasetWithFilters = [] as any[]
  const seriesList = [] as any[]
  poolNames.map((poolName) => {
    const datasetId = "dataset_" + poolName + "_2"
    datasetWithFilters.push({
      id: datasetId,
      fromDatasetId: "dataset_raw",
      transform: {
        type: "filter",
        config: {
          and: [
            { dimension: "poolName", "=": poolName },
            { dimension: "volume", gte: 0 },
          ],
        },
      },
    })
    seriesList.push({
      type: "bar",
      datasetId,
      showSymbol: false,
      stack: "volume",
      name: poolName,
      labelLayout: { moveOverlap: "shiftY" },
      emphasis: { focus: "series" },
      xAxisIndex: 1,
      yAxisIndex: 1,
      encode: { x: "day", y: "volume" },
    })
  })
  return {
    poolNames,
    rawData,
    datasetWithFilters,
    seriesList,
  }
}

export default function LineRaceChart(): ReactElement {
  const { t } = useTranslation()
  const theme = useTheme()
  const { chainId } = useActiveWeb3React()
  const [optionData, setOptionData] = useState<optionDataType>({
    poolNames: [],
    rawData: [],
    datasetWithFilters: [],
    seriesList: [],
  })
  const [optionData2, setOptionData2] = useState<optionDataType>({
    poolNames: [],
    rawData: [],
    datasetWithFilters: [],
    seriesList: [],
  })

  const getData = useCallback(async () => {
    if (!chainId) return

    const poolsData = {} as any
    Object.values(POOLS_MAP).map((item) => {
      const address = item.addresses?.[chainId]
      const metaSwapAddress = item.metaSwapAddresses?.[chainId]
      if (address) poolsData[address.toLowerCase()] = item
      if (metaSwapAddress) poolsData[metaSwapAddress.toLowerCase()] = item
    })
    if (!Object.keys(poolsData).length) return

    try {
      const res = await fetch(`${EXCH_URL}/api/swap/daily_tvls_volumes`)
      const body: Response = await res.json()
      if (!body?.ok) return
      const list = body.data.list
      if (!list.length) return

      list.map((i) => {
        i.dailyTvls = i.dailyTvls.reverse()
        i.dailyVolumes = i.dailyVolumes.reverse()
      })

      const tvlData = constructTvlData({ poolsData, swaps: list })
      if (tvlData) setOptionData(tvlData)

      const volumeData = constructVolumeData({ poolsData, swaps: list })
      if (volumeData) setOptionData2(volumeData)
    } catch (err) {
      console.error("getData", err)
    }
  }, [chainId])

  useEffect(() => {
    void getData()
  }, [getData])

  const getOption = () => {
    return {
      darkMode: true,
      animationDuration: 5e3,
      textStyle: { color: theme.palette.mute.main },
      dataset: [
        {
          id: "dataset_raw",
          source: [...optionData.rawData, ...optionData2.rawData],
        },
        ...optionData.datasetWithFilters,
        ...optionData2.datasetWithFilters,
      ],
      tooltip: { trigger: "axis", order: "valueDesc" },
      legend: {
        type: "scroll",
        orient: "horizontal",
        pageIconColor: theme.palette.mute.main,
        pageTextStyle: { color: theme.palette.mute.main },
        data: [...optionData.poolNames, ...optionData2.poolNames],
        textStyle: { color: theme.palette.mute.main },
      },
      xAxis: [
        { type: "category", boundaryGap: false },
        { type: "category", boundaryGap: false, gridIndex: 1 },
      ],
      yAxis: [
        { name: "tvl" },
        {
          gridIndex: 1,
          axisLabel: { show: false },
          splitLine: { show: false },
        },
      ],
      grid: [
        { right: 15, bottom: 200 },
        { right: 15, bottom: 80, height: 80 },
      ],
      dataZoom: [
        { type: "inside", xAxisIndex: [0, 1] },
        { show: true, xAxisIndex: [0, 1], type: "slider", bottom: 10 },
      ],
      series: [...optionData.seriesList, ...optionData2.seriesList],
    }
  }

  return (
    <Paper variant="outlined" sx={{ p: 3 }}>
      <Typography variant="subtitle2">{t("Daily TVL/Volume")}</Typography>
      <ECharts style={{ height: "600px" }} option={getOption()} />
    </Paper>
  )
}
