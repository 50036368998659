/* eslint-disable */
import {
  POOLS_MAP,
  SWAP_TYPES,
  SYNTH_TRACKING_ID,
  TRANSACTION_TYPES,
  ASTR_SYMBOL,
  isNativePool,
} from "../constants"
import { notifyCustomError, notify } from "../utils/notifyHandler"
import { useAllContracts, useSynthetixContract } from "./useContract"

import { AppState } from "../state"
import { BigNumber } from "@ethersproject/bignumber"
import { Bridge } from "../../types/ethers-contracts/Bridge"
import { Erc20 } from "../../types/ethers-contracts/Erc20"
// import { GasPrices } from "../state/user"
import { MetaSwapDeposit } from "../../types/ethers-contracts/MetaSwapDeposit"
import { SwapFlashLoan } from "../../types/ethers-contracts/SwapFlashLoan"
import { SwapFlashLoanNoWithdrawFee } from "../../types/ethers-contracts/SwapFlashLoanNoWithdrawFee"
import { SwapGuarded } from "../../types/ethers-contracts/SwapGuarded"
import checkAndApproveTokenForTrade from "../utils/checkAndApproveTokenForTrade"
import { formatDeadlineToNumber, formatBNToString } from "../utils"
// import { parseUnits } from "@ethersproject/units"
import { subtractSlippage } from "../utils/slippage"
import { updateLastTransactionTimes } from "../state/application"
import { useActiveWeb3React } from "."
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { utils } from "ethers"
import { useGasPrice } from "./useGas"
import { SwapETH } from "../../types/ethers-contracts/SwapETH"
import { useTranslation } from "react-i18next"

type Contracts = {
  swapContract:
    | SwapFlashLoan
    | SwapFlashLoanNoWithdrawFee
    | SwapGuarded
    | MetaSwapDeposit
    | null
  bridgeContract: Bridge | null
}
type SwapSide = {
  amount: BigNumber
  symbol: string
  poolName: string
  tokenIndex: number
}
type FormState = {
  from: SwapSide
  to: SwapSide & { amountMediumSynth: BigNumber }
  swapType: Exclude<SWAP_TYPES, SWAP_TYPES.INVALID>
}
type ApproveAndSwapStateArgument = FormState & Contracts

export function useApproveAndSwap(
  setTransitionStatus: any,
): (state: ApproveAndSwapStateArgument) => Promise<void> {
  const dispatch = useDispatch()
  const tokenContracts = useAllContracts()
  const { account, chainId } = useActiveWeb3React()
  const baseSynthetixContract = useSynthetixContract()
  const { t } = useTranslation()
  // const { gasStandard, gasFast, gasInstant } = useSelector(
  //   (state: AppState) => state.application,
  // )
  const _gasPrice = useGasPrice()
  const {
    slippageCustom,
    slippageSelected,
    // gasPriceSelected,
    // gasCustom,
    transactionDeadlineCustom,
    transactionDeadlineSelected,
    infiniteApproval,
  } = useSelector((state: AppState) => state.user)
  return async function approveAndSwap(
    state: ApproveAndSwapStateArgument,
  ): Promise<void> {
    try {
      if (!account) throw new Error("Wallet must be connected")
      if (state.swapType === SWAP_TYPES.DIRECT && !state.swapContract)
        throw new Error("Swap contract is not loaded")
      if (state.swapType !== SWAP_TYPES.DIRECT && !state.bridgeContract)
        throw new Error("Bridge contract is not loaded")
      if (chainId === undefined) throw new Error("Unknown chain")
      // For each token being deposited, check the allowance and approve it if necessary
      const tokenContract = tokenContracts?.[state.from.symbol] as Erc20
      // let gasPrice
      // if (gasPriceSelected === GasPrices.Custom) {
      //   gasPrice = gasCustom?.valueSafe
      // } else if (gasPriceSelected === GasPrices.Fast) {
      //   gasPrice = gasFast
      // } else if (gasPriceSelected === GasPrices.Instant) {
      //   gasPrice = gasInstant
      // } else {
      //   gasPrice = gasStandard
      // }

      // gasPrice = parseUnits(gasPrice ? String(gasPrice) : "45", 9)

      if (tokenContract == null) return
      let addressToApprove = ""
      if (state.swapType === SWAP_TYPES.DIRECT) {
        addressToApprove = state.swapContract?.address as string
      } else if (state.swapType === SWAP_TYPES.SYNTH_TO_SYNTH) {
        addressToApprove = baseSynthetixContract?.address as string
      } else {
        addressToApprove = state.bridgeContract?.address as string
      }

      setTransitionStatus({ code: -1, desc: "just open window" })
      if (state.from.symbol !== ASTR_SYMBOL) {
        await checkAndApproveTokenForTrade(
          //appove的操作
          tokenContract,
          addressToApprove,
          account,
          state.from.amount,
          infiniteApproval,
          _gasPrice,
          {
            onTransactionError: () => {
              setTransitionStatus({
                inApprove: true,
                code: 2,
                desc: "no Approved. start Approve. user reject",
              })
              throw new Error("Your transaction could not be completed")
            },
            onTransactionStart: (transaction) => {
              // console.log(transaction);
              setTransitionStatus({
                inApprove: true,
                code: 0,
                desc: "no Approved. start Approve",
              })
            },
            // onTransactionGetHash: (transaction) => {
            //   console.log(transaction);
            //   console.log('gethash');
            //   setTransitionStatus({inApprove: true, step: 1, desc: "no Approved. start Approve"});
            // },
            // onTransactionSuccess: (transaction) => {
            //   console.log(transaction);
            //   setTransitionStatus({inApprove: true, code: 1, desc: "Approved finish"});
            // }
          },
          () => {
            //已经approve
            setTransitionStatus({
              inApprove: false,
              code: -1,
              desc: "already Approved",
              noStep: true,
            })
          },
        )
      } else {
        // astar 已经无需 approve
        setTransitionStatus({
          inApprove: false,
          code: -1,
          desc: "already Approved",
          noStep: true,
        })
      }
      // setTransitionStatus({inApprove: true, step: 2, desc: "Approved finish"});
      setTransitionStatus((s: any) => ({
        ...s,
        inApprove: false,
        code: 0,
        desc: "begin swap",
      }))

      let toNum = BigNumber.from(0)
      let swapTransaction: any
      if (state.swapType === SWAP_TYPES.TOKEN_TO_TOKEN) {
        const originPool = POOLS_MAP[state.from.poolName]
        const destinationPool = POOLS_MAP[state.to.poolName]
        const args = [
          [
            originPool.addresses[chainId],
            destinationPool.addresses[chainId],
          ] as [string, string],
          state.from.tokenIndex,
          state.to.tokenIndex,
          state.from.amount,
          subtractSlippage(
            state.to.amountMediumSynth,
            slippageSelected,
            slippageCustom,
          ), // subtract slippage from minSynth
        ] as const
        console.debug("swap - tokenToToken", args)

        swapTransaction = await (state.bridgeContract as Bridge).tokenToToken(
          ...args,
          { gasPrice: _gasPrice },
        )
      } else if (state.swapType === SWAP_TYPES.SYNTH_TO_TOKEN) {
        const destinationPool = POOLS_MAP[state.to.poolName]
        const args = [
          destinationPool.addresses[chainId],
          utils.formatBytes32String(state.from.symbol),
          state.to.tokenIndex,
          state.from.amount,
          subtractSlippage(
            state.to.amountMediumSynth,
            slippageSelected,
            slippageCustom,
          ), // subtract slippage from minSynth
        ] as const
        console.debug("swap - synthToToken", args)
        swapTransaction = await (state.bridgeContract as Bridge).synthToToken(
          ...args,
          { gasPrice: _gasPrice },
        )
      } else if (state.swapType === SWAP_TYPES.TOKEN_TO_SYNTH) {
        const originPool = POOLS_MAP[state.from.poolName]
        const args = [
          originPool.addresses[chainId],
          state.from.tokenIndex,
          utils.formatBytes32String(state.to.symbol),
          state.from.amount,
          subtractSlippage(state.to.amount, slippageSelected, slippageCustom),
        ] as const
        console.debug("swap - tokenToSynth", args)
        swapTransaction = await (state.bridgeContract as Bridge).tokenToSynth(
          ...args,
          { gasPrice: _gasPrice },
        )
      } else if (state.swapType === SWAP_TYPES.DIRECT) {
        const deadline = formatDeadlineToNumber(
          transactionDeadlineSelected,
          transactionDeadlineCustom,
        )
        const args = [
          state.from.tokenIndex,
          state.to.tokenIndex,
          state.from.amount,
          subtractSlippage(state.to.amount, slippageSelected, slippageCustom),
          Math.round(new Date().getTime() / 1000 + 60 * deadline),
        ] as const
        console.debug("swap - direct", args)

        toNum = args[3]

        const swapContractTemp = state.swapContract as NonNullable<
          typeof state.swapContract // we already check for nonnull above
        >
        // console.log(swapContractTemp.swap);
        // console.log(swapContractTemp.exchangeUnderlying);
        if (swapContractTemp.swap) {
          if (
            isNativePool(state.from.poolName) &&
            state.from.symbol === ASTR_SYMBOL
          ) {
            swapTransaction = await (
              swapContractTemp as unknown as SwapETH
            ).swap(...args, { gasPrice: _gasPrice, value: args[2] })
          } else {
            swapTransaction = await swapContractTemp.swap(...args, {
              gasPrice: _gasPrice,
            })
          }
        } else if (swapContractTemp.exchangeUnderlying) {
          swapTransaction = await swapContractTemp.exchangeUnderlying(...args, {
            gasPrice: _gasPrice,
          })
        }
      } else if (state.swapType === SWAP_TYPES.SYNTH_TO_SYNTH) {
        const args = [
          utils.formatBytes32String(state.from.symbol),
          state.from.amount,
          utils.formatBytes32String(state.to.symbol),
          account,
          SYNTH_TRACKING_ID,
        ] as const
        console.debug("swap - synthToSynth", args)
        swapTransaction = await baseSynthetixContract?.exchangeWithTracking(
          ...args,
          { gasPrice: _gasPrice },
        )
      } else {
        throw new Error("Invalid Swap Type, or contract not loaded")
      }

      // console.log(toNum);
      // console.log(formatBNToString(toNum,18,2));

      setTransitionStatus((s: any) => ({
        ...s,
        inApprove: false,
        code: 0,
        desc: "begin swap. add hash",
        hash: swapTransaction?.hash,
        toNum: formatBNToString(toNum, 18, 2),
      }))
      // if (swapTransaction?.hash) {
      //   notifyHandler(swapTransaction.hash, "swap")
      // }

      notify.notification({
        type: "pending",
        message: "Your transaction has been sent to the network",
        autoDismiss: 5000,
      })
      await swapTransaction?.wait()
      notify.notification({
        type: "success",
        message: t("Yourtransactionhassucceeded"),
        autoDismiss: 5000,
      })
      setTransitionStatus((s: any) => ({
        ...s,
        inApprove: false,
        code: 1,
        desc: " swap success",
        hash: swapTransaction?.hash,
        toNum: formatBNToString(toNum, 18, 2),
      }))
      dispatch(
        updateLastTransactionTimes({
          [TRANSACTION_TYPES.SWAP]: Date.now(),
        }),
      )
      return Promise.resolve()
    } catch (e) {
      setTransitionStatus((s: any) => ({ ...s, code: 2, desc: " swap fail" }))
      console.error(e)
      notifyCustomError(e as Error)
    }
  }
}
