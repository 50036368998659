import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import s4SRSLogo from "../../assets/icons/s4SRS.svg"
import lDAILogo from "../../assets/icons/lDAI.png"
import lUSDCLogo from "../../assets/icons/lUSDC.png"
import lUSDTLogo from "../../assets/icons/lUSDT.png"
import lBUSDLogo from "../../assets/icons/lBUSD.png"

export const STARLAY_POOL_NAME = "Starlay 4Pool"

export const STARLAY_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xf2D7786fDd9a64Ae82CE8Fffd83B6f3c5a35945c",
  [ChainId.ASTAR]: "0x39399F42f700De9d547c6cF936a39046863764a3",
})
export const STARLAY_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xa0EaCFB2350f0c529a6474373C039657DbD6Cc7B",
  [ChainId.ASTAR]: "0x0fB8C4eB33A30eBb01588e3110968430E3E69D58",
})
export const STARLAY_FARM_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xA0C20BBDa03Bf7BFD8bfCA86d89c12cC38B223F8",
  [ChainId.ASTAR]: "0x1026F7B0f4A79C9caE3715035730419740A858FD",
})
export const STARLAY_SWAP_TOKEN = new Token(
  STARLAY_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "s4SRS",
  "binance-usd",
  "siriusLP",
  s4SRSLogo,
  false,
  true,
)

const LDAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x1F5BaA7B334590C70e1A45b8558B04F6D3d1f417",
  [ChainId.ASTAR]: "0x4dd9c468A44F3FEF662c35c1E9a6108B70415C2c",
})
export const LDAI = new Token(
  LDAI_CONTRACT_ADDRESSES,
  18,
  "lDAI",
  "dai",
  "Starlay lDAI",
  lDAILogo,
  false,
  true,
)

const LUSDC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xc93D6fd68BED9B4b128eBA8Ad6fDc3f31967113A",
  [ChainId.ASTAR]: "0xc404e12d3466accb625c67dbab2e1a8a457def3c",
})
export const LUSDC = new Token(
  LUSDC_CONTRACT_ADDRESSES,
  18,
  "lUSDC",
  "usd-coin",
  "Starlay lUSDC",
  lUSDCLogo,
  false,
  true,
)

const LUSDT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x084338f2D25f58F1541aC83CFAD905231cCE8E5D",
  [ChainId.ASTAR]: "0x430D50963d9635bBef5a2fF27BD0bDDc26ed691F",
})
export const LUSDT = new Token(
  LUSDT_CONTRACT_ADDRESSES,
  18,
  "lUSDT",
  "tether",
  "Starlay lUSDT",
  lUSDTLogo,
  false,
  true,
)

const LBUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xbD692cE3CC85CC767eD53e5A3127A9945a307a2b",
  [ChainId.ASTAR]: "0xb7aB962c42A8Bb443e0362f58a5A43814c573FFb",
})
export const LBUSD = new Token(
  LBUSD_CONTRACT_ADDRESSES,
  18,
  "lBUSD",
  "binance-usd",
  "Starlay lBUSD",
  lBUSDLogo,
  false,
  true,
)

export const STARLAY_POOL_TOKENS = [LDAI, LUSDC, LUSDT, LBUSD]

export const STARLAY_POOL_INFO: Pool = {
  name: STARLAY_POOL_NAME,
  lpToken: STARLAY_SWAP_TOKEN,
  poolTokens: STARLAY_POOL_TOKENS,
  isSynthetic: false,
  addresses: STARLAY_SWAP_ADDRESSES,
  farmAddresses: STARLAY_FARM_ADDRESSES,
  type: PoolTypes.USD,
  route: "starly",
  isOutdated: true,
  rewardPids: buildPids({}),
  show: true,
}
