import React, { ReactElement, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, Stack, Link } from "@mui/material"
import { OpenInNew } from "@mui/icons-material"
import moment from "moment"
import { getGraph } from "../utils"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import { getFormattedShortTime } from "../utils/dateTime"
import { getPoolByAddress } from "../utils/getPoolByAddress"
import { useActiveWeb3React } from "../hooks"

interface Response {
  data: {
    swapEvents: {
      id: string
      timestamp: string
      transaction: string
      swap: { id: string }
    }[]
    exchanges: {
      timestamp: string
      transaction: string
      swap: { id: string }
      data: { soldId: string; boughtId: string }
    }[]
  }
}

interface Transaction {
  eventName: string
  transaction: string
  timestamp: number
  status?: string
}

export default function Transactions(): ReactElement {
  const { t } = useTranslation()
  const { chainId, account } = useActiveWeb3React()
  const [transactionList, setTransactionList] = useState<Transaction[]>([])

  const fetchTxn = useCallback(async () => {
    if (!account || !chainId) return

    const acct = account.toLowerCase()
    const time24h = moment().subtract(1, "d").unix()
    const list: Transaction[] = []

    const query = `{
      swapEvents(orderBy: timestamp_DESC, limit: 100, where: {timestamp_gte: ${time24h}, data: {provider_eq: "${acct}"}}) {
        id
        timestamp
        transaction
        swap { id }
      }
      exchanges(orderBy: timestamp_DESC, limit: 100, where: {timestamp_gte: ${time24h}, data: {buyer_eq: "${acct}"}}) {
        timestamp
        transaction
        swap { id }
        data {
          ... on TokenExchangeData {
            soldId
            boughtId
          }
        }
      }
    }`

    try {
      const res: Response = await getGraph(query)

      res?.data?.swapEvents?.map((i) => {
        const poolName = getPoolByAddress(i.swap.id, chainId)?.name
        if (!poolName) return

        list.push({
          eventName: `${t(
            i.id.startsWith("add_liquidity") ? "depositIn" : "withdrawFrom",
          )} ${poolName}`,
          transaction: i.transaction,
          timestamp: +i.timestamp,
        })
      })
      res?.data?.exchanges?.map((i) => {
        const poolTokens = getPoolByAddress(i.swap.id, chainId)?.poolTokens
        if (!poolTokens) return

        const soldToken = poolTokens[parseInt(i.data.soldId)].symbol
        const boughtToken = poolTokens[parseInt(i.data.boughtId)].symbol
        list.push({
          eventName: `${t("swap")} ${soldToken} ${t("toBe")} ${boughtToken}`,
          transaction: i.transaction,
          timestamp: +i.timestamp,
        })
      })

      list.sort((a, b) => b.timestamp - a.timestamp)
      setTransactionList(list)
    } catch (err) {
      console.error("fetchTxn", err)
    }
  }, [chainId, t, account])

  useEffect(() => {
    void fetchTxn()
  }, [fetchTxn])

  return (
    <>
      <Box display="flex" justifyContent="space-between" mb={0.5}>
        <Typography variant="subtitle1">{t("recentTransactions")}</Typography>
        <Typography
          variant="body1"
          color="success.main"
          sx={{ "&:hover": { cursor: "pointer", opacity: 0.8 } }}
          onClick={() => setTransactionList([])}
        >
          {t("clear")}
        </Typography>
      </Box>
      {!transactionList.length && (
        <span style={{ fontSize: "16px" }}>{t("yourRecentTransactions")}</span>
      )}
      {transactionList.map((i, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={3}
          sx={{ fontSize: 16 }}
        >
          <Link
            href={getEtherscanLink(i.transaction, "tx", chainId)}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none", "&:hover": { opacity: 0.8 } }}
          >
            {i.eventName}
            <OpenInNew
              sx={{ fontSize: 16, verticalAlign: "middle", ml: 0.5 }}
            />
          </Link>
          <Box>{getFormattedShortTime(i.timestamp)}</Box>
        </Stack>
      ))}
    </>
  )
}
