import "./ReviewSwap.scss"

import { Box, Typography, Divider } from "@mui/material"

import React, { ReactElement, useEffect } from "react"
import { SWAP_TYPES, TOKENS_MAP, getIsVirtualSwap } from "../constants"
import { formatBNToString, commify, formatBNToPercentString } from "../utils"

import { AppState } from "../state/index"
import { BigNumber } from "@ethersproject/bignumber"
// import Button from "./Button"
// import HighPriceImpactConfirmation from "./HighPriceImpactConfirmation"
import { ReactComponent as ThinArrowDown } from "../assets/icons/thinArrowDown.svg"
import classnames from "classnames"
import { formatSlippageToString } from "../utils/slippage"
// import iconDown from "../assets/icons/icon_down.svg"
// import { isHighPriceImpact } from "../utils/priceImpact"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { ReactComponent as CloseSVG } from "./../assets/icons/close.svg"
import { ReactComponent as ArrowRight } from "./../assets/icons/right-arrow.svg"
import Button from "./../components/Button"
import { useGasPrice, GAS_PRECISION } from "../hooks/useGas"

interface Props {
  onClose: () => void
  onConfirm: () => void
  data: {
    from: { symbol: string; value: string }
    to: { symbol: string; value: string }
    exchangeRateInfo: {
      pair: string
      priceImpact: BigNumber
      exchangeRate: BigNumber
      route: string[]
    }
    swapType: SWAP_TYPES
    txnGasCost: {
      amount: BigNumber
      valueUSD: BigNumber | null // amount * ethPriceUSD
    }
  }
  poolName: string | undefined
}

function ReviewSwap({
  onClose,
  onConfirm,
  data,
  poolName,
}: Props): ReactElement {
  const { t } = useTranslation()
  const gasPrice = useGasPrice()
  const {
    slippageCustom,
    slippageSelected,
    // transactionDeadlineSelected,
    // transactionDeadlineCustom,
  } = useSelector((state: AppState) => state.user)
  // const [hasConfirmedHighPriceImpact, setHasConfirmedHighPriceImpact] =
  //   useState(false)
  // const isHighPriceImpactTxn = isHighPriceImpact(
  //   data.exchangeRateInfo.priceImpact,
  // )
  const isVirtualSwap = getIsVirtualSwap(data.swapType)
  // const deadline = formatDeadlineToNumber(
  //   transactionDeadlineSelected,
  //   transactionDeadlineCustom,
  // )
  const formattedPriceImpact = commify(
    formatBNToPercentString(data.exchangeRateInfo.priceImpact, 18),
  )
  const Max_slip = formatSlippageToString(slippageSelected, slippageCustom)
  // console.log(data.from['symbol']);

  return (
    <div className="reviewSwap">
      <div className="reviewSwapContainer">
        <Typography variant="h4" align="left" gutterBottom={true}>
          {t("previewOrder")}
          <span onClick={onClose}>
            <CloseSVG />
          </span>
        </Typography>
        {isVirtualSwap ? (
          <VirtualSwapTokens data={data} />
        ) : (
          <DirectSwapTokens data={data} maxSlip={Max_slip} />
        )}

        <div className="swapTable">
          {/*         
        {data.swapType === SWAP_TYPES.SYNTH_TO_SYNTH && (
          <div className="row">
            <span className="aside">
              {t("virtualSwapSynthToSynthInfo")}{" "}
              <a href="https://blog.synthetix.io/how-fee-reclamation-rebates-work/">
                {t("learnMore")}
              </a>
            </span>
          </div>
        )} */}
          {/* <div className="divider" style={{ height: "1px", width: "100%" }} /> */}
          <div className="swapInfo">
            <div className="row">
              <span className="title">{t("maxSlippage")}</span>
              <span className="value floatRight">{Max_slip}%</span>
            </div>
            <div className="row">
              <span className="title">{t("price")}</span>
              <span className="value floatRight">
                1&nbsp;{data.exchangeRateInfo.route[0]}
                &nbsp;
                <ArrowRight />
                &nbsp;
                {formatBNToString(data.exchangeRateInfo.exchangeRate, 18, 6)}
                &nbsp;
                {data.exchangeRateInfo.route[1]}
              </span>
            </div>
            <div className="row">
              <span className="title">{t("priceImpact")}</span>
              <span className="value floatRight">{formattedPriceImpact}</span>
            </div>
            <div className="row">
              <span className="title">{t("routedPool")}</span>
              <span className="value floatRight important-text">
                {poolName}
              </span>
            </div>

            {/* <div className="priceTable">
            <span className="title">{t("price")} </span>
            <span className="pair">{data.exchangeRateInfo.pair}</span>

            <span className="value floatRight">
              {formatBNToString(data.exchangeRateInfo.exchangeRate, 18, 6)}
            </span>
          </div> */}
            <div className="row">
              <span className="title">{t("gasPrice")}</span>
              <span className="value floatRight">
                {formatBNToString(gasPrice, GAS_PRECISION)} GWEI
              </span>
            </div>
            {/* TODO: Create a light API to expose the cached BlockNative gas estimates. */}
            {/* {data.txnGasCost?.valueUSD && (
            <div className="row">
              <span className="title">{t("estimatedTxCost")}</span>
              <span className="value floatRight">
                {`≈$${commify(
                  formatBNToString(data.txnGasCost.valueUSD, 2, 2),
                )}`}
              </span>
            </div>
          )} */}
            {/* <div className="row">
            <span className="title">{t("maxSlippage")}</span>
            <span className="value floatRight">
              {formatSlippageToString(slippageSelected, slippageCustom)}%
            </span>
          </div> */}
            {/* {!isVirtualSwap && (
            <div className="row">
              <span className="title">{t("deadline")}</span>
              <span className="value floatRight">
                {deadline} {t("minutes")}
              </span>
            </div>
          )} */}
            {/* {isHighPriceImpactTxn && (
            <div className="row">
              <HighPriceImpactConfirmation
                checked={hasConfirmedHighPriceImpact}
                onCheck={(): void =>
                  setHasConfirmedHighPriceImpact((prevState) => !prevState)
                }
              />
            </div>
          )} */}
          </div>
        </div>
      </div>
      <Box className="swap_btn_wrapper">
        <Button
          // disabled={true}
          kind="primary"
          fullWidth={true}
          onClick={onConfirm}
          size="large"
        >
          {t("confirmSwap")}
        </Button>
      </Box>
      {/* <div className="bottom">
        <p>{t("estimatedOutput")}</p>
        <div className="buttonWrapper">
          <Button
            variant="contained"
            onClick={onConfirm}
            color="primary"
            disabled={isHighPriceImpactTxn && !hasConfirmedHighPriceImpact}
          >
            {t("confirmSwap")}
          </Button>
          <Button variant="outlined" color="primary" onClick={onClose}>
            {t("cancel")}
          </Button>
        </div>
      </div> */}
    </div>
  )
}

function DirectSwapTokens({
  data,
  maxSlip,
}: {
  data: Props["data"]
  maxSlip: string
}) {
  const fromToken = TOKENS_MAP[data.from.symbol]
  const toToken = TOKENS_MAP[data.to.symbol]
  const { t } = useTranslation()
  // {+data.to.value !== 0
  //   ? ((+data.to.value * (100 - maxSlip)) / 100).toFixed(6)
  //   : data.to.value}
  // const toTokenVal = maxSlip;
  return (
    <>
      <Typography variant="subtitle2" className="rs_token_subtitle">
        {t("youArePaying")}
      </Typography>
      <div className="row directSwapItem">
        <div>
          <img
            className="tokenIcon"
            src={fromToken.icon}
            alt="icon"
            width={40}
            height={40}
          />
          <span className="tokenName">{data.from.symbol}</span>
        </div>
        <div>
          <span>{data.from.value}</span>
        </div>
      </div>
      <Divider className="Swap_divider"></Divider>
      {/* <img src={iconDown} alt="to" className="arrowDown" /> */}
      {/* <ArrowDownwardIcon className="arrowDown" /> */}
      <Typography variant="subtitle2" className="rs_token_subtitle">
        {t("youWillMinRecive")}
      </Typography>
      <div className="row directSwapItem">
        <div>
          <img
            className="tokenIcon"
            src={toToken.icon}
            alt="icon"
            width={40}
            height={40}
          />
          <span className="tokenName">{data.to.symbol}</span>
        </div>

        <div>
          <span>
            {+data.to.value !== 0
              ? ((+data.to.value * (100 - +maxSlip)) / 100).toFixed(6)
              : data.to.value}
          </span>
        </div>
      </div>
    </>
  )
}

function VirtualSwapTokens({ data }: { data: Props["data"] }) {
  const { t } = useTranslation()

  return (
    <>
      {data.exchangeRateInfo.route.map((symbol, i) => {
        const isFirst = i === 0
        const isLast = i === data.exchangeRateInfo.route.length - 1
        const token = TOKENS_MAP[symbol]
        return (
          <div className="row" key={symbol}>
            <div>
              {!isFirst && !isLast && <ThinArrowDown className="stepArrow" />}
              <img
                className="tokenIcon"
                src={token.icon}
                alt="icon"
                width={40}
                height={40}
              />
              <span className={classnames("tokenName", { grey: isLast })}>
                {token.symbol}
              </span>

              {(isFirst || isLast) && (
                <span className="aside">
                  {" "}
                  (
                  {t("stepN", {
                    step: isFirst ? 1 : 2,
                  })}
                  )
                </span>
              )}
            </div>
            <div>
              {isFirst && <span>{data.from.value}</span>}
              {isLast && <span className="grey">{data.to.value}</span>}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default ReviewSwap
