import React, { useEffect, useState, ReactElement, useCallback } from "react"
import {
  Grid,
  Container,
  Box,
  Dialog,
  Paper,
  Typography,
  Divider,
  Link,
} from "@mui/material"
import { useSelector } from "react-redux"
import { AppState } from "../state"
import {
  useFarmsContract,
  useFarmMinterContract,
  useTokenContract,
} from "../hooks/useContract"
import { useExtraRewards, useFarmRowData } from "../hooks/useFarmData"
import Button from "../components/Button"
import { SRS_TOKEN } from "../constants"
import { useActiveWeb3React } from "../hooks"
import { BigNumber } from "@ethersproject/bignumber"
import { parseUnits } from "@ethersproject/units"
import {
  parseBalance,
  formatBNToString,
  commify,
  sliceNum,
  getExtraTokenByAddress,
  expensiveDecimals,
} from "../utils"
import { shortenAddress } from "../utils/shortenAddress"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import styles from "./../pages/Farms.module.scss"

import "./FarmDetail.scss"
import ConfirmTransaction from "../components/ConfirmTransaction"
import { ReactComponent as BackArrow } from "./../assets/icons/back_arrow.svg"

import ConnectWallet from "./ConnectWallet"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import switchNetwork from "../utils/switchNetwork"
import { ChainId } from "../constants"
import { NETWORK_CHAIN_ID } from "../connectors"
import { NETWORK_LABEL } from "../constants/networks"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
import ReviewExtraRewards from "./ReviewExtraRewards"
import moment from "moment"
import { useGasPrice } from "./../hooks/useGas"
import { isMobile } from "react-device-detect"
import CustomTooltip from "../components/CustomTooltip"
import { useTranslation } from "react-i18next"

let timer = 0
interface Props {
  farmData: any
  TotalRewardSRSPerSecond: any
}

const FarmDetail = ({
  farmData,
  TotalRewardSRSPerSecond,
}: Props): ReactElement => {
  const lpName = farmData.lpToken.symbol
  const [FarmDetailTab, setFarmDetailTab] = useState("stake")
  const gasPrice = useGasPrice()
  const { srsPrice } = useSelector((state: AppState) => state.application)

  const FarmContract = useFarmsContract(farmData.farmAddresses)
  const LpTokenContract = useTokenContract(farmData.lpToken)
  const { account, chainId } = useActiveWeb3React()
  const [ExtraRewards, MyExtraRewards, getExtraRewards] =
    useExtraRewards(FarmContract)
  const [forceClaim, setForceClaim] = useState(false)
  const [isShowMobileDetails, setIsShowMobileDetails] = useState(false)
  
  const [FarmRowData, getFarmContractData, getLpTokenContractData] = useFarmRowData(farmData, TotalRewardSRSPerSecond);


  const [lpInput, setLpInput] = useState("")
  const [withdrawInput, setWithdrawInput] = useState("")
  const [withdrawRatio, setWithdrawRatio] = useState(0)
  const [showConfirmModal, setShowConfirmModal] = useState([false, ""])
  const [showConnectWallet, setShowConnectWallet] = useState(false)
  const [showExtraRewardClaim, setShowExtraRewardClaim] = useState(false)
  const [txHash, setTxHash] = useState("")
  const supportedNetworkName =
    NETWORK_LABEL[NETWORK_CHAIN_ID as ChainId] || "Ethereum"

  const { addToken } = useAddTokenToMetamask({
    ...SRS_TOKEN,
    icon: `${window.location.origin}/logo.svg`,
  })
  const { t } = useTranslation()
  useEffect(() => {
    if (!account) {
      return
    }
    timer = window.setInterval(() => {
      getFarmContractData()
      getLpTokenContractData()
    }, 3000)

    return () => window.clearInterval(timer)
  }, [getFarmContractData, getLpTokenContractData, account])

  const deposit = async () => {
    // try {
    const transaction = await FarmContract?.deposit(
      parseBalance(lpInput, 18),
      account,
      false,
      { gasPrice: gasPrice },
    )
    setTxHash(transaction?.hash)
    return transaction
    // } catch (error) {
    //   notifyCustomError(error as Error)
    // } finally {
    //   setTxHash("")
    // }
  }

  const withdraw = async () => {
    // try {
    const transaction = await FarmContract?.withdraw(
      parseBalance(withdrawInput, 18),
      false,
      { gasPrice: gasPrice },
    )
    setTxHash(transaction?.hash)
    return transaction
    // } catch (error) {
    //   notifyCustomError(error as Error)
    // } finally {
    //   setTxHash("")
    // }
  }

  useEffect(() => {
    const val =
      FarmRowData?.MyStakeLPNum && withdrawRatio
        ? `${+FarmRowData?.MyStakeLPNum * withdrawRatio}`
        : ""

    setWithdrawInput(
      val
        ? `${val?.split(".")[0]}.${val?.split(".")[1]?.slice(0, 4) || "00"}`
        : "",
    )
  }, [withdrawRatio, FarmRowData?.MyStakeLPNum])

  const claim = async () => {
    // try {
    const transaction = await farmMinter?.mint(FarmContract?.address, {
      gasPrice: gasPrice,
    })
    setTxHash(transaction?.hash)
    return transaction
    // } catch (error) {
    // todo
    // } finally {
    // setShowConfirmModal([false, ""])
    // }
  }

  const farmMinter = useFarmMinterContract()

  const [lpTokenBalance, setLpTokenBalance] = useState("")
  const getLpTokenBalance = useCallback(async () => {
    try {
      const res: BigNumber = await LpTokenContract?.balanceOf(account)
      setLpTokenBalance(formatBNToString(res, 18))
    } catch (error) {
      // todo
    }
  }, [LpTokenContract, setLpTokenBalance, account])

  useEffect(() => {
    void getLpTokenBalance()
  }, [getLpTokenBalance])

  const inputRateClick = (
    lpTokenBalance: any,
    rate: number,
    setWInput?: any,
  ) => {
    // const amtStr = lpTokenBalance * rate * 0.01
    const bn = parseUnits(lpTokenBalance || "0")
      .mul(rate)
      .div(100)
    let amtStr = formatBNToString(bn, 18, expensiveDecimals(lpName))
    if (+amtStr === 0) amtStr = "0"

    if (!amtStr) return
    if (setWInput) {
      setWInput(amtStr)
    } else {
      setLpInput(amtStr)
    }
  }
  const inputRateActive = (from_value: any, _key: any, lpTokenBalance: any) => {
    // const amtStr = lpTokenBalance * _key * 0.01
    const bn = parseUnits(lpTokenBalance || "0")
      .mul(_key)
      .div(100)
    let amtStr = formatBNToString(bn, 18, expensiveDecimals(lpName))
    if (+amtStr === 0) amtStr = "0"

    if (!amtStr) return
    if (+from_value === 0) return false
    if (!_key) return false
    return +from_value === +amtStr
  }
  const { error: Network_error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError

  ///add to metamask
  const [finalLPAmount, setFinalLPAmount] = useState("")
  const getCompletedInfo = (): ReactElement => {
    return (
      <>
        {finalLPAmount && (
          <Typography fontSize={12} fontWeight={400}>
            {t("youHaveReceived")} {finalLPAmount} {SRS_TOKEN.symbol}
          </Typography>
        )}

        <Typography color="#FFCDEC" fontSize={12} fontWeight={400}>
          <span className="add-to-wallet" onClick={addToken}>
            {t("addToWallet")}
          </span>
        </Typography>
      </>
    )
  }

  // extra reward begin
  const getSymbolByAddress = (address: string) => {
    if (!chainId) return
    return getExtraTokenByAddress(address, chainId)?.symbol
  }
  const claimExtra = async () => {
    // try {
    if (forceClaim) {
      // console.log(1);
      const transaction = await FarmContract?.claimRewardsForce(
        account,
        account,
        { gasPrice: gasPrice },
      )
      setTxHash(transaction?.hash)
      return transaction
    } else {
      // console.log(2);
      const transaction = await FarmContract?.claimRewards(account, account, {
        gasPrice: gasPrice,
      })
      setTxHash(transaction?.hash)
      return transaction
    }

  }
  const YearValueToAPY = (_YearValue: any, _TotalValueRaw: any) => {
    const tvl = +formatBNToString(_TotalValueRaw, 18)
    if(!tvl) return 0;
    return sliceNum(
      (100 * +_YearValue) /
      tvl
    )
  }
  const [TotalAPY, setTotalAPY] = useState(0)

  useEffect(() => {
    // console.log(ExtraRewards);
    if(ExtraRewards === undefined) return ;
    if(!FarmRowData["TotalValueRaw"]) return ;
    let APY = 0
    ExtraRewards.map((ExtraReward: any, _index: any) => {
      APY += +YearValueToAPY(
        ExtraReward.rewardPerYearValue,
        FarmRowData["TotalValueRaw"],
      )
    })
    APY += +FarmRowData?.apy
    setTotalAPY(APY)
  }, [ExtraRewards, FarmRowData])

  const disableExtraClaim = (MyExtraRewards: any) => {
    let v = 0
    MyExtraRewards.map((MyExtraReward: any) => {
      v += +MyExtraReward["vol"]
    })
    // console.log(v);
    return +sliceNum(+v) === 0 ? true : false
  }

  // extra rewards end
  const [hasUnlockTimeToken, setHasUnlockTimeToken] = useState(false)
  useEffect(() => {
    // console.log(MyExtraRewards);
    let _has = 0
    MyExtraRewards.map((item: any) => {
      // console.log(item.unlock_time);
      if (moment.unix(item.unlock_time).isAfter()) _has = 1
    })
    if (_has) setHasUnlockTimeToken(true)
    else setHasUnlockTimeToken(false)
  }, [MyExtraRewards])

  return (
    <Container
      disableGutters={true}
      maxWidth={false}
      sx={{ maxWidth: 920, marginTop: { xs:"0", sm:"40px" } }}
      
    >
      <Typography variant="h2" align="left" gutterBottom={true} mb={2} sx={{ display: {xs: "none", sm:"block"}}}>
        <Link
          href="#/farms"
          color="inherit"
          underline="none"
          className={styles.farm_detail_h2}
        >
          <BackArrow />
          {t("backDetailBack")}
        </Link>
      </Typography>
      {isMobile && isShowMobileDetails && (
        <Box sx={{position: "relative", zIndex: 10}}>
          <Typography
            fontSize={14}
            fontWeight={700}
            ml={3}
            color="#FFCDEC"
            onClick={() => {
              setIsShowMobileDetails(false)
            }}
          >
            {t("back")}
          </Typography>
        </Box>
      )}
      <Grid container spacing={4} columns={15}>
        <Grid item xs={15} sm={6}>
          <Paper variant="outlined" className={isMobile?"FarmNoBorder":""}>
            <Box className={styles.FarmDetailLeft}>
              {isMobile&&!isShowMobileDetails?"":(
                <>
                  <Typography
                    variant="subtitle2"
                    className={`${styles.color2} ${styles.bold}`}
                  >
                    {lpName}
                  </Typography>
                  <Box
                    className={styles.FarmDetailIconGroup}
                    sx={{ margin: "2px 0 10px 0" }}
                  >
                    {farmData.poolTokens.map((_token: any) => (
                      <img key={_token.symbol} src={_token.icon} alt="" />
                    ))}
                  </Box>
                  <Typography variant="h2" className={styles.FarmDetailLeftApy}>
                    <CustomTooltip info={t("rewardsAPRTooltip", { price: srsPrice }) as string}>
                      <span style={{ borderBottom: "1px dashed #FFCDEC" }}>
                        {t("rewardsAPR")}
                      </span>
                    </CustomTooltip>
                    <span>
                      {+sliceNum(TotalAPY) ? sliceNum(TotalAPY) + "%" : "-"}
                    </span>
                  </Typography>
                  <Typography variant="body2" className={styles.FarmPanelRow}>
                    <span>{t("baseReward")}</span>
                    <span>
                      {+FarmRowData?.apy ? commify(FarmRowData?.apy) + "%" : "-"}
                    </span>
                  </Typography>
                  {!!ExtraRewards?.length && (
                    <Typography
                      variant="body2"
                      className={styles.FarmPanelRow}
                      alignItems="start"
                    >
                      <span>{t("extraReward")}</span>
                      <div>
                        {ExtraRewards?.map((ExtraReward: any, _index: any) => (
                          <div key={_index}>
                            {getSymbolByAddress(ExtraReward.address)}: &nbsp;
                            {YearValueToAPY(
                              ExtraReward.rewardPerYearValue,
                              FarmRowData["TotalValueRaw"],
                            )
                              ? YearValueToAPY(
                                  ExtraReward.rewardPerYearValue,
                                  FarmRowData["TotalValueRaw"],
                                ).toString() + "%"
                              : "-"}
                          </div>
                        ))}
                      </div>
                    </Typography>
                  )}

                  <Divider sx={{ marginBottom: "24px" }} />
                </>
              ) }
              
              <Typography
                variant="subtitle2"
                className={[styles.bold, styles.FarmPanelRow].join(" ")}
                sx={{ marginBottom: "6px" }}
              >
                <span>{t("myStakeNRewards")}</span>
                {isMobile && !isShowMobileDetails?(
                  <span style={{color: "#FFCDEC"}} onClick={() => setIsShowMobileDetails(true)}>
                    {t("seeFarmDetails")}
                  </span>
                ):""}
                
              </Typography>
              <Typography variant="body2" className={styles.FarmPanelRow}>
                <span>{t("LPStakedValue")}</span>
                <span>
                  {+FarmRowData?.MyStakeValue
                    ? "$"+commify(FarmRowData?.MyStakeValue)
                    : "-"}
                </span>
              </Typography>
              <Typography variant="subtitle2" className={styles.FarmPanelRow}>
                <span style={{ fontWeight: 700 }}>{t("unclaimedRewards")}</span>
              </Typography>
              <Typography variant="body2" className={styles.FarmPanelRow}>
                <span>{t("baseReward")}</span>
                <span>
                  {+FarmRowData?.unClaimSRS
                    ? commify(FarmRowData?.unClaimSRS) + " SRS"
                    : "-"}
                </span>
              </Typography>
              {!isMobile?(
                <Button
                  fullWidth={true}
                  size="medium"
                  className={styles.FarmDetailLeftBtn}
                  onClick={() => {
                    setShowConfirmModal([true, "claim"])
                    setFinalLPAmount(
                      +FarmRowData?.unClaimSRS
                        ? commify(FarmRowData?.unClaimSRS)
                        : "-",
                    )
                  }}
                  disabled={
                    isUnsupportChainIdError ||
                    !account ||
                    +sliceNum(+FarmRowData?.unClaimSRS) === 0
                  }
                >
                  {t("claimBase")}
                </Button>
              ):""}
              

              {MyExtraRewards.length ? (
                <>
                  <Typography
                    variant="body2"
                    className={styles.FarmPanelRow}
                    sx={{ position: "absolute" }}
                  >
                    <span>{t("extraReward")}</span>
                    <span></span>
                  </Typography>
                  {/* {ExtraRewards.map((ExtraReward, _index) => (
                    <> */}
                  {MyExtraRewards.map((MyExtraReward: any, _key: any) => (
                    <Typography
                      key={_key}
                      variant="body2"
                      className={styles.FarmPanelRow}
                    >
                      <span></span>
                      <span>
                        {+(+MyExtraReward["vol"]).toFixed(2) ? (
                          <>
                            {(+MyExtraReward["vol"]).toFixed(2)}&nbsp;
                            {MyExtraReward["symbol"]}
                          </>
                        ) : (
                          "-"
                        )}
                      </span>
                    </Typography>
                  ))}
                  {/* </>
                  ))} */}
                  {!isMobile?(
                    <Button
                      fullWidth={true}
                      size="medium"
                      className={styles.FarmDetailLeftBtn}
                      onClick={() => {
                        // setShowConfirmModal([true, "extraClaim"])
                        if (hasUnlockTimeToken) {
                          setShowExtraRewardClaim(true)
                        } else {
                          setShowConfirmModal([true, "extraClaim"])
                        }
                      }}
                      disabled={
                        isUnsupportChainIdError ||
                        !account ||
                        disableExtraClaim(MyExtraRewards)
                        // +commify(FarmRowData?.unClaimSRS) === 0
                      }
                    >
                      {t("claimExtra")}
                    </Button>
                  ):""}
                </>
              ) : (
                ""
              )}
              {!isMobile?(
                <Box className="Farm_Lock_ad">
                  <Link href="#/governance">{t("boostsRewards")}</Link>
                </Box>
              ):""}
              {isMobile&&!isShowMobileDetails?"":(
                <>
                  <Divider sx={{ marginBottom: "17px" }} />
                  <Typography
                    variant="subtitle2"
                    className={`${styles.FarmPanelRow} ${styles.bold}`}
                  >
                    <span>{t("totalValueStaked")}</span>
                    <span>${FarmRowData.TotalValue}</span>
                  </Typography>
                  <Typography variant="body2" className={styles.FarmPanelRow}>
                    <span>{t("farmContract")}</span>
                    <span className={styles.color2}>
                      <Link
                        color="inherit"
                        underline="none"
                        href={getEtherscanLink(
                          FarmContract?.address || "",
                          "address",
                          chainId,
                        )}
                        target="_blank"
                      >
                        {shortenAddress(FarmContract?.address || "-")}
                      </Link>
                    </span>
                  </Typography>
                </>
              )}
            </Box>
          </Paper>
        </Grid>
        {isShowMobileDetails && isMobile?"":(
          <Grid item xs={15} sm={9} className={isMobile?"FarmRight_bot":""}>
            <Paper variant="outlined" sx={{ background: "#35235E", border: 0 }}>
              <div className="FarmDetailRight">
                <Box className="TabBtnGroup">
                  <span
                    className={FarmDetailTab === "stake" ? "active" : ""}
                    onClick={() => setFarmDetailTab("stake")}
                  >
                    {t("stake")}
                  </span>
                  <span
                    className={FarmDetailTab === "unstake" ? "active" : ""}
                    onClick={() => setFarmDetailTab("unstake")}
                  >
                    {t("unstake")}
                  </span>
                  {/* 只在mobile显示begin */}
                  <span
                    className={FarmDetailTab === "claimBase" ? "active" : ""}
                    onClick={() => setFarmDetailTab("claimBase")}
                  >
                    {t("claimBase")}
                  </span>
                  <span
                    className={FarmDetailTab === "claimExtra" ? "active" : ""}
                    onClick={() => setFarmDetailTab("claimExtra")}
                  >
                    {t("claimExtra")}
                  </span>
                  {/* 只在mobile显示end */}
                </Box>
                {FarmDetailTab === "stake" && (
                  <>
                    <div className="FarmDetailRightContent">
                      <div>
                        <div className="t1">{lpName}</div>
                        <div className="t2">
                          {t("balance")}: {commify(lpTokenBalance, expensiveDecimals(lpName)) || "-"}
                        </div>
                      </div>
                      <div>
                        <input
                          placeholder="0"
                          value={lpInput}
                          onChange={(e) => setLpInput(e.target.value)}
                          onKeyDown={(e) => {
                            const c = e.keyCode
                            if (c == 110 || c == 190) {
                              // 110 (190) - 小(主)键盘上的点
                              // (val.indexOf(".") >= 0 || !val.length) && prevent(e); // 已有小数点或者文本框为空，不允许输入点
                            } else {
                              if (
                                (c != 8 &&
                                  c != 46 && // 8 - Backspace, 46 - Delete
                                  (c < 37 || c > 40) && // 37 (38) (39) (40) - Left (Up) (Right) (Down) Arrow
                                  (c < 48 || c > 57) && // 48~57 - 主键盘上的0~9
                                  (c < 96 || c > 105)) || // 96~105 - 小键盘的0~9
                                e.shiftKey
                              ) {
                                // Shift键，对应的code为16
                                e.preventDefault() // 阻止事件传播到keypress
                              }
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div className="progress_button_group">
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(lpInput, 25, lpTokenBalance)
                                ? "active"
                                : ""
                            }
                            onClick={() => inputRateClick(lpTokenBalance, 25)}
                          >
                            25%
                          </Button>
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(lpInput, 50, lpTokenBalance)
                                ? "active"
                                : ""
                            }
                            onClick={() => inputRateClick(lpTokenBalance, 50)}
                          >
                            50%
                          </Button>
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(lpInput, 75, lpTokenBalance)
                                ? "active"
                                : ""
                            }
                            onClick={() => inputRateClick(lpTokenBalance, 75)}
                          >
                            75%
                          </Button>
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(lpInput, 100, lpTokenBalance)
                                ? "active"
                                : ""
                            }
                            onClick={() => inputRateClick(lpTokenBalance, 100)}
                          >
                            Max
                          </Button>
                        </div>
                      </div>
                    </div>

                    <Box className="swap_btn_wrapper">
                      {isUnsupportChainIdError ? (
                        <Button
                          // disabled={true}
                          kind="primary"
                          fullWidth={true}
                          onClick={() => switchNetwork(supportedNetworkName)}
                          size="large"
                        >
                          {t("switchNetwork")}
                        </Button>
                      ) : (
                        <>
                          {account ? (
                            <Button
                              // disabled={!!error || +toState.value <= 0}
                              kind="primary"
                              fullWidth={true}
                              size="large"
                              onClick={() =>
                                setShowConfirmModal([true, "deposit"])
                              }
                              disabled={
                                +lpInput <= 0 || +lpInput > +lpTokenBalance
                              }
                            >
                              {+lpInput > +lpTokenBalance
                                ? t("insufficientBalance")
                                : t("stake")}
                            </Button>
                          ) : (
                            <Button
                              // disabled={true}
                              kind="primary"
                              fullWidth={true}
                              onClick={(): void => setShowConnectWallet(true)}
                              size="large"
                            >
                              {t("connectWallet")}
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}
                {FarmDetailTab === "unstake" && (
                  <>
                    <div className="FarmDetailRightContent">
                      <div>
                        <div className="t1">{lpName}</div>
                        <div className="t2">
                          {t("avaliable")}: {commify(FarmRowData?.MyStakeLPNum) || "-"}
                        </div>
                      </div>
                      <div>
                        <input
                          placeholder="0"
                          value={withdrawInput}
                          onChange={(e) => setWithdrawInput(e.target.value)}
                          onKeyDown={(e) => {
                            const c = e.keyCode
                            if (c == 110 || c == 190) {
                              // 110 (190) - 小(主)键盘上的点
                              // (val.indexOf(".") >= 0 || !val.length) && prevent(e); // 已有小数点或者文本框为空，不允许输入点
                            } else {
                              if (
                                (c != 8 &&
                                  c != 46 && // 8 - Backspace, 46 - Delete
                                  (c < 37 || c > 40) && // 37 (38) (39) (40) - Left (Up) (Right) (Down) Arrow
                                  (c < 48 || c > 57) && // 48~57 - 主键盘上的0~9
                                  (c < 96 || c > 105)) || // 96~105 - 小键盘的0~9
                                e.shiftKey
                              ) {
                                // Shift键，对应的code为16
                                e.preventDefault() // 阻止事件传播到keypress
                              }
                            }
                          }}
                        />
                      </div>
                      <div>
                        <div className="progress_button_group">
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(
                                withdrawInput,
                                25,
                                FarmRowData?.MyStakeLPNum,
                              )
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              inputRateClick(
                                FarmRowData?.MyStakeLPNum,
                                25,
                                setWithdrawInput,
                              )
                            }
                          >
                            25%
                          </Button>
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(
                                withdrawInput,
                                50,
                                FarmRowData?.MyStakeLPNum,
                              )
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              inputRateClick(
                                FarmRowData?.MyStakeLPNum,
                                50,
                                setWithdrawInput,
                              )
                            }
                          >
                            50%
                          </Button>
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(
                                withdrawInput,
                                75,
                                FarmRowData?.MyStakeLPNum,
                              )
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              inputRateClick(
                                FarmRowData?.MyStakeLPNum,
                                75,
                                setWithdrawInput,
                              )
                            }
                          >
                            75%
                          </Button>
                          <Button
                            kind="outline"
                            size="small"
                            className={
                              inputRateActive(
                                withdrawInput,
                                100,
                                FarmRowData?.MyStakeLPNum,
                              )
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              inputRateClick(
                                FarmRowData?.MyStakeLPNum,
                                100,
                                setWithdrawInput,
                              )
                            }
                          >
                            Max
                          </Button>
                        </div>
                      </div>
                    </div>

                    <Box className="swap_btn_wrapper">
                      {isUnsupportChainIdError ? (
                        <Button
                          // disabled={true}
                          kind="primary"
                          fullWidth={true}
                          onClick={() => switchNetwork(supportedNetworkName)}
                          size="large"
                        >
                          {t("switchNetwork")}
                        </Button>
                      ) : (
                        <>
                          {account ? (
                            <Button
                              // disabled={!!error || +toState.value <= 0}
                              kind="primary"
                              fullWidth={true}
                              size="large"
                              onClick={() =>
                                setShowConfirmModal([true, "withdraw"])
                              }
                              disabled={
                                !FarmRowData?.MyStakeLPNum ||
                                +withdrawInput <= 0 ||
                                +withdrawInput > +FarmRowData?.MyStakeLPNum
                              }
                            >
                              {+withdrawInput > +FarmRowData?.MyStakeLPNum
                                ? t("insufficientBalance")
                                : t("unstake")}
                            </Button>
                          ) : (
                            <Button
                              // disabled={true}
                              kind="primary"
                              fullWidth={true}
                              onClick={(): void => setShowConnectWallet(true)}
                              size="large"
                            >
                              {t("connectWallet")}
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}
                {/* 只在mobile显示begin */}
                {FarmDetailTab === "claimBase" && (
                  <>
                    <div className="FarmDetailRightContent" style={{justifyContent: "center"}}>
                      <div>
                        <div className="t1">SRS</div>
                        <div className="t2">
                          {t("avaliable")}: {+FarmRowData?.unClaimSRS
                            ? commify(FarmRowData?.unClaimSRS)
                            : "0"}
                        </div>
                      </div>
                    </div>
                    
                    <Box className="swap_btn_wrapper">
                      {isUnsupportChainIdError ? (
                        <Button
                          // disabled={true}
                          kind="primary"
                          fullWidth={true}
                          onClick={() => switchNetwork(supportedNetworkName)}
                          size="large"
                        >
                          {t("switchNetwork")}
                        </Button>
                      ) : (
                        <>
                          {account ? (
                            <Button
                              // disabled={!!error || +toState.value <= 0}
                              kind="primary"
                              fullWidth={true}
                              size="large"
                              onClick={() => {
                                setShowConfirmModal([true, "claim"])
                                setFinalLPAmount(
                                  +FarmRowData?.unClaimSRS
                                    ? commify(FarmRowData?.unClaimSRS)
                                    : "-",
                                )
                              }}
                              disabled={
                                +sliceNum(+FarmRowData?.unClaimSRS) === 0
                              }
                            >
                              {t("claimBaseRewards")}
                            </Button>
                          ) : (
                            <Button
                              // disabled={true}
                              kind="primary"
                              fullWidth={true}
                              onClick={(): void => setShowConnectWallet(true)}
                              size="large"
                            >
                              {t("connectWallet")}
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}
                {FarmDetailTab === "claimExtra" && (
                  <>
                    <div className="FarmDetailRightContent" style={{justifyContent: "center"}}>
                      {MyExtraRewards.map((MyExtraReward: any, _key: any) => (
                        <div key={_key}>
                          <div className="t1">{MyExtraReward["symbol"]}</div>
                          <div className="t2">
                            {t("avaliable")}:&nbsp;
                            {+(+MyExtraReward["vol"]).toFixed(2) ? (
                              <>
                              {(+MyExtraReward["vol"]).toFixed(2)}
                              </>
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    
                    <Box className="swap_btn_wrapper">
                      {isUnsupportChainIdError ? (
                        <Button
                          // disabled={true}
                          kind="primary"
                          fullWidth={true}
                          onClick={() => switchNetwork(supportedNetworkName)}
                          size="large"
                        >
                          {t("switchNetwork")}
                        </Button>
                      ) : (
                        <>
                          {account ? (
                            <Button
                              // disabled={!!error || +toState.value <= 0}
                              kind="primary"
                              fullWidth={true}
                              size="large"
                              onClick={() => {
                                if (hasUnlockTimeToken) {
                                  setShowExtraRewardClaim(true)
                                } else {
                                  setShowConfirmModal([true, "extraClaim"])
                                }
                              }}
                              disabled={
                                disableExtraClaim(MyExtraRewards)
                              }
                            >
                              {t("claimExtraRewards")}
                            </Button>
                          ) : (
                            <Button
                              // disabled={true}
                              kind="primary"
                              fullWidth={true}
                              onClick={(): void => setShowConnectWallet(true)}
                              size="large"
                            >
                              {t("connectWallet")}
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </>
                )}
                {/* 只在mobile显示end */}
              </div>
            </Paper>
          </Grid>
        )}
        
      </Grid>
      <Dialog
        open={!!showConfirmModal[0]}
        onClose={(): void => setShowConfirmModal([false, ""])}
      >
        {showConfirmModal[1] === "deposit" && (
          <ConfirmTransaction
            onClose={(): void => setShowConfirmModal([false, ""])}
            txHash={txHash}
            tokens={[farmData.lpToken]}
            targetContractAddress={FarmContract?.address}
            handler={deposit}
            afterCompleted={() => {
              setLpInput("")
              void getLpTokenBalance()
              // getTotalDeposit()
              // getPool1Data()
              getFarmContractData()
              getLpTokenContractData()
              getExtraRewards()
            }}
          />
        )}
        {showConfirmModal[1] === "withdraw" && (
          <ConfirmTransaction
            onClose={(): void => setShowConfirmModal([false, ""])}
            txHash={txHash}
            // tokens={[lpTokenObj]}
            // targetContractAddress={poolContract.address}
            handler={withdraw}
            afterCompleted={() => {
              setWithdrawInput("")
              setWithdrawRatio(0)
              void getLpTokenBalance()
              // getTotalDeposit()
              // getPool1Data()
              getFarmContractData()
              getLpTokenContractData()
            }}
          />
        )}
        {showConfirmModal[1] === "claim" && (
          <ConfirmTransaction
            onClose={(): void => setShowConfirmModal([false, ""])}
            txHash={txHash}
            showToLockBtn={true}
            // tokens={[lpTokenObj]}
            // targetContractAddress={poolContract.address}
            handler={claim}
            afterCompleted={() => {
              getFarmContractData()
              getLpTokenContractData()
            }}
            completedInfo={getCompletedInfo()}
          />
        )}
        {showConfirmModal[1] === "extraClaim" && (
          <ConfirmTransaction
            onClose={(): void => setShowConfirmModal([false, ""])}
            txHash={txHash}
            // showToLockBtn={true}
            handler={claimExtra}
            afterCompleted={() => {
              void getExtraRewards()
            }}
            // completedInfo={getCompletedInfo()}
          />
        )}
      </Dialog>
      <Dialog
        open={!!showConnectWallet}
        onClose={(): void => setShowConnectWallet(false)}
      >
        <ConnectWallet onClose={(): void => setShowConnectWallet(false)} />
      </Dialog>

      <Dialog
        open={!!showExtraRewardClaim}
        onClose={(): void => setShowExtraRewardClaim(false)}
      >
        <ReviewExtraRewards
          onClose={(): void => setShowExtraRewardClaim(false)}
          onSubmit={(): void => {
            setShowConfirmModal([true, "extraClaim"])
          }}
          forceClaim={forceClaim}
          setForceClaim={setForceClaim}
          MyExtraRewards={MyExtraRewards}
        />
      </Dialog>
    </Container>
  )
}

export default FarmDetail