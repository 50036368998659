/* eslint-disable react/prop-types */
import React, { ReactElement, useEffect, useState } from "react"
import { Box, Dialog, IconButton } from "@mui/material"
import AccountDetails from "./AccountDetails"
import ConnectWallet from "./ConnectWallet"
// import Identicon from "./Identicon"
import { isAddress } from "../utils"
// import { useENS } from "../hooks/useENS"
import { useUDName } from "../hooks/useUDName"
import { useTranslation } from "react-i18next"
import switchNetwork from "../utils/switchNetwork"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import { ChainId } from "../constants"
import { NETWORK_CHAIN_ID } from "../connectors"
import { NETWORK_LABEL } from "../constants/networks"
import { NetworkIconsMap } from "./NetworkIcons"
import styles from "./Web3Status.module.scss"

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
}

const Web3Status = (): ReactElement => {
  const { account, chainId } = useWeb3React()
  // console.log(account);
  const [modalOpen, setModalOpen] = useState(false)
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)
  // const { ensName } = useENS(account)
  const udName = useUDName()
  const { t } = useTranslation()

  const networkLabel: string = NETWORK_LABEL[chainId as ChainId] || "Ethereum"
  const networkIcon: ReactElement | string =
    NetworkIconsMap[chainId as ChainId] || ""

  const { error: Network_error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError

  // always reset to account view
  useEffect(() => {
    if (modalOpen) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [modalOpen])
  const supportedNetworkName =
    NETWORK_LABEL[NETWORK_CHAIN_ID as ChainId] || "Ethereum"
  return (
    <div data-testid="walletStatusContainer">
      {isUnsupportChainIdError ? (
        <Box
          className={styles.btn}
          onClick={() => switchNetwork(supportedNetworkName)}
        >
          {t("switchNetwork")}
        </Box>
      ) : !account ? (
        <Box className={styles.btn} onClick={() => setModalOpen(true)}>
          {t("connectWallet")}
        </Box>
      ) : (
        <Box className={styles.btn} onClick={(): void => setModalOpen(true)}>
          {networkIcon ? (
            <IconButton sx={{ width: 32, height: 32, ml: -1 }}>
              {networkIcon}
            </IconButton>
          ) : networkLabel ? (
            <>{networkLabel} |&nbsp;</>
          ) : (
            ""
          )}

          {udName ||
            (isAddress(account)
              ? `${account.substring(0, 2)}...${account.substring(38)}`
              : "")}
        </Box>
      )}

      <Dialog
        open={modalOpen}
        onClose={(): void => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        {account && walletView === WALLET_VIEWS.ACCOUNT ? (
          <AccountDetails
            openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
            onClose={(): void => setModalOpen(false)}
          />
        ) : (
          <ConnectWallet onClose={(): void => setModalOpen(false)} />
        )}
      </Dialog>
    </div>
  )
}

export default Web3Status
