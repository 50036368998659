import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface VoteState {
  // First time entry page popup. Display popup only when the value is true on mobile
  firstTimePopup: boolean
}

const initialState: VoteState = {
  firstTimePopup: true,
}

export const VoteSlice = createSlice({
  name: "vote",
  initialState,
  reducers: {
    setFirstTimePopup: (state, action: PayloadAction<boolean>) => {
      state.firstTimePopup = action.payload
    },
  },
})

export const { setFirstTimePopup } = VoteSlice.actions

export default VoteSlice.reducer
