import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import baiLogo from "../../assets/icons/bai.png"
import bai4Logo from "../../assets/icons/4bai.svg"

export const BAI_METAPOOL_NAME = "BAI Metapool"

const BAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x0F901f9625fb61D5e93c0c3f1A7032E2d3767883",
  [ChainId.ASTAR]: "0x733ebcC6DF85f8266349DEFD0980f8Ced9B45f35",
})
export const BAI_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x170E2e0939317D60baCEC48d7116498c882430A8",
  [ChainId.ASTAR]: "0xBb47Fb5CcfFB44518D2Cbd3D1468A26329617038",
})
export const BAI_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xD8Bc543273B0E19eed34a295614963720c89f9e4",
  [ChainId.ASTAR]: "0x290c7577D209c2d8DB06F377af31318cE31938fB",
})
export const BAI_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xaeF5474612D9aF124464EB6d5efc77987c7365f4",
  [ChainId.ASTAR]: "0xD18aD1e2992Da974b5A8d69377e6aB3b16e30F29",
})
export const BAI_FARM_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xB433c75F32a75fCDfA4aC117B7A58A787baaD5bd",
  [ChainId.ASTAR]: "0xBbabf2184FbC4DFB17207E74cdB6B1587Dc158a4",
})

export const BAI = new Token(
  BAI_CONTRACT_ADDRESSES,
  18,
  "BAI",
  "bai-stablecoin",
  "BAI Stablecoin",
  baiLogo,
  true,
)
export const BAI_SWAP_TOKEN = new Token(
  BAI_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4BAI",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  bai4Logo,
  false,
  true,
)

export const BAI_POOL_TOKENS = [BAI, ...STABLECOIN_POOL_TOKENS]
export const BAI_UNDERLYING_POOL_TOKENS = [BAI, STABLECOIN_SWAP_TOKEN]

export const BAI_POOL_INFO: Pool = {
  name: BAI_METAPOOL_NAME,
  lpToken: BAI_SWAP_TOKEN,
  poolTokens: BAI_POOL_TOKENS,
  isSynthetic: true,
  addresses: BAI_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: BAI_FARM_ADDRESSES,
  type: PoolTypes.USD,
  route: "bai",
  metaSwapAddresses: BAI_META_SWAP_ADDRESSES,
  underlyingPoolTokens: BAI_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
}
