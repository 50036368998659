import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import wbtcLogo from "../../assets/icons/wbtc.svg"

export const WBTC_METAPOOL_NAME = "WBTC Metapool"

const WBTC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x4d4EA6206279B63e64A58bb09d2C16c763bb5896",
  [ChainId.ASTAR]: "0xad543f18cff85c77e140e3e5e3c3392f6ba9d5ca",
})
export const WBTC_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xF1d94b9C787C54Fc6ad04ecFA69Ec2a99392a5E4",
  [ChainId.ASTAR]: "0xb27157e749d1026a317139BaEf9020CA726b694f",
})
export const WBTC_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xd3c0b0aDea75FbA94ba636dA7917893661973E00",
  [ChainId.ASTAR]: "0xff390905269Ac30eA640dBaBdF5960D7B860f2CF",
})
export const WBTC_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x56bf2a61D7d88585F1C4c79BD0B7470Cd17c9F50",
  [ChainId.ASTAR]: "0xD25Cf814EeE54840A08Db8dfAbFE445B1DE37f0f",
})
export const WBTC_FARM_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x06dF5444dDDD7C45D333CE81B4B516C21236d0Cf",
  [ChainId.ASTAR]: "0x7f2fbBa3dd14Ef24aFA22E92796791D9a38bFBE0",
})

export const WBTC = new Token(
  WBTC_CONTRACT_ADDRESSES,
  8,
  "WBTC",
  "wrapped-bitcoin",
  "Wrapped Bitcoin",
  wbtcLogo,
  true,
)
export const WBTC_SWAP_TOKEN = new Token(
  WBTC_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4WBTC",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  wbtcLogo,
  false,
  true,
)

export const WBTC_POOL_TOKENS = [WBTC, ...STABLECOIN_POOL_TOKENS]
export const WBTC_UNDERLYING_POOL_TOKENS = [WBTC, STABLECOIN_SWAP_TOKEN]

export const WBTC_POOL_INFO: Pool = {
  name: WBTC_METAPOOL_NAME,
  lpToken: WBTC_SWAP_TOKEN,
  poolTokens: WBTC_POOL_TOKENS,
  isSynthetic: true,
  addresses: WBTC_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: WBTC_FARM_ADDRESSES,
  type: PoolTypes.Crypto,
  route: "wbtc",
  metaSwapAddresses: WBTC_META_SWAP_ADDRESSES,
  underlyingPoolTokens: WBTC_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
  unbalancePercent: 50,
}
