import React, { ReactElement } from "react"
import { SnackbarProvider } from "notistack"

interface Props {
  children: ReactElement | ReactElement[]
}

export default function CustomSnackbar(props: Props): ReactElement {
  const { children } = props
  return (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      className="CustomSnackbar"
    >
      {children}
    </SnackbarProvider>
  )
}
