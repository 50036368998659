/* eslint-disable */
import "./ConfirmTransaction.scss"
import "./ConfirmTransaction2.scss"

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react"
import Lottie from "lottie-react"
import { Token, ChainId } from "../constants"
import { useTranslation } from "react-i18next"
import DialogTitle from "./DialogTitle"
import { formatBNToString, getContract } from "../utils"
import ERC20_ABI from "../constants/abis/erc20.json"
import { useActiveWeb3React } from "../hooks"
import { Contract } from "@ethersproject/contracts"
import { MaxUint256 } from "@ethersproject/constants"
import { BigNumber } from "@ethersproject/bignumber"
import { notifyCustomError, notify } from "../utils/notifyHandler"
import { Web3Provider } from "@ethersproject/providers"
import _ from "lodash"
import { useTheme, Box, Stepper, Step, Typography } from "@mui/material"
import { styled } from "@mui/material"
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector"
import completedIcon from "../assets/icons/completed-icon.png"
import sfLoadingJson from "../assets/animation/sf-loading.json"
import failedIcon from "../assets/icons/failed-icon.png"
import Button from "./Button"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import useAddTokenToMetamask from "../hooks/useAddTokenToMetamask"
import { STABLECOIN_SWAP_TOKEN } from "../constants"
import { Link } from "react-router-dom"

interface Props {
  onClose?: any
  transitionStatus: any
  stepNum: number
  // toToken: any
  // toNum: any
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#6C7DFF",
    },
  },
}))

function ConfirmTransaction2({
  onClose,
  transitionStatus,
  stepNum,
}: // toToken, //contants
// toNum,
Props): ReactElement {
  const { account, library, chainId } = useActiveWeb3React()
  const [toNum, setToNum] = useState("")
  const { t } = useTranslation()

  useEffect(() => {
    // console.log(transitionStatus);
    // console.log(transitionStatus.toNum);
    if (transitionStatus.toNum) {
      setToNum(transitionStatus.toNum)
    }
  }, [transitionStatus])

  return (
    <div className="confirmTransaction confirmTransaction2">
      <DialogTitle onClose={onClose}></DialogTitle>
      {/* {transitionStatus.code === -1 && (
        <div>空空</div>
      )}
      {transitionStatus.code === 0 && (
        <>
          {transitionStatus.inApprove && ( //approve
            <div>approve 的 pending</div>
          )}
          {!transitionStatus.inApprove && (
            <div>swap 的 pending + hash</div>
          )}
        </>
      )}
      {transitionStatus.code === 1 && (//成功请空review 层
        <>
          {!transitionStatus.inApprove && (
            <div>swap 的 success + hash + num</div>
          )}
        </>
      )}
      {transitionStatus.code === 2 && (//失败不请空review 层
        <>
          {transitionStatus.inApprove && (
            <div>approve 的 失败 + hash</div>
          )}
          {!transitionStatus.inApprove && (
            <div>swap 的 失败 + hash</div>
          )}
        </>
      )} */}

      {transitionStatus.code !== -1 && (
        <Box className="confirmTransStepBox">
          {!transitionStatus.noStep && (
            <Box
              marginX="auto"
              width={"50%"}
              className="confirmTransactionStep"
            >
              <Stepper
                activeStep={transitionStatus.inApprove ? 1 : 2}
                connector={<QontoConnector />}
              >
                {[...Array(stepNum)].map((e, i) => (
                  <Step key={i}>{i + 1}</Step>
                ))}
              </Stepper>
            </Box>
          )}

          {transitionStatus.code === 0 && ( //pending
            <>
              {transitionStatus.inApprove && ( //approve
                <>
                  <Box marginY={4}>
                    <>
                      <Typography fontSize={20} mb={1} fontWeight={700}>
                        {t("Waitingforapproval")}
                      </Typography>
                      <Typography fontSize={12} fontWeight={400}>
                        {t("confirmTransactionApprovalDesc")}
                      </Typography>
                    </>
                    {/* ) : (
                      <Typography fontSize={20} mb={1} fontWeight={700}>
                        {confirmedHash
                          ? "Transaction Pending"
                          : "Waiting for confirmation"}
                      </Typography>
                    )} */}
                  </Box>
                  <Box className="iconFlexGrow1">
                    <Lottie
                      animationData={sfLoadingJson}
                      loop={true}
                      style={{ width: "64px", height: "64px", margin: "auto" }}
                    />
                  </Box>
                </>
              )}
              {!transitionStatus.inApprove && ( //swap pending
                <>
                  <Box className="confirmTransaction2_main_text" marginX="auto">
                    <Typography fontSize={20} mb={1} fontWeight={700}>
                      {transitionStatus.hash
                        ? t("TransactionPending")
                        : t("Waitingforconfirmation")}
                    </Typography>
                  </Box>
                  <Box className="iconFlexGrow1" sx={{ marginBottom: "15px" }}>
                    <Lottie
                      animationData={sfLoadingJson}
                      loop={true}
                      style={{ width: "64px", height: "64px", margin: "auto" }}
                    />
                  </Box>

                  {transitionStatus.hash && (
                    <Typography>
                      <a
                        className="tx-link"
                        href={getEtherscanLink(
                          transitionStatus.hash,
                          "tx",
                          chainId,
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("ViewDetails")}
                      </a>
                    </Typography>
                  )}
                </>
              )}
            </>
          )}
          {transitionStatus.code === 1 && ( //success清空表单 只有swap没有approve（num TODO）(TODO：加token到钱包)
            <>
              <Typography fontSize={20} mb={1} fontWeight={700}>
                {t("TransactionCompleted")}
              </Typography>
              {/* {transitionStatus.toNum && (
              <Typography fontSize={12} fontWeight={400}>
                You have received {transitionStatus.toNum} usdc
              </Typography>
              )} */}

              {/* <Typography color="#FFCDEC" fontSize={12} fontWeight={400}>
                <span className="add-to-wallet">
                  Add to Wallet
                </span>
              </Typography> */}
              <Box my={4} className="iconFlexGrow1">
                <img src={completedIcon} width={45} height={45} />
              </Box>
              <Box width={"60%"} marginX="auto">
                <Button onClick={() => onClose(true)} className="done-btn">
                  {t("Done")}
                </Button>
              </Box>
              {chainId && transitionStatus.hash && (
                <Box>
                  <Typography>
                    <a
                      className="tx-link"
                      href={getEtherscanLink(
                        transitionStatus.hash,
                        "tx",
                        chainId,
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("ViewDetails")}
                    </a>
                  </Typography>
                </Box>
              )}
            </>
          )}
          {transitionStatus.code === 2 && ( //失败不请空review 层
            <>
              <Typography fontSize={20} mb={1} fontWeight={700}>
                {t("TransactionFailed")}
              </Typography>
              <Box my={4} className="iconFlexGrow1">
                <img src={failedIcon} width={45} height={45} />
              </Box>
              <Box width={"60%"} marginX="auto">
                <Button size="large" className="stake-lp-btn" onClick={onClose}>
                  {t("TryAgain")}
                </Button>
              </Box>
              {chainId && transitionStatus.hash && (
                <Box>
                  <Typography>
                    <a
                      className="tx-link"
                      href={getEtherscanLink(
                        transitionStatus.hash,
                        "tx",
                        chainId,
                      )}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("ViewDetails")}
                    </a>
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      )}
    </div>
  )
}

export default ConfirmTransaction2
