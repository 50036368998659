import React, { ReactElement } from "react"
import { ChainId } from "../constants"

import { ReactComponent as EthSVG } from "./../assets/icons/eth.svg"
import { ReactComponent as HardhatSVG } from "./../assets/icons/hardhat.svg"
import { ReactComponent as ArbitrumSVG } from "./../assets/icons/arbitrum.svg"
import { ReactComponent as OptimismSVG } from "./../assets/icons/optimism.svg"
import { ReactComponent as FantomSVG } from "./../assets/icons/fantom.svg"
import { ReactComponent as BscTestSVG } from "./../assets/icons/bsc_test.svg"
import { ReactComponent as AstarSVG } from "./../assets/icons/astar.svg"

export const NetworkIcons = [
  { key: ChainId.MAINNET, icon: <EthSVG /> },
  { key: ChainId.ROPSTEN, icon: <EthSVG /> },
  { key: ChainId.KOVAN, icon: <EthSVG /> },
  { key: ChainId.HARDHAT, icon: <HardhatSVG /> },
  { key: ChainId.ARBITRUM, icon: <ArbitrumSVG /> },
  { key: ChainId.OPTIMISM, icon: <OptimismSVG /> },
  { key: ChainId.FANTOM, icon: <FantomSVG /> },
  { key: ChainId.BSCTEST, icon: <BscTestSVG /> },
  { key: ChainId.SHIBUYA, icon: <AstarSVG /> },
  { key: ChainId.ASTAR, icon: <AstarSVG /> },
]

export const NetworkIconsMap = NetworkIcons.reduce(
  (o, i) => (o[i.key] = i.icon) && o,
  {} as { [key in ChainId]: ReactElement },
)

export default NetworkIcons
