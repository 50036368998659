import React, { useEffect, useCallback, useState } from "react"
import {
  Grid,
  Container,
  Box,
  Stack,
  Paper,
  Typography,
  IconButton,
  Dialog,
  Divider,
} from "@mui/material"
import {
  POOLS_MAP,
  PoolsMap,
  Pool,
  ChainId,
  Token,
  buildAddresses,
  BUSD,
  SRS_TOKEN,
} from "../constants"
import { useActiveWeb3React } from "../hooks"
import { useDepositRewardsContract } from "../hooks/useContract"
import { getContract, formatBNToString, commify, parseBalance } from "../utils"
import ERC20_ABI from "../constants/abis/erc20.json"
import { Web3Provider } from "@ethersproject/providers"
import { Contract } from "@ethersproject/contracts"
import { shortenAddress } from "../utils/shortenAddress"
import srsLogo from "../assets/icons/logo.svg"
import Button from "./Button"
import { BigNumber } from "ethers"
import _ from "lodash"
import TokenInput from "./TokenInput"
import ConfirmTransaction from "./ConfirmTransaction"
import { notify } from "../utils/notifyHandler"
import { getEtherscanLink } from "../utils/getEtherscanLink"

import "./DepositRewardsItem.scss"

interface Props {
  data: Pool
}

interface ItemProps {
  depositRewardsContract: Contract | null
  tokenContract: Contract
  farmAddress: string
}

const tokens = [BUSD, SRS_TOKEN]

const Item = (props: ItemProps) => {
  const { tokenContract, depositRewardsContract, farmAddress } = props
  const { chainId, library, account } = useActiveWeb3React()

  const [adminAccount, setAdminAccount] = useState("")
  const [symbol, setSymbol] = useState("")
  const [contractBalance, setContractBalance] = useState("")
  const [walletBalance, setWalletBalance] = useState("")
  const [allownce, setAllownce] = useState("")
  const [isAllowWithdrawal, setIsAllowWithdrawal] = useState(false)

  const [depositInput, setDepositInput] = useState("")
  const [withdrawInput, setWithdrawInput] = useState("")
  const [approveInput, setApproveInput] = useState("")
  const [showModal, setShowModal] = useState("")

  const refresh = () => {
    depositRewardsContract
      ?.getBalance(tokenContract.address, farmAddress)
      .then((res: BigNumber) => {
        setContractBalance(_.floor(+formatBNToString(res, 18), 4).toString())
      })

    tokenContract?.balanceOf(account).then((res: BigNumber) => {
      setWalletBalance(_.floor(+formatBNToString(res, 18), 4).toString())
    })

    depositRewardsContract
      ?.allowance(tokenContract.address, farmAddress)
      .then((res: BigNumber) => {
        setAllownce(_.floor(+formatBNToString(res, 18), 4).toString())
      })

    depositRewardsContract
      ?.allowWithdrawal(tokenContract.address, farmAddress)
      .then((res: boolean) => {
        setIsAllowWithdrawal(res)
      })
  }

  useEffect(() => {
    tokenContract?.symbol().then((res: string) => {
      setSymbol(res)
    })
    depositRewardsContract
      ?.tokenAdmin(tokenContract.address, farmAddress)
      .then((res: string) => {
        setAdminAccount(res)
      })
    refresh()
  })

  const deposit = async (): Promise<void> => {
    if (!+depositInput) {
      return
    }
    const transaction: Promise<void> =
      await depositRewardsContract?.depositToken(
        tokenContract.address,
        farmAddress,
        parseBalance(depositInput, 18),
      )
    notify.notification({
      type: "pending",
      message: "Your transaction has been sent to the network",
      autoDismiss: 5000,
    })
    return transaction
  }

  const withdraw = async (): Promise<void> => {
    if (!+withdrawInput) {
      return
    }

    const transaction: Promise<void> =
      await depositRewardsContract?.withdrawToken(
        tokenContract.address,
        farmAddress,
        parseBalance(withdrawInput, 18),
      )
    notify.notification({
      type: "pending",
      message: "Your transaction has been sent to the network",
      autoDismiss: 5000,
    })
    return transaction
  }

  const approve = async (): Promise<void> => {
    if (!+approveInput) {
      return
    }

    const transaction: Promise<void> =
      await depositRewardsContract?.approveReward(
        tokenContract.address,
        farmAddress,
        parseBalance(approveInput, 18),
      )
    notify.notification({
      type: "pending",
      message: "Your transaction has been sent to the network",
      autoDismiss: 5000,
    })
    return transaction
  }

  const afterCompleted = () => {
    setDepositInput("")
    setWithdrawInput("")
    setApproveInput("")
    refresh()
  }

  const token =
    tokens.find(
      (item: Token) =>
        tokenContract.address.toLocaleLowerCase() ===
        item.addresses[chainId || ChainId.ASTAR].toLocaleLowerCase(),
    ) ||
    new Token(
      buildAddresses({
        [chainId || ChainId.ASTAR]: tokenContract.address,
      }),
      18,
      symbol,
      "saddle-dao", // TBD
      "Saddle DAO",
      srsLogo,
      false,
      false,
    )

  if (!account || !adminAccount || adminAccount !== account) {
    return null
  }

  return (
    <>
      <Box display="flex" gap={4} gridAutoFlow="column" className="token-item" pb={5}>
        <Box flex={1}>
          <Box mb={2} display="flex" alignItems="center">
            <img src={token.icon} width={40} height={40} />
            <Typography fontSize={16} fontWeight={700} ml={1}>
              {symbol}
            </Typography>
          </Box>
          <Box display="grid" gap={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={14}>Farm Contract Address:</Typography>
              <Typography fontSize={14}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#FFCDEC", textDecoration: "none" }}
                  href={getEtherscanLink(farmAddress, "address", chainId)}
                >
                  {shortenAddress(farmAddress)}
                </a>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={14}>Token Contract Address:</Typography>
              <Typography fontSize={14}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{ color: "#FFCDEC", textDecoration: "none" }}
                  href={getEtherscanLink(
                    tokenContract.address,
                    "address",
                    chainId,
                  )}
                >
                  {shortenAddress(tokenContract.address)}
                </a>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={14}>Contract Balance:</Typography>
              <Typography fontSize={14}>{commify(contractBalance)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={14}>Wallet Balance:</Typography>
              <Typography fontSize={14}>{commify(walletBalance)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography fontSize={14}>Allowance:</Typography>
              <Typography fontSize={14}>{allownce}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>Allow withdraw:</Typography>
              <Typography>{isAllowWithdrawal ? "true" : "false"}</Typography>
            </Box>
          </Box>
        </Box>
        <Box flex={1}>
          <Box mb={2}>
            <TokenInput
              inputValue={depositInput}
              onChange={(val) => setDepositInput(val)}
              symbol={symbol}
              icon={token.icon}
              max={walletBalance}
              showBorderBottom={false}
            />
            <Button
              onClick={() => setShowModal("deposit")}
              disabled={!+depositInput || +depositInput > +walletBalance}
            >
              Deposit
            </Button>
          </Box>
          {isAllowWithdrawal && (
            <Box mb={2}>
              <TokenInput
                inputValue={withdrawInput}
                onChange={(val) => setWithdrawInput(val)}
                symbol={symbol}
                icon={token.icon}
                max={contractBalance}
                showBorderBottom={false}
              />
              <Button
                onClick={() => setShowModal("withdraw")}
                disabled={!withdrawInput || +withdrawInput > +contractBalance}
              >
                Withdraw
              </Button>
            </Box>
          )}
          <Box>
            <TokenInput
              inputValue={approveInput}
              onChange={(val) => setApproveInput(val)}
              symbol={symbol}
              icon={token.icon}
              showMax={false}
              showBorderBottom={false}
            />
            <Button
              onClick={() => setShowModal("approve")}
              disabled={!+approveInput}
            >
              Approve
            </Button>
          </Box>
        </Box>
      </Box>
      <Dialog
        open={!!showModal}
        onClose={(): void => setShowModal("")}
        scroll="body"
        sx={{
          border: "0px",
        }}
      >
        {showModal === "deposit" && (
          <ConfirmTransaction
            tokens={[token]}
            handler={deposit}
            targetContractAddress={depositRewardsContract?.address}
            afterCompleted={afterCompleted}
            onClose={() => setShowModal("")}
          />
        )}
        {showModal === "withdraw" && (
          <ConfirmTransaction
            // tokens={[token]}
            handler={withdraw}
            // targetContractAddress={depositRewardsContract?.address}
            afterCompleted={afterCompleted}
            onClose={() => setShowModal("")}
          />
        )}
        {showModal === "approve" && (
          <ConfirmTransaction
            // tokens={[token]}
            handler={approve}
            // targetContractAddress={depositRewardsContract?.address}
            afterCompleted={afterCompleted}
            onClose={() => setShowModal("")}
          />
        )}
      </Dialog>
    </>
  )
}

const DepositRewardsItem = (props: Props) => {
  const { data } = props
  const { chainId, library, account } = useActiveWeb3React()
  const depositRewardsContract = useDepositRewardsContract()
  const [rewardTokenAddresses, setRewardTokenAddresses] = useState<
    Array<string>
  >([])
  const [rewardTokens, setRewardTokens] = useState<Array<Contract>>([])

  const farmAddress = data?.farmAddresses
    ? data.farmAddresses[chainId || ChainId.ASTAR]
    : ""

  const getRewardTokenAddresses = useCallback(async () => {
    if (!data?.farmAddresses) {
      return
    }

    try {
      const ret: Array<string> = await depositRewardsContract?.rewardList(
        farmAddress,
        0,
        10,
      )
      if (ret) {
        setRewardTokenAddresses(ret)
      }
    } catch (error) {
      // todo
    }
  }, [data, depositRewardsContract])

  const getRewardTokens = useCallback(() => {
    const list = rewardTokenAddresses.map((address) => {
      const tokenContract = getContract(
        address,
        ERC20_ABI,
        library as Web3Provider,
        account ?? undefined,
      )
      // const symbol = await tokenContract?.symbol()
      return tokenContract
    })
    setRewardTokens(list)
  }, [rewardTokenAddresses])

  useEffect(() => {
    void getRewardTokenAddresses()
  }, [getRewardTokenAddresses])

  useEffect(() => {
    if (!rewardTokenAddresses.length) {
      return
    }
    void getRewardTokens()
  }, [getRewardTokens, rewardTokenAddresses])

  if (!rewardTokenAddresses.length) {
    return null
  }

  return (
    <Box className="DepositRewardsItem" p={2} mb={4}>
      <Box mb={4}>
        <Typography fontSize={20} fontWeight={700}>
          {data.name}
        </Typography>
      </Box>
      {rewardTokens.map((tokenContract: Contract, index: number) => {
        return (
          <>
            <Item
              depositRewardsContract={depositRewardsContract}
              tokenContract={tokenContract}
              farmAddress={farmAddress}
              key={index}
            />
          </>
        )
      })}
    </Box>
  )
}

export default DepositRewardsItem
