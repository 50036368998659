import "./ConnectWallet.scss"

import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import React, { ReactElement } from "react"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { SUPPORTED_WALLETS } from "../constants"
import { logEvent } from "../utils/googleAnalytics"
import { map } from "lodash"
import { useTranslation } from "react-i18next"
import { notify } from "../utils/notifyHandler"
import { setConnectorName } from "../connectors"

interface Props {
  onClose: () => void
}

function ConnectWallet({ onClose }: Props): ReactElement {
  const { activate } = useWeb3React()
  const { t } = useTranslation()

  return (
    <>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "#ebebeb",
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="connectWallet" data-testid="connectWalletContainer">
        <h3>{t("connectWallet")}</h3>
        <div className="walletList">
          {map(SUPPORTED_WALLETS, (wallet, index) => (
            <button
              key={index}
              onClick={(): void => {
                setConnectorName(wallet.globalName)
                activate(wallet.connector, undefined, true).catch((error) => {
                  notify.notification({
                    type: "error",
                    message: error.message,
                    autoDismiss: 5000,
                  })
                  if (error instanceof UnsupportedChainIdError) {
                    void activate(wallet.connector) // a little janky...can't use setError because the connector isn't set
                  } else {
                    // TODO: handle error
                  }
                })
                logEvent("change_wallet", { name: wallet.name })
                onClose()
              }}
            >
              <span>{wallet.name}</span>
              <img src={wallet.icon} alt="icon" className="icon" />
            </button>
          ))}
        </div>
        <p>
          <a href="https://docs.sirius.finance/" target="blank">
            {t("connectWalletRisk")}
          </a>

          {/* <a href="https://ethereum.org/en/wallets/" target="blank">
            {t("getWallet")}
          </a> */}
        </p>
      </div>
    </>
  )
}

export default ConnectWallet
