import { BaseProvider, getDefaultProvider } from "@ethersproject/providers"

import { InjectedConnector } from "@web3-react/injected-connector"
import { NetworkConnector } from "@web3-react/network-connector"
import { UAuthConnector } from "@uauth/web3-react"
import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
import { WalletLinkConnector } from "@web3-react/walletlink-connector"
import { TalismanConnector } from "@talismn/web3react-v6-connector"
import { SubWalletConnector } from "@subwallet/web3-react-subwallet-connector-v6"

const NETWORK_URL = process.env.REACT_APP_NETWORK_URL

export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? "1",
)

if (typeof NETWORK_URL === "undefined") {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`,
  )
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
})

let networkLibrary: BaseProvider | undefined
export function getNetworkLibrary(): BaseProvider {
  const provider = getDefaultProvider(NETWORK_URL)
  return (networkLibrary = networkLibrary ?? provider)
}

function createInjectedMetaMaskProvider() {
  return new InjectedConnector({
    // mainnet, ropsten, rinkeby, goerli, optimism, kovan, fantom, local buidler
    // see: https://github.com/ethereum/EIPs/blob/master/EIPS/eip-155.md
    supportedChainIds: [592],
  })
}

export const injectedMetaMaskProvider = createInjectedMetaMaskProvider()

export const walletconnect = new WalletConnectConnector({
  rpc: { [NETWORK_CHAIN_ID]: NETWORK_URL },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  supportedChainIds: [592],
  // chainId: NETWORK_CHAIN_ID,
  // pollingInterval: POLLING_INTERVAL / 12000
})

export const uauth = new UAuthConnector({
  clientID: process.env.REACT_APP_UD_CLIENT_ID,
  clientSecret: process.env.REACT_APP_UD_CLIENT_SECRET,
  redirectUri: process.env.REACT_APP_UD_REDIRECT_URI,
  postLogoutRedirectUri: process.env.REACT_APP_UD_POST_LOGOUT_REDIRECT_URI,

  // Scope must include openid and wallet
  scope: "openid wallet",
  connectors: {
    injected: new InjectedConnector({ supportedChainIds: [592] }),
    walletconnect,
  },
})

export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: "Sirius",
})

export const injectedTalismanProvider = new TalismanConnector({
  supportedChainIds: [81, 592],
})

export const injectedSubWalletProvider = new SubWalletConnector({
  supportedChainIds: [81, 592],
})

const CONNECTOR_NAME = "connector_name"
export const setConnectorName = (name = "") =>
  localStorage.setItem(CONNECTOR_NAME, name)

export const getConnectorName = () =>
  localStorage.getItem(CONNECTOR_NAME) || "ethereum"

export const getCurrProvider = () => {
  const that = window as any
  const currConnector = that[getConnectorName()] || that.ethereum
  let currProvider = injectedMetaMaskProvider as any
  if (currConnector?.isMetaMask) {
    currProvider = injectedMetaMaskProvider
  } else if (currConnector?.isTalisman) {
    currProvider = injectedTalismanProvider
  } else if (currConnector?.isSubWallet && currConnector?.connected) {
    currProvider = injectedSubWalletProvider
  }
  return { currConnector, currProvider }
}
