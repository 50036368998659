import "./AdvancedOptions.scss"
import {
  Box,
  // Checkbox,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton,
} from "@mui/material"
import { Close as CloseIcon, Settings } from "@mui/icons-material"
import { Deadlines, GasPrices, Slippages } from "../state/user"
import React, { ReactElement } from "react"
import {
  updateGasPriceCustom,
  updateGasPriceSelected,
  // updateInfiniteApproval,
  updateSlippageCustom,
  updateSlippageSelected,
  updateTransactionDeadlineCustom,
  updateTransactionDeadlineSelected,
} from "../state/user"
import { useDispatch, useSelector } from "react-redux"

import { AppDispatch } from "../state"
import { AppState } from "../state/index"
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import { PayloadAction } from "@reduxjs/toolkit"
import { useTranslation } from "react-i18next"
import CustomPopover from "./CustomPopover"
import Button from "./Button"
// import { ReactComponent as CloseSVG } from "./../assets/icons/close.svg"

export default function AdvancedOptions(): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const {
    // infiniteApproval,
    slippageCustom,
    slippageSelected,
    transactionDeadlineSelected,
    transactionDeadlineCustom,
    gasCustom,
    gasPriceSelected,
    // userPoolAdvancedMode: advanced,
  } = useSelector((state: AppState) => state.user)
  // const [advanced, setAdvanced] = useState(false)

  const handleSlippage = (
    event: React.MouseEvent<HTMLElement>,
    slippageValue: Slippages,
  ) => {
    // console.log(slippageValue);
    if (slippageValue) dispatch(updateSlippageSelected(slippageValue))
  }

  const handleDeadline = (
    event: React.MouseEvent<HTMLElement>,
    deadlineValue: Deadlines,
  ) => {
    // toggle button return null value when toggle button is selected.
    if (deadlineValue)
      dispatch(updateTransactionDeadlineSelected(deadlineValue))
  }

  const handleGasPriceInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value || ""
    value = value.replace(/[^0-9.]/g, "")
    const decimal = value.split(".")[1] || "0"
    const extraDecimal = decimal.length - 3
    if (extraDecimal > 0) value = value.slice(0, -extraDecimal)
    // const value =
    //   Math.round(+e.target.value) > 0
    //     ? Math.round(+e.target.value).toString()
    //     : ""
    if (value && !isNaN(+value)) {
      dispatch(updateGasPriceCustom(value))
      if (gasPriceSelected !== GasPrices.Custom) {
        dispatch(updateGasPriceSelected(GasPrices.Custom))
      }
    } else {
      dispatch(updateGasPriceSelected(GasPrices.Fast))
    }
  }

  const [anchorPopover, setAnchorPopover] = React.useState<Element | null>(null)

  return (
    <>
      <Box data-testid="advOptionContainer" className="settingFrame">
        <IconButton onClick={(e) => setAnchorPopover(e.currentTarget)} sx={{ p: 0 }}>
          <Settings />
        </IconButton>

        <CustomPopover
          open={Boolean(anchorPopover)}
          onClose={() => setAnchorPopover(null)}
          PopoverProps={{
            open: Boolean(anchorPopover),
            anchorEl: anchorPopover,
            anchorOrigin: { vertical: "bottom", horizontal: "right" },
            transformOrigin: { vertical: "top", horizontal: "right" },
          }}
          className="adv_paper_container"
        >
          <div data-testid="advTableContainer" className="adv_paper">
            <Typography
              variant="subtitle2"
              sx={{
                marginBottom: "10px",
                fontWeight: "bold",
                fontSize: { xs: 20, sm: 16 },
              }}
            >
              {t("advancedSetup")}
            </Typography>
            <IconButton
              onClick={() => setAnchorPopover(null)}
              sx={{
                position: "absolute",
                right: 16,
                top: 16,
                display: { sm: "none" },
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box mb={2}>
              <Typography variant="subtitle2" mt={2} mb={1}>
                {t("maxSlippage")}
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                data-testid="maxSlippageInputGroup"
              >
                <ToggleButtonGroup
                  size="small"
                  fullWidth
                  exclusive
                  value={slippageSelected}
                  onChange={handleSlippage}
                  className="AdvBtnGroup"
                >
                  <ToggleButton size="small" value={Slippages.OneTenth}>
                    0.1%
                  </ToggleButton>
                  <ToggleButton value={Slippages.One}>1%</ToggleButton>
                </ToggleButtonGroup>
                <TextField
                  value={slippageCustom?.valueRaw}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={slippageCustom?.valueRaw ? "" : "noValue"}
                      >
                        %
                      </InputAdornment>
                    ),
                  }}
                  placeholder={t("Customize")}
                  size="small"
                  className={
                    slippageCustom?.valueRaw
                      ? "advBtnGroup_input"
                      : "advBtnGroup_input noValue"
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    const value = e.target.value
                    if (value && !isNaN(+value)) {
                      dispatch(updateSlippageCustom(value))
                      if (slippageSelected !== Slippages.Custom) {
                        dispatch(updateSlippageSelected(Slippages.Custom))
                      }
                    } else {
                      dispatch(updateSlippageSelected(Slippages.OneTenth))
                    }
                  }}
                  // sx={{ width: 150 }}
                />
              </Stack>
            </Box>

            <Typography variant="subtitle2" mt={2} mb={1}>
              {t("txnDeadline")} (MIN)
            </Typography>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              data-testid="txnDeadlineInputGroup"
            >
              <ToggleButtonGroup
                size="small"
                fullWidth
                exclusive
                value={transactionDeadlineSelected}
                onChange={handleDeadline}
                className="AdvBtnGroup"
              >
                <ToggleButton value={Deadlines.Twenty}>20</ToggleButton>
                <ToggleButton value={Deadlines.Forty}>40</ToggleButton>
              </ToggleButtonGroup>
              <TextField
                type="text"
                variant="outlined"
                size="small"
                className={
                  transactionDeadlineCustom
                    ? "advBtnGroup_input"
                    : "advBtnGroup_input noValue"
                }
                placeholder={t("Customize")}
                value={transactionDeadlineCustom}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  const value = e.target.value
                  if (value && !isNaN(+value)) {
                    dispatch(updateTransactionDeadlineCustom(value))
                    if (transactionDeadlineSelected !== Deadlines.Custom) {
                      dispatch(
                        updateTransactionDeadlineSelected(Deadlines.Custom),
                      )
                    }
                  } else {
                    dispatch(
                      updateTransactionDeadlineSelected(Deadlines.Twenty),
                    )
                  }
                }}
                sx={{ width: { sm: 150 } }}
              />
            </Stack>

              <Typography variant="subtitle2" mt={2} mb={1}>
                {t("gasPrice")} (GWEI)
              </Typography>
              <Stack spacing={2}>
                <ToggleButtonGroup
                  size="small"
                  value={gasPriceSelected}
                  exclusive
                  fullWidth
                  className="AdvBtnGroup"
                  onChange={(e, value) =>
                    value && dispatch(updateGasPriceSelected(value))
                  }
                >
                  {[GasPrices.Standard, GasPrices.Fast, GasPrices.Instant].map(
                    (value) => (
                      <ToggleButton key={value} value={value} sx={{ flex: 1 }}>
                        {t(value)}
                      </ToggleButton>
                    ),
                  )}
                </ToggleButtonGroup>
                <TextField
                  type="number"
                  value={gasCustom?.valueRaw}
                  variant="outlined"
                  size="small"
                  className={`advBtnGroup_input gas_input ${
                    gasCustom?.valueRaw ? "" : "noValue"
                  }`}
                  placeholder={t("Customize")}
                  onChange={handleGasPriceInput}
                />
              </Stack>

            <div>
              <Button
                kind="secondary"
                fullWidth={true}
                className="temp_secondary"
                onClick={() => setAnchorPopover(null)}
              >
                {t("Done")}
              </Button>
            </div>
          </div>
        </CustomPopover>
      </Box>
    </>
  )
}
