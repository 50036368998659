/* eslint-disable */
import React, {
  useEffect,
  useState,
  ReactElement,
  useRef,
  useCallback,
} from "react"
import { Link as RouterLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import ECharts, { EChartsOption } from "echarts-for-react"
import Countdown from "react-countdown"
import moment from "moment"
import { BigNumber } from "@ethersproject/bignumber"
import {
  useTheme,
  useMediaQuery,
  Container,
  Stack,
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  Dialog,
  Link,
  Drawer,
  IconButton,
} from "@mui/material"
import { Close as CloseIcon, ExpandMore, ArrowBack } from "@mui/icons-material"
import { useSnackbar } from "notistack"
import classnames from "classnames"
import { formatBNToString, commify } from "../utils"
import { notify } from "../utils/notifyHandler"
import { getEtherscanLink } from "../utils/getEtherscanLink"
import CustomPopover from "../components/CustomPopover"
import CustomTooltip from "../components/CustomTooltip"
import CustomConfirm from "../components/CustomConfirm"
import TrendIcon from "../components/TrendIcon"
import { BottomPadding } from "../components/MobileBottomBar"
import ConfirmTransaction from "../components/ConfirmTransaction"
import { shortenAddress } from "../utils/shortenAddress"
import { getLastThursday, getUpcomingThursday } from "../utils/dateTime"
import { useActiveWeb3React } from "../hooks"
import { useFarmsControllerContract } from "../hooks/useContract"
import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg"
import { ReactComponent as ArrowRightIcon } from "../assets/icons/arrow-right.svg"
import { ReactComponent as VoteIcon } from "../assets/icons/vote.svg"
import VoteInfoIcon from "../assets/icons/vote_info.png"
import { useVoteUserPower, useBaseAprData } from "../hooks/useFarmData"
import { AppState } from "../state"
import { setFirstTimePopup } from "./VoteSlice"
import {
  useRewardRateList,
  PoolItem,
  usePoolList,
  useUsdPoolData,
  useUsedPower,
  useAllUsedPower,
  useLastVoteTime,
  useVeTokenMinted,
  useVeTokenVoted,
  useVeSRSBalance,
  useVeSRSUnlockTime,
} from "./Vote.controller"
import styles from "./Vote.module.scss"
import { PoolName } from "../constants"
import { useGasPrice } from "../hooks/useGas"

const DAYS_AFTER_LAST_VOTE = 10

const PageDesc = (): ReactElement | null => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))
  const { firstTimePopup } = useSelector((state: AppState) => state.vote)
  const dispatch = useDispatch()

  if (!isDownSm || firstTimePopup === false) return null

  return (
    <Drawer
      anchor="bottom"
      open={firstTimePopup}
      onClose={() => dispatch(setFirstTimePopup(false))}
      PaperProps={{
        sx: { borderWidth: "1px 0 0 0", borderRadius: "16px 16px 0 0", p: 4 },
      }}
    >
      <IconButton
        onClick={() => dispatch(setFirstTimePopup(false))}
        sx={{ position: "absolute", right: 24, top: 24 }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ pb: 2, textAlign: "center" }}>
        <Typography variant="h2" mb={3}>
          {t("vote")}
        </Typography>
        <img src={VoteInfoIcon} className={styles.LeftBoxIcon} />
        <Typography variant="subtitle2" mt={3}>
          {t("votePageBrief")}
        </Typography>
        <Typography variant="body2" mt={2}>
          {t("votingTips")}:&nbsp;
          <Link
            underline="none"
            href="https://docs.sirius.finance/faq/how-does-the-voting-mechanism-work"
            target="_blank"
            className="g-link"
          >
            https://docs.sirius.finance
          </Link>
        </Typography>
      </Box>
    </Drawer>
  )
}

const DoughnutChart = (props: { selectedPool?: PoolItem }): ReactElement => {
  const { selectedPool } = props
  const theme = useTheme()
  const [rewardRateList, getRewardRateList] = useRewardRateList()

  const echartRef = useRef(null)

  const getOption = () => {
    return {
      darkMode: true,
      // color: ["#F7931A", "#6579DC", "#A9D517", "#FFCDEC"],
      legend: {
        type: "scroll",
        orient: "horizontal",
        pageIconColor: theme.palette.mute.main,
        pageTextStyle: { color: theme.palette.mute.main },
        bottom: 0,
        left: "center",
        selectedMode: false,
        itemWidth: 16,
        itemHeight: 16,
        formatter(name: PoolName) {
          const item = rewardRateList.find((i) => i.name === name)
          return `${name}\n${item?.value}%`
        },
        textStyle: {
          color: theme.palette.mute.main,
        },
      },
      series: [
        {
          name: "Farm",
          type: "pie",
          center: ["50%", "45%"],
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 20,
              fontWeight: "bold",
              // color: theme.palette.mute.main,
              formatter: "{b}\n{c}%",
            },
          },
          data: rewardRateList,
        },
      ],
    }
  }

  const echartInstance = (echartRef?.current as any)?.getEchartsInstance()
  let currIndex = 0
  // if (rewardRateList.length) {
  //   const nums = rewardRateList.map((i) => i.value)
  //   currIndex = nums.indexOf(Math.max(...nums))
  // }
  useEffect(() => {
    setTimeout(() => {
      echartInstance?.dispatchAction({ type: "highlight", dataIndex: 0 })
    }, 300)
    echartInstance?.on("mouseover", function (e: any) {
      if (e.dataIndex != currIndex) {
        echartInstance.dispatchAction({
          type: "downplay",
          dataIndex: currIndex,
        })
      }
    })
    echartInstance?.on("mouseout", function (e: any) {
      currIndex = e.dataIndex
      echartInstance.dispatchAction({
        type: "highlight",
        dataIndex: e.dataIndex,
      })
    })
  }, [echartInstance, rewardRateList])

  useEffect(() => {
    if (!rewardRateList.length || !selectedPool?.lpTokenName) return
    rewardRateList.map((item, index) => {
      const type =
        item.name === selectedPool.lpTokenName ? "highlight" : "downplay"
      echartInstance?.dispatchAction({ type, dataIndex: index })
    })
  }, [selectedPool, rewardRateList])

  return <ECharts ref={echartRef} option={getOption()} />
}

const LeftBox = (props: {
  totalVotingPower: BigNumber
  totalVotedPower: BigNumber
  myVotingPower: BigNumber
  myUsedVotingPower: number
}): ReactElement => {
  const {
    totalVotingPower,
    totalVotedPower,
    myVotingPower,
    myUsedVotingPower,
  } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const ThuCountdown = getUpcomingThursday()

  return (
    <Paper
      variant="outlined"
      className={styles.LeftBox}
      sx={{
        [theme.breakpoints.down("sm")]: {
          border: "none",
          background: "none",
          backdropFilter: "unset",
        },
      }}
    >
      <Box sx={{ display: { xs: "none", sm: "block" } }}>
        <img src={VoteInfoIcon} className={styles.LeftBoxIcon} />
        <Typography variant="subtitle2" sx={{ mt: 4 }}>
          {t("votePageBrief")}
        </Typography>
      </Box>
      <Typography variant="body2" sx={{ mt: { sm: 3 } }}>
        {t("totalVotingPower")}
      </Typography>
      <Typography variant="h2">
        {commify(formatBNToString(totalVotingPower, 18))}
      </Typography>
      <Typography variant="body2" sx={{ mt: 3 }}>
        {t("Total Voted Power")}
      </Typography>
      <Typography variant="h2">
        {commify(formatBNToString(totalVotedPower, 36))}
      </Typography>
      <Typography variant="body2" sx={{ mt: 3 }}>
        {t("thisWeekVotingEndingIn")}
      </Typography>
      <Typography variant="h2">
        <Countdown date={ThuCountdown.valueOf()} className={styles.Countdown} />
      </Typography>
      <Typography variant="body2" className={styles.TimeLabel}>
        {t("days")}
        <span className={styles.TimeSpace} />
        {t("hours")}
        <span className={styles.TimeSpace} />
        {t("mins")}
        <span className={styles.TimeSpace} />
        {t("secs")}
      </Typography>
      <Typography variant="body2" sx={{ mt: 3 }}>
        {t("myTotalVotingPower")}
      </Typography>
      <Typography variant="h2">
        {commify(formatBNToString(myVotingPower, 18))}
      </Typography>
      {!!myUsedVotingPower && (
        <>
          <Typography variant="body2" sx={{ mt: 3 }}>
            {t("myUsedVotingPower")}
          </Typography>
          <Typography variant="h2">
            {`${commify(myUsedVotingPower.toString())}%`}
          </Typography>
        </>
      )}
      <RouterLink to="/governance">
        <Typography variant="body2" sx={{ mt: 3 }} className="g-link">
          {t("gainVotingPower")}
        </Typography>
      </RouterLink>
    </Paper>
  )
}

const VotingTips = (): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  return (
    <Paper
      variant="outlined"
      className={styles.BoxPadding}
      sx={{
        mt: { sm: 3 },
        [theme.breakpoints.down("sm")]: {
          border: "none",
          background: "none",
          backdropFilter: "unset",
        },
      }}
    >
      <Typography variant="body2">
        {t("votingTips")}:&nbsp;
        <Link
          underline="none"
          href="https://docs.sirius.finance/faq/how-does-the-voting-mechanism-work"
          target="_blank"
          className="g-link"
        >
          https://docs.sirius.finance
        </Link>
      </Typography>
    </Paper>
  )
}

const SelectPoolBox = (props: {
  allUsedPower: useAllUsedPower
  selected?: PoolItem
  onSelect?: (item: PoolItem) => void
}): ReactElement => {
  const { allUsedPower, selected, onSelect } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [anchorPopover, setAnchorPopover] = React.useState<Element | null>(null)
  const poolDataList = usePoolList()

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        className="pointer"
        onClick={(e) => setAnchorPopover(e.currentTarget)}
      >
        {!!selected?.tokens?.length && (
          <Stack direction="row" spacing={-1} alignItems="center">
            {selected?.tokens?.map(({ symbol, icon }) => (
              <img key={symbol} className={styles.TokenIcon} src={icon} />
            ))}
          </Stack>
        )}

        <Typography
          variant="h2"
          sx={{
            color: selected?.lpTokenName ? "" : theme.palette.primary.main,
          }}
        >
          {selected?.lpTokenName || t("Select a pool")}
        </Typography>
        <Box
          className={classnames(
            styles.ArrowDown,
            anchorPopover && styles.ArrowDownActive,
          )}
        >
          <ArrowDownIcon className={styles.ArrowDownIcon} />
        </Box>
      </Stack>

      <CustomPopover
        open={Boolean(anchorPopover)}
        onClose={() => setAnchorPopover(null)}
        PopoverProps={{
          open: Boolean(anchorPopover),
          anchorEl: anchorPopover,
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
        }}
      >
        <Box sx={{ py: 2 }}>
          {poolDataList.map((item, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={3}
              className={styles.SelectOption}
              onClick={(e) => !void setAnchorPopover(null) && onSelect?.(item)}
            >
              <Stack direction="row" spacing={1}>
                <Stack direction="row" spacing={-1} alignItems="center">
                  {item.tokens?.map(({ symbol, icon }) => (
                    <img key={symbol} className={styles.TokenIcon} src={icon} />
                  ))}
                </Stack>
                <Typography variant="h2" color={item.isOutdated ? '#ababab' : '#ebebeb'}>
                  {item.lpTokenName}
                  {
                    item.isOutdated && (
                      <Typography fontSize={12}>(Archived)</Typography>
                    )
                  }
                </Typography>
              </Stack>
              {!!allUsedPower[item.name] && (
                <Typography>{allUsedPower[item.name]}%</Typography>
              )}
            </Stack>
          ))}
        </Box>
      </CustomPopover>
    </>
  )
}

type VoteDialogBoxProps = {
  selectedPool?: PoolItem
  selectedBalPct?: number
  calcBal?: string
  afterCompleted?: () => void
}
const VoteDialogBox = (props: VoteDialogBoxProps): ReactElement => {
  const { selectedPool, selectedBalPct, calcBal, afterCompleted } = props
  const { t } = useTranslation()

  const { enqueueSnackbar } = useSnackbar()
  const warn = (msg: string) => enqueueSnackbar(msg, { variant: "warning" })

  const [showDialog, setShowDialog] = useState(false)
  const [warmTips, setWarmTips] = useState(false)
  const farmContract = useFarmsControllerContract()
  const gasPrice = useGasPrice()

  const validate = () => {
    if (!farmContract) return warn(t("farmContractIsNotConfiguredYet"))
    if (!selectedPool?.farmAddress) return warn(t("noFarmAddress"))
    setWarmTips(true)
  }

  const voteAction = async (): Promise<void> => {
    const transaction: Promise<void> = await farmContract?.voteForGaugeWeights(
      selectedPool?.farmAddress,
      (selectedBalPct || 0) * 100,
      { gasPrice: gasPrice },
    )
    notify.notification({
      type: "pending",
      message: t("yourTransHasBeenSentToNetwork"),
      autoDismiss: 5000,
    })
    return transaction
  }

  const getCompletedInfo = (): ReactElement => (
    <Typography fontSize={12}>
      {t("voteCompletedInfo", {
        balance: commify(calcBal || "0"),
        lpTokenName: selectedPool?.lpTokenName,
      })}
    </Typography>
  )

  return (
    <>
      <Box className={styles.VoteBtn} onClick={() => validate()}>
        {t("voteToThisPool")}
      </Box>

      <CustomConfirm
        title={t("Kindly Reminder")}
        content={t("voteKindlyReminderTooltip", {
          days: DAYS_AFTER_LAST_VOTE,
          balance: commify(calcBal || "0"),
          lpTokenName: selectedPool?.lpTokenName,
        })}
        open={warmTips}
        onClose={() => setWarmTips(false)}
        onConfirm={() => {
          setWarmTips(false)
          setShowDialog(true)
        }}
      />

      <Dialog open={!!showDialog} onClose={() => setShowDialog(false)}>
        <ConfirmTransaction
          targetContractAddress={selectedPool?.farmAddress}
          handler={voteAction}
          afterCompleted={afterCompleted}
          onClose={() => setShowDialog(false)}
          completedInfo={getCompletedInfo()}
        />
      </Dialog>
    </>
  )
}

const RightBox = (props: {
  myBalance: BigNumber
  myUsedVotingPower: number
  refresh?: () => void
}): ReactElement => {
  const { myBalance, myUsedVotingPower, refresh } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const { chainId } = useActiveWeb3React()
  const lastThursday = getLastThursday()
  const [selectedPool, setSelectedPool] = useState<PoolItem>({} as PoolItem)

  // initial select 4SRS
  const [hasInitSelectPool, setHasInitSelectPool] = useState(false)
  const usdPoolData = useUsdPoolData()
  useEffect(() => {
    if (hasInitSelectPool || !usdPoolData.name) return
    setSelectedPool(usdPoolData)
    setHasInitSelectPool(true)
  }, [usdPoolData])

  const upcomThu = getUpcomingThursday().unix()
  const [baseAprData, getBaseAprData] = useBaseAprData({
    poolName: selectedPool?.name,
    tempPools: true,
  })
  const [baseAprUpcom, getBaseAprUpcom] = useBaseAprData({
    poolName: selectedPool?.name,
    timeStamp: upcomThu,
    tempPools: true,
  })
  const farmAddress = selectedPool?.farmAddress
  const [poolUsedPower, getPoolUsedPower] = useUsedPower(farmAddress)
  const [allUsedPower, getAllUsedPower] = useAllUsedPower()
  const [lastVoteTime, getLastVoteTime] = useLastVoteTime(farmAddress)
  // Selected balance percentage
  const [selectedBalPct, setSelectedBalPct] = useState(0)

  const [poolUsedBal, setPoolUsedBal] = useState("0")
  useEffect(() => {
    const bal = formatBNToString(
      myBalance.mul(BigNumber.from(poolUsedPower || 0)).div(100),
      18,
    )
    setPoolUsedBal(bal)
  }, [myBalance, poolUsedPower])

  const [calcBal, setCalcBal] = useState("0")
  useEffect(() => {
    const bal = formatBNToString(
      myBalance.mul(BigNumber.from(selectedBalPct)).div(100),
      18,
    )
    setCalcBal(bal)
  }, [myBalance, selectedBalPct])

  const inLastVoteLimit =
    lastVoteTime &&
    moment().diff(moment.unix(lastVoteTime), "days") < DAYS_AFTER_LAST_VOTE

  const remainingVotingPower =
    100 - (myUsedVotingPower || 0) + (poolUsedPower || 0)

  const unlockTime = useVeSRSUnlockTime()
  const [in7days, setIn7days] = useState(false)
  useEffect(() => {
    if (!unlockTime) return
    const future = moment.unix(unlockTime || 0)
    const days = future.diff(moment(), "days")
    setIn7days(days > 0 && days <= 7)
  }, [unlockTime])

  const disableAction =
    in7days ||
    !!inLastVoteLimit ||
    !+formatBNToString(myBalance, 18) ||
    !selectedPool?.farmAddress

  const disableVoteBtn = disableAction || selectedBalPct > remainingVotingPower

  return (
    <Box className={styles.RightBox}>
      <Box className={styles.BoxPadding} sx={{ textAlign: "center" }}>
        <Typography variant="h4">
          {t("proposedGaugeWeightAPRChanges")}
        </Typography>
        <Typography variant="body2" sx={{ mt: 0.5 }}>
          {t("lastUpdate")}: {lastThursday.local().format("D MMM,YYYY")}
        </Typography>
        <DoughnutChart selectedPool={selectedPool} />
      </Box>
      <Divider />
      <Box className={styles.BoxPadding}>
        <SelectPoolBox
          allUsedPower={allUsedPower}
          selected={selectedPool}
          onSelect={(item) => {
            setSelectedPool(item)
            setSelectedBalPct(0)
          }}
        />
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2.5 }}>
          <Typography variant="body2">{t("contract")}</Typography>
          <Typography variant="body2">
            {selectedPool?.farmAddress ? (
              <Link
                underline="none"
                href={getEtherscanLink(
                  selectedPool.farmAddress,
                  "address",
                  chainId,
                )}
                target="_blank"
              >
                {shortenAddress(selectedPool.farmAddress)}
              </Link>
            ) : (
              "-"
            )}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2">{t("currentBaseAPR")}</Typography>
          <Typography variant="body2">
            {baseAprData?.baseApr
              ? `${commify(baseAprData.baseApr.toString())}%`
              : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2">{t("currentProportion")}</Typography>
          <Typography variant="body2">
            {baseAprData?.rewardRate
              ? `${commify((baseAprData.rewardRate * 100).toString())}%`
              : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2">{t("estUpcomingProportion")}</Typography>
          <Typography variant="body2" sx={{ fontWeight: "bolder" }}>
            <TrendIcon
              current={baseAprData?.rewardRate || 0}
              target={baseAprUpcom?.rewardRate || 0}
            />
            {baseAprUpcom?.rewardRate
              ? `${commify((baseAprUpcom.rewardRate * 100).toString())}%`
              : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2">{t("estUpcomingBaseAPR")}</Typography>
          <Typography variant="body2" sx={{ fontWeight: "bolder" }}>
            <TrendIcon
              current={baseAprData?.baseApr || 0}
              target={baseAprUpcom?.baseApr || 0}
            />
            {baseAprUpcom?.baseApr
              ? `${commify(baseAprUpcom.baseApr.toString())}%`
              : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2">{t("estBaseRewardPerDay")}</Typography>
          <Typography variant="body2" sx={{ fontWeight: "bolder" }}>
            <TrendIcon
              current={baseAprData?.rewardSrsPerDay || 0}
              target={baseAprUpcom?.rewardSrsPerDay || 0}
            />
            {baseAprUpcom?.rewardSrsPerDay
              ? commify(baseAprUpcom.rewardSrsPerDay.toString())
              : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <Typography variant="body2">{t("usedPower")}</Typography>
          <Typography variant="body2">
            {poolUsedPower ? `${commify(poolUsedPower.toString())}%` : "-"}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ mt: 1 }}>
          <CustomTooltip
            info={
              t("lastVotingTimeTooltip", {
                days: DAYS_AFTER_LAST_VOTE,
              }) as string
            }
          >
            <Typography variant="body2" className="g-link">
              {t("lastVotingTime")}
            </Typography>
          </CustomTooltip>
          <Typography variant="body2">
            {lastVoteTime
              ? moment.unix(lastVoteTime).local().format("D MMM,YYYY H:mm:ss")
              : "-"}
          </Typography>
        </Stack>
      </Box>
      <Divider />
      <Box className={styles.BoxPadding}>
        <Stack direction="row">
          <VoteIcon className={styles.VoteIcon} />
          <Box sx={{ ml: 1.8, position: "relative", flexGrow: 1 }}>
            <Typography variant="h4">{t("votingPower")}</Typography>
            <Typography variant="body2" sx={{ marginTop: "4px" }}>
              {t("balance")}: {commify(formatBNToString(myBalance, 18))}
            </Typography>
            <Typography variant="h2">
              <Box
                className={classnames(styles.VoteAmount, {
                  [styles.VoteAmountBig]:
                    (+poolUsedBal ? commify(poolUsedBal) : "0").length +
                      (+calcBal ? commify(calcBal) : "0").length >
                    16,
                })}
              >
                <Box className={styles.TextMuted}>
                  {+poolUsedBal ? commify(poolUsedBal) : "0"}
                </Box>
                <ArrowRightIcon className={styles.VoteAmountArrow} />
                <Box className={classnames(!+calcBal && styles.TextMuted)}>
                  {+calcBal ? commify(calcBal) : "0"}
                </Box>
              </Box>
            </Typography>
          </Box>
        </Stack>
        <Stack direction="row" spacing={1} className={styles.PctBtns}>
          {(+poolUsedBal ? [0, 25, 50, 75, 100] : [25, 50, 75, 100]).map(
            (num, index) => (
              <Box
                key={index}
                onClick={() => setSelectedBalPct(num)}
                className={classnames(styles.PctBtn, {
                  [styles.PctBtnActive]: selectedBalPct == num,
                  [styles.PctBtnDisabled]:
                    disableAction || num > remainingVotingPower || (selectedPool.isOutdated && num > 0),
                })}
              >
                {num === 0 ? t("revoke") : num === 100 ? t("max") : `${num}%`}
              </Box>
            ),
          )}
        </Stack>
      </Box>

      <Box height={72} display={{ sm: "none" }} />
      <Box className={styles.VoteBtnMobile}>
        {disableVoteBtn ? (
          <Box className={`${styles.VoteBtn} ${styles.VoteBtnDisabled}`}>
            {in7days
              ? t("voteLockedPeriodTips", { days: 7 })
              : !!inLastVoteLimit
              ? t("Vote after date", {
                  date: moment
                    .unix(lastVoteTime)
                    .add(DAYS_AFTER_LAST_VOTE, "days")
                    .format("D MMM,YYYY"),
                })
              : t("vote")}
          </Box>
        ) : (
          <VoteDialogBox
            selectedPool={selectedPool}
            selectedBalPct={selectedBalPct}
            calcBal={calcBal}
            afterCompleted={() => {
              getBaseAprData()
              getBaseAprUpcom()
              getPoolUsedPower()
              getAllUsedPower()
              getLastVoteTime()
              setSelectedBalPct(0)
              refresh?.()
            }}
          />
        )}
        <Box display={{ sm: "none" }}>
          <BottomPadding />
        </Box>
      </Box>
    </Box>
  )
}

const Vote = (): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [tab, setTab] = useState("Vote")
  const { veTokenMinted, getVeTokenMinted } = useVeTokenMinted()
  const { veTokenVoted, getVeTokenVoted } = useVeTokenVoted()
  const { veSRSBalance, getVesrsBalance } = useVeSRSBalance()
  const { voteUserPower, getVoteUserPower } = useVoteUserPower()

  const refresh = () => {
    getVeTokenMinted()
    getVeTokenVoted()
    getVesrsBalance()
    getVoteUserPower()
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        marginTop: { xs: 1, sm: 5 },
        [theme.breakpoints.down("sm")]: { px: 0 },
      }}
    >
      <PageDesc />
      <Typography variant="h2" mb={2} display={{ xs: "none", sm: "block" }}>
        {t("vote")}
      </Typography>

      <Box className={styles.tab}>
        <Box
          className={styles.tabItem}
          display={tab == "Vote" ? "none" : "inline-block"}
          onClick={() => setTab("Vote")}
        >
          <ArrowBack sx={{ verticalAlign: "middle" }} />
          {t("backToVote")}
        </Box>
        <Box
          className={styles.tabItemRight}
          display={tab == "Total" ? "none" : "inline-block"}
          onClick={() => setTab("Total")}
        >
          {t("details")}
        </Box>
      </Box>

      <Grid container spacing={{ sm: 3 }}>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            [theme.breakpoints.down("sm")]: {
              display: tab == "Total" ? "block" : "none",
            },
          }}
        >
          <LeftBox
            totalVotingPower={veTokenMinted}
            totalVotedPower={veTokenVoted}
            myVotingPower={veSRSBalance}
            myUsedVotingPower={voteUserPower}
          />
          <Divider sx={{ display: { sm: "none" } }} />
          <VotingTips />
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            [theme.breakpoints.down("sm")]: {
              display: tab == "Vote" ? "block" : "none",
            },
          }}
        >
          <RightBox
            myBalance={veSRSBalance}
            myUsedVotingPower={voteUserPower}
            refresh={refresh}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default Vote
