import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import wbnbLogo from "../../assets/icons/wbnb.svg"

export const WBNB_METAPOOL_NAME = "WBNB Metapool"

const WBNB_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x3e6E0c6CaaCeb2e9e3615D685360577db3f44279",
  [ChainId.ASTAR]: "0x7f27352d5f83db87a5a3e00f4b07cc2138d8ee52",
})
export const WBNB_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x73709460e0253b8e589171697ae070816F893EB4",
  [ChainId.ASTAR]: "0xA5694645c380FeD521aCce3CD8FC0B2D98b30558",
})
export const WBNB_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x27EB295716E90eCCFD3F63ed6e7164aA784906db",
  [ChainId.ASTAR]: "0xA82222d8b826E6a741f6cb4bFC6002c34D32fF67",
})
export const WBNB_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x6026C7705bb74243cc0503121327833E5692d9ff",
  [ChainId.ASTAR]: "0xC9d4f937Fa8e0193b46817a41435a262867ff090",
})
export const WBNB_FARM_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xE33B870574F152De5C7E435749a295E17fDE96e6",
  [ChainId.ASTAR]: "0x941e9BEF5b558D7Ca97aBC98e0664E804A9C4B7b",
})

export const WBNB = new Token(
  WBNB_CONTRACT_ADDRESSES,
  18,
  "WBNB",
  "wbnb",
  "Wrapped Binance coin",
  wbnbLogo,
  true,
)
export const WBNB_SWAP_TOKEN = new Token(
  WBNB_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4WBNB",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  wbnbLogo,
  false,
  true,
)

export const WBNB_POOL_TOKENS = [WBNB, ...STABLECOIN_POOL_TOKENS]
export const WBNB_UNDERLYING_POOL_TOKENS = [WBNB, STABLECOIN_SWAP_TOKEN]

export const WBNB_POOL_INFO: Pool = {
  name: WBNB_METAPOOL_NAME,
  lpToken: WBNB_SWAP_TOKEN,
  poolTokens: WBNB_POOL_TOKENS,
  isSynthetic: true,
  addresses: WBNB_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: WBNB_FARM_ADDRESSES,
  type: PoolTypes.Crypto,
  route: "wbnb",
  metaSwapAddresses: WBNB_META_SWAP_ADDRESSES,
  underlyingPoolTokens: WBNB_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
  unbalancePercent: 50,
}
