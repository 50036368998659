import React, { useState, ReactElement } from "react"
import { useHistory, useParams, Link as RouterLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  useTheme,
  Container,
  Stack,
  Box,
  Typography,
  Grid,
  Paper,
  Divider,
  Dialog,
  Button,
  Link,
} from "@mui/material"
import { ArrowForward } from "@mui/icons-material"
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import classnames from "classnames"
import moment from "moment"
import { commify, formatBNToString } from "../utils"
import { notify } from "../utils/notifyHandler"
import { BottomPadding } from "../components/MobileBottomBar"
import ConfirmTransaction from "../components/ConfirmTransaction"
import { ChainId } from "../constants"
import { NETWORK_LABEL } from "../constants/networks"
import { NETWORK_CHAIN_ID } from "../connectors"
import switchNetwork from "../utils/switchNetwork"
import ConnectWallet from "../components/ConnectWallet"
import { useGasPrice } from "../hooks/useGas"
import { useContract } from "../hooks/useContract"
import Airdrop_ABI from "../constants/abis/Airdrop.json"
import {
  socialList,
  EventStatus,
  RecentEvent,
  useRecentEvents,
  useClaimData,
  ClaimData,
} from "./Community.controller"
import styles from "./Community.module.scss"

const LeftBox = (): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  const routerParams: { id?: string } = useParams()
  const currId = routerParams?.id
  const [eventList, getEventList] = useRecentEvents()

  return (
    <Paper variant="outlined" className={styles.LeftBox}>
      <Box p={{ xs: 2, sm: 3 }} sx={{ textAlign: "center" }}>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {t("communityPageBrief")}
        </Typography>
        <Typography variant="subtitle2" sx={{ mt: 3, fontWeight: "bold" }}>
          {t("If you’ll need any support, please join")}
        </Typography>
        <Box>
          <Stack spacing={5} direction="row" justifyContent="center" mt={3}>
            {socialList.map((item, index) => (
              <Link
                key={index}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: theme.palette.mute.main,
                  "&:hover": { color: theme.palette.primary.main },
                }}
              >
                {item.icon}
              </Link>
            ))}
          </Stack>
        </Box>
      </Box>

      <Divider />
      <Box p={{ xs: 2, sm: 3 }} sx={{ maxHeight: "500px", overflowY: "auto" }}>
        <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
          {t("Recent events")}
        </Typography>

        {eventList.map((item, index) => (
          <RouterLink
            key={index}
            className={classnames(styles.EventItem, {
              [styles.EventItemActive]: item.id === currId,
            })}
            to={`/community/${item.id}`}
          >
            <Stack direction="row" mt={2} spacing={1}>
              <Box>
                <Box
                  className={classnames(styles.EventTag, {
                    [styles.EventTagOngoing]:
                      item.status === EventStatus.ongoing,
                    [styles.EventTagUpcoming]:
                      item.status === EventStatus.upcoming,
                  })}
                >
                  {t(item.status as string)}
                </Box>
              </Box>
              <Typography variant="body2">{item.title}</Typography>
            </Stack>
          </RouterLink>
        ))}
      </Box>
    </Paper>
  )
}

type ClaimDialogBoxProps = {
  claimData: ClaimData
  info: RecentEvent
  afterCompleted?: () => void
}
const ClaimDialogBox = (props: ClaimDialogBoxProps): ReactElement => {
  const { claimData, info, afterCompleted } = props
  const { t } = useTranslation()

  const [showDialog, setShowDialog] = useState(false)
  const _contract = useContract(claimData.contract, Airdrop_ABI)
  const gasPrice = useGasPrice()

  const claimDisabled =
    !claimData.contract ||
    !+claimData.quantityAvailable.toString() ||
    info.status === EventStatus.upcoming ||
    (!!info.deadline && moment().isAfter(info.deadline))

  const txAction = async (): Promise<void> => {
    const transaction: Promise<void> = await _contract?.claimReward({
      gasPrice,
    })
    notify.notification({
      type: "pending",
      message: t("yourTransHasBeenSentToNetwork"),
      autoDismiss: 5000,
    })
    return transaction
  }

  const completedInfo = (): ReactElement => (
    <Typography fontSize={12}>
      {t("airdropClaimCompletedInfo", {
        quantity: commify(formatBNToString(claimData.quantityAvailable, 18)),
        token: claimData?.token?.symbol || "",
      })}
    </Typography>
  )

  return (
    <>
      <Button
        className={styles.Btn}
        disabled={claimDisabled}
        onClick={() => setShowDialog(true)}
      >
        {t("claim")}&nbsp;
        {commify(formatBNToString(claimData.quantityAvailable, 18))}&nbsp;
        {claimData?.token?.symbol || ""}
      </Button>

      <Dialog open={!!showDialog} onClose={() => setShowDialog(false)}>
        <ConfirmTransaction
          targetContractAddress={claimData.contract}
          handler={txAction}
          afterCompleted={afterCompleted}
          onClose={() => setShowDialog(false)}
          completedInfo={completedInfo()}
        />
      </Dialog>
    </>
  )
}

const RightBox = (): ReactElement => {
  const { t } = useTranslation()
  const routerParams: { id?: string } = useParams()
  const currId = routerParams?.id
  const fmt = "YYYY/M/D"

  const { account, error: Network_error } = useWeb3React()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError
  const supportedNetworkName =
    NETWORK_LABEL[NETWORK_CHAIN_ID as ChainId] || "Ethereum"
  const [showConnectWallet, setShowConnectWallet] = useState(false)

  const [eventList, getEventList] = useRecentEvents()
  const [claimData, getClaimData] = useClaimData(currId)

  if (!currId) return <></>
  const info = eventList.find((i) => i.id === currId)
  if (!info) return <></>

  return (
    <Box className={styles.RightBox}>
      <Box p={{ xs: 3, sm: 4 }}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h4" flexGrow={1}>
            {info.title}
          </Typography>
          <Box>
            <Box
              className={classnames(styles.EventTag, {
                [styles.EventTagOngoing]: info.status === EventStatus.ongoing,
                [styles.EventTagUpcoming]: info.status === EventStatus.upcoming,
              })}
            >
              {t(info.status as string)}
            </Box>
          </Box>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{ mt: 2, opacity: 0.5 }}
        >
          {(!!info.timeFrom || !!info.timeTo) && (
            <Typography variant="subtitle2" color="#ebebeb">
              {info.timeFrom && info.timeTo
                ? `${moment(info.timeFrom).format(fmt)} - ${moment(
                    info.timeTo,
                  ).format(fmt)}`
                : info.timeFrom
                ? `${t("Starting from")} ${moment(info.timeFrom).format(fmt)}`
                : info.timeTo
                ? `${t("Ended in")} ${moment(info.timeTo).format(fmt)}`
                : ""}
            </Typography>
          )}
          {!!info.deadline && (
            <Typography variant="subtitle2" color="#ebebeb">
              {`${t("Claim deadline")} ${moment(info.deadline).format(fmt)}`}
            </Typography>
          )}
        </Stack>

        <Box
          mt={3}
          className={styles.EventDesc}
          dangerouslySetInnerHTML={{ __html: info.desc }}
        />
        {!!info.link && (
          <Box textAlign="right" mt={3}>
            <Link
              href={info.link}
              underline="none"
              target="_blank"
              color="white"
            >
              {t("More")}
              <ArrowForward
                sx={{ verticalAlign: "middle", fontSize: "20px", ml: 1 }}
              />
            </Link>
          </Box>
        )}
      </Box>

      <Box height={72} display={{ sm: "none" }} />
      <Box className={styles.BtnMobile}>
        {isUnsupportChainIdError ? (
          <Button
            className={styles.Btn}
            onClick={() => switchNetwork(supportedNetworkName)}
          >
            {t("Switch to")} {supportedNetworkName}
          </Button>
        ) : !account ? (
          <Button
            className={styles.Btn}
            onClick={() => setShowConnectWallet(true)}
          >
            {t("connectWallet")}
          </Button>
        ) : !+claimData.quantityAvailable.toString() ? (
          <Button className={styles.Btn} disabled>
            {+claimData.received.toString()
              ? `${commify(formatBNToString(claimData.received, 18))} ${
                  claimData?.token?.symbol || ""
                } ${t("claimed")}`
              : t("Unclaimable")}
          </Button>
        ) : info.deadline && moment().isAfter(info.deadline) ? (
          <Button className={styles.Btn} disabled>
            {t("Claim deadline passed")}
          </Button>
        ) : (
          <ClaimDialogBox
            claimData={claimData}
            info={info}
            afterCompleted={() => getClaimData()}
          />
        )}
        <Box display={{ sm: "none" }}>
          <BottomPadding />
        </Box>
      </Box>

      <Dialog
        open={showConnectWallet}
        onClose={() => setShowConnectWallet(false)}
      >
        <ConnectWallet onClose={() => setShowConnectWallet(false)} />
      </Dialog>
    </Box>
  )
}

const Community = (): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()

  const history = useHistory()
  const routerParams: { id?: string } = useParams()
  const currId = routerParams?.id
  const [eventList, getEventList] = useRecentEvents()
  if (!currId && eventList.length) {
    history.replace(`/community/${eventList[0].id}`)
  }

  return (
    <Container
      maxWidth="md"
      sx={{
        marginTop: { xs: 1, sm: 5 },
        [theme.breakpoints.down("sm")]: { px: 0 },
      }}
    >
      <Box textAlign="right" mb={3}>
        <RouterLink
          to="/farms"
          style={{ color: "white", textDecoration: "unset" }}
        >
          {t("Gain veSRS")}
          <ArrowForward
            sx={{ verticalAlign: "middle", fontSize: "20px", ml: 1 }}
          />
        </RouterLink>
      </Box>

      <Grid container spacing={{ sm: 3 }}>
        <Grid item xs={12} md={5}>
          <LeftBox />
        </Grid>
        <Grid item xs={12} md={7}>
          {!!currId && <RightBox />}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Community
