import { ChainId } from "../constants"
export function getEtherscanLink(
  data: string,
  type: "tx" | "token" | "address" | "block",
  chainId?: ChainId,
): string {
  switch (chainId) {
    case ChainId.BSCTEST:
      return `https://testnet.bscscan.com/${type}/${data}`
    case ChainId.SHIBUYA:
      return `https://blockscout.com/shibuya/${type}/${data}`
    case ChainId.ASTAR:
      return `https://blockscout.com/astar/${type}/${data}`
    default:
      return `https://etherscan.io/${type}/${data}`
  }
}
