import React, { ReactElement } from "react"
import { Box, Typography } from "@mui/material"
import { ReactComponent as CloseSVG } from "./../assets/icons/close.svg"
import bellIcon from "../assets/icons/bell.svg"
import Button from "./Button"
import SRS_ICON from "./../assets/icons/srs.png"
import VESRS_ICON from "./../assets/icons/vesrs.svg"
import "./ReviewLock.scss"
import { commify } from "../utils"

interface Props {
  onClose: () => void
  onConfirm: () => void
  data: any
}

const ReviewUnlock = ({ onClose, onConfirm, data }: Props): ReactElement => {
  // console.log(data);
  return (
    <div className="ReviewLock">
      <Box className="top" px={{ xs: 3, sm: 5 }} pt={3}>
        <Box display="flex" justifyContent="space-between" mb={4}>
          <Typography variant="h4" align="left" gutterBottom={true}>
            Preview Unlock
          </Typography>
          <span className="close-btn" onClick={onClose}>
            <CloseSVG />
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <span className="label">You are unlocking</span>
          <span className="value">
            {data.veSRSAmount ? commify(data.veSRSAmount) : "-"} veSRS
            <img src={VESRS_ICON} width={24} height={24} />
          </span>
        </Box>

        {/* <Box display="flex" justifyContent="space-between" mb={4}>
          <span className="label">{isIncrease && "Adding"} Lock Period</span>
          <span className="value">{data.lockPeriod || "0 Days"}</span>
        </Box> */}
      </Box>
      <Box className="bottom" px={{ xs: 3, sm: 5 }} pt={3}>
        {data.isEmergency ? (
          <>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <span className="label">You will recieve</span>
              <span className="value">
                {commify((data.SRSAmount * (1 - data.penaltyRate)).toString())}{" "}
                SRS
                <img src={SRS_ICON} width={24} height={24} />
              </span>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <span className="label">Early unlock penalty fee</span>
              <span className="value">
                <span style={{ color: "#EA6666" }}>
                  -{data.penaltyRate * 100}%
                </span>
                <img src={SRS_ICON} width={24} height={24} />
              </span>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={10}>
              <span className="label">You will recieve at maturity</span>
              <span className="value">
                {data.SRSAmount ? commify(data.SRSAmount) : "-"} SRS
                <img src={SRS_ICON} width={24} height={24} />
              </span>
            </Box>
          </>
        ) : (
          <Box display="flex" justifyContent="space-between" mb={1}>
            <span className="label">You will recieve</span>
            <span className="value">
              {data.SRSAmount ? commify(data.SRSAmount) : "-"} SRS
              <img src={SRS_ICON} width={24} height={24} />
            </span>
          </Box>
        )}

        {/* <Box display="flex" justifyContent="space-between" mb={1}>
          <span className="label">Total locked SRS</span>
          <span className="value">
            {data.totalLocked}
            <img src={SRS_ICON} width={24} height={24} />
          </span>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <span className="label">Total minted veSRS</span>
          <span className="value">
            {data.myVeTokenMinted} veSRS
            <img src={VESRS_ICON} width={24} height={24} />
          </span>
        </Box> */}
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box className="bottom" px={{ xs: 3, sm: 5 }}>
        {data.isEmergency ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={4}
            className="alert"
          >
            <img src={bellIcon} />
            <Typography fontSize={12} ml={1}>
              If you unlock earlier than the unlock date, the penalty fee will
              be &nbsp;
              {data.penaltyRate * 100}%.
            </Typography>
          </Box>
        ) : (
          <Box mb={10}></Box>
        )}
      </Box>
      <Button size="large" fullWidth onClick={onConfirm} className="lock-btn">
        Confirm Unlock
      </Button>
    </div>
  )
}

export default ReviewUnlock
