import React, { ReactElement } from "react"
import { Container, Link } from "@mui/material"
import PeckShield from "./../assets/PeckShield.svg"
import Slowmist from "./../assets/Slowmist.png"
import PoweredByAstar from "./../assets/PoweredByAstar.svg"

export default function Footer(): ReactElement {
  return (
    <Container maxWidth="xl" sx={{ pt: 5, pb: 2, textAlign: "right" }}>
      <span style={{fontSize: "14px", fontWeight: 600, display: 'inline-flex', alignItems: "center"}}>
        Audited by 
        <Link        
          rel="noreferrer"
          target="_blank"
          href="https://github.com/SiriusFinance/siriusfinance-contract/blob/main/audit/SlowMist%20Audit%20Report%20-%20Sirius%20finance.pdf"
          sx={{display: "flex"}}
        >
          <img
            src={Slowmist}
            style={{ marginLeft: "1vw", marginRight: "1vw" }}
            height="38px"
          />
        </Link>
        
        <Link        
          rel="noreferrer"
          target="_blank"
          href="https://github.com/SiriusFinance/siriusfinance-contract/blob/main/audit/PeckShield-Audit-Report-SiriusFinance-v1.0.pdf"
          sx={{display: "flex"}}
        >
          <img
            src={PeckShield}
            style={{ marginLeft: "0vw", marginRight: "3vw" }}
            height="21px"
          />
        </Link>
        Powered by
          
        <img
          src={PoweredByAstar}
          style={{ marginLeft: "1vw"}}
        />
        
      </span>
      
    </Container>
  )
}
