import { Components, Theme } from "@mui/material"

export default function PaperTheme(theme: Theme): Components {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.background.paper,
          backgroundImage: "none",
          border: `1px solid ${theme.palette.other.divider}`,
          boxShadow: "none",
          borderRadius: 16,
          opacity: 1,
          color: "#ebebeb",
        },
        outlined: {
          background:
            "linear-gradient(126.6deg, rgba(255, 255, 255, 0.06) 28.69%, rgba(255, 255, 255, 0) 100%)",
          backdropFilter: "blur(100px)",
          border: "3px solid rgba(255, 255, 255, 0.1)",
        },
      },
    },
  }
}
