/* eslint-disable */
import "./SwapPage.scss"
// import SwiftIcon from "../assets/icons/swift_icon.svg"

import {
  useTheme,
  Box,
  Container,
  Dialog,
  Typography,
  Divider,
} from "@mui/material"
import React, { ReactElement, useMemo, useState, useEffect } from "react"
import { SWAP_TYPES, getIsVirtualSwap, ChainId } from "../constants"
import {
  formatBNToPercentString,
  formatBNToString,
  expensiveDecimals,
} from "../utils"
import { Link } from "react-router-dom"
import banner from '../assets/banner2.png'
// import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"

// import AdvancedOptionsForSwap from "./AdvancedOptionsForSwap"
import { AppState } from "../state/index"
import { BigNumber } from "@ethersproject/bignumber"
import ConfirmTransaction from "./ConfirmTransaction2"
import { ReactComponent as InfoIcon } from "../assets/icons/info.svg"
import { PendingSwap } from "../hooks/usePendingSwapData"
import PendingSwapModal from "./PendingSwapModal"
import ReviewSwap from "./ReviewSwap"
import { Slippages } from "../state/user"
import SwapInput from "./SwapInput"
import type { TokenOption } from "../pages/Swap"
import ConnectWallet from "./ConnectWallet"
import { Zero } from "@ethersproject/constants"
import { commify } from "../utils"
import switchNetwork from "../utils/switchNetwork"
// import SwitchNetwork from "../utils/switchNetwork"
import { formatUnits } from "@ethersproject/units"
import { isHighPriceImpact } from "../utils/priceImpact"
import { logEvent } from "../utils/googleAnalytics"
import { useActiveWeb3React } from "../hooks"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import AccountDetails from "./AccountDetails"
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import { ReactComponent as LightningSVG } from "./../assets/icons/lightning.svg"
import { ReactComponent as ExchangeSVG } from "./../assets/icons/exchange.svg"
import Button from "./../components/Button"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import { NETWORK_CHAIN_ID } from "../connectors"
import { NETWORK_LABEL } from "../constants/networks"
// import CustomTooltip from "../components/CustomTooltip"

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
}

interface Props {
  tokenOptions: {
    from: TokenOption[]
    to: TokenOption[]
  }
  exchangeRateInfo: {
    pair: string
    exchangeRate: BigNumber
    priceImpact: BigNumber
    route: string[]
  }
  txnGasCost: {
    amount: BigNumber
    valueUSD: BigNumber | null // amount * ethPriceUSD
  }
  error: string | null
  swapType: SWAP_TYPES
  fromState: { symbol: string; value: string; valueUSD: BigNumber }
  toState: { symbol: string; value: string; valueUSD: BigNumber }
  pendingSwaps: PendingSwap[]
  onChangeFromToken: (tokenSymbol: string) => void
  onChangeFromAmount: (amount: string) => void
  onChangeToToken: (tokenSymbol: string) => void
  onConfirmTransaction: () => Promise<void>
  onClickReverseExchangeDirection: () => void
  isApproved: boolean
  ApproveForTrade: (callback: () => void) => Promise<void>
  poolName: string | undefined
  transitionStatus: any
  resetTransitionStatus: any
  clearSwapInput: any
}

const SwapPage = (props: Props): ReactElement => {
  const { t } = useTranslation()
  const { account } = useActiveWeb3React()
  const {
    tokenOptions,
    exchangeRateInfo,
    txnGasCost,
    error,
    fromState,
    toState,
    pendingSwaps,
    swapType,
    onChangeFromToken,
    onChangeFromAmount,
    onChangeToToken,
    onConfirmTransaction,
    onClickReverseExchangeDirection,
    // isApproved,
    // ApproveForTrade,
    poolName,
    transitionStatus,
    resetTransitionStatus,
    clearSwapInput,
  } = props
  const theme = useTheme()

  const [currentModal, setCurrentModal] = useState<string | null>(null)
  const [activePendingSwap, setActivePendingSwap] = useState<string | null>(
    null,
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)
  const { slippageCustom, slippageSelected } = useSelector(
    (state: AppState) => state.user,
  )

  const fromToken = useMemo(() => {
    return tokenOptions.from.find(({ symbol }) => symbol === fromState.symbol)
  }, [tokenOptions.from, fromState.symbol])
  const toToken = useMemo(() => {
    return tokenOptions.to.find(({ symbol }) => symbol === toState.symbol)
  }, [tokenOptions.to, toState.symbol])

  const formattedPriceImpact = commify(
    formatBNToPercentString(exchangeRateInfo.priceImpact, 18),
  )
  // const formattedExchangeRate = commify(
  //   formatBNToString(exchangeRateInfo.exchangeRate, 18, 6),
  // )
  // const formattedRoute = exchangeRateInfo.route.join(" > ")
  const formattedBalance = commify(
    formatBNToString(fromToken?.amount || Zero, fromToken?.decimals || 0, 6),
    expensiveDecimals(fromToken?.symbol),
  )
  const toformattedBalance = commify(
    formatBNToString(toToken?.amount || Zero, toToken?.decimals || 0, 6),
    expensiveDecimals(fromToken?.symbol),
  )
  const isVirtualSwap = getIsVirtualSwap(swapType)
  // const isHighSlippage =
  //   slippageSelected === Slippages.OneTenth ||
  //   (slippageSelected === Slippages.Custom &&
  //     parseFloat(slippageCustom?.valueRaw || "0") < 0.5)

  useEffect(() => {
    if (modalOpen) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [modalOpen])

  const [slip, setSlip] = useState(1)
  useEffect(() => {
    if (slippageSelected === Slippages.Custom) {
      //console.log(slippageCustom?.valueRaw || 0);
      setSlip(parseFloat(slippageCustom?.valueRaw || "1"))
    } else if (slippageSelected === Slippages.One) {
      setSlip(1)
    } else if (slippageSelected === Slippages.OneTenth) {
      setSlip(0.1)
    }
  }, [slippageSelected, slippageCustom])
  const fromTokenAmountCal = (rate: number) => {
    if (fromToken == null) return
    return formatBNToString(
      fromToken.amount.mul(BigNumber.from(rate)).div(100),
      fromToken.decimals || 0,
      2,
    )
  }
  const inputRateClick = (rate: number) => {
    const amtStr = fromTokenAmountCal(rate)
    if (!amtStr) return
    onChangeFromAmount(amtStr)
  }
  const inputRateActive = (from_value: any, _key: any) => {
    // console.log(inputRateData);
    if (+from_value === 0) return false
    if (!_key) return false
    return +from_value === +fromTokenAmountCal(_key)!
  }

  const [showReview, setShowReview] = useState(false)
  // const { error: Network_error } = useWeb3React()
  // const isUnsupportChainIdError = Network_error instanceof UnsupportedChainIdError
  // console.log(isUnsupportChainIdError);

  // console.log(poolName);

  const { error: Network_error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError
  // console.log(isUnsupportChainIdError);

  // useEffect(() => {
  //   // console.log(transitionStatus);
  //   // if(transitionStatus.step !== 0)
  //   //   setCurrentModal("confirm")
  //   // else
  //   //   setCurrentModal(null)

  // }, [transitionStatus])
  const supportedNetworkName =
    NETWORK_LABEL[NETWORK_CHAIN_ID as ChainId] || "Ethereum"
  return (
    <Container
      maxWidth="sm"
      sx={{
        display: { xs: "flex", sm: "block" },
        marginTop: { xs: 1, sm: 5 },
        px: 0,
      }}
      className="swapPage"
    >
      <Typography
        variant="h2"
        mb={2}
        sx={{ display: { xs: "none", sm: "block" } }}
        // className="swapTitle"
      >
        {t("swap")}
      </Typography>
      <div className={showReview ? "swapForm hide" : "swapForm"}>
        <div className="swapFormContainer">
          <Typography variant="subtitle2">{t("from")}</Typography>

          <div className="row">
            <SwapInput
              tokens={tokenOptions.from.filter(
                ({ symbol }) => symbol !== toState.symbol,
              )}
              onSelect={onChangeFromToken}
              onChangeAmount={onChangeFromAmount}
              selected={fromState.symbol}
              inputValue={fromState.value}
              inputValueUSD={fromState.valueUSD}
              isSwapFrom={true}
              balance={formattedBalance}
            />
          </div>
          <div className="progress_button_group">
            <Button
              kind="outline"
              size="small"
              className={inputRateActive(fromState.value, 25) ? "active" : ""}
              onClick={() => inputRateClick(25)}
            >
              25%
            </Button>
            <Button
              kind="outline"
              size="small"
              className={inputRateActive(fromState.value, 50) ? "active" : ""}
              onClick={() => inputRateClick(50)}
            >
              50%
            </Button>
            <Button
              kind="outline"
              size="small"
              className={inputRateActive(fromState.value, 75) ? "active" : ""}
              onClick={() => inputRateClick(75)}
            >
              75%
            </Button>
            <Button
              kind="outline"
              size="small"
              className={inputRateActive(fromState.value, 100) ? "active" : ""}
              onClick={() => inputRateClick(100)}
            >
              Max
            </Button>
          </div>
          <Divider flexItem={true} className="Swap_divider">
            <button onClick={onClickReverseExchangeDirection}>
              <ExchangeSVG />
            </button>
          </Divider>
          <Box sx={{ height: 40 }} />

          <Typography variant="subtitle2">{t("to")}</Typography>
          <div className="row">
            <SwapInput
              tokens={tokenOptions.to.filter(
                ({ symbol }) => symbol !== fromState.symbol,
              )}
              onSelect={onChangeToToken}
              selected={toState.symbol}
              inputValue={toState.value}
              inputValueUSD={toState.valueUSD}
              isSwapFrom={false}
              balance={toformattedBalance}
            />
          </div>
        </div>
        <Box className="swap_btn_wrapper">
          {isUnsupportChainIdError ? (
            <Button
              // disabled={true}
              kind="primary"
              fullWidth={true}
              onClick={() => switchNetwork(supportedNetworkName)} //TO DO 优化
              size="large"
            >
              {t("switchNetwork")}
            </Button>
          ) : (
            <>
              {account ? (
                <Button
                  disabled={!!error || +toState.value <= 0}
                  kind="primary"
                  fullWidth={true}
                  size="large"
                  onClick={(): void => {
                    // setCurrentModal("review")
                    setShowReview(true)
                  }}
                >
                  {error ? error : t("previewSwap")}
                </Button>
              ) : (
                <Button
                  // disabled={true}
                  kind="primary"
                  fullWidth={true}
                  onClick={(): void => setModalOpen(true)}
                  size="large"
                >
                  {t("connectWallet")}
                </Button>
              )}
            </>
          )}
        </Box>
      </div>
      {showReview && (
        <div className="swapForm">
          <ReviewSwap
            onClose={(): void => setShowReview(false)}
            onConfirm={async (): Promise<void> => {
              setCurrentModal("confirm")
              // setShowReview(false)
              logEvent("swap", {
                from: fromState.symbol,
                to: toState.symbol,
              })
              await onConfirmTransaction?.()
              // setCurrentModal(null)
            }}
            data={{
              from: fromState,
              to: toState,
              exchangeRateInfo,
              txnGasCost,
              swapType,
            }}
            poolName={poolName}
          />
        </div>
      )}

      {account && isHighPriceImpact(exchangeRateInfo.priceImpact) ? (
        <div className="exchangeWarning">
          {t("highPriceImpact", {
            rate: formattedPriceImpact,
          })}
        </div>
      ) : null}
      {isVirtualSwap && (
        <div className="virtualSwapInfoBubble">
          <InfoIcon />
          {t("crossAssetSwapsUseVirtualSwaps")} {"<"}
          <a href="#" target="_blank" rel="noreferrer">
            {t("learnMore")}
          </a>
          {">"}
        </div>
      )}

      <div className="pendingSwaps">
        {pendingSwaps.map((pendingSwap) => {
          const formattedSynthBalance = commify(
            formatUnits(
              pendingSwap.synthBalance,
              pendingSwap.synthTokenFrom.decimals,
            ),
          )
          return (
            <div
              className="pendingSwapItem"
              key={pendingSwap.itemId?.toString()}
              onClick={() => {
                setActivePendingSwap(pendingSwap.itemId)
                setCurrentModal("pendingSwap")
              }}
            >
              <span className="swapDetails">
                {formattedSynthBalance} {pendingSwap.synthTokenFrom.symbol}{" "}
                {"->"} {pendingSwap.tokenTo.symbol}
              </span>
              <div className="swapTimeContainer">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.23467 1H5.5C7.98605 1 10 3.01525 10 5.49924C10 7.98311 7.98618 10 5.5 10C3.01388 10 1 7.98469 1 5.49924C1 4.30732 1.46423 3.22282 2.21973 2.41912L2.60641 2.78249C1.93974 3.49169 1.53066 4.4476 1.53066 5.49924C1.53066 7.69191 3.30721 9.46943 5.5 9.46943C7.69273 9.46943 9.46934 7.69046 9.46934 5.49924C9.46934 3.39724 7.83438 1.67581 5.76533 1.5393V2.96008H5.23467V1Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M5.76204 5.52774L5.76861 5.53328L5.77577 5.53804C5.82206 5.5688 5.85082 5.61957 5.84998 5.67802L5.84997 5.67802V5.68017C5.84997 5.77327 5.77431 5.85 5.67911 5.85C5.62153 5.85 5.56861 5.81994 5.53676 5.77321L5.53241 5.76682L5.52742 5.76091L4.26017 4.26001L5.76204 5.52774Z"
                    fill="black"
                    stroke="black"
                    strokeWidth="0.3"
                  />
                </svg>
                <span className="swapTime">
                  {Math.ceil(pendingSwap.secondsRemaining / 60)} min.
                </span>
              </div>
            </div>
          )
        })}
      </div>
      <Link to={"/pools/pusdt/deposit"}>
        <img src={banner} className="swap-banner" />
      </Link>
      <Dialog open={modalOpen} onClose={(): void => setModalOpen(false)}>
        {account && walletView === WALLET_VIEWS.ACCOUNT ? (
          <AccountDetails
            openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
            onClose={(): void => setModalOpen(false)}
          />
        ) : (
          <ConnectWallet onClose={(): void => setModalOpen(false)} />
        )}
      </Dialog>
      <Dialog open={!!currentModal} onClose={(): void => setCurrentModal(null)}>
        {/* {currentModal === "review" ? (
              <ReviewSwap
                onClose={(): void => setCurrentModal(null)}
                onConfirm={async (): Promise<void> => {
                  setCurrentModal("confirm")
                  logEvent("swap", {
                    from: fromState.symbol,
                    to: toState.symbol,
                  })
                  await onConfirmTransaction?.()
                  setCurrentModal(null)
                }}
                data={{
                  from: fromState,
                  to: toState,
                  exchangeRateInfo,
                  txnGasCost,
                  swapType,
                }}
              />
            ) : null} */}
        {currentModal === "confirm" ? (
          <ConfirmTransaction
            transitionStatus={transitionStatus}
            stepNum={2}
            // toToken={}
            // toNum={((+toState.value * (100 - +slip)) / 100).toFixed(2)}
            onClose={(need_clear?: Boolean) => {
              //TODO 判断，是否重置review的层
              // alert(need_clear);
              setShowReview(false)
              setCurrentModal(null)
              resetTransitionStatus()
              if (need_clear === true) {
                clearSwapInput()
              }
            }}
          />
        ) : null}
        {currentModal === "pendingSwap" ? (
          <PendingSwapModal
            pendingSwap={
              pendingSwaps.find(
                (p) => p.itemId === activePendingSwap,
              ) as PendingSwap
            }
            onClose={() => {
              setCurrentModal(null)
              setActivePendingSwap(null)
            }}
          />
        ) : null}
      </Dialog>
    </Container>
  )
}

export default SwapPage
