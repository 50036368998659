import React, { useState, useEffect } from "react"
import { Box, Paper, Typography, Divider, Link, Stack } from "@mui/material"
import { useSelector } from "react-redux"
import { AppState } from "../state"
import { formatBNToString, commify, sliceNum } from "../utils"
import { AVAULT_POOL_NAME } from "../constants"
import { useFarmsContract } from "../hooks/useContract"
import {
  useExtraRewards,
  IExtraRewards,
  useFarmRowData,
} from "../hooks/useFarmData"
import CustomTooltip from "../components/CustomTooltip"
import { useTranslation } from "react-i18next"
import styles from "./../pages/Farms.module.scss"
import styles2 from "./FarmRow.module.scss"

interface Props {
  farmData: any
  TotalRewardSRSPerSecond: any
  // RewardRate: any
  setTotalData: any
}

const FarmRow = ({
  farmData,
  TotalRewardSRSPerSecond,
  // RewardRate,
  setTotalData,
}: Props) => {
  const FarmContract = useFarmsContract(farmData.farmAddresses)
  const [ExtraRewards, MyExtraRewards, getExtraRewards] =
    useExtraRewards(FarmContract)

  const [FarmRowData, getFarmContractData, getLpTokenContractData] =
    useFarmRowData(farmData, TotalRewardSRSPerSecond)
  const { t } = useTranslation()
  const { srsPrice } = useSelector((state: AppState) => state.application)

  // extra reward begin

  const YearValueToAPY = (_YearValue: any, _TotalValueRaw: any) => {
    const tvl = +formatBNToString(_TotalValueRaw, 18)
    if (!tvl) return 0
    return sliceNum((100 * +_YearValue) / tvl)
  }
  const [TotalAPY, setTotalAPY] = useState(0)
  const [extraRewardList, setExtraRewardList] = useState<any[]>([])
  // Whether the reward with no price is included
  const [isIncludeRewardWithNoPrice, setIsIncludeRewardWithNoPrice] =
    useState(false)

  useEffect(() => {
    if (ExtraRewards === undefined) return
    if (!FarmRowData["TotalValueRaw"]) return
    let APY = 0
    ExtraRewards.map((ExtraReward: any, _index: any) => {
      APY += +YearValueToAPY(
        ExtraReward.rewardPerYearValue,
        FarmRowData["TotalValueRaw"],
      )
    })
    APY += +FarmRowData?.apy
    setTotalAPY(APY)

    // calculate displayValue
    const _list: any[] = []
    setIsIncludeRewardWithNoPrice(false)
    ExtraRewards.map((i: IExtraRewards) => {
      const apy = YearValueToAPY(
        i.rewardPerYearValue,
        FarmRowData.TotalValueRaw,
      )

      const displayValue = i.tokenPrice
        ? +apy
          ? `${apy}% ${i.symbol}`
          : ""
        : i.rewardPerDayNum
        ? `${commify((i.rewardPerDayNum || 0).toString())} ${i.symbol}`
        : ""

      if (!displayValue) return
      _list.push({ ...i, displayValue })
      if (!i.tokenPrice) setIsIncludeRewardWithNoPrice(true)
    })
    setExtraRewardList(_list)
  }, [ExtraRewards, FarmRowData])

  // extra rewards end

  useEffect(() => {
    setTotalData((prev: any) => ({
      ...prev,
      [farmData.lpToken.symbol]: {
        unClaimSRS: FarmRowData.unClaimSRS,
        TotalValueRaw: FarmRowData.TotalValueRaw,
        MyStakeValue: FarmRowData.MyStakeValue,
        MyExtraRewards: MyExtraRewards,
      },
    }))
  }, [FarmRowData, MyExtraRewards])

  return (
    <Paper
      variant="outlined"
      sx={{ marginBottom: "15px" }}
      className={styles2.Paper}
    >
      <Link
        href={`#/farms/${farmData.lpToken.symbol}`}
        color="inherit"
        underline="none"
      >
        <Box className={styles.FarmLayoutRight}>
          {farmData.hot && <Box className={styles2.Hot}>HOT</Box>}
          <Typography
            component="div"
            variant="body2"
            className={styles.FarmPanelRow}
          >
            <span>{farmData.lpToken.symbol}</span>
            <CustomTooltip
              info={
                <>
                  {t("rewardsAPRTooltip", { price: srsPrice })}
                  <Stack direction="row" spacing={1}>
                    <Box>{t("baseReward")}:</Box>
                    <Box>
                      {TotalAPY ? commify(TotalAPY.toString()) + "%" : "-"}
                    </Box>
                  </Stack>
                  {!!extraRewardList.length && (
                    <Stack direction="row" spacing={1}>
                      <Box>{t("extraReward")}:</Box>
                      <Box>
                        {extraRewardList.map((item: any, index: number) => (
                          <Box key={index}>
                            {item.tokenPrice
                              ? item.displayValue
                              : t("rewards per day", {
                                  rewards: item.displayValue,
                                })}
                          </Box>
                        ))}
                      </Box>
                    </Stack>
                  )}
                  {farmData.poolName === AVAULT_POOL_NAME && (
                    <Box mt={1}>{t("avaultPriceTooltip")}</Box>
                  )}
                </>
              }
            >
              <span style={{ borderBottom: "1px dashed #FFCDEC" }}>
                {t("rewardsAPR")}
              </span>
            </CustomTooltip>
          </Typography>
          <Typography
            component={"div"}
            variant="body2"
            className={styles.FarmPanelRow}
          >
            <div className={styles.FarmSvgGroup}>
              {farmData.poolTokens.map((_token: any) => (
                <img key={_token.symbol} src={_token.icon} alt="" />
              ))}
            </div>
            <span className={styles.FarmAPYText}>
              {+sliceNum(TotalAPY)
                ? sliceNum(TotalAPY) +
                  "%" +
                  (isIncludeRewardWithNoPrice ? "*" : "")
                : "-"}
            </span>
          </Typography>
          <Divider className={styles.Farm_divider} />
          <Typography variant="body2" className={styles.FarmPanelRow}>
            <span>{t("totalValueStaked")}</span>
            <span>${FarmRowData?.TotalValue}</span>
          </Typography>
          <Typography variant="body2" className={styles.FarmPanelRow}>
            <span>{t("myStakedValue")}</span>
            <span>
              {+FarmRowData?.MyStakeValue
                ? "$" + commify(FarmRowData?.MyStakeValue)
                : "-"}
            </span>
          </Typography>
          <Typography
            component="div"
            variant="body2"
            className={styles.FarmPanelRow}
          >
            <CustomTooltip
              info={t("unclaimedRewardsTooltip") || "unclaimedRewardsTooltip"}
            >
              <span style={{ borderBottom: "1px dashed #FFCDEC" }}>
                {t("unclaimedRewards")}
              </span>
            </CustomTooltip>
            <span>
              {!MyExtraRewards.length &&
                (+FarmRowData?.unClaimSRS
                  ? `SRS: ${sliceNum(+FarmRowData?.unClaimSRS)}`
                  : "-")}
              {MyExtraRewards.map((MyExtraReward: any, _key: any) => (
                <span key={_key} style={{ marginLeft: "8px" }}>
                  {MyExtraReward["symbol"] === "SRS"
                    ? +MyExtraReward["vol"] + +FarmRowData?.unClaimSRS
                      ? `${MyExtraReward["symbol"]}: ${sliceNum(
                          +MyExtraReward["vol"] + +FarmRowData?.unClaimSRS,
                        )}`
                      : "-"
                    : +MyExtraReward["vol"]
                    ? `${MyExtraReward["symbol"]}: ${sliceNum(
                        +MyExtraReward["vol"],
                      )}`
                    : "-"}
                </span>
              ))}
            </span>
          </Typography>
        </Box>
      </Link>
    </Paper>
  )
}

export default FarmRow
