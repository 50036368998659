import React, { ReactElement, useState, useEffect } from "react"
import {
  useTheme,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Typography,
  Dialog,
} from "@mui/material"
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import Web3Status from "./Web3Status"
import { shortenAddress } from "../utils/shortenAddress"
import { ChainId } from "../constants"
import { NETWORK_LABEL } from "../constants/networks"
import { useUDName } from "../hooks/useUDName"
import AdvancedOptions from "./AdvancedOptions"
import { NetworkIconsMap } from "./NetworkIcons"
import AccountDetails from "./AccountDetails"
import TopMenuLanguage from "./TopMenuLanguage"

const WALLET_VIEWS = {
  OPTIONS: "options",
  ACCOUNT: "account",
}


export function BottomPadding(): ReactElement {
  const { account } = useWeb3React()
  const { error: Network_error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError

  const isShow = !isUnsupportChainIdError && account
  if (!isShow) return <></>
  return <Toolbar sx={{ display: { md: "none" }, minHeight: { xs: 48 } }} />
}

export default function MobileBottomBar(): ReactElement {
  const theme = useTheme()
  const { account, chainId } = useWeb3React()
  const [modalOpen, setModalOpen] = useState(false)
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT)
  
  const udName = useUDName()
  const networkLabel: string = NETWORK_LABEL[chainId as ChainId] || "Ethereum"
  const networkIcon: ReactElement | string =
    NetworkIconsMap[chainId as ChainId] || ""

  const { error: Network_error } = useWeb3React<Web3Provider>()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError
  useEffect(() => {
    if (modalOpen) {
      setWalletView(WALLET_VIEWS.ACCOUNT)
    }
  }, [modalOpen])
  const isShow = !isUnsupportChainIdError && account
  if (!isShow) return <></>

  

  return (
    <Box sx={{ display: { xs: "block", md: "none" } }}>
      <AppBar
        position="fixed"
        sx={{
          top: "auto",
          bottom: 0,
          borderRadius: 0,
          bgcolor: theme.palette.other.heavierColor,
        }}
      >
        <Toolbar sx={{ minHeight: { xs: 48 } }}>
          {/* <Web3Status networkLabel={networkLabel} /> */}
          {networkIcon ? (
            <IconButton sx={{ width: 40, height: 40, overflow: "hidden" }}>
              {networkIcon}
            </IconButton>
          ) : networkLabel ? (
            <>{networkLabel} |&nbsp;</>
          ) : (
            ""
          )}
          <Typography variant="h6" component="div" onClick={() => setModalOpen(true)}>
            {udName || shortenAddress(account || "")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          {account && <AdvancedOptions />}
          <TopMenuLanguage />
        </Toolbar>
      </AppBar>
      <BottomPadding />
      <Dialog
        open={modalOpen}
        onClose={(): void => setModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <AccountDetails
          openOptions={() => setWalletView(WALLET_VIEWS.OPTIONS)}
          onClose={(): void => setModalOpen(false)}
        />
      </Dialog>
    </Box>
  )
}
