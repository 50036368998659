import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import {
  STABLECOIN_POOL_NAME,
  STABLECOIN_POOL_TOKENS,
  STABLECOIN_SWAP_TOKEN,
} from "./stablecoinPool"
import jpycLogo from "../../assets/icons/jpyc.png"

export const JPYC_POOL_NAME = "JPYC Metapool"

const JPYC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xE8DCB249e832f605394479394d0eEA2749Bae862",
  [ChainId.ASTAR]: "0x431d5dff03120afa4bdf332c61a6e1766ef37bdb",
})
export const JPYC_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0xD71499c0d30866307d50dcf83d82cd8C081CF4b5",
  [ChainId.ASTAR]: "0xea910845d8BB0c3b3A4F03D0A77D46A95bb762bA",
})
export const JPYC_META_SWAP_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x698e0EAd7b393Ea4BC27199Ee520798687dcFcAa",
  [ChainId.ASTAR]: "0xEd6e10Fc171f2768D9c056260b18D814035F8266",
})
export const JPYC_META_SWAP_DEPOSIT_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x561776CbC282590a95118a035154f56dc3d08b12",
  [ChainId.ASTAR]: "0x3cd1Fa4EeeFdf6c30E66c66A474e8E4dd509f54c",
})
export const JPYC_FARM_ADDRESSES = buildAddresses({
  [ChainId.SHIBUYA]: "0x6D3a6618526487a10416D19A2f4ba1C8C3Af41C2",
  [ChainId.ASTAR]: "0xe1762b802Cf306C60b0C2C1af991646eFc8B5C6b",
})

export const JPYC = new Token(
  JPYC_CONTRACT_ADDRESSES,
  18,
  "JPYC",
  "jpyc",
  "JPY Coin",
  jpycLogo,
)
export const JPYC_SWAP_TOKEN = new Token(
  JPYC_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4JPYC",
  "siriuUSDSMetaLP",
  "siriuUSDSMetaLP",
  jpycLogo,
  false,
  true,
)

export const JPYC_POOL_TOKENS = [JPYC, ...STABLECOIN_POOL_TOKENS]
export const JPYC_UNDERLYING_POOL_TOKENS = [JPYC, STABLECOIN_SWAP_TOKEN]

export const JPYC_POOL_INFO: Pool = {
  name: JPYC_POOL_NAME,
  lpToken: JPYC_SWAP_TOKEN,
  poolTokens: JPYC_POOL_TOKENS,
  isSynthetic: true,
  addresses: JPYC_META_SWAP_DEPOSIT_ADDRESSES,
  farmAddresses: JPYC_FARM_ADDRESSES,
  type: PoolTypes.Crypto,
  route: "jpyc",
  metaSwapAddresses: JPYC_META_SWAP_ADDRESSES,
  underlyingPoolTokens: JPYC_UNDERLYING_POOL_TOKENS,
  underlyingPool: STABLECOIN_POOL_NAME,
  rewardPids: buildPids({}),
  show: true,
  unbalancePercent: 45,
}
