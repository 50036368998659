import React, { useEffect, useState, ReactElement, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  useTheme,
  useMediaQuery,
  Stack,
  Grid,
  Box,
  Paper,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
} from "@mui/material"
import { BigNumber } from "@ethersproject/bignumber"
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core"
import { ChainId } from "../constants"
import { NETWORK_LABEL } from "../constants/networks"
import { NETWORK_CHAIN_ID } from "../connectors"
import Button from "./Button"
import ConnectWallet from "./ConnectWallet"
import { useActiveWeb3React } from "../hooks"
import { PoolDataType, UserShareType } from "../hooks/usePoolData"
import { useMyPoolsData, MyPoolsDataType } from "../hooks/useMyData"
import { formatBNToPercentString, commify } from "../utils"
import switchNetwork from "../utils/switchNetwork"
import CustomTooltip from "../components/CustomTooltip"
import styles from "./MyPools.module.scss"

export type PoolToken = {
  symbol: string
  icon: string
  name: string
  value: string
}
export type FarmData = {
  MyStakeValue: BigNumber
  TotalValue: string
  TotalValueRaw: BigNumber
  apy: string
}

export type PoolProps = {
  name: string
  poolData: PoolDataType
  userShareData: UserShareType
  poolRoute: string
  farmData: FarmData
}

export default function MyPools(): ReactElement {
  const { t } = useTranslation()
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down("sm"))

  const history = useHistory()

  const poolsData = useMyPoolsData()
  const [MyPoolsData, setMyPoolsData] = useState<MyPoolsDataType[]>([])
  useEffect(() => {
    setMyPoolsData(Object.values(poolsData))
  }, [poolsData])

  const { account, chainId } = useActiveWeb3React()
  const { error: Network_error } = useWeb3React()
  const isUnsupportChainIdError =
    Network_error instanceof UnsupportedChainIdError
  const supportedNetworkName =
    NETWORK_LABEL[NETWORK_CHAIN_ID as ChainId] || "Ethereum"

  const [showConnectWallet, setShowConnectWallet] = useState(false)

  const MobilePools = (
    <Box className={styles.MPool}>
      {MyPoolsData.map((row, index) => (
        <Box
          className={styles.MItem}
          key={index}
          onClick={() => history.push(`${row.poolRoute}/deposit`)}
        >
          <Stack spacing={2} direction="row">
            <Stack direction="row" spacing={-1} alignItems="center">
              {row.tokens?.map(({ symbol, icon }) => (
                <img width={24} height={24} key={symbol} src={icon} />
              ))}
            </Stack>
            <Box>{row.name}</Box>
          </Stack>
          <Stack direction="row" className={styles.MItemSub}>
            <Box flex={1}>
              <Box>
                <CustomTooltip info={t("feeRewardTooltip") || ""}>
                  <Typography
                    variant="body1"
                    fontSize={12}
                    sx={{
                      textDecoration: "dashed underline",
                      display: "inline-block",
                    }}
                  >
                    {t("Fee Reward")}
                  </Typography>
                </CustomTooltip>
              </Box>
              <Box className={`${styles.MItemText} ${styles.gradientText}`}>
                {row.apy ? formatBNToPercentString(row.apy, 18, 2) : "-"}
              </Box>
            </Box>
            <Box flex={1}>
              <Box>{t("deposited")}</Box>
              <Box className={styles.MItemText}>
                {row.deposited ? `$${row.deposited}` : "-"}
              </Box>
            </Box>
          </Stack>
          <Stack direction="row" className={styles.MItemSub}>
            <Box flex={1}>
              <Box>{t("Reward APR")}</Box>
              <Box className={`${styles.MItemText} ${styles.gradientText}`}>
                {+row.rewardApy ? `${commify(row.rewardApy)}%` : "-"}
              </Box>
            </Box>
            <Box flex={1}>
              <Box>{t("staked")}</Box>
              <Box className={styles.MItemText}>
                {row.staked ? `$${row.staked}` : "-"}
              </Box>
            </Box>
          </Stack>
        </Box>
      ))}
    </Box>
  )
  const PcPools = (
    <Table>
      <TableHead>
        <TableRow
          sx={{
            th: { borderColor: "rgba(235,235,235,0.1)" },
          }}
        >
          <TableCell>{t("assets")}</TableCell>
          <TableCell>{t("name")}</TableCell>
          <TableCell align="right">
            <CustomTooltip info={t("feeRewardTooltip") || ""}>
              <Typography
                variant="body1"
                fontSize={12}
                sx={{
                  textDecoration: "dashed underline",
                  cursor: "pointer",
                }}
              >
                {t("Fee Reward")}
              </Typography>
            </CustomTooltip>
          </TableCell>
          <TableCell align="right">{t("Reward APR")}</TableCell>
          <TableCell align="right">{t("deposited")}</TableCell>
          <TableCell align="right">{t("staked")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {MyPoolsData.map((row, index) => (
          <TableRow
            key={index}
            sx={{
              "th,td": { borderColor: "rgba(235,235,235,0.1)" },
              "&:last-child th,&:last-child td": { border: "none" },
              "&:hover": { backgroundColor: "#554979" },
            }}
            onClick={() => history.push(`${row.poolRoute}/deposit`)}
          >
            <TableCell>
              <Stack direction="row" spacing={-1} alignItems="center">
                {row.tokens?.map(({ symbol, icon }) => (
                  <img width={24} height={24} key={symbol} src={icon} />
                ))}
              </Stack>
            </TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell align="right" className={styles.gradientText}>
              {row.apy ? formatBNToPercentString(row.apy, 18, 2) : "-"}
            </TableCell>
            <TableCell align="right" className={styles.gradientText}>
              {+row.rewardApy ? `${commify(row.rewardApy)}%` : "-"}
            </TableCell>
            <TableCell align="right">
              {row.deposited ? `$${row.deposited}` : "-"}
            </TableCell>
            <TableCell align="right">
              {row.staked ? `$${row.staked}` : "-"}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )

  const btnSeeAllPools = (
    <Link href="#/pools" color="inherit" underline="none">
      <Typography
        variant="subtitle2"
        fontWeight="bolder"
        className={styles.textSecondary}
      >
        {t("See all pools")}
      </Typography>
    </Link>
  )

  return (
    <Paper variant="outlined" sx={{ p: 3, px: { md: 5 } }}>
      {isUnsupportChainIdError ? (
        <>
          {!MyPoolsData.length && (
            <Typography variant="subtitle2">
              {t("You haven’t deposit into pools yet")}
            </Typography>
          )}
          <Button
            fullWidth
            kind="secondary"
            className={styles.connectWalletBtn}
            onClick={() => switchNetwork(supportedNetworkName)}
          >
            {t("Switch to")} {supportedNetworkName}
          </Button>
          <Box sx={{ marginTop: "16px" }}>{btnSeeAllPools}</Box>
        </>
      ) : !account ? (
        <>
          <Typography variant="subtitle2">
            {t("We can’t detect any pools, please connect wallet")}
          </Typography>
          <Button
            fullWidth
            kind="secondary"
            className={styles.connectWalletBtn}
            onClick={() => setShowConnectWallet(true)}
          >
            {t("connectWallet")}
          </Button>
          <Box sx={{ marginTop: "16px" }}>{btnSeeAllPools}</Box>
        </>
      ) : !MyPoolsData.length ? (
        <>
          <Typography variant="subtitle2">
            {t("You haven’t deposit into pools yet")}
          </Typography>
          <Box sx={{ marginTop: "16px" }}>{btnSeeAllPools}</Box>
        </>
      ) : (
        <>
          <Grid container justifyContent="space-between">
            <Typography variant="subtitle2">{t("My Pools")}</Typography>
            {btnSeeAllPools}
          </Grid>
          {isDownSm ? MobilePools : PcPools}
        </>
      )}

      <Dialog
        open={showConnectWallet}
        onClose={() => setShowConnectWallet(false)}
      >
        <ConnectWallet onClose={() => setShowConnectWallet(false)} />
      </Dialog>
    </Paper>
  )
}
