import "../styles/global.scss"
import "./NotifyStyle.scss"

import { AppDispatch, AppState } from "../state"
import { BLOCK_TIME, POOLS_MAP } from "../constants"
import React, {
  ReactElement,
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useMemo,
} from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { BrowserView, MobileView } from "react-device-detect"
import { isChainSupportedByNotify, notify } from "../utils/notifyHandler"
import { useDispatch, useSelector } from "react-redux"

import Deposit from "./Deposit"
import PendingSwapsProvider from "../providers/PendingSwapsProvider"
import Pools from "./Pools"
// import RewardsBalancesProvider from "../providers/RewardsBalancesProvider"
import CustomSnackbar from "../components/CustomSnackbar"
import Layout from "../components/Layout"
import MobileUnderDev from "../components/MobileUnderDev"
import Dashboard from "./Dashboard"
import Swap from "./Swap"
import Farms from "./Farms"
import Governance from "./Governance"
import Community from "./Community"
import Vote from "./Vote"
import Faucet from "./Faucet"
import DepositRewards from './DepositRewards'
// import Version from "../components/Version"
import Web3ReactManager from "../components/Web3ReactManager"
import Withdraw from "./Withdraw"
// import WrongNetworkModal from "../components/WrongNetworkModal"
import fetchGasPrices from "../utils/updateGasPrices"
import fetchSwapStats from "../utils/getSwapStats"
import fetchTokenPricesUSD, { fetchSrsPrice } from "../utils/updateTokenPrices"
import { styled } from "@mui/material"
import { useActiveWeb3React } from "../hooks"
// import { useIntercom } from "react-use-intercom"
import usePoller from "../hooks/usePoller"
// import BgImg from "./../assets/bg.png"

// const VestingClaim = lazy(() => import("./VestingClaim"))
const VestingClaim = lazy(() => import("./Vest"))
const VeHolders = lazy(() => import("./VeHolders"))
const Risk = lazy(() => import("./Risk"))
const AdminFee = lazy(() => import("./AdminFee"))

// const AppContainer = styled("div")(({ theme }) => {
//   const darkBackground = `url(${BgImg}), linear-gradient(0deg, #FFCDEC -83.19%, #150847 55.63%)`
//   const lightBackground =
//     "linear-gradient(319deg, rgba(249, 201, 235, 0.34) 0%, #EFF4FF 50%);"
//   return {
//     background: "#150847",
//     backgroundImage:
//       theme.palette.mode === "light" ? lightBackground : darkBackground,
//     minHeight: "100vh",
//     backgroundAttachment: "fixed",
//     backgroundPosition: "center center",
//     backgroundRepeat: "no-repeat",
//     backgroundSize: "cover",
//   }
// })
export default function App(): ReactElement {
  const { chainId } = useActiveWeb3React()
  const { userDarkMode } = useSelector((state: AppState) => state.user)
  // const { boot } = useIntercom()

  useEffect(() => {
    notify?.config({
      networkId: isChainSupportedByNotify(chainId) ? chainId : undefined,
      darkMode: true,
      // darkMode: userDarkMode,
    })
  }, [chainId, userDarkMode])
  const pools = useMemo(() => {
    return Object.values(POOLS_MAP).filter(
      ({ addresses }) => chainId && addresses[chainId],
    )
  }, [chainId])

  // useEffect(() => {
  //   boot()
  // }, [boot])

  return (
    <Suspense fallback={null}>
      <Web3ReactManager>
        <GasAndTokenPrices>
          <PendingSwapsProvider>
            {/* <RewardsBalancesProvider> */}
            <CustomSnackbar>
              <Layout>
                <BrowserView>
                  {/* <AppContainer> */}
                  <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/">
                      <Redirect to="/swap" />
                    </Route>
                    <Route exact path="/swap" component={Swap} />
                    <Route exact path="/faucet" component={Faucet} />
                    <Route exact path="/pools" component={Pools} />
                    <Route exact path="/farms" component={Farms} />
                    <Route exact path="/farms/:poolName" component={Farms} />
                    {/* {pools.map(({ name, route }) => (
                    <Route
                      exact
                      path={`/farms/${route}`}
                      render={(props) => <FarmDetail {...props} poolName={name} />}
                      key={`${name}-farms`}
                    />
                  ))} */}

                    <Route exact path="/risk" component={Risk} />
                    {pools.map(({ name, route }) => (
                      <Route
                        exact
                        path={`/pools/${route}/deposit`}
                        render={(props) => (
                          <Deposit {...props} poolName={name} />
                        )}
                        key={`${name}-deposit`}
                      />
                    ))}
                    {pools.map(({ name, route }) => (
                      <Route
                        exact
                        path={`/pools/${route}/withdraw`}
                        render={(props) => (
                          <Withdraw {...props} poolName={name} />
                        )}
                        key={`${name}-withdraw`}
                      />
                    ))}
                    <Route exact path="/governance" component={Governance} />
                    <Route exact path="/community" component={Community} />
                    <Route exact path="/community/:id" component={Community} />
                    <Route exact path="/vote" component={Vote} />
                    {/* <Redirect from="/pools/:route/:action" to="/pools" /> */}
                    <Route exact path="/risk" component={Risk} />
                    <Route exact path="/deposit-rewards" component={DepositRewards} />
                    <Route
                      exact
                      path="/vesting-claim"
                      component={VestingClaim}
                    />
                    <Route exact path="/leaderboard" component={VeHolders} />
                    <Route exact path="/admin-fee" component={AdminFee} />
                    <Redirect from="" to="/swap" />
                  </Switch>
                  {/* <WrongNetworkModal /> */}
                  {/* <Version /> */}
                  {/* </AppContainer> */}
                </BrowserView>
                <MobileView>
                  <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/">
                      <Redirect to="/swap" />
                    </Route>
                    <Route exact path="/swap" component={Swap} />
                    <Route exact path="/pools" component={Pools} />
                    {pools.map(({ name, route }) => (
                      <Route
                        exact
                        path={`/pools/${route}/deposit`}
                        render={(props) => (
                          <Deposit {...props} poolName={name} />
                        )}
                        key={`${name}-deposit`}
                      />
                    ))}
                    {pools.map(({ name, route }) => (
                      <Route
                        exact
                        path={`/pools/${route}/withdraw`}
                        render={(props) => (
                          <Withdraw {...props} poolName={name} />
                        )}
                        key={`${name}-withdraw`}
                      />
                    ))}

                    <Route exact path="/farms" component={Farms} />
                    <Route exact path="/farms/:poolName" component={Farms} />
                    {/* <Redirect from="/pools/:route/:action" to="/pools" /> */}
                    <Route exact path="/governance" component={Governance} />
                    <Route exact path="/community" component={Community} />
                    <Route exact path="/community/:id" component={Community} />
                    <Route exact path="/vote" component={Vote} />
                    <Route exact path="/leaderboard" component={VeHolders} />
                    <Route
                      exact
                      path="/vesting-claim"
                      component={VestingClaim}
                    />
                    <Route exact path="/admin-fee" component={AdminFee} />
                    <Route from="" component={MobileUnderDev} />
                  </Switch>
                </MobileView>
              </Layout>
            </CustomSnackbar>
            {/* </RewardsBalancesProvider> */}
          </PendingSwapsProvider>
        </GasAndTokenPrices>
      </Web3ReactManager>
    </Suspense>
  )
}

function GasAndTokenPrices({
  children,
}: React.PropsWithChildren<unknown>): ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const { chainId, library } = useActiveWeb3React()

  const fetchAndUpdateGasPrice = useCallback(() => {
    void fetchGasPrices(dispatch)
  }, [dispatch])
  const fetchAndUpdateTokensPrice = useCallback(() => {
    fetchTokenPricesUSD(dispatch, chainId, library)
  }, [dispatch, chainId, library])
  const fetchAndUpdateSwapStats = useCallback(() => {
    void fetchSwapStats(dispatch)
  }, [dispatch])
  const fetchAndUpdateSrsPrice = useCallback(() => {
    fetchSrsPrice(dispatch)
  }, [dispatch])
  usePoller(fetchAndUpdateGasPrice, 5 * 1000)
  usePoller(fetchAndUpdateTokensPrice, BLOCK_TIME * 3)
  usePoller(fetchAndUpdateSwapStats, BLOCK_TIME * 280) // ~ 1hr
  usePoller(fetchAndUpdateSrsPrice, 12e4) // 2 minutes
  return <>{children}</>
}

