import "./ReviewDeposit.scss"

import { Box, Typography, Divider } from "@mui/material"
import React, { ReactElement, useState } from "react"
import {
  commify,
  formatBNToPercentString,
  formatBNToString,
  formatDeadlineToNumber,
  expensiveDecimals,
} from "../utils"
import { BigNumber } from "@ethersproject/bignumber"
import { AppState } from "../state/index"
import Button from "./Button"
import { isCryptoPool } from "../constants"
import { DepositTransaction } from "../interfaces/transactions"
import HighPriceImpactConfirmation from "./HighPriceImpactConfirmation"
import { formatSlippageToString } from "../utils/slippage"
import { isHighPriceImpact } from "../utils/priceImpact"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import usdsLogo from "../assets/icons/usds-logo.png"
import { ReactComponent as CloseSVG } from "./../assets/icons/close.svg"
import { Zero } from "@ethersproject/constants"
import { ArrowForward } from "@mui/icons-material"

interface Props {
  onClose: () => void
  onConfirm: () => void
  transactionData: DepositTransaction
  poolName?: string
}

function ReviewDeposit({
  onClose,
  onConfirm,
  transactionData,
  poolName,
}: Props): ReactElement {
  const { t } = useTranslation()
  const {
    slippageCustom,
    slippageSelected,
    transactionDeadlineSelected,
    transactionDeadlineCustom,
  } = useSelector((state: AppState) => state.user)
  const [hasConfirmedHighPriceImpact, setHasConfirmedHighPriceImpact] =
    useState(false)
  const isHighPriceImpactTxn = isHighPriceImpact(transactionData.priceImpact)
  const deadline = formatDeadlineToNumber(
    transactionDeadlineSelected,
    transactionDeadlineCustom,
  )

  return (
    <div className="reviewDeposit">
      <Typography variant="h4" align="left" gutterBottom={true}>
        {t("Preview Deposit")}
        <span onClick={onClose}>
          <CloseSVG />
        </span>
      </Typography>

      <div className="table">
        <Typography
          variant="subtitle2"
          className="rs_token_subtitle"
          sx={{ fontWeight: 700, marginBottom: "15px" }}
        >
          {t("You will receive")}
        </Typography>
        <div className="tokenList">
          {!isCryptoPool(poolName) && (
            <div
              className="eachToken"
              key={transactionData.to.item.token.symbol}
            >
              {transactionData.priceImpact.gte(0) ? (
                <Typography
                  component="span"
                  variant="body1"
                  color="#3FF5C3"
                  fontSize={14}
                >{`${t("bonus")}: `}</Typography>
              ) : (
                <Typography
                  component="span"
                  variant="body1"
                  color="error"
                  fontSize={14}
                >
                  {t("priceImpact")}
                </Typography>
              )}
              <Typography
                component="span"
                color={transactionData.priceImpact.gte(0) ? "#3FF5C3" : "error"}
                className="value"
                fontSize={14}
              >
                {" "}
                {formatBNToPercentString(transactionData.priceImpact, 18, 4)}
              </Typography>
            </div>
          )}
          <div className="eachToken">
            <div className="token">
              Est. {transactionData.to.item.token.symbol}
            </div>
            <Box display="flex" alignItems="center" className="value">
              {!isCryptoPool(poolName) && (
                <>
                  {commify(
                    (
                      +formatBNToString(
                        BigNumber.from("1000000000000000000").sub(
                          transactionData.priceImpact,
                        ),
                        18,
                      ) * +formatBNToString(transactionData.to.item.amount, 18)
                    ).toString(),
                  )}
                  <ArrowForward
                    sx={{
                      fontSize: "14px",
                      margin: "0 4px 0 4px",
                      transform: "translateY(2px)",
                    }}
                  />
                </>
              )}
              <Typography
                component="span"
                color={isCryptoPool(poolName) ? '#EBEBEB' : transactionData.priceImpact.gte(0) ? "#3FF5C3" : "error"}
                className="value"
                fontWeight="700"
                fontSize={14}
              >
                {commify(
                  formatBNToString(
                    transactionData.to.item.amount,
                    transactionData.to.item.token.decimals,
                  ),
                )}
              </Typography>
            </Box>
          </div>
          <div className="eachToken">
            <div className="token">
              {t("shareOfPool")}
              {/* <span>{transactionData.to.item.token.symbol}</span> */}
            </div>
            <div className="value">
              <span className="value">
                ~{formatBNToPercentString(transactionData.shareOfPool, 18)}
              </span>
            </div>
          </div>
        </div>
        <Box marginY={4}>
          <Divider />
        </Box>
        <Typography
          variant="subtitle2"
          className="rs_token_subtitle"
          sx={{ fontWeight: 700, marginBottom: "15px" }}
        >
          {t("You are depositing")}
        </Typography>
        <div className="tokenList">
          {transactionData.from.items.map(({ token, amount }) => (
            <div className="eachToken" key={token.symbol}>
              <div className="token">
                <img src={token.icon} alt="icon" width={24} height={24} />
                <span>{token.symbol}</span>
              </div>
              <div className="value">
                <span className="value">
                  {commify(
                    formatBNToString(amount, token.decimals),
                    expensiveDecimals(token.symbol),
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* <h4>{t("depositing")}</h4> */}

        {/* <div className="tokenList">
          {transactionData.from.items.map(({ token, amount }) => (
            <div className="eachToken" key={token.symbol}>
              <div className="token">
                <img src={token.icon} alt="icon" width={24} height={24} />
                <span>{token.symbol}</span>
              </div>
              <div className="value">
                <span className="value">
                  {commify(formatBNToString(amount, token.decimals))}
                </span>
              </div>
            </div>
          ))}
          <div className="eachToken">
            <div className="token">
              <b>{t("total")}</b>
            </div>
            <div className="value">
              <b className="value">
                {commify(
                  formatBNToString(transactionData.from.totalAmount, 18),
                )}
              </b>
            </div>
          </div>
        </div>
        <div className="divider" style={{ height: "1px", width: "100%" }}></div>
        
        <div className="divider" style={{ height: "1px", width: "100%" }}></div>
        <div className="depositInfoItem">
          <span className="label">{t("shareOfPool")}</span>
          <span className="value">
            {formatBNToPercentString(transactionData.shareOfPool, 18)}
          </span>
        </div> */}
        {/* TODO: Create a light API to expose the cached BlockNative gas estimates. */}
        {/* {transactionData.txnGasCost?.valueUSD && (
          <div className="depositInfoItem">
            <span className="label">{t("estimatedTxCost")}</span>
            <span className="value">
              {`≈$${commify(
                formatBNToString(transactionData.txnGasCost.valueUSD, 2, 2),
              )}`}
            </span>
          </div>
        )} */}
        {/* <div className="depositInfoItem">
          <span className="label">{t("maxSlippage")}</span>
          <span className="value">
            {formatSlippageToString(slippageSelected, slippageCustom)}%
          </span>
        </div>
        <div className="depositInfoItem">
          <span className="label">{t("deadline")}</span>
          <span className="value">
            {deadline} {t("minutes")}
          </span>
        </div> */}
        {/* <div className="depositInfoItem">
          <span className="label">{t("rates")}</span>
          <div className="rates value">
            {transactionData.from.items.map(
              ({ token, singleTokenPriceUSD }) => (
                <span key={token.symbol}>
                  1 {token.symbol} = $
                  {commify(formatBNToString(singleTokenPriceUSD, 18, 2))}
                </span>
              ),
            )}
            {[transactionData.to.item].map(({ token, singleTokenPriceUSD }) => (
              <span key={token.symbol}>
                1 {token.symbol} = $
                {commify(formatBNToString(singleTokenPriceUSD, 18, 2))}
              </span>
            ))}
          </div>
        </div> */}
      </div>
      {/* {isHighPriceImpactTxn && (
        <HighPriceImpactConfirmation
          checked={hasConfirmedHighPriceImpact}
          onCheck={(): void =>
            setHasConfirmedHighPriceImpact((prevState) => !prevState)
          }
        />
      )} */}
      <Box marginTop={4}>
        <Button
          className="deposit-btn"
          kind="primary"
          fullWidth={true}
          onClick={onConfirm}
          size="large"
        >
          {t("confirmDeposit")}
        </Button>
      </Box>

      {/* <div className="bottom">
        <p>{t("estimatedOutput")}</p>
        <div className="buttonWrapper">
          <Button
            onClick={onConfirm}
            kind="primary"
            disabled={isHighPriceImpactTxn && !hasConfirmedHighPriceImpact}
          >
            {t("confirmDeposit")}
          </Button>
          <Button onClick={onClose} kind="cancel">
            {t("cancel")}
          </Button>
        </div>
      </div> */}
    </div>
  )
}

export default ReviewDeposit
