import {
  ChainId,
  buildAddresses,
  Token,
  Pool,
  PoolTypes,
  buildPids,
} from "./common"
import usdsLogo from "../../assets/icons/usds-logo.png"
import daiLogo from "../../assets/icons/dai.svg"
import usdcLogo from "../../assets/icons/usdc.svg"
import usdtLogo from "../../assets/icons/usdt.svg"
import busdLogo from "../../assets/icons/busd.svg"

export const STABLECOIN_POOL_NAME = "Celer 4Pool"

export const STABLECOIN_SWAP_TOKEN_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x76204f8CFE8B95191A3d1CfA59E267EA65e06FAC",
  [ChainId.ROPSTEN]: "0x4AA2a97E0A06cbaf9340CA2Ead20b581492D8F83",
  [ChainId.HARDHAT]: "0x6D1c89F08bbB35d80B6E6b6d58D2bEFE021eFE8d",
  [ChainId.BSCTEST]: "0x89460BA97B14c37DCD3B26339153eD02b54E52EA",
  [ChainId.SHIBUYA]: "0xc3f00468E8cac805a98b745b5aDBC3bCc8f16F5e",
  [ChainId.ASTAR]: "0xB6Df5baFdcDCE7AEb49af6172143E1942999ef14",
})
export const STABLECOIN_SWAP_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x3911F80530595fBd01Ab1516Ab61255d75AEb066",
  [ChainId.ROPSTEN]: "0x64436bC8E7a18fbD7b4D8574f1248DD08Ba19c2E",
  [ChainId.HARDHAT]: "0x98A0Bc3f9FdAD482CB2e40dE1291f8b0A6FE1860",
  [ChainId.BSCTEST]: "0xdBF7508b913e5E3BAB4ea477e58d80ee7E17d132",
  [ChainId.SHIBUYA]: "0x98d3a1A7BC448816ef65cb2f156Fe028FbE1B082",
  [ChainId.ASTAR]: "0x417E9d065ee22DFB7CC6C63C403600E27627F333",
})
export const STABLECOIN_FARM_ADDRESSES = buildAddresses({
  [ChainId.BSCTEST]: "0x22229672c49940f874B8170a374aB8a640265A10",
  [ChainId.SHIBUYA]: "0xE4902e653743DeE10A62e7bd7cDd8bAbdAc1F936",
  [ChainId.ASTAR]: "0xCfd15008Df89D961611071BfC36e220204E9A3a8",
})
export const STABLECOIN_SWAP_TOKEN = new Token(
  STABLECOIN_SWAP_TOKEN_CONTRACT_ADDRESSES,
  18,
  "4SRS",
  "binance-usd",
  "siriusLP",
  usdsLogo,
  false,
  true,
)

const DAI_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
  [ChainId.ROPSTEN]: "0xE36BD79b9087d947bA2CF6B703043Af5e3A5854a",
  [ChainId.HARDHAT]: "0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0",
  [ChainId.OPTIMISM]: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
  [ChainId.FANTOM]: "0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e",
  [ChainId.BSCTEST]: "0xe68dD81895A3690a540de27eA0B9B88dc706b3C2",
  [ChainId.SHIBUYA]: "0xf62a23C47c87806420Bf1c4FC5aCA32dc1Eba3df",
  [ChainId.ASTAR]: "0x6De33698e9e9b787e09d3Bd7771ef63557E148bb",
})
export const DAI = new Token(
  DAI_CONTRACT_ADDRESSES,
  18,
  "ceDAI",
  "dai",
  "celer bridged DAI",
  daiLogo,
)

const USDC_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
  [ChainId.ROPSTEN]: "0x7b99D69510c6c6a87bf293d784B9b07d6B3CD4c9",
  [ChainId.HARDHAT]: "0x9A676e781A523b5d0C0e43731313A708CB607508",
  [ChainId.ARBITRUM]: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8",
  [ChainId.OPTIMISM]: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
  [ChainId.FANTOM]: "0x04068da6c83afcfa0e13ba15a6696662335d5b75",
  [ChainId.BSCTEST]: "0x8D62108d892e917b61b7de854E05d29a2B81B149",
  [ChainId.SHIBUYA]: "0x6dD342c075e2e031d58493813Df81492F1A2E7cf",
  [ChainId.ASTAR]: "0x6a2d262D56735DbA19Dd70682B39F6bE9a931D98",
})
export const USDC = new Token(
  USDC_CONTRACT_ADDRESSES,
  6,
  "ceUSDC",
  "usd-coin",
  "celer bridged USDC",
  usdcLogo,
)

const USDT_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.MAINNET]: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  [ChainId.ROPSTEN]: "0x9F519083A069Cee2585cB4931C77C6EA21c3517E",
  [ChainId.HARDHAT]: "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
  [ChainId.ARBITRUM]: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9",
  [ChainId.OPTIMISM]: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
  [ChainId.BSCTEST]: "0xD9cD7862E5e2efEcD7B2fad5E0F195dEB98f2a70",
  [ChainId.SHIBUYA]: "0x9e8072A188a4654a2247B90F16E41772158c8e6f",
  [ChainId.ASTAR]: "0x3795C36e7D12A8c252A20C5a7B455f7c57b60283",
})
export const USDT = new Token(
  USDT_CONTRACT_ADDRESSES,
  6,
  "ceUSDT",
  "tether",
  "celer bridged USDT",
  usdtLogo,
)

const BUSD_CONTRACT_ADDRESSES = buildAddresses({
  [ChainId.BSCTEST]: "0xD5c66813bE022F9d3dF25b03b999579bD34305Ef",
  [ChainId.SHIBUYA]: "0x765E4061584E1aA114c606A543b21911F999230A",
  [ChainId.ASTAR]: "0x4Bf769b05E832FCdc9053fFFBC78Ca889aCb5E1E",
})
export const BUSD = new Token(
  BUSD_CONTRACT_ADDRESSES,
  18,
  "ceBUSD",
  "binance-usd",
  "celer bridged BUSD",
  busdLogo,
)

export const STABLECOIN_POOL_TOKENS = [DAI, USDC, USDT, BUSD]

export const STABLECOIN_POOL_INFO: Pool = {
  name: STABLECOIN_POOL_NAME,
  addresses: STABLECOIN_SWAP_ADDRESSES,
  farmAddresses: STABLECOIN_FARM_ADDRESSES,
  lpToken: STABLECOIN_SWAP_TOKEN,
  poolTokens: STABLECOIN_POOL_TOKENS,
  isSynthetic: false,
  type: PoolTypes.USD,
  route: "usd",
  show: true,
  rewardPids: buildPids({}),
  unsort: true,
}
