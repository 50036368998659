import React, { ReactElement, useEffect, useState } from "react"
import { BigNumber } from "@ethersproject/bignumber"
import CountUp from "react-countup"
import { bnToShortNumAndSuffix } from "../utils"

interface Props {
  end: BigNumber
  precision?: number // default: 18
  prefix?: string
  suffix?: string
  redraw?: boolean
  duration?: number
  decimal?: string
  decimals?: number
}

export default function CountUpBN(props: Props): ReactElement {
  const { end, precision, suffix } = props
  const [num, setNum] = useState({ value: 0, suffix: "" })

  useEffect(() => {
    setNum(bnToShortNumAndSuffix(end, precision ?? 18))
  }, [end, precision])

  return (
    <CountUp
      {...props}
      end={num.value}
      suffix={`${num.suffix || ""}${suffix || ""}`}
    />
  )
}
